import React from 'react';
import {Container, Row, Col, Table} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import CoreReceiptVoucher from './../../../../core/components/salesVouchers/CoreReceiptVoucher';
import AccountAddress from '../components/AccountAddress';

class Receipt extends CoreReceiptVoucher {	
	render(){
		const { vouchers, tallyCompany, tallyVoucher, amount, voucherReference, total} = this.state;

		return (
			<React.Fragment> 
				<div className="main pb-5">
					<Container className="">
						<div className="border p-3">								
							<Row className="mb-4">
								<Col md={{ span: 6, offset: 3 }} className="text-center">
									<AccountAddress tallyCompany={tallyCompany} tallyVoucher={tallyVoucher}/>
								</Col>
							</Row>
							<hr/>
							<Row className="mt-4">
								<Col xs={12}>
									<h4 className="black bold text-center mb-3">Receipt Voucher</h4>
								</Col>
								<Col xs={6}>
									<p>No. : <span className="bold black">1</span></p>
								</Col>
								<Col xs={6} className="text-right">
									<p>Dated : <span className="bold black">{typeof tallyVoucher.date !='undefined' && tallyVoucher.date !='' ? tallyVoucher.date:''}</span></p>
								</Col>
								<Col xs={12}>
									<p>Through : <span className="bold black">{typeof tallyVoucher.company_bank_name != 'undefined' && tallyVoucher.company_bank_name !='' ? tallyVoucher.company_bank_name : ''}</span></p>
								</Col>								
							</Row>
							<Row>
								<Col sm={12}>
									<div className="table-responsive">
										<Table className="receipt_table" width="100%">
											<thead>
												<tr>
													<th className="black" width="85%">Particulars</th>
													<th className="text-right black" width="15%">Amount</th>
												</tr>
											</thead>
											<tbody>																				
												<tr>
													<td>
														<div>
															<p>Account : <span className="bold black mb-3">{typeof tallyVoucher.party_name != 'undefined' && 
																						tallyVoucher.party_name != '' ? tallyVoucher.party_name:'-'}
																						</span>
															</p>
															{voucherReference.length > 0 &&  voucherReference.map((reference)=>(
																<div className="d-flex pl-0 pl-sm-5 account_list">
																	<div className="mr-3 ref_name">
																		{/* <p className="mb-0">Agst Ref</p> */}
																	</div>
																	<div className="mr-3 ref_id">
																		<p className="bold black mb-0">{reference.reference_number}</p>
																	</div>
																	<div className="text-right mr-3 ref_amount">
																		<p className="bold black mb-0">
																			{Math.abs(reference.debit_amount)>0?
																			(parseFloat(Math.abs(reference.debit_amount))).toFixed(2):
																			(parseFloat(Math.abs(reference.credit_amount))).toFixed(2)}
																		</p>
																	</div>
																	<div className="mr-3 ref_code">
																		<p className="bold black mb-0">
																		{Math.abs(reference.debit_amount)>0?
																			'Dr':'Cr'
																		}
																		</p>
																	</div>
																</div>
															))}
															{/*  	 */}
														</div>													
													</td>
													<td className="bold black text-right">
														<p className="mb-3">&nbsp;</p>
														<p className="bold black mb-0 mt-0">{total}</p>
													</td>
												</tr>											
											</tbody>
											<tfoot>
												<tr>
													<td>
														<p className="mb-0">Amount (in words) :</p>
														<p className="bold">Indian Rupees {amount} Only</p>
													</td>
													<td className="text-right text-nowrap">
														<p className="bold mb-0"><i className="fal fa-rupee-sign" style={{fontWeight:'700'}}></i> {total}</p>
													</td>
												</tr>
											</tfoot>
										</Table>
									</div>
								</Col>
							</Row>								
						</div>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

export default Receipt;
