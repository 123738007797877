const initialState = {styles: {}}

const styles = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_STYLES':
    	return { styles: action.payload}; 
  }
  return state
}

export default styles;