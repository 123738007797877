import React from 'react';
import {Container, Row, Col, Button, ButtonGroup, Table} from 'react-bootstrap';

import GeomatryImg from '../../../assets/images/banners/geomatry.png';
import { Document, Page } from "react-pdf";
import { getGemetory } from '../../../../core/helpers/ServicesHelper';
class WhyToBuy extends React.Component { 
  constructor(props) {
    super(props);
  }
  render() { 
    return (
      <Container>
        <Row>
          <Col className="center"  align="left" lg={12}>
            <ul>
              {
                this.props.whyToBuy.map((value, index) => (
                  <li key={index}><h6>{value.name}</h6></li>
                ))
              }
            </ul>
          </Col>
        </Row>
      </Container>
    );
  };
};

export default WhyToBuy;