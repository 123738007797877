import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";
import fr from '../../core/utils/locales/fr';
import en from '../../core/utils/locales/en';

const options = {
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  
  // lng: 'en',

  resources: {
    fr: {
      common: fr,
    },
    en: {
      common: en,
    },
  },

  fallbackLng: 'en',

  debug: true,

  keySeparator: false,
  
  ns: ['common'],

  defaultNS: 'common',

  react: {
    wait: false,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default'
  },

  interpolation: {
    escapeValue: false
  }
  
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(options)  
  // .changeLanguage('en', (err, t) => {
  //   if (err) return console.log('something went wrong loading', err);
  // });

export default i18n;