import React from 'react';
import {Container, Row, Col, Carousel, Button, Image} from 'react-bootstrap';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import {Link} from 'react-router-dom';
import CoreHome from '../../../../core/components/home/CoreHome';
import news2 from '../../../assets/images/avanti/news2.jpg';
import news3 from '../../../assets/images/avanti/news3.jpg';
import news4 from '../../../assets/images/avanti/news4.jpg';


 
 
class AvantiNewsLetter extends CoreHome {
    render(){
      return(
          <React.Fragment>
						
              {/* <div className='d-flex flex justify-content-center mt-5 p-3' style={{backgroundColor:"black",color:'white'}}>
                  <h2 style={{fontWeight:"600" }}>NEWSROOM</h2>
              </div>  
               */}
              {/* <div className='d-flex flex justify-content-center mt-1 '>
                  <h6 className='' style={{color:'black',fontSize:"20px",fontWeight:"500",textAlign:"center" }}>For any press and media queries, write to us at cs@sportnetwork.in</h6>
              </div> */}

<div className='d-flex flex justify-content-center flex-wrap p-2 ' style={{margin : "20px"}}>
            
            <a target="_blank" style={{textDecoration:"none"}} href="https://auto.economictimes.indiatimes.com/news/two-wheelers/nz-based-avanti-enters-indian-bicycle-market-launches-avanti-giro-fm1-along-with-scott-sports/89619897">
              <div className="card NewsLetterCard " style={{}}>
                <img className="card-img-top img-fluid bg p-1" style={{height:"18rem"}} src={news4} alt="Card image cap"/>
                <div className="card-body">
                    <div style={{margin:"0%",padding:"0%"}}>
                    <h6 className="" style={{color: "black", fontFamily:"ubuntumedium",}}><b>NZ-based Avanti enters Indian bicycle market</b></h6>

                    <div class="card-footer">
                      <p className="card-text" style={{textAlign:"center" }}><small class="text-muted">By: Economic Times Auto</small></p>
                      <p className="card-text" style={{textAlign:"center" }}><small class="text-muted">Published on: February 16, 2022</small></p>      
                    </div>
                    </div>
                </div>
              </div> 
            </a>         

            <a target="_blank" style={{textDecoration:"none"}} href="https://www.financialexpress.com/express-mobility/scott-sports-india-launches-avanti-giro-fm1-in-india/2435759/">
              <div className="card NewsLetterCard" style={{ }}>
                <img className="card-img-top img-fluid bg p-1" style={{height:"16.8rem"}} src={news2} alt="Card image cap"/>
                <div className="card-body">
                <h6 className="" style={{color: "black", fontFamily:"ubuntumedium"}}><b>Scott Sports India launches Avanti Giro FM1 in India</b></h6>

                     <div class="card-footer">

                      <p className="card-text" style={{textAlign:"center" }}><small class="text-muted">By: Financial Express</small></p>
                      <p className="card-text" style={{textAlign:"center" }}><small class="text-muted">Published on: February 17, 2022</small></p>      
                    </div>

                </div>
                </div> 
            </a>         

            <a target="_blank" style={{textDecoration:"none"}} href="https://timesofindia.indiatimes.com/nz-based-avanti-enters-indian-bicycle-market-launches-avanti-giro-fm1-along-with-scott-sports/articleshow/89619241.cms">
              <div className="card NewsLetterCard" style={{ }}>
                <img className="card-img-top img-fluid bg p-1" style={{height:"16.6rem"}} src={news3} alt="Card image cap"/>
                <div className="card-body">
                <h6 className="" style={{color: "black", fontFamily:"ubuntumedium"}}><b> NZ-based Avanti enters Indian bicycle market; launches 'Avanti Giro FM1' with Scott Sports</b></h6>
                   
                  <div class="card-footer">
                      <p className="card-text" style={{textAlign:"center" }}><small class="text-muted">By: Times Of India</small></p>
                      <p className="card-text" style={{textAlign:"center" }}><small class="text-muted">Published on: February 16, 2022</small></p>      
                    </div>
                </div>
                </div> 
            </a>         

            <a target="_blank" style={{textDecoration:"none"}} href="https://www.thehindubusinessline.com/companies/scott-sports-launches-new-zealand-bicycle-brand-avanti-in-india/article65055846.ece">
              <div className="card NewsLetterCard" style={{}}>
                <img className="card-img-top img-fluid bg p-1" style={{height:"18rem"}} src={news4} alt="Card image cap"/>
                <div className="card-body">
                <h6 className="" style={{color: "black", fontFamily:"ubuntumedium"}}><b> Scott Sports launches New Zealand bicycle brand Avanti in India</b></h6>

                    <div class="card-footer">

                      <p className="card-text" style={{textAlign:"center" }} ><small class="text-muted">By: The Hindu Business Line</small></p>
                      <p className="card-text" style={{textAlign:"center" }}><small class="text-muted">Published on: February 16, 2022</small></p>      
                    </div>
                </div>
                </div> 
            </a>         

            <a target="_blank" style={{textDecoration:"none"}} href="https://www.freepressjournal.in/business/bicycle-firm-avanti-enters-indian-market-launches-avanti-giro-fm1-along-with-scott-sports">
              <div className="card NewsLetterCard" style={{}}>
                <img className="card-img-top img-fluid bg p-1" style={{height:"18rem"}} src={news2} alt="Card image cap"/>
                <div className="card-body">
                <h6 className="" style={{color: "black", fontFamily:"ubuntumedium"}}><b> Scott Sports launches New Zealand bicycle brand Avanti in India</b></h6>
                
                <div class="card-footer">


                      <p className="card-text" style={{textAlign:"center" }}><small class="text-muted">By: Free Press Journal</small></p>
                      <p className="card-text" style={{textAlign:"center" }}><small class="text-muted">Published on: February 16, 202h6</small></p>      
                    </div>
                </div>
                </div> 
            </a>     

            
            
                

</div>


        </React.Fragment>
       )
    }
 }

 export default (AvantiNewsLetter);
