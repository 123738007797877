import React from 'react';
import {Container, Row, Col, Carousel, Button, Card} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";

import checkedImg from '../../../assets/images/icons/checked.png';
import CoreHome from '../../../../core/components/dealer/CoreHome';
import Loader from '../../../components/Loader';
import SideLoader from '../../../components/SideLoader';
import MetaTags from '../../../components/MetaTags';
import DueAmountTable from '../controllers/DueAmountTable';
import download from "../../../assets/images/avanti/download.svg";

class Home extends CoreHome { 
  render() {
    const { dueDetails } = this.state;
    console.log(dueDetails)
    const { t } = this.props;
    return (
      <React.Fragment> 
        <MetaTags {...this.props}/>
        <div className="main">
          <div className="main_section">
            <Container>
              {this.state.loader == false ? (
                <React.Fragment>


                  {(this.state.ledgerLoading == false)?
                    <Row className="mt-3 pd7 mb-5">
                      <Col sm={4} className="mb-3 mb-sm-0">
                        <Card className="h-100" style={{backgroundColor: '#fbe9e7'}}>
                          <Card.Body className="text-center">
                            <div className="">
                              <h6 className="bold mb-2 black">{t('remainingCreditBal')}</h6>
                              <h4 className="mb-0 black"><i className="fal fa-rupee-sign"></i>{parseFloat(this.state.totalCreditAvailable).toFixed(2)}</h4>
                            </div>
                            <hr/>                           
                            <div>
                              <h6 className="bold mb-2 black">{t('totalCreditAmount')}</h6>
                              <h4 className="mb-0 black"><i className="fal fa-rupee-sign"></i>{(this.state.totalCredit != null)?parseFloat(this.state.totalCredit).toFixed(2):'0.00'}</h4>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col sm={8}>
                        <DueAmountTable dueDetails={dueDetails}
                                        totalCredit={this.state.totalCredit}/>
                      </Col>
                    </Row>:<SideLoader width="100%" height="200"/> }
                  <hr/>
                  
                  <Link to='/avantipdf'>
                  <div class="alert alert-info " style={{width:"100%",textAlign:"center",color:"black",fontFamily:"ubuntumedium"}} role="alert">
                  Training and Manual <img className='mb-2 ' src={download}/>
                  </div>
                  </Link>
                  
                  {this.props.dealerCatalogs.length > 0 && (
                  <Row className="mt-5">
                      <Col sm={12}>
                        {this.props.dealerCatalogs.map((catalog, index) => (
                          catalog.text_align == 'right' ? 
                            <Row  key={index} className="sports_ride d-flex align-items-center justify-content-center">
                              <Col sm={5} className="mb-3 mb-sm-5">
                                <div className="catalog_img">
                                  <img src={this.props.settings.image_url+"dealer_catalogs/original/"+catalog.image_name} className="img-fluid float-sm-left" />
                                </div>
                              </Col> 
                              <Col sm={7} className="mb-5">
                                <div className="catalog_text">
                                  <h2 className="bold black">{catalog.title}</h2>
                                  <hr/>
                                  <p className="mb-0 black">{catalog.description}</p>
                                  <Link to={catalog.url} className="mt-3 btn btn-primary btn-sm" style={this.props.styles.primary_btn}>View</Link>
                                </div>
                              </Col>   
                            </Row> : 
                            
                            <Row className="sports_ride flex-column-reverse flex-sm-row d-flex align-items-center justify-content-center">      
                              <Col sm={7} className="mb-5">
                                <div className="catalog_text">
                                  <h2 className="bold black">{catalog.title}</h2>
                                  <hr/>
                                  <p className="mb-0 black">{catalog.description}</p>
                                  <Link to={catalog.url} className="mt-3 btn btn-primary btn-sm" style={this.props.styles.primary_btn}>View</Link>
                                </div>
                              </Col>
                              <Col sm={5} className="mb-3 mb-sm-5">
                                <div>
                                  <img src={this.props.settings.image_url+"dealer_catalogs/original/"+catalog.image_name} className="img-fluid float-sm-right" />
                                </div>
                              </Col>    
                            </Row>                      
                          )
                        )}           
                      </Col>                   
                  </Row> 
                  )
                  }
                  <hr/>

              


                  <Row id="category_sec" className="mt-5">
                    {this.props.dealerCategories.length > 0 && (
                      <Col sm={12}>
                        <h2 className="sec_heading text-center">{t('shopByCategory')}</h2>
                        <Row className="sports_ride">
                          {this.props.dealerCategories.map((category, index) => (
                            <Col key={index} xs={6} className="item mt15 mb15">
                              <Link to={'/pl/'+category.category_slug}>
                                <img src={this.props.settings.image_url+"categories/original/"+category.image_name} className="img-fluid d-none d-sm-block" />
                                <div className="img_btn text-center">
                                  <span>{category.category_name}</span>
                                </div>
                              </Link>
                            </Col>
                          ))}            
                        </Row>
                      </Col>
                    )
                    }
                  </Row>
                  <hr/>
                  <Row id="brand_sec" className="mt-5">
                    {this.props.dealerBrands.length > 0 && (
                      <Col sm={12}>
                        <h2 className="sec_heading text-center">{t('shopByBrand')}</h2>
                        <div className="text-center">
                          <ul className="list-unstyled brand_list first">
                            {this.props.dealerBrands.map((brand, index) => (
                              <li key={index}>
                                <Link to={"/bpl/"+brand.brand_slug}>
                                  <img src={this.props.settings.image_url+"brands/original/"+brand.image_name} className="img-fluid" />
                                </Link>
                              </li>  
                            ))}               
                          </ul>
                        </div>              
                      </Col>
                      
                    )}
                  </Row>
                </React.Fragment>
              ) : 
              <Loader loaderState={this.state.loader}/>
              }
            </Container>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings,
    user: state.users.user,
    dealerCategories: state.dealers.categories,
    dealerBrands: state.dealers.brands,
    dealerCatalogs: state.dealers.catalogs,
    styles: state.styles.styles,
  }
}

export default withTranslation()(connect(mapStateToProps)(Home));
