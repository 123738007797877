const initialState = {filters: [],variationFilters:[] }

const filters = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_FILTERS':
      return { filters: action.payload,variationFilters:state.variationFilters}; 
    case 'SET_VARIATION_FILTERS':
      return { variationFilters: action.payload,filters:state.filters}; 
  }
  return state
}

export default filters;