import React from 'react';
import {Container, Row, Col, Table, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import helmet from '../../../assets/images/faq/helmet.png';
import helmet2 from '../../../assets/images/faq/helmet2.png';
import chinStrap from '../../../assets/images/faq/chin_strap.png';
import lensFrame from '../../../assets/images/faq/lens_frame.png';
import lensClip from '../../../assets/images/faq/lens_clip.png';
import grid from '../../../assets/images/faq/grid.png';
import film from '../../../assets/images/faq/film.png';
import noseGuard from '../../../assets/images/faq/nose_guard.png';
import noseGuardTwo from '../../../assets/images/faq/nose_guard2.png';
import noseGuardThree from '../../../assets/images/faq/nose_guard3.png';
import noseGuardFour from '../../../assets/images/faq/nose_guard4.png';
import noseGuardPosition from '../../../assets/images/faq/nose_guardPosition.png';
import elbowCap from '../../../assets/images/faq/elbow_cap.png';

class MotosportsFaq extends React.Component {	
	render(){
		return (
			<div className="tab-pane fade" id="motosports_faq" role="tabpanel" aria-labelledby="motosports_faq_tab">			
				<h5 className="black bold mt-5">Motosports</h5>
				<div id="MotoAccordion" className="mt-3">
					<div className="card mb-3">
			      <div className="card-header">
			        <a className="card-link" data-toggle="collapse" href="#collapseOne">
			          <h6 className="black bold mb-0">Helmets:</h6>
			        </a>			       
			      </div>
			      <div id="collapseOne" className="collapse" data-parent="#MotoAccordion">
			        <div className="card-body">
								<ol className="pl-3">
									<li className="pb-2">
										<h6 className="bold black">How should I dispose my old helmet?</h6>
										<p>A used helmet, even if it has not been damaged, should be always be destroyed. Do not sell or give away old helmets as the protective foam contours to the shape of the original rider’s head and will not provide a new user with full protection. Helmets are designed to absorb only ONE impact. After your helmet has endured any impact, the absorption liner portion of your helmet will have dispersed the energy from the impact, thus rendering the liner compacted and therefore compromised. Even if there is NO VISIBLE damage to the shell, the helmet’s usefulness is expired after one impact.</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">Can I personally modify my helmet?</h6>
										<p>It is extremely dangerous to modify your helmet by drilling holes, cutting the shell and/or absorption liner. Any such actions will weaken the helmet and may cause serious injury in the event of an accident. Removing any parts such as, vents, liners, cheek pads, mouth guards, rubber trimming, etc. before use will also disrupt the intended design of the helmet and may expose you to severe injury or death in the event of an accident. Do not paint your helmet as the chemicals of the paint may adversely affect the materials used in your helmet, thereby weakening it.</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">Can I Use my helmet after an impact?</h6>
										<p>Helmets are designed to absorb only ONE impact. After your helmet has endured any impact, the absorption liner portion of your helmet will have dispersed the energy from the impact, thus rendering the liner compacted and therefore compromised. Even if there is NO VISIBLE damage to the shell, the helmet’s usefulness is expired after one impact.</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">Are SCOTT Goggles compatible with all helmets?</h6>
										<p>SCOTT goggles are engineered to engage flawlessly with a variety of helmets.</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">How to wear helmet correctly with proper fitting?</h6>
										<p>While it is not possible for any helmet to protect the user against all foreseeable impacts and accidents, the proper use of this helmet will provide a far greater level of protection in the event of an accident than if no helmet was worn. For the helmet to function properly and to help reduce the risk of permanent injury or death, it is imperative that the helmet fits securely and firmly on the head and that all retention straps are properly fastened. When the retention strap is properly fastened it should not be possible, in most instances, for the helmet to be removed from the head by either rolling the helmet towards the rear or front. The helmet must NOT be too low or high on the head because it will interfere with your line of sight. Eyes should be central to the eye aperture.</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">How do I choose the right helmet size?</h6>
										<p>IT IS IMPERATIVE THAT THE FIT OF THE HELMET BE SNUG FOR IT TO PERFORM PROPERLY.</p>
										<ul>
											<li>Grasp the chinstraps (see diagram 1) and expand the helmet opening.</li>
											<li>Slide your head into the helmet. If the helmet does not fit snugly or if the helmet feels loose in any way, you should try a smaller size.</li>
											<li>To check for proper fitting, place the helmet on your head.</li>
											<li>Measure your head size by passing a tape measure around your head approximately 2 cm above the eyebrow line</li>
											<li>Make sure the helmet lining fits snugly around your head and that the cheek pads are pressed close onto your cheeks.</li>
											<li>There should be no space between your brow and the lining.</li>
											<li>Take hold of the helmet on each side and try to move the helmet up and down as well as side to side (see diagram 2).</li>
											<li>You should feel the skin on your face and head being pulled along with the helmet.</li>
											<li>If the helmet is able to move freely without rubbing your skin, the helmet is too large, and you need a smaller size.</li>
										</ul>
										<Row className="mt-4">
											<Col lg={6}>
												<img src={helmet} />
											</Col>
											<Col lg={6}>
												<img src={helmet2} />
											</Col>
										</Row>
									</li>
									<li className="pb-2">
										<h6 className="bold black">How do I fasten the chin strap on my helmet?</h6>
										<ul>
											<li>Pass the strap through both rings starting with the inside ring.</li>
											<li>Separate the rings and pull the free end of strap back through the inside ring only.</li>
											<li>Secure the free end of the snap using the red connectors.</li>
											<li>Proper tightening of the chinstrap is critical to the functionality of this helmet.</li>
											<li>If you are able to pull down on the fastened chinstrap from under your chin and create slack, it is not properly fastened through the D-Rings.</li>
											<li>Follow the steps below to ensure proper fastening.</li>											
										</ul>
										<Row className="mt-4">
											<Col lg={12}>
												<img src={chinStrap} className="img-fluid" />
											</Col>											
										</Row>
									</li>
									<li className="pb-2">
										<h6 className="bold black">How to remove and install visor on helmet?</h6>
										<ul>
											<li>Remove the two front screws from the visor.</li>
											<li>Press down on the visor and give it a firm push toward the front.</li>
											<li>To replace the visor, reverse the mentioned process.</li>
											<li>Avoid damage to the visor by over tightening the screws.</li>
										</ul>										
									</li>
									<li className="pb-2">
										<h6 className="bold black">350 EVO, 350 PRO, 350 KIDS HELMETS</h6>
										<ul>
											<li>Remove the two side screws and washers from the visor.</li>
											<li>Remove the central screw located underneath the visor.</li>
											<li>To replace the visor, reverse the mentioned process.</li>
											<li>Avoid damage to the visor by over tightening the screws.</li>
											<li>Use the central screw to lock the visor in your favoured position.</li>
										</ul>										
									</li>
									<li className="pb-2">
										<h6 className="bold black">How to take care of your helmet?</h6>
										<p>It is essential that the goggles worn with all off-road helmets are always clean and the lens is replaced regularly. Before purchasing your goggles, ensure the goggles fit your helmet correctly and that they do not interfere with your vision and allow the maximum peripheral vision.</p>								
									</li>
									<li className="pb-2">
										<h6 className="bold black">How to remove inner liner and cheek pads?</h6>
										<ul>
											<li>To remove the cheek pads, gently pull the padding off from the three snap buttons holding it in place and pull out the retaining flap from underneath the lower helmet beading.</li>
											<li>Repeat on the other side.</li>
											<li>To remove the liner, unsnap the rear portion of the inner located towards the rear of the helmet. Gently pull out the liner without forcing the liner out from its front clips located at the top portion of the visor opening.</li>
											<li>Reverse the steps to reinstall liner.</li>
										</ul>										
									</li>
									<li className="pb-2">
										<h6 className="bold black">How should I clean my helmet?</h6>
										<ul>
											<li>Do not use any solvents, hot water, glass cleaners, or gasoline to clean your helmet.</li>
											<li>These materials can cause severe damage to the helmet and weaken its structural integrity.</li>
											<li>Any such damage to the helmet can prevent the helmet from performing properly in the event of an accident which could result in serious injury or death.</li>
											<li>To clean your helmet, it is best to use lukewarm water with a damp soft cloth.</li>
											<li>Rinse off and dry using a soft cloth.</li>
											<li>Clean your visor with warm water and rinse well.</li>
											<li>Do not use any cleaners or solvents to clean your visor as they may damage the coating of the visor. Majority of the Helmets come with a removable liner to make the cleaning and upkeep of your helmet interior easy and hassle free.</li>
											<li>Remove the liner and cheek pads and hand wash with normal detergent in cold water.</li>
											<li>Let them air dry.</li>
											<li>Do not use a washing machine or tumble dry.</li>
											<li>Hand wash and air dry only.</li>
											<li>For more cheek pad and liner sizing, to maximize comfort. please check with your local dealer for liner/cheek pad sizing options.</li>
										</ul>										
									</li>
									<li className="pb-2">
										<h6 className="bold black">What’s the best way to store my helmet?</h6>
										<ul>
											<li>Let your helmet air dry after each use and then store it in a cool dry place.</li>
											<li>Excessive heat can damage your helmet.</li>
											<li>In direct sunlight, dark equipment cases or trunks of automobiles can become so hot as to cause heat damage.</li>
											<li>Heat damaged helmets are recognizable by uneven or blistered surfaces.</li>
											<li>Exposure to sunlight may cause colour fading.</li>
										</ul>										
									</li>
								</ol>
							</div>
						</div>						
					</div>
					<div className="card mb-3">
			      <div className="card-header">
			        <a className="card-link" data-toggle="collapse" href="#collapseTwo">
			          <h6 className="black bold mb-0">Goggles:</h6>
			        </a>			        
			      </div>
			      <div id="collapseTwo" className="collapse" data-parent="#MotoAccordion">
			        <div className="card-body">
								<ol className="pl-3">
									<li className="pb-2">
										<h6 className="bold black">Do SCOTT Goggles provide UV protection?</h6>
										<p>All SCOTT lenses are optically engineered to screen out 100% of harmful UVA /UVB rays.</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">What Lens is best for me?</h6>
										<p>Lens tints are personal and the best choice for you may vary depending on the usage and the conditions.</p>
										<p>IMPORTANT: Only filter class 0 lenses are suitable for use in conditions of darkness or poor visibility. You will find the filter class on the midtop of the lens. The purpose of the motorsport goggles is to protect you from wind, dust, humidity and harmful rays of the sun.</p>
										<p>NB : Only use original accessories, as only they will ensure an exact fit and safety.</p>
										<Table bordered size="sm" className="mb-3">
											<thead>
												<tr>
													<th>Scott Category</th>
													<th colspan="2">Transmission</th>
													<th></th>
												</tr>
												<tr>
													<th></th>
													<th>From</th>
													<th>To</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>Category 0</td>
													<td>80%</td>
													<td>100%</td>
													<td>clear or lightly tinted</td>
												</tr>
												<tr>
													<td>Category 1</td>
													<td>43%</td>
													<td>80%</td>
													<td>Weak sunlight</td>
												</tr>
												<tr>
													<td>Category 2</td>
													<td>18%</td>
													<td>43%</td>
													<td>Average sunlight</td>
												</tr>
											</tbody>
										</Table>
										<Table bordered size="sm">
											<thead>
												<tr>
													<th></th>
													<th>Filter Class</th>
													<th>Light Transmission</th>
													<th>Ref</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td colspan="4" className="bold">Light Sensetive Lenses</td>
												</tr>
												<tr>
													<td>Light Sensetive Grey</td>
													<td>S0-S2</td>
													<td>28-86%</td>
													<td>L601, L601w, L908w</td>
												</tr>
												<tr>
													<td colspan="4" className="bold">Base Color Lenses</td>
												</tr>
												<tr>
													<td>Clear</td>
													<td>S0</td>
													<td>87%</td>
													<td>L553, L701, L553w, L701w, L900w, L602</td>
												</tr>
												<tr>
													<td>Grey<sup>*</sup></td>
													<td>S1</td>
													<td>54%</td>
													<td>L555, L761, L761w, L903w</td>
												</tr>
												<tr>
													<td>Sky blue<sup>*</sup></td>
													<td>S1</td>
													<td>54%</td>
													<td>L557, L550w</td>
												</tr>
												<tr>
													<td>Enhancer Rose<sup>*</sup></td>
													<td>S1</td>
													<td>47%</td>
													<td>L552</td>
												</tr>
												<tr>
													<td colspan="4" className="bold">Chrome Lenses</td>
												</tr>
												<tr>
													<td>Electric blue Chrome<sup>*</sup></td>
													<td>S1</td>
													<td>52%</td>
													<td>L708w, L901w</td>
												</tr>
												<tr>
													<td>Orange Chrome<sup>* **</sup></td>
													<td>S1</td>
													<td>48%</td>
													<td>L762w, L904w</td>
												</tr>
												<tr>
													<td>Green Chrome<sup>*</sup></td>
													<td>S2</td>
													<td>40%</td>
													<td>L760w, L902w</td>
												</tr>
												<tr>
													<td>Silver Chrome<sup>*</sup></td>
													<td>S2</td>
													<td>39%</td>
													<td>L707w, L906w</td>
												</tr>
												<tr>
													<td>Yellow Chrome<sup>* **</sup></td>
													<td>S2</td>
													<td>35%</td>
													<td>L764w, L907w</td>
												</tr>
												<tr>
													<td>Purple Chrome<sup>*</sup></td>
													<td>S2</td>
													<td>28%</td>
													<td>L763w, L905w</td>
												</tr>
												<tr>
													<td>Gold Chrome<sup>*</sup></td>
													<td>S2</td>
													<td>23%</td>
													<td>L759w</td>
												</tr>
											</tbody>
										</Table>
										<p className="mb-0">* Not suitable for driving at night or in twillight conditions</p>
										<p>** Lenses not suitable for road use</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">How do you put a lens in a goggles frame?</h6>
										<p>LENS REPLACEMENT INSTRUCTION</p>
										<ul>
											<li>Remove old lens from the frame.</li>
											<li>Insert the new lens into the lens slot at both ends. Holding the top of the lens, pull the nose of the frame up to engage the keyholes. Next, engage the cheek keyholes and lastly, put the top of the lens into the lens slot and engage the top keyholes.</li>
											<li>It may be necessary to stretch the frame slightly to install the lens, so make sure the frame is warm.</li>
											<li>Be sure that the entire lens is properly seated in the frame slot and all frame retention posts are fully engaged with the lens keyholes.</li>
										</ul>
										<img src={lensFrame} className="img-fluid" />
										<div>
											<h6>SCOTT PROSPECT:</h6>
											<ul>
												<li>Open the holding Clips (two on the top and two on the bottom) by pulling away from the frame to remove the old lens from the frame.</li>
												<li>Insert the new lens into the lens slot at both ends. Put the top of the lens into the lens groove, make sure the holes are in line with the pins and close the two holding clips.</li>
												<li>Put the lower part and the nose part of the lens into the lens groove, make sure the holes are in line with the pins and close the holding Clips.</li>
												<li>It may be necessary to stretch the frame slightly to install the lens, so make sure the frame is warm.</li>
												<li>Make sure the four Clips are closed.</li>
											</ul>
											<img src={lensClip} className="img-fluid" />
										</div>
									</li>
									<li className="pb-2">
										<h6 className="bold black">How to clean the Lenses and Goggles?</h6>
										<p>Depending on the model the lenses have a hydrophilic (anti-fog) coating, which prevents from fogging. It is therefore important to proceed in accordance with the following directions:</p>
										<ul>
											<li>In the event of slight soiling the goggle can be cleaned sufficiently using the storage bag or a damp cloth.</li>
											<li>In the event of heavy soiling the lens can be cleaned under running water and with a little soap.</li>
											<li>Please make sure that the wet lens is not wiped but that the water is merely shaken off and dabbed with a soft, clean cloth. Wiping can destroy the anti-fog coating. This can also occur if paper towels are used.</li>
											<li>Care should be taken with mirrored lenses. Please ensure that there are no dust particles on the lens or the cleaning cloth when cleaning.</li>
											<li>We advise strongly against the use of solvents.</li>
											<li>Do not wash motorsport goggles in the wash machine.</li>
											<li>It is essential for the lens to be changed if it can no longer be guaranteed to work properly and provide good protection because of soiling, mechanical wear and tear or improper usage.</li>
											<li>Scratched or damaged lenses have to be replaced and must not be used again.</li>
										</ul>
									</li>
									<li className="pb-2">
										<h6 className="bold black">What Is SCOTT Work Film System?</h6>
										<p>The WORKS Film system is made up of the SCOTT Anti-Stick Grid and two individual Roll off canisters.</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">How to install Grid and Canister?</h6>
										<ul>
											<li>Before installing the grid and the canister open the canisters. Lift the end of the Mud flap and assemble the canisters to the grid by fitting the Canister pins in the Grid holes. PICTURE 1</li>
											<li>Reattach the doors and make sure the Mud flap lug is placed inside the Canisters. PICTURE 2</li>
											<li>Put the Grid with Canister onto the lens and fix it with the pins by turning them 90°. PICTURE 3 and 4</li>
										</ul>
										<img src={grid} className="img-fluid" />
									</li>
									<li className="pb-2">
										<h6 className="bold black">How to Install the Film?</h6>
										<ul>
											<li>Remove the door from the supply canister and remove the tape from the film. Expose the first few centimetres of film and orient the film roll (A).</li>
											<li>Insert the film roll into the supply canister and position the exposed tail of film beneath the Mud flap, the lug helps.</li>
											<li>Reattach the door and make sure the Mud flap lug is placed inside the Canister</li>
											<li>Attach the exposed tail to the motor side axle (B). This is performed with the axle out of the motor RL canister. Place the axle into the receiver in the motor canister bottom. Replace the motor side door. Pull the string once or twice to ensure that the film is securely attached to the axle.</li>
										</ul>
										<img src={film} className="img-fluid" />
									</li>
									<li className="pb-2">
										<h6 className="bold black">What are the safety Precautions for SCOTT WORK FILM System?</h6>
										<ul>
											<li>Make sure the lens is clean and dry before loading the film.</li>
											<li>Store your goggle in a bag after it has been properly cleaned and loaded.</li>
											<li>When advancing the film across the lens use the SCOTT Anti-stick grid as a guide and tuck the film under the Mud flap for best performance.</li>
										</ul>
									</li>
									<li className="pb-2">
										<h6 className="bold black">What are my goggle options if I use prescription lens?</h6>
										<p>OTG goggles offer protection from the elements and an optimized fit for people requiring prescription glasses.</p>										
									</li>
									<li className="pb-2">
										<h6 className="bold black">How to mount nose guard?</h6>
										<p>Locate the nose guard clip-in holes in the nose area of your goggles.</p>
										<div className="mb-3">
											<p className="mb-0">SCOTT HUSTLE MX, SCOTT RECOIL, SCOTT 89Si, SCOTT 89Xi:</p>
											<ol>
												<li>Introduce the upper clip (A) of the nose guard into the nose bridge of your goggles.</li>
												<li>Insert the lower clips (B) of the nose guard into the holes located at the bottom of the goggle frame.</li>
											</ol>
											<img src={noseGuardTwo} className="img-fluid mt-3" />
										</div>	
										<div>
											<p className="mb-0">SCOTT PROSPECT:</p>
											<ol>
												<li>Introduce the two side pins (A) of the nose guard in the two holes at the bottom of the goggle frame.</li>
												<li>Then, insert the centre pin (B) of the nose guard into the middle hole.</li>
											</ol>
											<img src={noseGuardThree} className="img-fluid mt-3" />
										</div>	
										<div>
											<p className="mb-0">SCOTT TYRANT:</p>
											<ol>
												<li>Insert the nose guard clips into the holes.</li>
											</ol>
											<p>Exclusive to SCOTT, the Adjustable Nose Guard on the Tyrant Goggle can be set to one of three positions allowing riders to configure the guard exactly to their liking.</p>
											<img src={noseGuardFour} className="img-fluid mt-3" />
											<img src={noseGuardPosition} className="img-fluid mt-3" />
											<p className="mt-2">After mounting, ensure that the nose guard is securely fastened to the goggle frame. To remove the nose guard, reverse steps 1 and 2. First unclip the lower clips (B) and then the upper clip (A).</p>
										</div>									
									</li>
									<li className="pb-2">
										<h6 className="bold black">How to clean the nose guard?</h6>
										<p>The nose guard can be cleaned under running water and with a little soap. We advise strongly against the use of solvents.</p>
									</li>
								</ol>
							</div>
						</div>						
					</div>
					<div className="card mb-3">
			      <div className="card-header">
			        <a className="card-link" data-toggle="collapse" href="#collapseThree">
			          <h6 className="black bold mb-0">Protector:</h6>
			        </a>			       
			      </div>
			      <div id="collapseThree" className="collapse" data-parent="#MotoAccordion">
			        <div className="card-body">
								<ol className="pl-3">
									<li className="pb-2">
										<h6 className="bold black">How do I adjust my protector for proper fit?</h6>
										<p>To work properly, the protector must cover the protected area correctly. The garment must be the correct size, chosen from the manufacturer’s size chart. Wear, dirt or any kind of damage may compromise their efficiency.</p>
									</li>		
									<li className="pb-2">
										<h6 className="bold black">What are the sizes of protector?</h6>
										<p>Please refer to below link:</p>
										<p><a href="https://dfp2hfrf3mn0u.cloudfront.net/233/233483_original_4.jpg" target="_blank">Protection Size Chart 1</a></p>
										<p><a href="https://dfp2hfrf3mn0u.cloudfront.net/173/173406_original_2.jpg" target="_blank">Protection Size Chart 2</a></p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">How do I adjust my protector for proper fit?</h6>
										<div>
											<h6>BACK PROTECTOR AND CHEST POSITIONING</h6>
											<p>For a protector to work properly, it must cover the protected area correctly. The garment must be the correct size, chosen from the manufacturer’s size chart. All protectors are designed to stay firmly in contact with the body. To obtain maximum adherence to the protected area the protector must be worn under tear proof clothing. Belts, straps or elastic bands (see drawing below) should be fastened tightly to guarantee stability of the garment during use without being uncomfortable for the user. Check the entire closure system, including gripping bands and the correct placement of the vest before use. Wear, dirt or any kind of damage may compromise their efficiency.</p>
										</div>
										<div>
											<h6>ELBOW AND FOREARM CAPS POSITIONING</h6>
											<p>Ensure that the caps are well positioned to cover the elbows and forearms and that the straps are well fixed and adjusted. Do not over tighten the straps, this may affect the blood circulation in your arms. This should not cause discomfort in your usual riding position.</p>
											<img src={elbowCap} className="img-fluid mt-2" />
										</div>
									</li>		
									<li className="pb-2">
										<h6 className="bold black">How do I adjust my knee guard protector for proper fit?</h6>
										<p>The knee guards should not cause discomfort in your usual riding position. Be sure that the knee caps are correctly positioned over your own knee caps. Do not over tighten the gripping straps</p>
										<p>blood circulation could be affected.</p>
									</li>	
									<li className="pb-2">
										<h6 className="bold black">How should I take care and maintain my protector?</h6>
										<div className="mb-2">
											<p className="mb-0">STORAGE</p>
											<ul>
												<li className="pb-2">Contamination with foreign substances and improper care can seriously compromise the effectiveness of your protection and reduce its performance.</li>
												<li className="pb-2">Do not expose your protector to extreme direct sunlight.</li>
												<li className="pb-2">Do not expose your protector to extremely hot and cold temperatures like those found in a closed car or outdoor storage.</li>
												<li className="pb-2">Hang your gear dry and away from direct heat sources.</li>
												<li className="pb-2">We recommend storing your gear at a temperature between 0°C and 30°C.</li>
												<li className="pb-2">The gear should be cleaned accurately before putting it away for long periods.</li>
												<li className="pb-2">Keep it in its original packaging for the storage and for the transportation.</li>
											</ul>
										</div>
										<div className="mb-2">
											<p className="mb-0">CLEANING</p>
											<ul>
												<li className="pb-2">Washing instructions are indicated on the garment itself.</li>
												<li className="pb-2">Remove any dirt with a damp cloth and mild soap.</li>
												<li className="pb-2">Do not bleach, iron or dry clean.</li>
												<li className="pb-2">Do not use hair dryers or other heaters.</li>
												<li className="pb-2">Do not use solvents or toxic substances to clean it.</li>
											</ul>
										</div>
										<div className="mb-2">
											<p className="mb-0">CHECKING</p>
											<ul>
												<li className="pb-2">It is recommended to regularly inspect your gear.</li>
												<li className="pb-2">This product has a limited lifespan and for optimum safety we recommend replacing your product three (3) years after purchasing date.</li>
												<li className="pb-2">After a strong stroke on the protection it is recommended to replace it.</li>
												<li className="pb-2">After a strong stroke on the protection it is recommended to replace it.</li>
												<li className="pb-2">The protection they offer could be reduced due to mechanical wear or improper use.</li>
											</ul>
										</div>
										<div className="mb-2">
											<p className="mb-0">DISPOSAL</p>
											<ul>
												<li className="pb-2">Dispose of the products in the domestic waste.</li>
												<li className="pb-2">Do not mechanically disrupt or incinerate the product.</li>
												<li className="pb-2">This may lead to potential hazards.</li>
											</ul>
										</div>
									</li>		
									<li className="pb-2">
										<h6 className="bold black">What will a neck brace help protect against?</h6>
										<ul>
											<li className="pb-2">Hyperextension (over-bending of the head in rearward direction)</li>
											<li className="pb-2">Lateral Hyper flexion (over-bending of the head to one side)</li>
											<li className="pb-2">Posterior Hyper translation (extreme movement of head and helmet, rearward on the neck)</li>
											<li className="pb-2">Coupled Axial Loading (Helps prevent axial loading only when the axial forces act in combination with other mechanisms)</li>
											<li className="pb-2">Hyper flexion (over-bending of the head in forward direction)</li>
										</ul>
									</li>
									<li className="pb-2">
										<h6 className="bold black">What is the use of Motorcycle garments?</h6>
										<p>They are designed and manufactured to provide limited protection against the risk of abrasion, shock, tearing and cutting in the event of a fall. The Motorcycle garments do not safeguard against other traumas or extreme movements. They provide this mentioned limited protection only in areas covered by the garments.</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">How do I adjust my motorcycle garment for proper fit?</h6>
										<p>For a protector to work properly, it must cover the protected area correctly. The garment must be the correct size, chosen from the manufacturer’s size chart. Protectors are designed to stay firmly in contact with the body. Straps and elastic bands should be fastened tightly to guarantee stability of the garment during use. Check that all closure systems are securely closed. The Motorcycle garments and protector should fit snugly but not too tight. For maximum protection and safety, the garments should not cause any discomfort. One single size cannot fit all body dimensions (height and shape) so it is important to avoid choosing a protector that is too big as interference with other accessories may occur resulting in a dangerous riding condition. The sizing system of back protectors are based on the jacket size as above schedule. The dimension is intended to be measured on the body with a tape measure. Wear, dirt or any kind of damage may compromise their efficiency.</p>
									</li>							
								</ol>
							</div>
						</div>						
					</div>
					<div className="card mb-3">
			      <div className="card-header">
			        <a className="card-link" data-toggle="collapse" href="#collapseFour">
			          <h6 className="black bold mb-0">Gloves:</h6>
			        </a>			       
			      </div>
			      <div id="collapseFour" className="collapse" data-parent="#MotoAccordion">
			        <div className="card-body">
								<ol className="pl-3">
									<li className="pb-2">
										<h6 className="bold black">How the gloves work?</h6>
										<p>Gloves are designed and manufactured to provide limited protection against the risk of impact in the event of a fall. Most of the gloves provide a limited protection only in areas covered by the glove. Gloves intend to protect the hand from external impacts without reducing the user’s ability to operate the motorcycle controls. The knuckle protection - if present - is meant to cover the area of all four knuckles and is intended to offer a limited impact and abrasion protection of the back of the hand from accidents or sudden collisions with objects.</p>
									</li>		
									<li className="pb-2">
										<h6 className="bold black">How do I adjust my gloves for proper fit?</h6>
										<p>For a Glove to work properly, it must cover the protected area correctly. The garment must be the correct size, chosen from the manufacturer’s size chart. Gloves are designed to stay firmly in contact with the hand. Straps and elastic bands should be fastened tightly to guarantee stability of the gloves during use. Check that all closure systems are securely closed. The gloves should fit snugly but not too tight. For maximum protection and safety, the gloves should not cause any discomfort. Gloves should be worn over or under the jacket sleeves so that the sleeves and cuff overlap; they must not slip off easily even in the event of an accident. Wear, dirt or any kind of damage may compromise their efficiency.</p>
									</li>									
									<li className="pb-2">
										<h6 className="bold black">How do I wash and care for my gloves?</h6>
										<div className="mb-2">
											<p className="mb-0">STORAGE</p>
											<ul>
												<li className="pb-2">Contamination with foreign substances and improper care can seriously compromise the effectiveness of your gloves and reduce its performance.</li>
												<li className="pb-2">Do not expose your gloves to direct sunlight.</li>
												<li className="pb-2">Do not expose your gloves to extremely hot and cold temperatures like those found in a closed car or outdoor storage.</li>
												<li className="pb-2">Hang your gear dry and away from direct heat sources.</li>
												<li className="pb-2">We recommend storing your gear at a temperature between 0°C and 30°C.</li>
												<li className="pb-2">The gear should be cleaned accurately before putting it away for long periods. Keep it in its original</li>
												<li className="pb-2">Packaging for the storage and for the transportation.</li>
											</ul>
										</div>
										<div className="mb-2">
											<p className="mb-0">CLEANING</p>
											<ul>
												<li className="pb-2">Washing instructions are indicated on the gloves itself.</li>
												<li className="pb-2">Remove any dirt with a damp cloth and mild soap, up to max. 3 washing cycles.</li>
												<li className="pb-2">Do not bleach, iron or dry clean.</li>
												<li className="pb-2">Do not use hair dryers or other heaters.</li>
												<li className="pb-2">Do not use solvents or toxic substances to clean it.</li>
											</ul>
										</div>
										<div className="mb-2">
											<p className="mb-0">CHECKING</p>
											<ul>
												<li className="pb-2">It is recommended to regularly inspect (in any doubt please ask an expert or your friendly dealer) your gear and to replace it at least every three (3) years as of first use and to ensure it is correctly stored.</li>
												<li className="pb-2">After a strong stroke on the protection it is recommended to replace it.</li>
												<li className="pb-2">The protection they offer could be reduced due to mechanical wear or improper use.</li>
												<li className="pb-2">Before use, please check the gloves for any damage to the straps, stitching and pads.</li>
												<li className="pb-2">Dispose of the product if you notice any damage.</li>
											</ul>
										</div>
										<div className="mb-2">
											<p className="mb-0">DISPOSAL</p>
											<ul>
												<li className="pb-2">Dispose of the products in the domestic waste.</li>
												<li className="pb-2">Do not mechanically disrupt or incinerate the product.</li>
												<li className="pb-2">This may lead to potential hazards.</li>
											</ul>
										</div>
									</li>		
									<li className="pb-2">
										<h6 className="bold black">How do I restore water repellence on SCOTT Motorsports garments and gloves?</h6>
										<p>Upon production, SCOTT GORE-TEX® garments and gloves have a water repellent treatment. This treatment prevents the fabric from attracting moisture and dirt, while simultaneously giving optimal moisture vapour transportation to the fabric. Over time, the water repellence will be worn away (especially in vulnerable places like the seat, knees, shoulders, elbows and hands). In order to maintain this water repellence, the garments and gloves should be re-impregnated after each wash (before drying), or when the water repellence is no longer performing. For water repellence treatments, you can use a re-impregnation spray which ensures that only the outer side of the garment and glove will be water repellent. This will again, optimize the moisture transportation of your SCOTT product.</p>
									</li>													
								</ol>
							</div>
						</div>						
					</div>
					<div className="card mb-3">
			      <div className="card-header">
			        <a className="card-link" data-toggle="collapse" href="#collapseFive">
			          <h6 className="black bold mb-0">Boots:</h6>
			        </a>			       
			      </div>
			      <div id="collapseFive" className="collapse" data-parent="#MotoAccordion">
			        <div className="card-body">
								<ol className="pl-3">
									<li className="pb-2">
										<h6 className="bold black">How do I adjust my boots for proper fit?</h6>
										<ul>
											<li className="pb-2">To obtain maximum comfort and protection, the motorcycle boots must be adjusted
											correctly as follows:</li>
											<li className="pb-2">To ensure optimal performance a proper fit is essential.</li>
											<li className="pb-2">First, Place your foot in the boot. If you feel pressure on your ankle, remove your foot from the boot and check for obstructions, then reconfirm comfort level, finally the straps will need to be adjusted.</li>
											<li className="pb-2">Loosen the straps before placing your foot in the boot.</li>
											<li className="pb-2">Place your foot in the boot and begin with the bottom strap.</li>
											<li className="pb-2">Fasten the strap, if the strap is loose, unfasten the buckle and press the strap in a few notches.</li>
											<li className="pb-2">Now close the buckle. If it is not tight enough, repeat the process until you have a snug fit.</li>
											<li className="pb-2">Repeat the process on the three top buckles.</li>
											<li className="pb-2">Now the boots are ready to be worn. The boot offers protection by way of its design and construction materials. Over-tightening the boot will not offer additional protection.</li>
										</ul>
									</li>		
									<li className="pb-2">
										<h6 className="bold black">How can I determine the correct size of Boots for me?</h6>
										<p>For a motorcycle boot to work properly, it must fit and cover the foot and leg correctly. The motorcycle boot must have the correct size, chosen from the manufacturer’s size chart. All motorcycle boots are designed to stay in contact with the foot and the leg. The boots should be used in combination with “PPE” products making sure to choose compatible pants that guarantee continuous leg protection without any obstruction and pressure that could block the user’s movements. To obtain maximum comfort and protection, the motorcycle boots must be adjusted correctly as follows: To ensure optimal performance a proper fit is essential. First, Place your foot in the boot. If you feel pressure on your ankle, remove your foot from the boot and check</p>
										<p>for obstructions, then reconfirm comfort level, finally the straps will need to be adjusted. Loosen the straps before placing your foot in the boot. Place your foot in the boot and begin with the bottom strap. Fasten the strap, if the strap is loose, unfasten the buckle and press the strap in a few notches. Now close the buckle. If it is not tight enough, repeat the process until you have a snug fit. Repeat the process on the three top buckles. Now the boots are ready to be worn. The boot offers protection by way of its design and construction materials. Over-tightening the boot will not offer additional protection.</p>
									</li>									
									<li className="pb-2">
										<h6 className="bold black">How to take care and maintain boots?</h6>
										<div className="mb-2">
											<p className="mb-0">STORAGE</p>
											<p>When you are not using the Motorcycling boots do not expose them to any heat source and keep them in a dry place.</p>
										</div>
										<div className="mb-2">
											<p className="mb-0">CARE & CLEANING</p>
											<p>When cleaning the boots remove the foot beds. These soft parts can be machine washed using mild detergent on the gentle setting or hand washed. Dry the soft parts in a well-ventilated area. Do not tumble, dry soft parts in a machine; domestic and commercial dryers operate at high temperatures which could result in damage to the foot beds. Although many of the other polymers used in construction of the boots’ exterior are resistant to a wide range of solvents, it is still suggested that nothing stronger than a mild detergent and water be used when cleaning the boot. Use of a semi-stiff bristle nylon brush and a thorough rinse will clean the exterior adequately. Do not use a power washer for cleaning the boots.</p>
										</div>
										<div className="mb-2">
											<p className="mb-0">CHECKING</p>
											<p>It is recommended to regularly control your gear and to replace it at least every three (3) years. After a strong stroke on the protection it is recommended to replace it. The protection they offer could be reduced due to mechanical wear or improper use.</p>
										</div>
										<div className="mb-2">
											<p className="mb-0">DISPOSAL</p>
											<p>Dispose of the products in the domestic waste.</p>
										</div>
									</li>																				
								</ol>
							</div>
						</div>						
					</div>
				</div>
			</div>
		);
	}
}

export default MotosportsFaq;