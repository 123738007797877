import React from 'react';
import {Container, Row, Col, Button, Form} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

import ResetPasswordController from '../controllers/ResetPasswordController';

class ResetPassword extends ResetPasswordController {	
	render(){
		const { t } = this.props;
		return (
			<React.Fragment> 
			
				<div className="login">	
					<div className="main_section">
						<Container>
							<div className="mb-5">
								<a href="/"><img src={this.props.settings.image_url+'/application_images/original/'+this.props.settings.header_logo} width="100px" /></a>
							</div>							
							{(this.state.message == "")?
							<Row>
								<Col lg={6} className="mx-auto">
									<div className="">
										<h5 className="bold text-uppercase black text-center mb-4">{t('resetPassword')}</h5>
									</div>
									<Form className="fields-group-md">
										<Form.Group as={Row}>
									    <Form.Label column lg="4" >{t('newPassword')} :</Form.Label>
									    <Col lg="8">
									    	<Form.Control type="password" className="mb-1" onChange={(password)=>this.setState({password:password.target.value})}/>
									    	<span className="red">{(typeof this.state.errors['reset_password[password]'] != 'undefined' && this.state.errors['reset_password[password]'] != "")?this.state.errors['reset_password[password]']:""}</span>										    	
									    </Col>							   
									  </Form.Group>
									  <Form.Group as={Row}>
									    <Form.Label column lg="4" >{t('confirmNewPassword')} :</Form.Label>
									    <Col lg="8">
									    	<Form.Control type="password" onChange={(confirmPassword)=>this.setState({confirmPassword:confirmPassword.target.value})} className="mb-1" />
									    	<span className="red">{(typeof this.state.errors['confirm_password'] != 'undefined' && this.state.errors['confirm_password'] != "")?this.state.errors['confirm_password']:""}</span>									    	
									    </Col>							   
									  </Form.Group>
									  <div className="text-center mt-5">
											<Link to='/Login'>
												<Button className="btn btn-md transparent mr-3" style={this.props.styles.secondary_btn}>{t('back')}</Button>
											</Link>
											<Button className="btn btn-md" onClick={()=>this.updatePassword()} style={this.props.styles.primary_btn}>{t('reset')}</Button>
										</div>									  
									</Form>
									
								</Col>		
							</Row>:
								<Alert show={this.state.showMessage} variant={this.state.type}>
					        <Alert.Heading>{this.state.messageTitle}</Alert.Heading>
					        <p>
					          {this.state.message}
					        </p>
					        <hr />
					      </Alert>	
					    }					
						</Container>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings,
    styles: state.styles.styles,
  }
}

export default withTranslation() (connect(mapStateToProps)(ResetPassword));
