import React from 'react';
import { Helmet } from 'react-helmet';
import { getMetaTags } from '../../core/helpers/ServicesHelper';
class MetaTags extends React.Component {
		constructor(props){
			super(props)
			this.state = {metatags:[]}
		}

		async componentDidMount(){
	    await this.getMetaTags();
	  }

	  async componentDidUpdate(nextProps, nextState){
	  	if((nextProps.match.url != this.props.match.url) 
																|| (nextProps.location.search != this.props.location.search)){
				await this.getMetaTags();
			}else{
				return false;
			}
	  }

	  async getMetaTags(){
	    var route = this.props.location.pathname.split('/');
	    console.log('inside getMetaTags'+this.props.location.pathname);
	    var where = 'where[page_name]='+route[1];
	    if(typeof route[2] != 'undefined')
	    	where = 'where[page_name]='+route[1]+'/'+route[2];

	    if (typeof route[3] != 'undefined')
	    	where = 'where[page_name]='+route[1]+'/'+route[2]+'/'+route[3];


	    var metatags = await getMetaTags('where[page_name]='+this.props.location.pathname);
	    if (metatags.data.status == 'success') {
	    	console.log('inside success getMetaTags');
	      this.setState({ metatags: metatags.data.data });
	    } else {
	    	console.log('inside error getMetaTags');
	      console.log(metatags.data.status);
	    }
	  }

	  render(){
	  	const currentUrl = window.location.href;
	  	return (
	  		<Helmet>
		  		
					{this.state.metatags.length > 0 ?
            this.state.metatags.map((metatag, index) => (
              <title key={index}>{metatag.meta_title}</title>
            )):
            <title>Sport Network</title>
          }
          {this.state.metatags.length > 0 && 
            this.state.metatags.map((metatag, index) => (
              <meta key={index} name="keywords" content={metatag.meta_title} />
            ))
          }
          {this.state.metatags.length > 0 && 
            this.state.metatags.map((metatag, index) => (
              <meta key={index}  name="description" content={metatag.description} />
            ))
          }
          {this.state.metatags.length > 0 && 
            this.state.metatags.map((metatag, index) => (
              <meta key={index} name="robots" content={metatag.robot_name} />
            ))
          }
          
          <meta property="og:image" content="https://admin.sportnetwork.in/assets/luma/images/sport-logo.png" />
          {this.state.metatags.length > 0 && 
            this.state.metatags.map((metatag, index) => (
              <meta property="og:title" content={metatag.meta_title} />
            ))
          }
          {this.state.metatags.length > 0 && 	
            this.state.metatags.map((metatag, index) => (
              <meta property="og:description" content={metatag.description} />
            ))
          }
          <meta property="og:type" content="website" />
          <meta property="og:url" content={currentUrl} />
          <link rel="canonical" href={currentUrl} />
          
          {this.state.metatags.length > 0 && 
            this.state.metatags.map((metatag, index) => (
	          <script type="application/ld+json">
		          {`
						    {
						      "@context": "http://schema.org",
						      "@type": "website",
						      "name": "${metatag.meta_title}"
						      "description": "${metatag.description}",
						      "url":"${currentUrl}"
						    }
						  `}
					  </script>
					  ))
          }
        </Helmet>
	  	);
	  }
}

export default MetaTags;