import React from 'react';
import {Container, Row, Col, Table, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import sizeChart from '../../../assets/images/faq/size_chart.png';

class BikeFaq extends React.Component {	
	render(){
		return (
			<div className="tab-pane fade" id="bike_faq" role="tabpanel" aria-labelledby="bike_faq_tab">			
				<h5 className="black bold mt-5">Bike</h5>
				<div className="card mb-3">
	        <div className="card-body">
						<ol className="pl-3">
							<li className="pb-2">
								<h6 className="bold black">What Bike Size do I need?</h6>
								<p>The right bike size can be influenced by several variables, such as flexibility or personal anthropometrics (refers to the measurement of the human individual). The sizing chart which can be found on our website is a solid starting point to choose the right frame size. Choosing the right bike size depends upon your riding style, your priority, your height and the type of bike. A proper and correct fit bike will be more comfortable, efficient and fun to ride.</p>
								<p>Please note that size chart is an indicative measure based on best case scenarios possible and assumptions. Hence it is important and advisable to understand how to read the chart.</p>
								<p>You still have confusion and doubts on deciding the bike size, we advise you to visit our nearest authorized Scott Dealer and get a Bike Fit done. In case of more information you can write to us at <a href="mailto:cs@sportnetwork.in" target="_blank">cs@sportnetwork.in</a> or call us <a href="tel:8080754321" target="_blank">8080754321</a></p>
								<p>Check Authorized Dealer At: <a href={window.location.origin+"/find-dealer"} target="_blank">Find a Dealer</a></p>
							</li>
							<li className="pb-2">
								<h6 className="bold black">How do I choose the right size bike for my child?</h6>
								<p>Please click on the size chart link below for Kids bike size with respect to age and height. In case of any doubts, we request you to visit the nearest Authorized Scott Dealer.</p>
							</li>
							<li className="pb-2">
								<h6 className="bold black">What is Bike Fit and why is it needed?</h6>
								<p>Bike Fit is an activity that mates the bike geometry to the cyclist’s body measurements, to ensure an optimum riding position and thus helps you get the most out of your bike from a performance perspective.  Each bike brand has a different geometry and a different bike set up. A medium size frame of A Brand does not necessarily mean it is the same Medium size in Brand B. Similarly, a person who is 6ft in height will not have the same body dimensions as another cyclist who is 6ft in height. This is where the Bike Fit service comes in handy. You can check the size chart available against each bike available on our website or in case of confusion you can visit the nearest Authorized Scott/Bergamont dealer to get Bike Fit done.</p>
								<p>Check Authorized Dealer At: <a href={window.location.origin+"/find-dealer"} target="_blank">Find a Dealer</a></p>
							</li>
							<li className="pb-2">
								<h6 className="bold black">What are the important points to note while choosing the bike?</h6>
								<p>Different people have different biking needs; hence different types of bicycles are designed to meet those needs. Some people like tricks, some like races, and some like speed control, some cycle to work. You will need to take into account your own preferences when choosing the right bicycle for yourself.</p>
								<p className="mb-0">There are 3 important points:</p>
								<Table size="sm" bordered>
									<thead>
										<tr>
											<th>Bike Type :</th>
											<th>Best For :</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Road Bikes (Racing, Aero, Endurance, Cyclocross, Lightweight, Touring Bikes)</td>
											<td>Paved/ Tarmac Roads</td>
										</tr>
										<tr>
											<td>Mountain Bikes (including trail, cross-country, and all-mountain bikes)</td>
											<td>Rugged trails and gravel roads</td>
										</tr>
										<tr>
											<td>Hybrid Bikes (Features of Both Mountain & Road Bike)</td>
											<td>Pavement or moderate gravel/dirt roads</td>
										</tr>
										<tr>
											<td>Specialty Bikes (E Bikes)</td>
											<td>Paved/ Tarmac Roads</td>
										</tr>
									</tbody>
								</Table>
								<p><span className="bold">Bike Features and Components:</span> Frames, Suspension, Wheel Size, Gears, Brakes, Bike Weight are factors that a first-time buyer must consider. Frames are the most important component of the bike which should help you decide which bike to invest in.</p>
								<p><span className="bold">Bike Fit:</span> Once you have narrowed down on your buying choice it’s important to make sure you go for a bike fit to ensure that your riding is not affected by incorrect setup of the bike.</p>
							</li>
							<li className="pb-2">
								<h6 className="bold black">What Bike Size do I have?</h6>
								<p>The frame size is embedded in the serial number of your bike (f.e. 52, 54, 56 for a road bike or S, M, L for a MTB). It is as well embedded in a logo on the frame close to the toptube/seattube intersection.</p>
							</li>
							<li className="pb-2">
								<h6 className="bold black">What is the weight of my Bike?</h6>
								<p>The weight of the complete bikes can be found on the website in the specifications tab in the product section.</p>
							</li>
							<li className="pb-2">
								<h6 className="bold black">Is there a rider weight limit for Bikes?</h6>
								<p className="bold">For Scott Bikes:</p>	
								<p>In general, we recommend not to exceed 110kgs/243lbs. Please note: kids bikes are designed with a combined weight limit of 50kgs/110lbs. <span className="bold">*(Rider weight=Max Permissible System Weight-Weight of Bike – Weight of Luggage).</span>  Kindly find the maximum permissible weight of Scott Bikes in below chart</p>		
								<Table size="sm" bordered responsive>
									<thead>
										<tr>
											<th width="10%">Categories</th>
											<th width="35%">Category Name</th>
											<th width="35%">Bike Series/Models</th>
											<th width="20%">Max Permissible System Weight (Rider incl Luggage & Bicycle)</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Category 1</td>
											<td>Scott Road Racing, Triathalon Bikes, Time Travel Machines (Scott Bikes Aero, Lightweight, Endurance, Contessa Road)</td>
											<td>Addict RC Disc, Addict RC, Plasma, Foil, Foil Disc, Addict, Addict Disc, Speedster, Speedster Disc, Metrix, Contessa Addict, Contessa Speedster</td>
											<td>120Kg/264 lbs</td>
										</tr>
										<tr>
											<td rowspan="3">Category 2</td>
											<td>Scott City, Trekking and Urban Bikes</td>
											<td>Silence, Sub Sport, Sub Comfort, Sub Cross</td>
											<td>120Kg/264 lbs</td>
										</tr>
										<tr>
											<td>Scott Kids Bikes</td>
											<td>Junior (Scale, Speedster, Voltage, Contessa, Volt)</td>
											<td>50Kg / 110 lbs</td>
										</tr>
										<tr>
											<td>Scott cyclo-cross bikes</td>
											<td>Addict Gravel, Speedster Gravel, Addict CX</td>
											<td>120Kg/264 lbs</td>
										</tr>
									</tbody>
								</Table>						
							</li>
							<li className="pb-2">
								<h6 className="bold black">What is the difference between Hardtail and a full suspension bike?</h6>
								<p>Hardtail bikes are more efficient on paths and roads and a bit lighter, but they are less comfy and less stable as soon as the terrain starts to become rough.</p>
								<p>Full suspension bikes are more trail-oriented, the rear suspension gives additional grip, and as a result the bike is comfier on rough terrain. All SCOTT full suspension are equipped with the TwinLoc Suspension system to always optimize your ride.</p>
							</li>	
							<li className="pb-2">
								<h6 className="bold black">What is the difference between HMF and HMX Frames?</h6>
								<p>HMF is a high modules carbon matrix allowing lightweight frame production. HMX (High Modules Extreme) utilizes higher modules fibers allowing us to use even less material than HMF, resulting in an even lighter frame that maintains the same riding characteristics and strength as the HMF frame</p>
							</li>	
							<li className="pb-2">
								<h6 className="bold black">What is BOA system?</h6>
								<p>Boa is a proprietary closure system of steel Lace, nylon guides and a mechanical reel designed to address the long list of deficiencies found in traditional closures.</p>
							</li>
							<li className="pb-2">
								<h6 className="bold black">Does BOA have a guarantee?</h6>
								<p>Boa Technology guarantees the Boa Closure System reel and laces for the lifetime of the product.</p>
							</li>	
							<li className="pb-2">
								<h6 className="bold black">What is traction control?</h6>
								<p>Traction control is the middle travel setting on the Scott Spark and Genius. This travel setting decreases your sag, giving you a better head angle for climbing and allows the shock to ramp up faster, keeping you on top of the pedals while riding your bike in uphill sections.</p>
							</li>		
							<li className="pb-2">
								<h6 className="bold black">What is Twinloc?</h6>
								<p>TwinLoc is SCOTT's proprietary bar mounted lever that controls 3 travel settings and the fork lockout, so you can keep both hands on the bars at all times. Watch the TwinLoc Video for more information. (<a href="https://www.youtube.com/watch?v=5o0Msdi1bCQ" target="_blank">The SCOTT TwinLoc Suspension System - 3 Bikes in One</a>)</p>
							</li>	
							<li className="pb-2">
								<h6 className="bold black">Do all your full suspension bikes have 3 travel settings?</h6>
								<p>We offer full suspension bikes that feature the following travel settings: Genius LT: Front: 170, Rear: 170mm, 110mm, Climb / Genius 900All of our Genius LT bikes have 3 travel settings 185mm, 110mm and lockout (all Genius have 150mm, 95mm and lockout). The full carbon Sparks (RC, 10, 20 and 30) all have 3 settings 110mm, 80mm and lockout. The aluminum Sparks (35 and up) have full travel 110mm and lockout.</p>
								<p>Freeride and Downhill bikes do offer different travel settings but are not adjustable on the fly.</p>
							</li>		
							<li className="pb-2">
								<h6 className="bold black">Is it legal rule to wear helmets while riding?</h6>
								<p>Different countries have different legal norms in Road Safety. We ALWAYS advise to wear helmets while riding a bicycle for safety measures.</p>
							</li>		
							<li className="pb-2">
								<h6 className="bold black">What headset do I need?</h6>
								<p>We use semi-integrated or Zero Stack headsets, although headsets from all manufacturers are compatible with our frames if the cup is designed for a 44mm internal diameter head tube. If you want to find out exactly which headset you need you can find it engraved on your original headset (either 45x45 or 45x36). New headsets or bearings can be purchased at Authorized Dealer</p>
							</li>
							<li className="pb-2">
								<h6 className="bold black">I want to fit a larger tire on my bike, what is the largest tire that will fit?</h6>
								<p>We recommend a 3 to 4mm gap between the tire and the rear triangle on both sides to have enough clearance in muddy conditions. Please check the actual width of the tire as measurements and indications may vary for different manufacturers.</p>
							</li>	
							<li className="pb-2">
								<h6 className="bold black">What is the correct tire pressure for my bike?</h6>
								<p>Every tire brand comes with recommended tire pressure embossed on the sides of tire or in the manual. You need to ensure proper tire pressure before every ride.</p>
							</li>	
							<li className="pb-2">
								<h6 className="bold black">What should I wear underneath my Bike shorts?</h6>
								<p>We offer bike shorts come with either an integrated padding for the tight fit shorts, or a removable inner short with padding for the loose fit shorts. Any cyclist will tell you "no underwear under your padding - ever"! Our padded shorts are designed in a way that you do not need to wear anything underneath. Wearing underwear underneath your padding would just cause rubbing and chaffing, making you very uncomfortable.</p>
							</li>	
							<li className="pb-2">
								<h6 className="bold black">Do I need to wear a proper cycling apparel while riding?</h6>
								<p>Yes, we highly recommend wearing a proper cycling apparel while racing for an event, practicing, fun ride, longer or shorter distance. While riding you may work up a sweat, to be comfortable it is best advisable to wear Jersey’s, Bib shorts, Bike Pants that will wick the moisture away to keep you dry.  Make sure to wear a proper cycling shoes for better pedalling, you will find a variety of shoes as per your requirement on website which will suit your need.</p>
								<p>Remember!!! <span className="bold">ALWAYS</span> wear a Helmet for every ride.</p>
							</li>	
							<li className="pb-2">
								<h6 className="bold black">Where is the serial no of my bike frame?</h6>
								<p>You will find the serial no of the bike frame at the bottom of the downtube.</p>
							</li>
							<li className="pb-2">
								<h6 className="bold black">How do I clean my Cycling Helmet?</h6>
								<p>Clean your helmet with a soft cloth or sponge, warm water and mild soap. For more detailed instructions and warnings read the manual along with the product.</p>
							</li>
							<li className="pb-2">
								<h6 className="bold black">Do I need Cycling shoes?</h6>
								<p>Yes, definitely!! Cycling shoes provides comfortable pedalling with better pedal strokes enabling you to ride more efficiently. You will find a variety of cycling shoes on the website. Explore to find best one as per your needs.</p>
							</li>
							<li className="pb-2">
								<h6 className="bold black">How do I pack my bike for transport?</h6>
								<p>There is a variety of Bike Transport Bag available on our website which is specifically made to pack your bike in a standard way to ensure maximum safety of your bike while transportation.</p>
							</li>
							<li className="pb-2">
								<h6 className="bold black">What are the checklist before every ride?</h6>
								<ol type="i">
									<li className="pb-2">Quick Release Levers</li>
									<li className="pb-2">Tire Pressure</li>
									<li className="pb-2">Rims</li>
									<li className="pb-2">Brakes & Suspension</li>
									<li className="pb-2">Repair Kit</li>
								</ol>
							</li>		
							<li className="pb-2">
								<h6 className="bold black">Where can I Assemble my Bike and its implications on Warranty?</h6>
								<p>You need to Assemble your bike only through an Authorized Scott/BERGAMONT Dealer otherwise the warranty will stand void. Please visit the below link to find your nearest Authorized Dealer</p>
								<p>Link: <a href={window.location.origin+"/find-dealer"} target="_blank">Find a Dealer</a></p>
							</li>	
							<li className="pb-2">
								<h6 className="bold black">What is Bike Warranty, Coverage and its Claim process?</h6>
								<p>Please visit the below link to get the complete information about warranty, coverage and the process of claiming warranty.</p>
								<p><a href={window.location.origin+"/warranty"} target="_blank">Warranty</a></p>
							</li>
							<li className="pb-2">
								<h6 className="bold black">How do I take care of my bicycle?</h6>
								<p>In order to keep the function and optic of the bike in good condition we recommend periodical bicycle care. Doing so keeps the value of the bike and helps to prevent from corrosion or other damages: clean with soft brush, water and soft towel. Do not use high pressure cleaner, otherwise bearings, color or decals can be damaged. Do not use aggressive cleaning additives. Repair color damages at once. Grease or oil all metal parts especially during winter use. Please use biodegradable bicycle cleaners and degreasers which are offered from Authorized Dealers.</p>
							</li>
							<li className="pb-2">
								<h6 className="bold black">How to purchase replacement parts for bike?</h6>
								<p>You can contact your nearest Authorized Dealer of Scott/BERGAMONT Bike. You can even call us at <a href="tel:8080754321" target="_blank">8080754321</a> or write to us on <a href="mailto:cs@sportnetwork.in" target="_blank">cs@sportnetwork.in</a> seeking for more information.</p>
							</li>
							<li className="pb-2">
								<h6 className="bold black">What is SCOTT Bike Service Plan?</h6>
								<p>After the purchase of the bike, it is very important to service your bike properly for standard functioning. You should always get your bike serviced by an Authorized BERGAMONT Dealer to maintain its Warranty.</p>
								<ul>
									<li>1st Service: After 100-300 km or 5-15 hours of use or after 3 months from date of purchase</li>
									<li>2nd Service: After 2000 km or 100 hours of use or after 1 year</li>
								</ul>
								<p>For more information on service plan, refer your manual book which comes along with Bike</p>
							</li>	
							<li className="pb-2">
								<h6 className="bold black">Can I exchange the bike if the size does not fit?</h6>
								<p>If you have any difficulties in reading the size chart or you are unsure of bike size, we request you to visit the nearest Authorized Scott Dealer and check for correct frame size. Once the bike is shipped out and you wish to exchange it at the dealer, a new bike will have to be shipped out to you for which you will have to pay a shipping charge of Rs 1500. Do note that the swap of the bike is not possible once you have collected the bike from the dealer.</p>
							</li>
							<li className="pb-2">
								<h6 className="bold black">Can I exchange my apparels if the size does not fit? </h6>
								<p>We request our customers to check the size chart of the apparels like t-shirts, jersey, shoes, gloves etc. before your place the order online. We will provide a replacement with desired size as per your requirement in the case the size does not fit. Please note that we do not provide any refund for size issues. The products should be return to us in the original condition with tags intact along with it.</p>
							</li>
						</ol>
					</div>
				</div>	
			</div>
		);
	}
}

export default BikeFaq;