import React from 'react';
import {Container, Row, Col, Table, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import helmet from '../../../assets/images/faq/helmet.png';
import helmet2 from '../../../assets/images/faq/helmet2.png';
import chinStrap from '../../../assets/images/faq/chin_strap.png';
import lensFrame from '../../../assets/images/faq/lens_frame.png';
import lensClip from '../../../assets/images/faq/lens_clip.png';
import grid from '../../../assets/images/faq/grid.png';
import film from '../../../assets/images/faq/film.png';
import noseGuard from '../../../assets/images/faq/nose_guard.png';
import noseGuardTwo from '../../../assets/images/faq/nose_guard2.png';
import noseGuardThree from '../../../assets/images/faq/nose_guard3.png';
import noseGuardFour from '../../../assets/images/faq/nose_guard4.png';
import noseGuardPosition from '../../../assets/images/faq/nose_guardPosition.png';
import elbowCap from '../../../assets/images/faq/elbow_cap.png';

class EbikeFaq extends React.Component {	
	render(){
		return (
			<div className="tab-pane fade" id="ebike_faq" role="tabpanel" aria-labelledby="ebike_faq_tab">			
				<h5 className="black bold mt-5">Ebike</h5>
				<div id="EbikeAccordion" className="mt-3">
					<div className="card mb-3">
			      <div className="card-header">
			        <a className="card-link" data-toggle="collapse" href="#collapseOne">
			          <h6 className="black bold mb-0">Scott:</h6>
			        </a>			       
			      </div>
			      <div id="collapseOne" className="collapse" data-parent="#EbikeAccordion">
			        <div className="card-body">
								<ol className="pl-3">
									<li className="pb-2">
										<h6 className="bold black">HOW TO WASH AN E-BIKE?</h6>
										<ul>
											<li className="pb-2">As an E-Bike is water resistant you can wash it as you would wash a normal bike. </li>
											<li className="pb-2">Remove the major part of the dirt with a water-jet (never use a high-pressure car washer)</li>
											<li className="pb-2">With a soft brush and with the aid of a bike cleaner (bike specific, do not use car or motorbike products), clean the remaining dirt</li>
											<li className="pb-2">Once it’s done, rinse the bike with water to remove the washing fluid (never use a high-pressure car washer)</li>
											<li className="pb-2">When the bike is clean, turn the bike upside down during few seconds in order to remove standing water, then wipe off the bike.</li>
											<li className="pb-2">We recommend to wipe the electrical contact plates of the battery afterwards as well</li>
											<li className="pb-2">To finish, lubricate the chain with bike specific chain lube </li>
										</ul>
									</li>
									<li className="pb-2">
										<h6 className="bold black">WHAT KIND OF MAINTENANCE DO I NEED TO DO ON MY E-BIKE AND HOW OFTEN?</h6>
										<p>The maintenance is pretty limited and simple. By yourself you can wash it, apply oil on the chain, wipe it down and check brake pad wear. A visit every 3 months to your local dealer is recommended to avoid any mechanical issues.</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">ARE E-BIKE BATTERIES FIXED ON THE FRAME?</h6>
										<p>Yes, the battery has a retaining system. You need a key to release the battery from the frame.</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">DO I NEED TO PEDAL WITH AN E-BIKE?</h6>
										<p>Yes, SCOTT only sells E-Bikes with pedal assist. This means that if you don’t pedal, nothing happens. This assistance goes generally from +50% of additional power to +300% depending on the mode.</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">CAN I RIDE MY E-BIKE EVEN IF THE MOTOR IS OFF OR NOT ENGAGED?</h6>
										<p>Yes. Just as with a non-E-Bike, if the drive unit is off you are still able to pedal. In case of electrical Shimano DI2 gear shifting, even if you run out of battery in the middle of your ride, you will still have 100 "emergency" gear shifts.</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">HOW SHOULD I STORE THE BATTERY OF MY E-BIKE?</h6>
										<p>For longer storage we recommend you to stock the battery at ambient temperature, ideally between 18-20° Celsius. Avoid leaving your battery on your bike during cold nights or directly exposing it to the sun.</p>									
									</li>
									<li className="pb-2">
										<h6 className="bold black">WHAT DO I DO IF I LOSE MY E-BIKE'S BATTERY'S KEYS?</h6>
										<p>All SCOTT E-Bike keys come with a serial number. As soon as you have your bike, register your key on <a href="https://mobilesecurity.abus.com/users/register" target="_blank">Mobile Security</a>, <a href="https://keyservice.axasecurity.com" target="_blank">Keyservice Axasecurity</a>. This allows you to order a replacement key whenever you want.</p>
										<p>If you don’t have this number anymore, please contact your dealer.</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">CAN I WASH MY E-BIKE?</h6>
										<p>As an E-Bike is water resistant you can wash it as you would wash a normal bike.</p>
										<p>For improving the bearing life-cycle, do not use a high-pressure car washer. Once it’s clean, wipe off the bike and lubricate the chain with of bit of oil in order to improve the lifetime of your drive train. We recommend to wipe the electrical contact plates of the battery afterwards as well.</p>							
									</li>
									<li className="pb-2">
										<h6 className="bold black">CAN I RIDE MY E-BIKE WHEN IT’S RAINING OR IN THE MUD?</h6>
										<p>Both the engine and the battery is water resistant, so there is no problem for riding in wet conditions.</p>									
									</li>
									<li className="pb-2">
										<h6 className="bold black">CAN I FLY WITH AN E-BIKE?</h6>
										<p>Getting on a plane with an E-Bike requires special authorizations, please contact your airline company before booking your ticket.</p>								
									</li>
									<li className="pb-2">
										<h6 className="bold black">CAN I DO THE MAINTENANCE BY MYSELF ON MY E-BIKE?</h6>
										<p>Everything related to the drive unit needs to be carried out by the manufacturer for safety and security reasons.</p>								
									</li>
									<li className="pb-2">
										<h6 className="bold black">IS IT POSSIBLE TO REMOVE THE BATTERY FROM MY E-BIKE?</h6>
										<p>Batteries are removable on all SCOTT bikes without tools; all you need is the key for opening the retaining system.</p>						
									</li>
									<li className="pb-2">
										<h6 className="bold black">HOW FAST CAN I GO ON AN E-BIKE?</h6>
										<p>Theoretically there is no maximum speed on an E-Bike; the motor will cut out the assistance above:</p>
										<p>45km/h: assistance's speed limit for “Speed Pedelec”(L1e-B)</p>
										<p>25km/h: assistance's speed limit for “Powered Cycles” (L1e-A)</p>									
									</li>
									<li className="pb-2">
										<h6 className="bold black">HOW LONG DOES IT TAKES TO CHARGE MY E-BIKE BATTERY?</h6>
										<p>Nowadays most of the batteries (500wh) need around 4.5-5 hours to be charged completely and less than 3 hours for 80%</p>									
									</li>
								</ol>
							</div>
						</div>						
					</div>
					<div className="card mb-3">
			      <div className="card-header">
			        <a className="card-link" data-toggle="collapse" href="#collapseTwo">
			          <h6 className="black bold mb-0">Bergamont:</h6>
			        </a>			        
			      </div>
			      <div id="collapseTwo" className="collapse" data-parent="#EbikeAccordion">
			        <div className="card-body">
								<ol className="pl-3">
									<li className="pb-2">
										<h6 className="bold black">How fast can I go on an E-Bike?</h6>
										<p>Theoretically there is no maximum speed on an E-Bike; the motor will cut out the assistance above:</p>
										<p>45km/h: assistance's speed limit for "Speed Pedelec" (L1e-B)</p>
										<p>25km/h: assistance's speed limit for "Powered Cycles" (L1e-A)</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">How far and how long I can ride with an E-Bike?</h6>
										<p>The higher the amount of Whatt hours (Wh) of the battery is, the greater range you can cover in theory. In reality lots of factors have to be taken into consideration:</p>
										<ul>
											<li className="pb-2">The weight of the bike: the heavier the bike is, the more energy it needs to be propelled. At BERGAMONT the weight and the balance of the bike is always a key point.</li>
											<li className="pb-2">The quality of the motor: a smooth and smart motor will save energy and increase range. BERGAMONT bikes are equipped with Shimano and Bosch motors, which are the actual benchmark on the market. Motors are also adjusted with regards to the program of the bike, a city bike, for instance, will have different settings compared to an off-road bike.</li>
											<li className="pb-2">The environment: road quality, wind, temperature, grade of the road, all of these play an important role.</li>
										</ul>
										<p>Riding 80km* with a 500Wh battery is a good point of reference on an e-mountain bike and 100km for an e-urban bike</p>
										<p>*on eco mode with 80kg rider.</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">Does every BERGAMONT E-Bike have a walk assist mode?</h6>
										<p>Yes, all drive units mounted on BERGAMONT E-Bikes have a walk mode (Up to 6km/h).</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">Can I ride my E-Bike even if the motor is off or not engaged?</h6>
										<p>Yes. Just as with a non-E-Bike, if the drive unit is off you are still able to pedal. In case of electrical Shimano DI2 gear shifting, even if you run out of battery in the middle of your ride, you will still have 100 "emergency" gear shifts.</p>										
									</li>
									<li className="pb-2">
										<h6 className="bold black">Do I need to pedal with an E-Bike?</h6>
										<p>Yes, BERGAMONT only sells E-Bikes with pedal assist. This means that if you dont pedal, nothing happens. This assistance goes generally from +50% of additional power to +300% depending on the mode.</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">What kind of maintenance do I need to do on my E-Bike and how often?</h6>
										<p>The maintenance is pretty limited and simple. By yourself you can wash it, apply oil on the chain, wipe it down and check brake pad wear. We recommend to visit your local dealer for scheduled maintenance work as specified in the product manual to avoid any mechanical issues.</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">Can I do the maintenance by myself on my E-Bike?</h6>
										<p>Everything related to the drive unit needs to be carried out by the manufacturer for safety and security reasons.</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">Can I wash my E-Bike?</h6>
										<p>As an E-Bike is water resistant you can wash it as you would wash a normal bike.</p>
										<p>For improving the bearing life-cycle, do not use a high-pressure washer. Once it's clean, wipe off the bike and lubricate the chain with of bit of oil in order to improve the lifetime of your drive train. We recommend to wipe the electrical contact plates of the battery afterwards as well.</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">Can I ride my E-Bike when it's raining or in the mud?</h6>
										<p>Both the engine and the battery is water resistant, so there is no problem for riding in wet conditions.</p>										
									</li>
									<li className="pb-2">
										<h6 className="bold black">Can I take an E-Bike onboard a plane?</h6>
										<p>Getting on a plane with an E-Bike requires special authorization, please contact your airline before booking your ticket.</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">What are Amp hours (Ah) and Watt hours (Wh) for an E-Bike?</h6>
										<p>Both refer to the capacity of the battery. The higher these numbers are, the more autonomy the battery has but the, larger, heavier and more expensive the battery is. Standard capacity is 500Wh.</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">How long does it takes to charge my E-Bike battery?</h6>
										<p>Nowadays most of the batteries (500wh) need around 4.5-5 hours to be charged completely and less than 3 hours for 80%</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">Are E-Bike batteries fixed on the frame or can they be removed?</h6>
										<p>The battery has a retaining system to secure it against theft. You need a key to remove the battery from the frame but no further tools are required.</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">What do I do if I lose my E-Bike's battery's keys?</h6>
										<p>All BERGAMONT E-Bike keys come with a serial number. As soon as you have your bike, register your key on <a href="https://mobilesecurity.abus.com/users/register" target="_blank">Mobile Security</a>. This allows you to order a replacement key whenever you want.</p>
										<p>If you don't have this number anymore, please contact your dealer.</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">How should I store the battery of my E-Bike?</h6>
										<p>For longer storage we recommend you to stock the battery at ambient temperature, ideally between 18-20° Celsius. Avoid leaving your battery on your bike during cold nights or directly exposing it to the sun.</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">Does my battery get damaged if i dont charge it all the way?</h6>
										<p>With Lithium Ion batteries interim charging doesn't matter. As all BERGAMONT bikes are equipped with Lithium batteries, feel free to charge your battery as you wish. For the first charging cycle please read the user manual and follow the indications.</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">Can I plug any battery into the battery mount of my E-Bike?</h6>
										<p>Always use the original battery and the original charger of your E-Bike to avoid any issues or defects.</p>
									</li>
								</ol>
							</div>
						</div>						
					</div>					
				</div>
			</div>
		);
	}
}

export default EbikeFaq;