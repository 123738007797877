import React, { Component } from 'react'
import MasterProduct from '../products/MasterProduct'

import { getCartListing, deleteCart, getImageContent, checkInventory, applyDiscount,updateCart } from '../../helpers/ServicesHelper';
import { getImageUrl, checkValidHash,getImagePath } from  '../../helpers/OperationsHelper';
import { submitOrder } from  '../../helpers/TransactionsHelper';

export default class CoreCarts extends MasterProduct {
  constructor(props){
		super(props);
		this.state={
			cartList:[],
			compareProducts:[],
      quantity:1,
			subTotal:0,
			itemsCount:0,
			images:{},
			errors: [],
			cartErrors: [],
			loader: true,
			unit: '',
			currency: '',
			errors: [],
			isFetching:false,
			discountCoupanCode:'',
			confirmOrderModalVisibility: false,
			orderSuccess: false,
		}
		this.images={};
	}
	
	async componentDidMount(){
		window.history.pushState(null, null, window.location.pathname);
		await this.getCartListing()
		await this.getCompareProducts();
		this.setState({ loader: false });
	}

	async getCartListing(){
    var carts = await getCartListing();
		if (carts.data.status == 'success') {
			await this.setState({cartList:carts.data.data,loader:false,isFetching:false})
			this.getSubtotal();
			this.getItemsCount();	
			this.convertToBlob(carts.data.data);
		}
		this.setState({ loader: false,isFetching:false });
	}

	async convertToBlob(carts){
    carts.map((cart,index)=>{
      if (cart.item_details.item_images != null && typeof cart.item_details.item_images.name != 'undefined' && cart.item_details.item_images.name != null) {
        this.fetchBlob(cart.id, cart.item_details.item_images.id,cart.item_details.item_images.name);
      } else {
        var imageUrl = this.props.settings.image_url+'/application_images/original/'+this.props.settings.error_image;
        this.setImageInState(imageUrl, cart.id);
      }
    })    
  }

  async fetchBlob(id, item_id,image_name) {  	
	  	var medium_image = this.props.settings.image_url+'products/images/medium/'+image_name;
	  	this.setImageInState(medium_image, id);
  }

  async setImageInState(base64,id){
  	this.images = this.state.images;
    this.images[id] = base64;
    if (Object.keys(this.images).length == this.state.cartList.length) {
      this.setState({ images: this.images });
    }
  }

	async deleteCartItem(id){
		await this.deleteCart(id);
		await this.getCartListing();
	}

	async moveItemToWishList(id){
		await this.moveToWishList(id);
		await this.getCartListing();
	}

	async placeOrder(orderType=0){
		var orderSubmit = await submitOrder(this.state.cartList, orderType);
		if(orderSubmit != "" && typeof orderSubmit != 'undefined')
        this.props.history.push('/order-result'+orderSubmit)
	}

	async checkInventory() {
		var response = await checkInventory();
		if (response.data.status == 'success') {
			this.props.history.push('/preparing-checkout');
		} else {
			this.setState({ errors: response.data.errors });
		}
	}

	getSubtotal(){
		var sum=0
		this.state.cartList.map((total, index)=>{
			console.log(total.item_details.prod);
			if (index == 0) 
				this.setState({ unit: total.item_details.product_type_field_unit, currency: total.item_details.product_type_field_currency });
			
			if(typeof total.item_details !='undefined' && total.item_details.product_type_field_value !='')
				sum+=(total.quantity * parseInt(total.item_details.product_type_field_value)) - (((total.quantity * parseInt(total.item_details.product_type_field_value)) * total.dealer_discount) / 100);
    })
    this.setState({subTotal:sum})
	}

	async checkStorePickup(value,cart_id){
		var formdata = [];
		formdata['carts[delivery_prefrence]'] = (value == true)?'store pickup':'home delivery';
		formdata['carts[id]'] = cart_id;
		var response = await updateCart(formdata, cart_id);
		if(response.data.status == 'success'){
			let message = ""
			if(value == true)
				message = 'Delivery prefrence updated for store pickup';
			else
				message = 'Delivery prefrence updated for home delivery';
			await this.successToast(message);
		}
	}

	getItemsCount(){
		var sum=0
		this.state.cartList.map((total)=>{
			sum+=parseInt(total.quantity)
		})
		this.setState({itemsCount:sum})
	}
}
