const initialState = { addresses: [] }

const addresses = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_ADDRESSES':
      return { addresses: action.payload }; 
  }
  return state
}

export default addresses;