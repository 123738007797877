import React from 'react';
import {Container, Row, Col, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MetaTags from '../../../components/MetaTags';
import bikeManualBackpackImg from '../../../assets/images/thumbnail/manual-bike-backpack.jpg';

import pdf1 from '../../../assets/pdf/Backpack-Manual/Backpack_BikeTransportBagPremium20_Manual_320x233_2016_BIKE_SCOTT-Sports_Multi.pdf';
import pdf2 from '../../../assets/pdf/Backpack-Manual/Backpack_BikeTransportBagPremium20_Manual_A5_2018_BIKE_SCOTT-Sports_Multi.pdf';
import pdf3 from '../../../assets/pdf/Backpack-Manual/Backpack_BikeTransportBagRepairSet_Leaflet_A5_2019_BIKE_SCOTT-Sports_Multi.pdf';


class ManualsBackpacksView extends React.Component {	
	componentDidMount() {
	  window.scrollTo(0, 0)
	}
	render(){
		return (
			<React.Fragment> 
        <MetaTags {...this.props}/>
				<div className="main">
					<div className="main_section">
						<Container className="">
              <h1 className="page_heading black mb-4 text-uppercase bold"><Link to="/manuals-bike"><i className="fal fa-long-arrow-left pr-2 black" style={{fontWeight:'700'}}></i></Link> Backpack Manuals</h1>
							<Row className="pd7">
                <Col lg={3} sm={12} className="mb-4 mb-lg-0">
                  <ul className="nav nav-tabs vertical_tabs">
                    <li className="nav-item">
                      <a className="nav-link active" data-toggle="tab" href="#all_backpack">
                        <i className="far fa-chevron-right"></i> All Backpack Manuals | SCOTT Bike
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#sixteen_backpack">
                        <i className="far fa-chevron-right"></i> 2016 Backpack Manuals | SCOTT Bike
                      </a>
                    </li>                    
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#eighteen_backpack">
                        <i className="far fa-chevron-right"></i> 2018 Backpack Manuals | SCOTT Bike
                      </a>
                    </li>   
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#ninteen_backpack">
                        <i className="far fa-chevron-right"></i> 2019 Backpack Manuals | SCOTT Bike
                      </a>
                    </li>
                                      
                  </ul>
                </Col>
                <Col lg={9} sm={12}>
                  <div className="tab-content">
                    <div className="tab-pane container active" id="all_backpack">
                      <Row className="pd7 pdf_list">
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf1} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualBackpackImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Backpack_BikeTransportBagPremium20_Manual_320x233_2016_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf2} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualBackpackImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Backpack_BikeTransportBagPremium20_Manual_A5_2018_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf3} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualBackpackImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Backpack_BikeTransportBagRepairSet_Leaflet_A5_2019_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>                       
                      </Row>
                    </div>
                    <div className="tab-pane container fade" id="sixteen_backpack">
                      <Row className="pd7 pdf_list">                       
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf1} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualBackpackImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Backpack_BikeTransportBagPremium20_Manual_320x233_2016_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>                   
                      </Row>
                    </div>                    
                    <div className="tab-pane container fade" id="eighteen_backpack">
                      <Row className="pd7 pdf_list">
                        <Col md={3} sm={6} xs={6} className="mb-3">
                            <a href={pdf2} target="_blank">
                              <Card>
                                <Card.Body className="p-0">
                                  <img src={bikeManualBackpackImg} className="img-fluid" />
                                </Card.Body>
                                <Card.Footer>
                                  <p className="mb-0">Backpack_BikeTransportBagPremium20_Manual_A5_2018_BIKE_SCOTT-Sports_Multi</p>
                                </Card.Footer>
                              </Card>
                            </a>
                        </Col>
                      </Row>
                    </div>
                    <div className="tab-pane container fade" id="ninteen_backpack">
                      <Row className="pd7 pdf_list">
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf3} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualBackpackImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Backpack_BikeTransportBagRepairSet_Leaflet_A5_2019_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>  
                      </Row>
                    </div>                                   
                  </div>
                </Col>
              </Row>
						</Container>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default ManualsBackpacksView;