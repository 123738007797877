import React from 'react';
import {Container, Row, Col, Table, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import MetaTags from '../../../components/MetaTags';
import Faq from '../components/Faq';

class TermsConditions extends React.Component {	
	componentDidMount() {
	  window.scrollTo(0, 0)
	}
	render(){
		return (
			<React.Fragment> 
				<MetaTags {...this.props}/>
				<div className="main mt-5">
					<div className="main_section">
						<Container className="">
							<h1 className="page_heading bold text-uppercase black mb-3 text-left mb-3">Terms & Conditions</h1>
							<div>
								<p className="black bold">PLEASE READ THESE TERMS OF USE (“TERMS”), CAREFULLY. BY USING THIS PORTAL, YOU INDICATE YOUR UNDERSTANDING AND ACCEPTANCE OF THESE TERMS. IF YOU DO NOT AGREE TO THESE TERMS YOU MAY NOT USE THIS PORTAL, IN ITS ENTIRETY.</p>
							</div>
							<h1 className="page_heading bold text-uppercase black mb-3 text-left mb-3">Sale T&C</h1>
							<div>
								<p className="black bold text-uppercase">*STRICTLY NO EXCHANGE | NO RETURN I NO REFUND ON ALL SALE/DISCOUNTED STOCK Note: All offers & sale prices are valid only through the sale period & we reserve the right to withdraw offers without notice.</p>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">1. Definitions</h2>
								<ol type="1." className="pl-3">
									<li className="pb-2">“Buyer(s)” means the users who subscribe and register on the Portal for purchasing the Products.</li>
									<li className="pb-2">“User”, “You”, “Your” means any person who browses, views, accesses or uses this Portal;</li>
									<li className="pb-2">“Use” or “Using” means to browse, access, view, purchase the Products or otherwise gain benefit from using this Portal;</li>
									<li className="pb-2">“We”, “SNS”, “Us” and “Our” means Scott Network Solutions.</li>
									<li className="pb-2">“Portal” means www.sportnetwork.in and the mobile application duly owned, operated and maintained by SNS.</li>
									<li className="pb-2">“Products” shall mean and include Seller’s products, listed by the Sellers for selling and distributing to the Users on the Portal.</li>
									<li className="pb-2">“Sellers” shall include the individuals or the entities that offer the Products, through the Portal.</li>
								</ol>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">2. Introduction</h2>
								<p>Welcome to our Portal. Our Portal offers sports related products including but not limited to exclusive sports equipment, bikes, sports gear, sports apparel, sports accessories, water sports bikes, accessories, gear. If You continue to browse and use this Portal, You are agreeing to comply with and be bound by these Terms, which together with our privacy policy (“Privacy Policy”) govern SNS's relationship with You.</p>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">3. Eligibility</h2>
								<p>You represent and warrant that:</p>
								<ol type="a">
									<li className="pb-2">you are fully able, competent and authorized to enter into the terms, conditions, obligations, representations and warranties set forth in these Terms;</li>
									<li className="pb-2">you are of legal age to form a binding contract and are not a person barred from availing the Products under the applicable laws.</li> 
								</ol>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">4. User Account</h2>
								<p>You represent and warrant that:</p>
								<ol type="a">
									<li className="pb-2">In order to purchase the Products offered on this Portal, You are required to register and create a User Account with password (“User Account”). At the time of registration, You shall be required to share certain information such as name, age, gender, mobile no., address, city, state, email ID, date of birth, anniversary date, etc. with SNS. You understand that only upon the creation of User Account, you would be in a position to buy or purchase the Products offered on the Portal.</li>
										<li className="pb-2">Without prejudice to section 3.1 and 3.2 above, The Portal is also available to Users on a temporary basis, where Users may view, access and browse through the Portal, without creating a User Account.</li>
								</ol>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">5. Purchase and Delivery of Products</h2>
								<p>You represent and warrant that:</p>
								<ol type="a">
									<li className="pb-2">The Products are listed on the Portal; User shall place an order by signing in on the Portal, by using their User account. SNS has the sole right to accept or reject the order. On acceptance of an order, User shall receive a confirmation against the order.</li>
									<li className="pb-2">SNS shall not be responsible for any claims related to the quality, size, shape, colour, look and feel, texture, style, etc. of the Products as they might appear different on the Portal. Any claims to that effect shall be determined as per the refund, return and cancellation policy of the Portal.</li>
									<li className="pb-2">There are various delivery models for delivery of Product purchased by You, based on the business engagement between the SNS and the Seller. For certain Products including but not limited to bikes, You shall be required to obtain delivery directly from the Seller at the business-place of the Seller. You understand that, Sellers represent and warrant that the Products being delivered are not faulty or defective and are exactly the same as those Products, listed and advertised by Sellers on the Portal and purchased by the User and shall meet all descriptions and specifications as provided on the Portal, based on these representations of the Seller, SNS has listed the Products on the Portal.</li>
									<li className="pb-2">All Products purchased by the User shall be delivered by third party delivery agencies. Since SNS is merely an intermediary, SNS disclaims all claims and liabilities arising out of delivery of the Products. You understand that the Seller shall be liable for the loss, damage or deterioration of the Products during the course of delivery. We do not guarantee the delivery of Products within a fixed period of time. However, upon the acceptance of order, we would provide an estimated time of delivery, which may alter or change due any unforeseen circumstances. SNS shall not be liable for any loss or damage, including but not limited to, mental agony suffered by the User due to such delay.</li>
									<li className="pb-2">If in case the Users do not accept the Products delivered to them, Seller may have sole discretion to cancel the order and refund the payment to the User, in accordance with the refund policy.</li>
									<li className="pb-2">In case there are orders for multiple Products placed by the User, SNS shall deliver such Products based on the availability of the stock and may not be delivered simultaneously or will be delivered separately. SNS is not responsible for unsatisfactory or delayed delivery of Products or damages or delays as a result of Products which are out of stock or unavailable.</li>
									<li className="pb-2">All commercial/contractual terms are offered by and agreed to between Buyers and Sellers alone. The commercial/contractual terms include without limitation price, shipping costs, payment methods, payment terms, date, period and mode of delivery, warranties related to Products and after sales services related to Products. SNS does not have any control or does not determine or advise or in any way involve itself in the offering or acceptance of such commercial/contractual terms between the Buyers and Sellers.</li>
									<li className="pb-2">SNS does not make any representation or warranty as to specifics (such as quality, value, saleability, etc.) of the Products proposed to be sold or offered to be sold or purchased on the Portal. SNS does not implicitly or explicitly support or endorse the sale or purchase of any Products on the Portal. SNS accepts no liability for any errors or omissions, whether on behalf of itself or third parties, in relation to the Products, offered through the Portal.</li>
									<li className="pb-2">SNS is not responsible for any non-performance or breach of any contract entered into between Buyers and Sellers. SNS cannot and does not guarantee that the concerned Buyers and/or Sellers will necessarily perform any transaction concluded on the Portal. SNS shall not and is not required to mediate or resolve any dispute or disagreement between Buyers and Sellers.</li>
									<li className="pb-2">At no time shall SNS hold any right, title or interest over the Products nor shall SNS have any obligations or liabilities in respect of such contract entered into between Buyers and Sellers.</li>
								</ol>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">6. Anti Spam Policy</h2>
								<p>You certify that You will use the Services in accordance with these Terms of Use. You are forbidden to transmit unsolicited commercial email (spam) by using the Services. Additionally, you are required to reconfirm (permission pass) or stop mailing an existing list when it is determined to be in violation of our anti-spam policy. Repeated violations or failures to comply with this anti-spam policy will result in termination of Your access to the Services. You agree and acknowledge that, we shall not be held liable for any claim that may arise due to Your violation of this anti-spam policy.</p>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">7. Prohibited Conduct</h2>
								<p>You agree not to engage in any of the following activities:</p>
								<ul>
									<li>Violating laws and rights:
										<p>You shall not (a) use the Portal for any illegal purpose or in violation of any local, state, national, or international laws, (b) violate or encourage others to violate any right of or obligation to a third party, including but not limited to, by infringing, misappropriating, or violating intellectual property, confidentiality, or privacy rights.</p>
									</li>
									<li>Solicitation:
										<p>You shall not use the Portal for the transmission of advertising or promotional materials, including junk mail, spam, chain letters, or any other form of unsolicited or unwelcome solicitation.</p>
									</li>
									<li>Disruption:
										<p>You shall not use the Portal in any manner that could disable, overburden, damage, or impair the Portal, or interfere with any other party’s use and enjoyment of the Portal; including by (a) uploading or otherwise disseminating any virus, adware, spyware, worm or other malicious code, or (b) interfering with or disrupting any network, equipment, or server connected to or used to provide any of the Services on the Portal, or violating any regulation, policy, or procedure of any network, equipment, or server.</p>
									</li>
									<li>Harming others:
										<p>You shall not share or transmit contents or details that are harmful, offensive, obscene, abusive, invasive of privacy, defamatory, hateful or otherwise discriminatory, false or misleading, or incites an illegal act.</p>
									</li>
									<li>Impersonation or unauthorized access:
										<p>You shall not impersonate another person or entity, or misrepresent your affiliation with a person or User, while using the Portal.</p>
										<p>You shall not use or attempt to use another’s account or personal information; and, you shall not attempt to gain unauthorized access to the Portal, or the computer systems or networks connected to the Portal, through hacking password mining or any other means.</p>
									</li>
								</ul>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">8. Payment</h2>
								<ol type="a">
									<li className="pb-2">Currently, the Portal is free of cost for the Users for accessing or buying the Products. However, the SNS reserves that right to levy certain fees or charges, to the Users, for the use of the Portal or certain features available at the Portal. You agree to pay any such fees, as may be applicable. You understand that the SNS may, at any time, charge, modify or waive fees required to use the Portal. Your continued use of the Portal after such change shall be considered to be Your acceptance of such changes, and the applicability of these Terms of Use to such changes.</li>
									<li className="pb-2">Prices for Products would be listed on the Portal and are incorporated into these Terms of Use by reference. All prices are exclusive of taxes unless stated otherwise and are in Indian rupees. Prices, Products and services are offered by the respective Seller and may change in accordance with the brand guidelines or other terms and conditions applicable to each Seller.</li>
									<li className="pb-2">Users can pay for the Products through various modes of payment:
										<ul>
											<li className="pb-2">Debit Card/Credit Card/ATM Card</li>
											<li className="pb-2">Net Banking</li>
											<li className="pb-2">E-Wallets</li>
											<li className="pb-2">Any other mode of payment as may be acceptable to SNS</li>
										</ul>
									</li>
									<li className="pb-2">Use of the payment facilities provided by the Portal shall not render the Portal liable or responsible for the non-delivery, non-receipt, non-payment, damage, breach of representations and warranties, non-provision of after sales or warranty services or fraud as regards the Products listed on the Portal. The Portal shall not be responsible for any damages, interests or claims arising from not processing a transaction. We should receive any payment made by credit card or any other mode as mentioned above on time. If for any reason We do not receive payment from the credit card issuer or any agent involved in any of the above payment modes, you agree that You shall pay all amounts due upon demand by Us.</li>
									<li className="pb-2">Every User hereby agrees to provide accurate information, such as credit/debit information for purchasing Products on the Portal.  Every User further warrants that he/she shall not use payment information or instrument that is not lawfully owned by the User. The Portal disclaims all liabilities arising out of loss of any information pertaining to the Confidentiality of the credit/debit card details or pre-paid instrument account. In addition to these Terms of Use, the terms and conditions of the bank or other financial institution shall also be applicable to every User. The Portal disclaims any liability arising out of declining of payment by such bank or financial institution.</li>
									<li className="pb-2">The Portal may in its sole discretion impose limits on the number of transaction which an individual holding a financial instrument may use for payment for Products.</li>
									<li className="pb-2">The Portal is merely a facilitator for providing the User with payment channels through automated online electronic payments (either itself or through Sellers), cash on delivery, collection and remittance facility for the payment of Products purchased by the User on the Portal using the existing authorized banking infrastructure and credit card payment gateway networks (of either the Portal or Sellers).</li>
								</ol>
							</div>		
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">9. Return Policy and Cancellation Policy</h2>
								<p>The Portal has a refund, return and cancellation policy which is detailed in <a href={window.location.origin+"/buyer-return-policy"} target="_blank">Buyer Return Policy</a></p>
							</div>		
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">10. Advertisements</h2>
								<p>The Portal may post, display, publish or provide advertisements at various places on the Portal. If You access any of these advertisements the terms and conditions of those links will govern Your usage. These advertisements might set cookies on their websites which may use Your data, information for various other links. You are advised to refer to the relevant terms and conditions of the respective advertisements to know more about their practice. SNS does not endorse, recommend, market or warrant the advertisements, or quality, suitability, merchantability, fitness, commitment, reasonability of the products offered by such links. SNS shall not be responsible for any of these advertisements.</p>
							</div>		
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">11. Ownership of Intellectual Property</h2>
								<ol type="a">
									<li className="pb-2">The Portal and all the rights including but not limited to intellectual property rights subsisting under or in relation to the Portal are owned by SNS and its affiliates, subsidiaries, licensors as the case may be. SNS respects copyright, and we prohibit Users from submitting, uploading, posting, or otherwise transmitting any Content or details on the Portal that violates another person’s proprietary rights.</li>
									<li className="pb-2">If You believe that the Portal contains elements that infringe Your intellectual property rights in Your work, please notify Us immediately. If we receive intellectual property right infringement claim notification, and it is felt appropriate, we may remove all such content which is indicated as infringing and/or take any other appropriate action at our discretion.</li>  
									<li className="pb-2">All materials on this Portal, including but not limited to audio, images, photographs, software, text, icons and such like (the “Portal Content”), are protected by copyright under the copyright laws or any other relevant intellectual property laws. You cannot use the Portal Content, except as specified herein.</li>
									<li className="pb-2">There may be proprietary logos, service marks and trademarks found on the Portal whether owned/used by us or otherwise. By displaying them on the Portal, we are not granting You any license to utilize those proprietary logos, service marks, or trademarks. Any unauthorized use of the Portal Content may violate copyright laws, trademark laws, the laws of privacy and publicity, and civil and criminal statutes.</li>
								</ol>
							</div>	
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">12. Indemnification</h2>
								<p>You agree to indemnify, defend and hold SNS, its partners, subsidiaries, affiliates, associates, successors, assigns and licensors or any of their respective officers, agents, vendors, licensors, representatives, advertisers, Sellers harmless from and against any and all claims, actions, losses, expenses, damages and costs (including attorneys' fees), arising out of Your use of the Portal or resulting from any i) breach or violation of these Terms of Use by You, or due to Your activities related to the Portal or due to any breach of applicable laws ii) any claims made by any third party due to, or arising out of, or in connection with, Your use of the Portal.</p>
							</div>		
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">13. Disclaimer and Limitations of Liability</h2>
								<p>THE PORTAL, CONTENT, MATERIALS, PRODUCTS ARE MADE AVAILABLE TO YOU ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY REPRESENTATION OR WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, OR ANY GUARANTEE OR ASSURANCE THAT ALL FEATURES, FUNCTIONS OR OPERATIONS THEREOF WILL BE AVAILABLE. WITHOUT LIMITING THE FOREGOING, SNS SHALL NOT BE RESPONSIBLE OR LIABLE FOR ANY MALICIOUS CODE, DELAYS, INACCURACIES, ERRORS, OR OMISSIONS. YOU UNDERSTAND, ACKNOWLEDGE AND AGREE THAT YOU ARE ASSUMING THE ENTIRE RISK AS TO YOUR DATA, ITS QUALITY, ACCURACY, PERFORMANCE, TIMELINESS, ADEQUACY, COMPLETENESS, CORRECTNESS, AUTHENTICITY, SAFETY, SECURITY AND VALIDITY OF ANY AND ALL FEATURES AND FUNCTIONS OF THE PORTAL INCLUDING, WITHOUT LIMITATION, POSTINGS AND MATERIALS ASSOCIATED WITH YOUR USE OF THE PORTAL, OR YOUR OWN INFORMATION AND DATA THAT YOU VOLUNTARILY SHARE ON THE PORTAL.</p>
								<p>YOU UNDERSTAND AND AGREE THAT, TO THE FULLEST EXTENT PERMISSIBLE BY LAW, THE PORTAL, SNS AND THEIR SUCCESSORS AND ASSIGNS, OR ANY OF THEIR AFFILIATES, OR THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, LICENSORS, REPRESENTATIVES, OPERATIONAL SELLERS, ADVERTISERS, OR VENDORS, SHALL NOT BE LIABLE FOR ANY LOSS OR DAMAGE, OF ANY KIND, DIRECT OR INDIRECT, IN CONNECTION WITH OR ARISING FROM USE OF THE PRODUCTS OR THE PORTAL, OR FOR THE ACCURACY, RELIABILITY OR CONTENT OF ANY INFORMATION, PRODUCTS PROVIDED THROUGH THE PORTAL, BUT NOT LIMITED TO, COMPENSATORY, CONSEQUENTIAL, INCIDENTAL, INDIRECT, SPECIAL OR PUNITIVE DAMAGES. SNS SHALL NOT BE RESPONSIBLE FOR THE DELAY, INTERRUPTION OR INABILITY TO USE THE PORTAL OR FAILURE TO PROVIDE THE PRODUCTS, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE. FURTHER, SNS SHALL NOT BE HELD RESPONSIBLE FOR NON-AVAILABILITY OF THE PORTAL DURING PERIODIC MAINTENANCE OPERATIONS OR ANY UNPLANNED SUSPENSION OF ACCESS TO THE PORTAL THAT MAY OCCUR DUE TO TECHNICAL REASONS OR FOR ANY REASON BEYOND SNS’S CONTROL.  NOTWITHSTANDING ANYTHING CONTAINED IN THIS AGREEMENT OR OTHERWISE, THE MAXIMUM LIABILITY OF SNS FOR DIRECT DAMAGES UNDER THIS AGREEMENT SHALL NOT EXCEED RUPEES THOUSAND (RS.1000/-).</p>
							</div>				
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">14. Force Majeure</h2>
								<p>Without limiting the foregoing, under no circumstances shall SNS be held liable for any damage or loss, penalty due to delay or deficiency in performance of the Portal or availability of the Products,  resulting directly or indirectly from act of nature, natural forces, or causes beyond its reasonable control, including, without limitation, Internet failures, change in law, government regulations, computer equipment failures, telecommunication failures, major electricity failures, internet outage, downtime, floods, storms, accidents, civil disturbances, riots, strikes, shortages of labour, fluctuations in heat, light, fire or air.</p>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">15. Entire Agreement</h2>
								<p>These Terms of Use along with the Privacy Policy contain the sole legal understanding and agreement between You and SNS and supersedes any and all prior understandings.</p>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">16. Notice</h2>
								<p>All notices of SNS will be served by email or by general notification on the Portal. Any notice provided to SNS pursuant to these Terms of Use should be sent to support services, email ID of SNS or registered office address of SNS.</p>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">17. Changes</h2>
								<p>We have and continue to have the right to modify, change, substitute, remove, suspend or update these Terms of Use or any information in it at any time by sufficiently highlighting on the Portal about such change. Such changes shall be effective immediately upon posting to the Portal. We assume no responsibility for functionality which is dependent on Your browser or other third party software to operate. For the avoidance of doubt, we may also withdraw any information from the Portals at any time.</p>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">18. Applicable Law</h2>
								<p>These Terms of Use shall be governed by and interpreted and construed in accordance with the laws of India. The courts in Pune shall have exclusive jurisdiction in respect of any matters arising hereunder.</p>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">19. No Agency Relationship</h2>
								<p>You agree that no joint venture, employment, or agency relationship exists between You and SNS as a result of these Terms of Use or from Your use of the Portal.</p>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">20. Severability</h2>
								<p>If any part of these Terms of Use is held to be invalid or unenforceable by any law or regulation or final determination of a competent court or tribunal, that provision will be deemed severable and will not affect the validity and enforceability of the remaining provisions.</p>
							</div>
						</Container>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default TermsConditions;