import React from 'react';
import {Container, Row, Col, Carousel, Button,Image} from 'react-bootstrap';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import {Link} from 'react-router-dom';

import CoreHome from '../../../../core/components/home/CoreHome';
import MetaTags from '../../../components/MetaTags';


import logo from '../../../assets/images/avanti/logo.jpg';
import bannerHeading from '../../../assets/images/avanti/banner-heading.png';
import headingOne from '../../../assets/images/avanti/heading1.jpg';
import headingTwo from '../../../assets/images/avanti/heading2.jpg';
import secOneImg from '../../../assets/images/avanti/sec1-img.jpg';
import secTwoImg from '../../../assets/images/avanti/sec2-img.jpg';
import bannerRed from '../../../assets/images/avanti/1A.jpg';
import bannerwhite from '../../../assets/images/avanti/1B.jpg';
import bannerH from '../../../assets/images/avanti/Break-free-banner-AW.jpg';
import fm1 from "../../../../web/assets/images/avanti/FM1.jpg";
import f1 from "../../../../web/assets/images/avanti/F1.jpg";

class Avanti2 extends CoreHome { 
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    const { homeBanner,tags } = this.state;
    return (
      <React.Fragment>  
      <div>
      <div className="" >          
  
          <div id="top" style={{maxWidth:"100%",height:"auto"}}>
                     <img src={bannerH}  className='img-fluid' />
                  </div>

          <div className="main-section" >
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6">
                  <img src={headingOne} className="img-fluid" />
                  <div className="mt-5 sec_content" >
                    <p style={{fontSize:'17px'}}>We’re RIDE pretty sprightly for a world class cycling business of this vintage.</p>
                    <p style={{fontSize:'17px'}} >From the humblest of beginnings, we’ve pioneered and innovated cycling. Asking questions about how and why bikes are built and work, then looking at ways of doing them better. Bike design is constantly improving, with new technologies, componentry and materials appearing on the market every year - the sheer responsiveness of our design team means we are always innovating and pushing the boundaries, making sure we stay out in front. We proudly call this approach ‘Avanti Design Technology’. It’s what the ADT insignia means, it’s what has given us the edge and it’s the reason why we’ve achieved multiple design awards, Olympic medals and, more importantly, a loyal following of bike riders along the way.</p>
                    <p style={{fontSize:'17px'}} >We’re proud to be an Australasian based business that creates bikes with the rider’s purpose and enjoyment in mind, to not only exceed expectations but also deliver the best experience possible on two wheels.</p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <img src={secOneImg} className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
      
          <div className="row mt-5">
            <div className="col col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                <div id="carousel-example" className="carousel slide">
                    
                    <div className="carousel-inner" style={{marginBottom:"20px"}}>
                        <div className="carousel-item active">
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/YRACA_mGMOQ" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                    {/* <a className="carousel-control-prev" href="#carousel-example" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carousel-example" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a> */}
                </div>
            
            </div>
        </div> 

            
            {/* <div className="container-fluid pl-lg-0">
              <div className="row d-flex flex-column-reverse flex-lg-row">
                <div className="col-lg-6">
                  <img src={secTwoImg} className="img-fluid" />
                </div>
                <div className="col-lg-6">
                  <img src={headingTwo} className="img-fluid" />
                  <div className="mt-5 sec_content">
                    <p>Our purpose has been the same since our early days back in 1985.</p>
                    <p><b>To create bikes with the rider’s purpose and enjoyment in mind, to not only exceed expectations but also deliver the best experience possible on two wheels.</b></p>
                    <p>The bike industry is an ever-changing beast. Innovation, safety, parts and materials are not unique selling points; they are expected and demanded from discerning loyal followers and potential Avanti purchasers like you.</p>
                    <p>We understand this so our tight knit, passionate design team is constantly innovating, and doing so faster than the others. We can zig while the other bike brands are zagging. All the time striving for the best performance and the best possible value for money.</p>
                  </div>
                </div>            
              </div>
            </div> */}
          
          
          
          <div className='mb-3'> 
                    <Link to="/cpl/c5dbf9a860492e11d0d22c4abd7dfb1e">
                        <Image src={f1} style={{width:"50%"}}/>
                    </Link>
                    <Link to="/cpl/b18634daad46c2a21fd0fb3289831171">
                        <Image src={fm1} style={{width:"50%"}}/>
                    </Link>
                  </div>
               

        </div>
      </div>
        
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings,
    styles: state.styles.styles,
    categories:state.categories.categories,
    brands:state.brands.brands,
  }
}

export default withTranslation()(connect(mapStateToProps, null)(Avanti2));