import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import CoreEditAddresses from '../../../../core/components/transactions/CoreEditAddresses';
import Addresses from '../components/Addresses';
import { connect } from "react-redux";

class EditAddresses extends CoreEditAddresses {

	render(){
		const { t } = this.props;		
		return (
			<React.Fragment> 
				<div className="main mb-5 mt-5">
					<Container>			
						<h5 className="bold text-uppercase black mt-5">{t('checkout')} {t('details')}</h5>
						<Row className="flex-column-reverse flex-sm-row">
				  		<Col lg={8}>				  			
							<hr />
							<div className="tab-content">
							  <div className="tab-pane container active p-0" id="checkout">
							  	<div className="tab-content">								  		
										<div className="tab-pane container active p-0">
										 	<Addresses 
										 		{...this.props} 
										 		shippingAddress={this.state.shippingAddress} 
										 		shippingState={this.state.statesArray.shippingAddress.states} 
										 		statesArray={this.state.statesArray} 
										 		showBillingAddress={false}
										 		setStatesArray={true}
										 	/>
										</div>
									</div>
							  </div>					  
							</div>
				  	</Col>
				  </Row>
				</Container>
				
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
  return {
  	user: state.users.user,
    settings: state.settings.settings,
    styles:state.styles.styles,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCartCount: (count) => {dispatch({ type: 'SET_CART_COUNT', payload: count})},
    setCartProducts: (products) => {dispatch({ type: 'SET_CART_PRODUCTS', payload: products})},
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(EditAddresses));