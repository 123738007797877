import React from 'react';
import {Container, Row, Col, Table, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import MetaTags from '../../../components/MetaTags';
import ScottWarranty from '../components/ScottWarranty';
import BergamontWarranty from '../components/BergamontWarranty';
import SyncrosWarranty from '../components/SyncrosWarranty';
import ProbikeWarranty from '../components/ProbikeWarranty';
import AvantiWarranty  from '../components/AvantiWarranty';

class Warranty extends React.Component {	
	componentDidMount() {
	  window.scrollTo(0, 0)
	}
	render(){
		return (
			<React.Fragment> 
				<MetaTags {...this.props}/>
				<div className="main mt-5">					
					<div className="main_section">
						<Container className="">
							<h1 className="page_heading bold text-uppercase black mb-3 text-left mb-5">Warranty</h1>						
							<ul className="nav nav-pills nav-justified warranty_tabs">
							  <li className="nav-item">
								<a className="nav-link active text-uppercase bold" data-toggle="pill" href="#scottWarranty">Scott</a>
							  </li>
							  <li className="nav-item">
								<a className="nav-link text-uppercase bold" data-toggle="pill" href="#bergamontWarranty">Bergamont</a>
							  </li>
							  <li className="nav-item">
								<a className="nav-link text-uppercase bold" data-toggle="pill" href="#syncrosWarranty">Syncros</a>
							  </li>
							  <li className="nav-item">
								<a className="nav-link text-uppercase bold" data-toggle="pill" href="#probikeWarranty">Probike</a>
							  </li>
							  <li className="nav-item">
								<a className="nav-link text-uppercase bold" data-toggle="pill" href="#AvantiWarranty">Avanti</a>
							  </li>
							  
							</ul>							
							<div className="tab-content">
							  <div className="tab-pane container active" id="scottWarranty">
								<ScottWarranty />
							  </div>
							  <div className="tab-pane container fade" id="bergamontWarranty">
								<BergamontWarranty />
							  </div>
							  <div className="tab-pane container fade" id="syncrosWarranty">
								<SyncrosWarranty />
							  </div>
							  <div className="tab-pane container fade" id="probikeWarranty">
								<ProbikeWarranty />
							  </div>
							  <div className="tab-pane container fade" id="AvantiWarranty">
								<AvantiWarranty />
							  </div>
							</div>
						</Container>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Warranty;