const initialState = { user: {}, categories:[], subCategories: {}, subSubCategories: {}}

const users = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_USER':
    	return { user: action.payload, categories: state.categories, subCategories: state.subCategories, subSubCategories: state.subSubCategories }; 
		case 'SET_CATEGORIES':
			return { user: state.user, categories: action.payload, subCategories: state.subCategories, subSubCategories: state.subSubCategories };
		case 'SET_SUB_CATEGORIES':
			return { user: state.user, categories: state.categories, subCategories: action.payload, subSubCategories: state.subSubCategories };
		case 'SET_SUB_SUB_CATEGORIES':
			return { user: state.user, categories: state.categories, subCategories: state.subCategories, subSubCategories: action.payload };
		 
		 
  }
  return state
}

export default users;