import React from 'react';
import { Container, Row, Col, Table, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import pdf1 from '../../../../web/assets/images/AvantiPdf/Warranty-Process.pdf';
import pdf2 from '../../../../web/assets/images/AvantiPdf/Addict-22-FD-Plate.pdf';
import pdf3 from '../../../../web/assets/images/AvantiPdf/Addict-RC-Di2-Installation.pdf';
import pdf4 from '../../../../web/assets/images/AvantiPdf/Foil-Seatpost-Assembly.pdf';
import pdf5 from '../../../../web/assets/images/AvantiPdf/Helmet-AeroPlugs-Cadence_Leaflet.pdf';

import logo from '../../../../web/assets/images/avanti/paper-roll.png';


import MetaTags from '../../../components/MetaTags';


class AvantiPDF extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (

      <React.Fragment> 
      <MetaTags {...this.props}/>
      <div className="main">
        <div className="main_section">
          <Container className="">
            <h1 className="page_heading black mb-4 text-uppercase bold"><Link to="/manuals-bike"><i className="fal fa-long-arrow-left pr-2 black" style={{fontWeight:'700'}}></i></Link> Bike Manuals</h1>
            <Row className="pd7">
              <Col lg={3} sm={12} className="mb-4 mb-lg-0">
                {/* <ul className="nav nav-tabs vertical_tabs"> 
                  <li className="nav-item">
                    <a className="nav-link active" data-toggle="tab" href="#all_bike">
                      <i className="far fa-chevron-right"></i>AVANTI BIKE
                    </a>
                  </li>
                   
                         
                </ul> */}
              </Col>
              <Col lg={9} sm={12}>
                <div className="tab-content">
                  <div className="tab-pane container active" id="all_bike">
                    <Row className="pd7 pdf_list">
                      <Col md={3} sm={6} xs={6} className="mb-3">
                        <a href={pdf1} target="_blank">
                          <Card>
                            <Card.Body className="p-0 mt-4 mb-4 " style={{marginLeft:'17%'}}>
                              <img src={logo} className="img-fluid"  />
                            </Card.Body>
                            <Card.Footer>
                              <p className="mb-0">Warranty Process</p>
                            </Card.Footer>
                          </Card>
                        </a>
                      </Col>
                      <Col md={3} sm={6} xs={6} className="mb-3" id="all_bike">
                        <a href={pdf2} target="_blank" >
                          <Card>
                            <Card.Body className="p-0 mt-4 mb-4 " style={{marginLeft:'17%'}}>
                              <img src={logo} className="img-fluid" />
                            </Card.Body>
                            <Card.Footer>
                              <p className="mb-0">Addict 22 FD Plate</p>
                            </Card.Footer>
                          </Card>
                        </a>
                      </Col>
                      <Col md={3} sm={6} xs={6} className="mb-3">
                        <a href={pdf3} target="_blank">
                          <Card>
                            <Card.Body className="p-0 mt-4 mb-4 " style={{marginLeft:'17%'}}>
                              <img src={logo} className="img-fluid" />
                            </Card.Body>
                            <Card.Footer>
                              <p className="mb-0">Addict RC Di2 Installation</p>
                            </Card.Footer>
                          </Card>
                        </a>
                      </Col>
                      <Col md={3} sm={6} xs={6} className="mb-3">
                        <a href={pdf4} target="_blank">
                          <Card>
                            <Card.Body className="p-0 mt-4 mb-4 " style={{marginLeft:'17%'}}>
                              <img src={logo} className="img-fluid" />
                            </Card.Body>
                            <Card.Footer>
                              <p className="mb-0">Foil Seatpost Assembly</p>
                            </Card.Footer>
                          </Card>
                        </a>
                      </Col>
                      <Col md={3} sm={6} xs={6} className="mb-3">
                        <a href={pdf5} target="_blank">
                          <Card>
                            <Card.Body className="p-0 mt-4 mb-4 " style={{marginLeft:'17%'}}>
                              <img src={logo} className="img-fluid" />
                            </Card.Body>
                            <Card.Footer>
                              <p className="mb-0">Helmet-AeroPlugs</p>
                            </Card.Footer>
                          </Card>
                        </a>
                      </Col>
                      {/* <Col md={3} sm={6} xs={6} className="mb-3">
                        <a href=''>
                          <Card>
                            <Card.Body className="p-0 mt-4 mb-4 " style={{marginLeft:'17%'}}>
                              <img src={logo} className="img-fluid" />
                            </Card.Body>
                            <Card.Footer>
                              <p className="mb-0">Warranty process</p>
                            </Card.Footer>
                          </Card>
                        </a>
                      </Col>
                      <Col md={3} sm={6} xs={6} className="mb-3">
                        <a href=''>
                          <Card>
                            <Card.Body className="p-0 mt-4 mb-4 " style={{marginLeft:'17%'}}>
                              <img src={logo} className="img-fluid" />
                            </Card.Body>
                            <Card.Footer>
                              <p className="mb-0">Warranty process</p>
                            </Card.Footer>
                          </Card>
                        </a>
                      </Col>
                      <Col md={3} sm={6} xs={6} className="mb-3">
                        <a href=''>
                          <Card>
                            <Card.Body className="p-0 mt-4 mb-4 " style={{marginLeft:'17%'}}>
                              <img src={logo} className="img-fluid" />
                            </Card.Body>
                            <Card.Footer>
                              <p className="mb-0">Warranty process</p>
                            </Card.Footer>
                          </Card>
                        </a>
                      </Col>
                      <Col md={3} sm={6} xs={6} className="mb-3">
                        <a href=''>
                          <Card>
                            <Card.Body className="p-0 mt-4 mb-4 " style={{marginLeft:'17%'}}>
                              <img src={logo} className="img-fluid" />
                            </Card.Body>
                            <Card.Footer>
                              <p className="mb-0">Warranty process</p>
                            </Card.Footer>
                          </Card>
                        </a>
                      </Col>
                      <Col md={3} sm={6} xs={6} className="mb-3">
                        <a href=''>
                          <Card>
                            <Card.Body className="p-0 mt-4 mb-4 " style={{marginLeft:'17%'}}>
                              <img src={logo} className="img-fluid" />
                            </Card.Body>
                            <Card.Footer>
                              <p className="mb-0">Warranty process</p>
                            </Card.Footer>
                          </Card>
                        </a>
                      </Col>
                    </Row>
                  </div>
                  <div className="tab-pane container fade" id="fourteen_bike">
                    <Row className="pd7 pdf_list">                       
                      <Col md={3} sm={6} xs={6} className="mb-3">
                        <a href=''>
                          <Card>
                            <Card.Body className="p-0 mt-4 mb-4 " style={{marginLeft:'17%'}}>
                              <img src={logo} className="img-fluid" />
                            </Card.Body>
                            <Card.Footer>
                              <p className="mb-0">Warranty process</p>
                            </Card.Footer>
                          </Card>
                        </a>
                      </Col>
                      <Col md={3} sm={6} xs={6} className="mb-3">
                        <a href='' target="_blank">
                          <Card>
                            <Card.Body className="p-0 mt-4 mb-4 " style={{marginLeft:'17%'}}>
                              <img src={logo} className="img-fluid" />
                            </Card.Body>
                            <Card.Footer>
                              <p className="mb-0">Warranty process</p>
                            </Card.Footer>
                          </Card>
                        </a>
                      </Col>                        
                    </Row>
                  </div>
                  <div className="tab-pane container fade" id="fifteen_bike">
                    <Row className="pd7 pdf_list">                       
                      <Col md={3} sm={6} xs={6} className="mb-3">
                        <a href='' target="_blank">
                          <Card>
                            <Card.Body className="p-0 mt-4 mb-4 " style={{marginLeft:'17%'}}>
                              <img src={logo} className="img-fluid" />
                            </Card.Body>
                            <Card.Footer>
                              <p className="mb-0">Warranty process</p>
                            </Card.Footer>
                          </Card>
                        </a>
                      </Col>  
                      <Col md={3} sm={6} xs={6} className="mb-3">
                        <a href='' target="_blank">
                          <Card>
                            <Card.Body className="p-0 mt-4 mb-4 " style={{marginLeft:'17%'}}>
                              <img src={logo} className="img-fluid" />
                            </Card.Body>
                            <Card.Footer>
                              <p className="mb-0">Warranty process</p>
                            </Card.Footer>
                          </Card>
                        </a>
                      </Col>
                      <Col md={3} sm={6} xs={6} className="mb-3">
                        <a href='' target="_blank">
                          <Card>
                            <Card.Body className="p-0 mt-4 mb-4 " style={{marginLeft:'17%'}}>
                              <img src={logo} className="img-fluid" />
                            </Card.Body>
                            <Card.Footer>
                              <p className="mb-0">Warranty process</p>
                            </Card.Footer>
                          </Card>
                        </a>
                      </Col>   
                      <Col md={3} sm={6} xs={6} className="mb-3">
                        <a href='' target="_blank">
                          <Card>
                            <Card.Body className="p-0 mt-4 mb-4 " style={{marginLeft:'17%'}}>
                              <img src={logo} className="img-fluid" />
                            </Card.Body>
                            <Card.Footer>
                              <p className="mb-0">Warranty process</p>
                            </Card.Footer>
                          </Card>
                        </a>
                      </Col>                   
                    </Row>
                  </div>
                  <div className="tab-pane container fade" id="sixteen_bike">
                    <Row className="pd7 pdf_list">                       
                      <Col md={3} sm={6} xs={6} className="mb-3">
                        <a href='' target="_blank">
                          <Card>
                            <Card.Body className="p-0 mt-4 mb-4 " style={{marginLeft:'17%'}}>
                              <img src={logo} className="img-fluid" />
                            </Card.Body>
                            <Card.Footer>
                              <p className="mb-0">Warranty process</p>
                            </Card.Footer>
                          </Card>
                        </a>
                      </Col>
                      <Col md={3} sm={6} xs={6} className="mb-3">
                        <a href='' target="_blank">
                          <Card>
                            <Card.Body className="p-0 mt-4 mb-4 " style={{marginLeft:'17%'}}>
                              <img src={logo} className="img-fluid" />
                            </Card.Body>
                            <Card.Footer>
                              <p className="mb-0">Warranty process</p>
                            </Card.Footer>
                          </Card>
                        </a>
                      </Col>
                      <Col md={3} sm={6} xs={6} className="mb-3">
                        <a hhref='' target="_blank">
                          <Card>
                            <Card.Body className="p-0 mt-4 mb-4 " style={{marginLeft:'17%'}}>
                              <img src={logo} className="img-fluid" />
                            </Card.Body>
                            <Card.Footer>
                              <p className="mb-0">Warranty process</p>
                            </Card.Footer>
                          </Card>
                        </a>
                      </Col>
                      <Col md={3} sm={6} xs={6} className="mb-3">
                        <a href='' target="_blank">
                          <Card>
                            <Card.Body className="p-0 mt-4 mb-4 " style={{marginLeft:'17%'}}>
                              <img src={logo} className="img-fluid" />
                            </Card.Body>
                            <Card.Footer>
                              <p className="mb-0">Warranty process</p>
                            </Card.Footer>
                          </Card>
                        </a>
                      </Col>                 */}
                    </Row>
                  </div>                    
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
    );
  }
}

export default AvantiPDF;
