import React from 'react';
import {Container, Row, Col, Button, Card, Modal} from 'react-bootstrap';
import {connect} from 'react-redux'
import { withTranslation } from 'react-i18next';

import CoreOrdersDetail from '../../../../core/components/transactions/CoreOrdersDetail';
import OrderProductDetail from '../components/OrderProductDetail';
import OrderSummeryContent from '../components/OrderSummeryContent';
import { dealersPortal } from  '../../../../core/helpers/OperationsHelper';
import Loader from '../../../components/Loader';
import OrderNotFound from '../../../components/OrderNotFound';
import successImg from '../../../../web/assets/images/icons/checked-big.png';
import Tostify from '../../../components/Toaster';
import ReturnComponent from '../components/ReturnPopup';



class OrderDetails extends CoreOrdersDetail {

	render(){
		const {orderDetail} = this.state
		const { t } = this.props;
		return (
			<React.Fragment> 
				<Tostify />
				<div className="main mt-5">
					<div className="main_section">	
						{this.state.loader == false && (
							<Container>
								{Object.keys(orderDetail).length > 0 ? 
									<React.Fragment>
										<Row>
											{this.state.showSuccessMessage == true && (
												<Col md={12} className="text-center mx-auto">
													<img src={successImg}  className="mb-3" width="60px" />
													<h3 className="black medium">{t('thankYou')}!</h3>
													<h5 className="black">{t('orderReceive')}</h5>
												</Col>
											)}
										</Row>
										<Row>
											<Col md={6}>
												<h1 className="page_heading bold text-uppercase black mb-2">{t('orderDetails')}</h1>
											</Col>														
										</Row>
										<Row className="order_detail_head">
											<Col xs={8}>
												<div className="item_action">						
													<div className="item_action_link">
														<span>{this.state.orderDetail.date}</span>
													</div>
													<div className="item_action_link">
														<span>{t('order')} # {this.state.orderDetail.id}</span>
													</div>									
												</div>
											</Col>										
										</Row>
										<Row id="printableArea">
											<Col lg={12}>
												<Card className="order_card mb-3">				    			
								    			<Card.Body>
								    				<Row>
								    					<Col md={4} className="mb-3 mb-sm-0">
								    						<div>
								    							<h2 className="data_heading bold mb-1">{t('shippingAddress')}</h2>
								    							{(typeof orderDetail.shipping_address != "undefined" &&  orderDetail.billing_address!= null)?
								    								<React.Fragment>
								    									{dealersPortal == true && (<p className="mb-0">{orderDetail.shipping_address.trading_name}</p>)}
													  					{dealersPortal == false && (<p className="mb-0">{orderDetail.shipping_address.name}</p>)}
										    							<p className="mb-0">{orderDetail.shipping_address.street_address}</p>
										    							<p className="mb-0">{orderDetail.shipping_address.postal_code}</p>
										    							<p className="mb-0">{orderDetail.shipping_address.city+', '
										    																	+orderDetail.shipping_address.state}</p>
										    							<p className="mb-0">{orderDetail.shipping_address.country}</p>
									    							</React.Fragment>:<p className="mb-0">-</p>
								    							}
								    						</div>
								    					</Col>

								    					<Col md={4} className="mb-3 mb-sm-0">
								    						<div>
								    							<h2 className="data_heading bold mb-1">{t('billingAddress')}</h2>
								    							{(typeof orderDetail.billing_address != "undefined" &&  orderDetail.billing_address!= null)?
								    								<React.Fragment>
								    									{dealersPortal == true && (<p className="mb-0">{(orderDetail.billing_address.trading_name != null)?orderDetail.billing_address.trading_name:'-'}</p>)}
													  					{dealersPortal == false && (<p className="mb-0">{(orderDetail.billing_address.name != null)?orderDetail.billing_address.name:'-'}</p>)}
										    							<p className="mb-0">{orderDetail.billing_address.street_address}</p>
										    							<p className="mb-0">{orderDetail.billing_address.postal_code}</p>
										    							<p className="mb-0">{orderDetail.billing_address.city+', '
										    																	+orderDetail.billing_address.state}</p>
										    							<p className="mb-0">{orderDetail.billing_address.country}</p>
									    							</React.Fragment>:<p className="mb-0">-</p>
								    							}
								    						</div>
								    					</Col>								    					
								    					<Col md={4} className="">
								    						<h2 className="data_heading bold mb-1">{t('orderSummary')}</h2>
								    						<OrderSummeryContent 
								    							orderSummery={this.state.orderDetail} 
								    							currency={this.state.orderDetail.product_type_field_currency} 
								    							unit={this.state.orderDetail.product_type_field_unit}
								    						/>
								    					</Col>
								    				</Row>
								    			</Card.Body>
								    			{!dealersPortal && 
								    				<Card.Footer>
								    					<h2 className="data_heading bold black">Orders Approved</h2>

										    				<p className="mb-0">
																  <a className="collapse_link collapsed" data-toggle="collapse" href="#transactions" role="button" aria-expanded="false" aria-controls="transactions">
																    Transactions Details
																  </a>										  
																</p>
																{this.state.orderDetail.payments != null ? 
																	<div className="collapse show" id="transactions">
																		<p className="mb-0 text-capitalize">
																			Payment status : <span className="green blue"> {this.state.orderDetail.payment_status} </span> 
																		</p>
																		{this.state.orderDetail.payment_status == 'success' && (
																			<React.Fragment>
																				<p className="mb-0">Email : {this.state.orderDetail.payments.email }</p>
																				<p className="mb-0">Phone : {this.state.orderDetail.payments.contact }</p>
																				<p className="mb-0 text-capitalize">Payment Mode : {this.state.orderDetail.payments.method }</p>
																		  </React.Fragment>
																		)}
																		
																		{this.state.orderDetail.payments.status != 'captured' && (
																			<Button 
																				onClick={() => this.props.history.push('/payment/'+this.state.orderDetail.id)}
																				style={this.props.styles.primary_btn}
																				className="btn btn-sm mt-2" 
																				> {t('payNow')} 
																			</Button>
																		)}  
																	</div> : 
																	((typeof this.state.orderDetail != 'undefined' && Object.keys(this.state.orderDetail).length > 0
																	  && this.state.orderDetail.status == 'pending' && (parseInt(this.state.orderDetail.amount_to_be_paid) > 0)) ?
																		<div className="collapse" id="transactions">
																			<p className="mb-0 text-capitalize">
																				Payment Status : <span className="green bold"> {'Waiting for payment'} </span> 
																			</p>
																			<Button 
																				onClick={() => this.props.history.push('/payment/'+this.state.orderDetail.id)}
																				style={this.props.styles.primary_btn}
																				className="btn btn-sm mt-2" 
																				> {t('payNow')} 
																			</Button> 
																		</div> : 
																		<div>Payment is not required.</div>
																	)
																}														
									    			</Card.Footer>
								    			}
								    		</Card>
								    		<Card className="order_card cart_table mb-3">			
								    			<OrderProductDetail 
								    				showStatus={true}
								    				orderDetails={this.state.orderDetail.products} 
								    				orderID={this.state.orderDetail.id} 
								    				images={this.state.images} 
								    				settings={this.props.settings}
								    				returnReplaceItem={(order_detail_id) => this.returnReplace(this.state.orderDetail.id, order_detail_id)} 
								    				cancelOrderItem={(order_detail_id) => this.cancelOrder(this.state.orderDetail.id, order_detail_id)} 
								    				showNotes={true}
								    				showCancelOrderButton={true}
								    			/>
								    		</Card>
											</Col>
										</Row>										
									</React.Fragment> : 
									<OrderNotFound parentProps={this.props}/>
								}
									<Modal show={this.state.returnOrReplacementPoupup} >
						        <Modal.Header>
						          <Modal.Title>Return/Replacement</Modal.Title>
						          <a onClick={() => this.setState({ returnOrReplacementPoupup: false })} className="action_icon"><i className="far fa-window-close"></i></a>
						        </Modal.Header>
						        <Modal.Body>
						        	<ReturnComponent 
						        		orderId={this.state.cancleOrder.order_id}
						        		orderDetailId={this.state.cancleOrder.order_detail_id}
						        		closePopup={()=>this.setState({returnOrReplacementPoupup:false})}
						        		getOrderDetail={()=>this.getOrderDetail()}
						        	/>
						        </Modal.Body>
						      </Modal>
							</Container>
						)}				
						<Loader loaderState={this.state.loader}/>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
const mapStateToProps = (state) => {
  return {
    settings:state.settings.settings,
    styles: state.styles.styles,
  }
}
export default withTranslation()(connect(mapStateToProps, null)(OrderDetails));