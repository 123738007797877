import React from 'react';
import {Container, Row, Col, Table, Card, Form, Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

import MetaTags from '../../../components/MetaTags';

import CoreFindDealers from '../../../../core/components/customerConnect/CoreFindDealers';

class FindDealer extends CoreFindDealers {	
	
	render(){
		const {t} = this.props
		return (
			<React.Fragment> 
				<MetaTags {...this.props}/>
				<div className="main mt-5">
					<div className="main_section">
						<Container className="mt-4">
							<h5 className="bold text-uppercase black mb-3 text-left">Find a Dealer</h5>						
							<Form className="fields-group-md">
								<Row>
									<Col sm={6}>	
									  <Form.Group>
									    <Form.Label>State </Form.Label>
									    <Form.Control as="select" onChange={(e)=>this.setDealerState(e.target.value)}>
									    	<option>Select State</option>
									    	{this.state.countryState.map((state,index)=>(
									    		<option value={state.name}>{state.name}</option>
									    	))}
									    </Form.Control>
									  	<span className="red"></span>
								  	</Form.Group>
								  </Col>
								  <Col sm={6}>
								  	<Form.Group>
									    <Form.Label>City </Form.Label>
								     	<Form.Control as="select" onChange={(e)=>this.setState({city:e.target.value})}>
									    	<option>Select City</option>
									    	{this.state.cities.map((cities,index)=>(
									    		<option value={cities.name}>{cities.name}</option>
									    	))}
									    </Form.Control>
									  	<span className="red"></span>
									  </Form.Group>
									</Col>
									<Col sm={6}>
									  <Form.Group>
									    <Form.Label>Brands </Form.Label>
									    <Form.Control as="select" onChange={(e)=>this.setState({brand:e.target.value})}>
									    	<option>Select Brand</option>
									    	{this.state.brands.map((brand,index)=>(
									    		<option value={brand.name}>{brand.name}</option>
									    	))}
									    </Form.Control>
									  	<span className="red"></span>
									  </Form.Group>
									</Col>
									<Col sm={6}>
									  <Form.Group>
									    <Form.Label>Store Name </Form.Label>
									    <Form.Control type="text" className="mb-1" onChange={(e)=>this.setState({storeName:e.target.value})}/>
									  </Form.Group>	
									</Col>
									<Col sm={12}>
									  <span className="red">{this.state.error}</span>
									</Col>
								</Row>								
							</Form>

							<div className="text-center mt-4">
								<Button style={this.props.styles.primary_btn} onClick={()=>this.submitFindTheDealer()}>Search</Button>
							</div>
							{(this.state.isSearched == 1)&&(
								<React.Fragment>
									<hr/>
									<h5 className="black">{t('searchResult')}</h5>
									<Row className="mt-4 pd7 flex-row">
										{
											(this.state.dealers.length > 0)?
												this.state.dealers.map((dealer,index)=>(
												<Col lg={4} md={6} className="mb-3">
													<Card className="dealer_card">
														<Card.Body>
															<p className="mb-0">{dealer.classification_name} </p>															
															<p className="mb-0">{dealer.trading_name} </p>															
															<p className="mb-0">{dealer.billing_address}</p>
															<p className="mb-0">{dealer.pin_code}</p>
															<p className="mb-0">{dealer.state_name}</p>
															<p className="mb-0">{dealer.country_name}</p>
															<p className="mb-0"><a href={"mailto:" + dealer.email_id} target="_blank">{dealer.email_id}</a></p>
															<p className="mb-0"><a href={"tel:" + dealer.mobile_no} target="_blank">{dealer.mobile_no}</a></p>
														</Card.Body>
													</Card>
												</Col>  
											)):<Col lg={8} md={12} className="mb-3">
													<h6>{t('noRecord')}</h6>
												</Col>
										}
									</Row>
								</React.Fragment>			
							)}
						</Container>
					</div>
				</div>
			</React.Fragment>
		);  
	}
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings,
    styles: state.styles.styles,
  }
}

export default withTranslation()(connect(mapStateToProps, null)(FindDealer));
