import React from 'react';
import {Container, Row, Col, Button, Card, Form} from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next';
import Alert from 'react-bootstrap/Alert';

import LogoImg from '../../../assets/images/logos/scott-logo.png'; 
import CoreUser from '../../../../core/components/users/CoreUser';
import { connect } from "react-redux";

class ForgotPassword extends CoreUser {	
	render(){
		const { t } = this.props;
		return (
			<React.Fragment> 
				<div className="login dealer_login">	
					<div className="main_section">
						<Container>												
							<Row>
								<Col lg={5} sm={9} xs={12} className="mx-auto mt-4">
									<Card>
									<Card.Body className="py-4">		
										<div className="text-center mb-4">
											<a href=""><img src={this.props.settings.image_url+'/application_images/original/'+this.props.settings.dealer_header_logo} /></a>
										</div>			
											<div className="">
												<h5 className="bold text-uppercase black text-center mb-4">{t('forgotYourPassword')}</h5>
												<p className="">{t('resetMail')}.</p>
											</div>
											<Form className="fields-group-md">
												<Form.Group as={Row}>
											    <Col lg="12">
											    	<Form.Control type="email" onChange={(email)=>this.setState({'email_id':email.target.value})} className="mb-1" placeholder="Email ID" />
											    	<span className="red">{(typeof this.state.errors['forgot_password[email_id]'] != 'undefined' && this.state.errors['forgot_password[email_id]'] != "")?this.state.errors['forgot_password[email_id]']:""}</span>									    	
											    </Col>							   
											  </Form.Group>									  
											</Form>
											<Alert show={this.state.showMessage} variant="success">
								        <Alert.Heading>{t('passwordResetRequest')}.</Alert.Heading>
								        <p>
								          {this.state.message}
								        </p> 
								        <hr />
								      </Alert>
											<div className="text-center mt-5">
												<Link to='/login'>
													<Button className="btn btn-md transparent mr-3" style={this.props.styles.secondary_btn}>{t('back')}</Button>
												</Link>
												{ this.state.visible ? <Button className="btn btn-md" onClick={()=>this.forgotPassword()} style={this.props.styles.primary_btn}>{t('submit')}</Button> : <div></div> }
											</div>
										</Card.Body>
									</Card>
								</Col>								
							</Row>
						</Container>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings,
    styles: state.styles.styles,
  }
}

export default withTranslation()(connect(mapStateToProps)(ForgotPassword));
