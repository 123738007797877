import React from 'react';
import {Container, Row, Col, Table, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MetaTags from '../../../components/MetaTags';

import Bike2016 from '../../../assets/images/thumbnail/2016_bike.png';

import pdf1 from '../../../assets/pdf/Declaration-Of-Confirmity-Bikes/BackPacks_DeclarationOfConformity_2021_BIKE_SCOTT-SPORTS_Multi.pdf';
import pdf2 from '../../../assets/pdf/Declaration-Of-Confirmity-Bikes/BackPacks_DeclarationOfConformity_A4_2019_BIKE_SCOTT-SPORTS_Multi.pdf';
import pdf3 from '../../../assets/pdf/Declaration-Of-Confirmity-Bikes/BackPacks_DeclarationOfConformity_A4_2020_BIKE_SCOTT-SPORTS_Multi.pdf';
import pdf4 from '../../../assets/pdf/Declaration-Of-Confirmity-Bikes/BackProtection_DeclarationOfConformity_A4_2019_SCOTT-SPORTS_Multi.pdf';
import pdf5 from '../../../assets/pdf/Declaration-Of-Confirmity-Bikes/Bicycle_DeclarationConformity_A4_2020_BIKE_SCOTT-Sports_Multi.pdf';
import pdf6 from '../../../assets/pdf/Declaration-Of-Confirmity-Bikes/BodyProtection_DeclarationOfConformity_A4_2021_SCOTT-SPORTS_Multi.pdf';
import pdf7 from '../../../assets/pdf/Declaration-Of-Confirmity-Bikes/DeclarationConformity_Bicycle_2021_BIKE_SCOTT-Sports_190120.pdf';
import pdf8 from '../../../assets/pdf/Declaration-Of-Confirmity-Bikes/DeclarationConformity_Pedelec_2021_BIKE_SCOTT-Sports_print210220.pdf';
import pdf9 from '../../../assets/pdf/Declaration-Of-Confirmity-Bikes/Declaration-of-conformity-Bicycle_Guideline_A4_2017_BIKE_SCOTT-Sports_Multi.pdf';
import pdf10 from '../../../assets/pdf/Declaration-Of-Confirmity-Bikes/Declaration-of-conformity-Bicycle_Guideline_A4_2018_BIKE_SCOTT-Sports_Multi.pdf';
import pdf11 from '../../../assets/pdf/Declaration-Of-Confirmity-Bikes/Declaration-of-conformity-Bicycle_Guideline_A4_2019_BIKE_SCOTT-Sports_Multi.pdf';
import pdf12 from '../../../assets/pdf/Declaration-Of-Confirmity-Bikes/Declaration-of-conformity-Pedelec_Guideline_A4_2015_BIKE_SCOTT-Sports_Multi.pdf';
import pdf13 from '../../../assets/pdf/Declaration-Of-Confirmity-Bikes/Declaration-of-conformity-Pedelec_Guideline_A4_2016_BIKE_SCOTT-Sports_Multi.pdf';
import pdf14 from '../../../assets/pdf/Declaration-Of-Confirmity-Bikes/Declaration-of-conformity-Pedelec_Guideline_A4_2017_BIKE_SCOTT-Sports_Multi.pdf';
import pdf15 from '../../../assets/pdf/Declaration-Of-Confirmity-Bikes/Declaration-of-conformity-Pedelec_Guideline_A4_2018_BIKE_SCOTT-Sports_Multi.pdf';
import pdf16 from '../../../assets/pdf/Declaration-Of-Confirmity-Bikes/Declaration-of-conformity-Pedelec_Guideline_A4_2019_BIKE_SCOTT-Sports_Multi.pdf';
import pdf17 from '../../../assets/pdf/Declaration-Of-Confirmity-Bikes/Gloves_DeclarationOfConformity_A4_2019_BIKE_SCOTT-SPORTS_Multi.pdf';
import pdf18 from '../../../assets/pdf/Declaration-Of-Confirmity-Bikes/Gloves_DeclarationOfConformity_A4_2020_BIKE_SCOTT-SPORTS_Multi.pdf';
import pdf19 from '../../../assets/pdf/Declaration-Of-Confirmity-Bikes/Helmet_DeclarationOfConformity_2021_BIKE_SCOTT-SPORTS_Multi.pdf';
import pdf20 from '../../../assets/pdf/Declaration-Of-Confirmity-Bikes/Helmet_DeclarationOfConformity_A4_2019_BIKE_SCOTT-SPORTS_Multi.pdf';
import pdf21 from '../../../assets/pdf/Declaration-Of-Confirmity-Bikes/Helmet_DeclarationOfConformity_A4_2020_BIKE_SCOTT-SPORTS_Multi.pdf';
import pdf22 from '../../../assets/pdf/Declaration-Of-Confirmity-Bikes/Helmet_DeclarationOfConformity_A4_2021_BIKE_SCOTT-SPORTS_Multi.pdf';
import pdf23 from '../../../assets/pdf/Declaration-Of-Confirmity-Bikes/Limb Protection-Ricotest_DeclarationOfConformity_A4_2019_SCOTT-SPORTS_Multi.pdf';
import pdf24 from '../../../assets/pdf/Declaration-Of-Confirmity-Bikes/LimbProtection-Italcert_DeclarationOfConformity_A4_2019_SCOTT-SPORTS_Multi.pdf';
import pdf25 from '../../../assets/pdf/Declaration-Of-Confirmity-Bikes/LimbProtection-Italcert_DeclarationOfConformity_A4_2020_SCOTT-SPORTS_Multi.pdf';
import pdf26 from '../../../assets/pdf/Declaration-Of-Confirmity-Bikes/LimbProtection-Italcert_DeclarationOfConformity_A4_2021_SCOTT-SPORTS_Multi.pdf';
import pdf27 from '../../../assets/pdf/Declaration-Of-Confirmity-Bikes/LimbProtection-Ricotest_DeclarationOfConformity_A4_2020_SCOTT-SPORTS_Multi.pdf';
import pdf28 from '../../../assets/pdf/Declaration-Of-Confirmity-Bikes/Pedelec_DeclarationConformity_A4_2020_BIKE_SCOTT-Sports_Multi.pdf';
import pdf29 from '../../../assets/pdf/Declaration-Of-Confirmity-Bikes/Sunglasses_DeclarationOfConformity_2021_SCOTT-SPORTS_Multi.pdf';
import pdf30 from '../../../assets/pdf/Declaration-Of-Confirmity-Bikes/Sunglasses_DeclarationOfConformity_A4_2019_BIKE_SCOTT-SPORTS_Multi.pdf';
import pdf31 from '../../../assets/pdf/Declaration-Of-Confirmity-Bikes/Sunglasses_DeclarationOfConformity_A4_2020_BIKE_SCOTT-SPORTS_Multi.pdf';


class DeclarationOfConfirmityBikes extends React.Component {	
	componentDidMount() {
	  window.scrollTo(0, 0)
	}
	render(){
		return (
			<React.Fragment> 
        <MetaTags {...this.props}/>
				<div className="main">
					<div className="main_section">
						<Container className="">
              <h1 className="page_heading black mb-4 text-uppercase bold"><Link to="/manuals-bike"><i className="fal fa-long-arrow-left pr-2 black" style={{fontWeight:'700'}}></i></Link> Declaration Of Confirmity Bikes</h1>
							<Row className="pd7">
                <Col lg={3} sm={12} className="mb-4 mb-lg-0">
                  <ul className="nav nav-tabs vertical_tabs">
                    <li className="nav-item pt-0">
                      <a className="nav-link active" data-toggle="tab" href="#all_declaration">
                        <i className="far fa-chevron-right"></i> All Declaration of Confirmity
                      </a>
                    </li>  
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#bike_declaration">
                        <i className="far fa-chevron-right"></i> Bike Declaration of Confirmity
                      </a>
                    </li>                  
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#helmet_declaration">
                        <i className="far fa-chevron-right"></i> Helmet Declaration of Confirmity
                      </a>
                    </li>   
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#backpack_declaration">
                        <i className="far fa-chevron-right"></i> Backpack Declaration of Confirmity
                      </a>
                    </li> 
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#gloves_declaration">
                        <i className="far fa-chevron-right"></i> Gloves Declaration of Confirmity
                      </a>
                    </li> 
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#eyeware_declaration">
                        <i className="far fa-chevron-right"></i> Eyeware Declaration of Confirmity
                      </a>
                    </li>    
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#protection_declaration">
                        <i className="far fa-chevron-right"></i> Protection Declaration of Confirmity
                      </a>
                    </li>                                      
                  </ul>
                </Col>
                <Col lg={9} sm={12}>
                  <div className="tab-content">
                    <div className="tab-pane container active" id="all_declaration">
                      <Row className="pd7 pdf_list">
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf1} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">BackPacks_DeclarationOfConformity_2021_BIKE_SCOTT-SPORTS_Multi.pdf</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf2} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">BackPacks_DeclarationOfConformity_A4_2019_BIKE_SCOTT-SPORTS_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf3} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">BackPacks_DeclarationOfConformity_A4_2020_BIKE_SCOTT-SPORTS_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf4} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">BackProtection_DeclarationOfConformity_A4_2019_SCOTT-SPORTS_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>  
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf5} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Bicycle_DeclarationConformity_A4_2020_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf6} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">BodyProtection_DeclarationOfConformity_A4_2021_SCOTT-SPORTS_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf7} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">DeclarationConformity_Bicycle_2021_BIKE_SCOTT-Sports_190120</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf8} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">DeclarationConformity_Pedelec_2021_BIKE_SCOTT-Sports_print210220</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf9} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Declaration-of-conformity-Bicycle_Guideline_A4_2017_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf10} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Declaration-of-conformity-Bicycle_Guideline_A4_2018_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf11} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Declaration-of-conformity-Bicycle_Guideline_A4_2019_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf12} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Declaration-of-conformity-Pedelec_Guideline_A4_2015_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf13} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Declaration-of-conformity-Pedelec_Guideline_A4_2016_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf14} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Declaration-of-conformity-Pedelec_Guideline_A4_2017_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col> 
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf15} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Declaration-of-conformity-Pedelec_Guideline_A4_2018_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf16} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Declaration-of-conformity-Pedelec_Guideline_A4_2019_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf17} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Gloves_DeclarationOfConformity_A4_2019_BIKE_SCOTT-SPORTS_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf18} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Gloves_DeclarationOfConformity_A4_2020_BIKE_SCOTT-SPORTS_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf19} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet_DeclarationOfConformity_2021_BIKE_SCOTT-SPORTS_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf20} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet_DeclarationOfConformity_A4_2019_BIKE_SCOTT-SPORTS_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col> 
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf21} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet_DeclarationOfConformity_A4_2020_BIKE_SCOTT-SPORTS_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf22} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet_DeclarationOfConformity_A4_2021_BIKE_SCOTT-SPORTS_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf23} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Limb Protection-Ricotest_DeclarationOfConformity_A4_2019_SCOTT-SPORTS_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf24} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">LimbProtection-Italcert_DeclarationOfConformity_A4_2019_SCOTT-SPORTS_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf25} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">LimbProtection-Italcert_DeclarationOfConformity_A4_2020_SCOTT-SPORTS_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf26} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">LimbProtection-Italcert_DeclarationOfConformity_A4_2021_SCOTT-SPORTS_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>                        
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf27} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">LimbProtection-Ricotest_DeclarationOfConformity_A4_2020_SCOTT-SPORTS_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf28} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Pedelec_DeclarationConformity_A4_2020_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf29} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Sunglasses_DeclarationOfConformity_2021_SCOTT-SPORTS_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf30} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Sunglasses_DeclarationOfConformity_A4_2019_BIKE_SCOTT-SPORTS_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf31} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Sunglasses_DeclarationOfConformity_A4_2020_BIKE_SCOTT-SPORTS_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>                                           
                      </Row>
                    </div>
                    <div className="tab-pane container fade" id="bike_declaration">
                      <Row className="pd7 pdf_list">                       
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf5} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Bicycle_DeclarationConformity_A4_2020_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col> 
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf7} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">DeclarationConformity_Bicycle_2021_BIKE_SCOTT-Sports_190120</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf8} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">DeclarationConformity_Pedelec_2021_BIKE_SCOTT-Sports_print210220</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf9} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Declaration-of-conformity-Bicycle_Guideline_A4_2017_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf10} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Declaration-of-conformity-Bicycle_Guideline_A4_2018_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf11} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Declaration-of-conformity-Bicycle_Guideline_A4_2019_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf12} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Declaration-of-conformity-Pedelec_Guideline_A4_2015_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf13} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Declaration-of-conformity-Pedelec_Guideline_A4_2016_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf14} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Declaration-of-conformity-Pedelec_Guideline_A4_2017_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col> 
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf15} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Declaration-of-conformity-Pedelec_Guideline_A4_2018_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf16} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Declaration-of-conformity-Pedelec_Guideline_A4_2019_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf28} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Pedelec_DeclarationConformity_A4_2020_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>             
                      </Row>
                    </div>                   
                    <div className="tab-pane container fade" id="helmet_declaration">
                      <Row className="pd7 pdf_list">  
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf19} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet_DeclarationOfConformity_2021_BIKE_SCOTT-SPORTS_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf20} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet_DeclarationOfConformity_A4_2019_BIKE_SCOTT-SPORTS_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf21} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet_DeclarationOfConformity_A4_2020_BIKE_SCOTT-SPORTS_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf22} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet_DeclarationOfConformity_A4_2021_BIKE_SCOTT-SPORTS_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                      </Row>
                    </div>
                    <div className="tab-pane container fade" id="backpack_declaration">
                      <Row className="pd7 pdf_list">
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf1} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">BackPacks_DeclarationOfConformity_2021_BIKE_SCOTT-SPORTS_Multi.pdf</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf2} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">BackPacks_DeclarationOfConformity_A4_2019_BIKE_SCOTT-SPORTS_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf3} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">BackPacks_DeclarationOfConformity_A4_2020_BIKE_SCOTT-SPORTS_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                      </Row>    
                    </div>
                    <div className="tab-pane container fade" id="gloves_declaration">
                      <Row className="pd7 pdf_list">
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf17} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Gloves_DeclarationOfConformity_A4_2019_BIKE_SCOTT-SPORTS_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf18} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Gloves_DeclarationOfConformity_A4_2020_BIKE_SCOTT-SPORTS_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                      </Row>
                    </div> 
                    <div className="tab-pane container fade" id="eyeware_declaration">
                      <Row className="pd7 pdf_list">
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf29} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Sunglasses_DeclarationOfConformity_2021_SCOTT-SPORTS_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf30} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Sunglasses_DeclarationOfConformity_A4_2019_BIKE_SCOTT-SPORTS_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf31} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Sunglasses_DeclarationOfConformity_A4_2020_BIKE_SCOTT-SPORTS_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>                    
                      </Row>
                    </div>  
                    <div className="tab-pane container fade" id="protection_declaration">
                      <Row className="pd7 pdf_list">
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf4} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">BackProtection_DeclarationOfConformity_A4_2019_SCOTT-SPORTS_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>                          
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf6} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">BodyProtection_DeclarationOfConformity_A4_2021_SCOTT-SPORTS_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf23} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Limb Protection-Ricotest_DeclarationOfConformity_A4_2019_SCOTT-SPORTS_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf24} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">LimbProtection-Italcert_DeclarationOfConformity_A4_2019_SCOTT-SPORTS_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf25} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">LimbProtection-Italcert_DeclarationOfConformity_A4_2020_SCOTT-SPORTS_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf26} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">LimbProtection-Italcert_DeclarationOfConformity_A4_2021_SCOTT-SPORTS_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>                        
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf27} target="_blank">
                            <Card>
                              <Card.Body>
                                <img src={Bike2016} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">LimbProtection-Ricotest_DeclarationOfConformity_A4_2020_SCOTT-SPORTS_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>                    
                      </Row>
                    </div>                   
                  </div>
                </Col>
              </Row>
						</Container>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default DeclarationOfConfirmityBikes;