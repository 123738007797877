import React from 'react';
import cookie from 'react-cookies';
import { Redirect } from 'react-router';
import AsyncStorage from '@react-native-async-storage/async-storage';

import {checkCredential, checkOtp, registerUser, forgotPassword, resetPassword, changePassword, Logout, checkUserValid, getDealerProfileView} from '../../helpers/ServicesHelper'
import {mobile, dealersPortal} from '../../helpers/OperationsHelper'
import { toast } from 'react-toastify';

class CoreUser extends React.Component {
  constructor(props){
		super(props);
    this.state={
    	accessToken: '',
      loginUser:'',
			loginPassword:'',
			error:[],
			errorRegister:[],
			errorChangePassword:[],
      registerUser:'',
			email_id:'',
			mobile_no:'',
			registerPassword:'',
			confirm_password:'',
			oldPassword:'',
			newPassword:'',
			changeConfirmPassword:'',
			isOtpCheck:false,
			errors:[],
			message:"",
			showMessage:false,
			timer:30,
			otpText:'Get OTP',
			otpSent:false,
			isOtpClick:false,
			isLoaded:true,
			isCommingFromCheckout:0,
			show:false,
			isAccessTokenLogin: '',
			isLoginPopupVisible: false,
	    hidePass:true,
	    isChecked:false,
	    is_remember: false,
	    showViewPassword: true,
	    redirectTo:'',
	    profile:[],
	    hidePassC:false,
	    gst:'',
	    loginButtonLoader:false,
		visible:true

		}
		this.timeStore=''
	}

	useStorage=async(credentials)=>{
		var userData = credentials;
		cookie.save('authToken', userData.data.data.authToken, { path: '/' });

		if (typeof userData.data.data.sales_login != 'undefined')
			cookie.save('sales_login', userData.data.data.sales_login, { path: '/' });

		if (this.state.isAccessTokenLogin == true) 
			cookie.save('accessToken', this.state.accessToken, { path: '/' });
		this.setUser(credentials);
	}

	setUser = async (credentials) => {
		var userData = credentials;
		var response = await checkUserValid(userData.data.data.authToken);
		if (response.data.status == 'success') {
			this.props.setUser(response.data.data[0]);
			if(mobile == false)
				window.location.href = '/';
		}	else {
			cookie.remove('authToken', { path: '/' });
			cookie.remove('sales_login', { path: '/' });
			if(mobile == false)
				window.location.href = '/login';
		}
	}

	time(){
		this.timeStore=setInterval(() => {
			if(this.props.otpCount<=0){
				clearInterval(this.timeStore);
				this.setState({otpText:'Get OTP',isOtpClick:false,isLoaded:false});
			}
			else{
				this.props.decrementOtpCount();
				this.setState({otpText:'Resend OTP In :'+this.props.otpCount})
				localStorage.setItem('otpCount',this.props.otpCount);
				this.setState({timer:this.state.timer-1,isOtpClick:true});
				this.setState({isLoaded:true})
			}
		
		}, 1000);

	}

  checkOtp=async()=>{
		var formdata=[];
		formdata['send_otp[username]'] = this.state.loginUser;
		if(dealersPortal == true)
			formdata['send_otp[user_type]'] = 2;
		else
			formdata['send_otp[user_type]'] = 1;
		var credentials = await checkOtp(formdata);
		this.setState({isOtpCheck:true});
		if(credentials.data.status==='success'){
			this.setState({otpText:'Resend OTP',otpSent:true})
		}
		else{
			this.setState({error:credentials.data.errors})
		}
	}

	forgotPassword = async () => {
		var formdata = []
		this.setState({errors:[]});
		formdata['email_id'] = this.state.email_id;
		var response =  await forgotPassword(formdata);
		if(response.data.status == 'success'){
			this.setState({message:this.props.t('resetPasswordMsg'),
										showMessage:true});
		}else{
			this.setState({'errors':response.data.errors,message:"",showMessage:false});
		}
		this.setState({visible:false})
	}

	checkGoogleLogin=async(response)=>{
		var formdata=[],credentials;
		formdata['login[username]']=response.profileObj.email;
		formdata['login[user_type]']=1;
		formdata['login[name]']=response.profileObj.name;
		formdata['is_social_login']=1;
		formdata['login[profile_image]']=response.profileObj.imageUrl;
		credentials=await checkCredential(formdata)
		if(credentials.data.status==='success'){
			this.useStorage(credentials);
		}
	}

	checkFacebookLogin=async(response)=>{
		var formdata=[],credentials;
		formdata['login[username]']=response.email;
		formdata['login[name]']=response.name;
		formdata['login[user_type]']=1;
		formdata['is_social_login']=1;
		formdata['login[profile_image]']=response.picture.data.url;
		credentials=await checkCredential(formdata)
		if(credentials.data.status==='success'){
			this.useStorage(credentials);
		}
	}

  checkCredential =async () => {
  	this.setState({'loginButtonLoader':true})
		var formdata=[],credentials;
		if (this.state.isAccessTokenLogin == true) {
			formdata['login[admin_access_token]'] = this.state.accessToken;
		} else {
			formdata['login[username]'] = this.state.loginUser;
			if(this.state.isOtpCheck){
				formdata['otp'] = this.state.loginPassword;
			}
			else{
				formdata['password'] = this.state.loginPassword;
			}	
		}
		if(dealersPortal == true)
			formdata['login[user_type]'] = 2;
		credentials =await checkCredential(formdata);
		if(credentials.data.status==='success') {			
      	await this.useStorage(credentials);
    } else { 
      this.setState({error:credentials.data.errors,'loginButtonLoader':false})
    }
  }

 
  registration=async()=>{
		var formdata=[]
		formdata['customers[name]']=this.state.registerUser;
		formdata['customers[user_type]']=1;
		formdata['customers[gst_number]']=this.state.gst;
		formdata['customers[email_id]']=this.state.email_id;
		formdata['customers[mobile_no]']=this.state.mobile_no;
		formdata['customers[password]']=this.state.registerPassword;
    formdata['confirm_password']=this.state.confirm_password;
		var credentials=await registerUser(formdata);
		if(credentials.data.status==='success'){			
    	await this.useStorage(credentials);
    	this.props.history.push('/');
		}
		else{
			this.setState({errorRegister:credentials.data.errors})
		}
  }

  removeStore=async()=>{
    cookie.remove('authToken', {path: '/'});
    cookie.remove('sales_login', { path: '/' });
    cookie.remove('accessToken', {path: '/'});
    if(mobile == true){
      this.props.navigation.navigate('/Login');
    }else{
    	await toast.success('Pass has been changes, Logging out.', {
				position: "top-center",
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
			setTimeout(()=>{
    		this.props.history.push('/');
    	},2000)
    }
  }

	changePassword=async()=>{
		var formdata=[];
		formdata['change_password[old_password]']=this.state.oldPassword;
		formdata['change_password[new_password]']=this.state.newPassword;
		formdata['confirm_password']=this.state.changeConfirmPassword;
		var passwords=await changePassword(formdata);
		if(passwords.data.status=='success'){
			await this.removeStore();
		}
		else{
			this.setState({errorChangePassword:passwords.data.errors});
		}
	}

	handleClose(){
		this.setState({show:false});
	}

	handleShow(){
		this.setState({show:true});
	}
	
	allDeviceLogout=async()=>{
		var device=await Logout();
		if(device.data.status=='success'){
			this.removeStore()
		}
	}

	async onClickSkip(){
		await AsyncStorage.setItem('skipLogin','1');
		this.props.navigation.navigate('Splash')
	}
}

export default CoreUser;