import React from 'react';
import {Container, Row, Col, Table, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom'


class BergamontWarranty extends React.Component {	
	render(){
		return (		
			<div className="mt-5">
		  	<h2 className="small_heading bold black text-center mb-5 text-uppercase">Bergamont Warranty</h2>
		  	<div className="mb-4">
		  		<h3 className="content_heading black bold mb-3 text-underline">WARRANTY AND GUARANTEE</h3>
		  		<p>Your BERGAMONT bike was manufactured with great care. Normally it is delivered to you by your BERGAMONT dealer fully assembled.</p>
					<p>As direct purchaser you have full warranty rights within the first two years after purchase. Please contact your BERGAMONT dealer in the event of defects.</p>
					<p>To ensure a smooth handling of your claim, it is necessary to present your receipt and the proofs of the service plan. Therefore, be sure to keep these documents in a safe place.</p>
					<p>To ensure a long service life and good durability of your BERGAMONT bike, use it only for its intended purpose. Please observe the permissible load specifications as specified in the manual. Be sure to follow the mounting instructions of the manufacturers (above all, the torque values of the bolts) as well as the prescribed maintenance schedule.</p>
					<p>Observe the checks and routines listed in this owner's manual or in any other manuals enclosed with this delivery (see the chapter "BERGAMONT service and maintenance schedule") as well as any instructions concerning the replacement of safety-relevant components such as handlebars or brakes etc.</p>
					<div className="bg_light_gray p-3">
						<p className="mb-1 black">DANGER!</p>
						<p className="mb-0 black">Keep in mind that retrofitted accessories can impair the functioning of your BERGAMONT bike. If you are in doubt or if you have any questions, contact your BERGAMONT dealer.</p>
					</div>
		  	</div>
		  	<div className="mb-4">
		  		<h3 className="content_heading black bold text-underline">NOTES ON WEARING PARTS</h3>
		  		<p>Some components of your BERGAMONT bike are subject to wear due to their function. The rate of wear will depend on care and maintenance and the way you use your BERGAMONT bike (mileage, riding in the rain, dirt, salt etc.). BERGAMONT bikes that are often left standing in the open may also be subject to increased wear through weathering.</p>
					<p>The components below require regular care and maintenance. Nevertheless, sooner or later they will reach the end of their service life, depending on conditions and intensity of use. The following parts which have reached their limit of wear must be replaced:</p>
					<Row>
						<Col xs={6}>
							<ul className="pl-3" style={{listStyleType:'disc'}}>
				  			<li className="pb-2">Drive chain</li>
								<li className="pb-2">Brake pads</li>
								<li className="pb-2">Brake fluid (DOT)</li>
								<li className="pb-2">Brake discs/rotors</li>
								<li className="pb-2">Brake cables</li>
								<li className="pb-2">Brake cable housings</li>
								<li className="pb-2">Seals of suspension elements</li>
								<li className="pb-2">Rim sides (of rim brakes)</li>
								<li className="pb-2">Incandescent bulbs/LED</li>
								<li className="pb-2">Rubber grips</li>
								<li className="pb-2">Hydraulic oil</li>
								<li className="pb-2">Chain wheels</li>
							</ul>
						</Col>
						<Col xs={6}>
							<ul className="pl-3" style={{listStyleType:'disc'}}>	
							<li className="pb-2">Chain stay protection</li>			  		
								<li className="pb-2">Bearings in hubs, joints etc.</li>
								<li className="pb-2">Handlebar tape</li>
								<li className="pb-2">Lamps</li>
								<li className="pb-2">Tyres</li>
								<li className="pb-2">Sprockets</li>
								<li className="pb-2">Saddle covering / saddle</li>
								<li className="pb-2">Pulleys</li>
								<li className="pb-2">Bowden cables</li>
								<li className="pb-2">Bowden cable housings</li>
								<li className="pb-2">Inner tubes</li>
								<li className="pb-2">Lubricants</li>
				  		</ul>
						</Col>
					</Row>		  		
		  	</div>
		  	<div className="mb-4">
		  		<h3 className="content_heading black bold text-underline mb-3">GUARANTEE ON BERGAMONT BIKES</h3>
		  		<h6 className="black bold">What is covered?</h6>
		  		<p>This warranty covers defects in materials and workmanship at the time of transfer of risk in frames, rear frames and forks (provided it is a BERGAMONT fork) on BERGAMONT branded bikes ("Product") sold completely assembled by an authorized BERGAMONT dealer.</p>
		  	</div>
		  	<div className="mb-4">
		  		<h6 className="black bold">How long does coverage last?</h6>
		  		<p>This voluntary manufacturer's warranty is limited to five years for frames and rear frames, respectively two years for forks, from the date of purchase of the Product and is limited to the first purchaser of the Product. Transfer of the Product from the first purchaser to another person renders the mentioned warranty invalid.</p>
					<p>The limited warranty of five years for the frames and rear frames shall only be granted if a maintenance service has been performed once a year according to maintenance requirements as set forth in the manual. The annual maintenance service performed shall be confirmed by stamp and signature. In case such an annual maintenance service has not been performed, the warranty of five years for the frame and the rear frame shall be reduced to three years. The costs for maintenance and service have to be borne by the owner of the Product.</p>
					<p>The warranty period is limited to 2 years for all models included in the categories 5.1 and 5.2 (e.g. Strait line, Big Air, Kiez).</p>
					<p>Repaired or replaced Products are covered for the remaining warranty period and subject to the conditions outlined in the original warranty, to the extent permitted by law.</p>
					<p>With this warranty BERGAMONT grants a worldwide valid, voluntary manufacturer's warranty. To the extent permitted by law and unless a shorter duration is stipulated by law, any warranties implied by law are limited in duration to maximum five, respectively two years, from the date of purchase of the Product and are limited to the first purchaser of the Product.</p>
		  	</div>
		  	<div className="mb-4">
		  		<h6 className="black bold">What will BERGAMONT do in case of a claim?</h6>
		  		<p>BERGAMONT will replace or repair any defective Product, or will refund your purchase price (upon presentation of the receipt of purchase of the Product) at BERGAMONT's own discretion. Non-defective parts are only replaced at your own expenses. In such a case, we will contact you prior to replacement of the non-defective components to obtain your consent.</p>
		  	</div>
		  	<div className="mb-4">
		  		<h6 className="black bold">What is excluded from this warranty?</h6>
		  		<p>This warranty does not cover defects occurred following the transfer of risk. This warranty does not cover Products used commercially, e.g. in a business offering services for hire. This warranty excludes bicycles which were not completely assembled at the moment of purchase. This warranty excludes wearing parts, as long they are affected by normal wear and tear (a complete list of all wearing parts can be found in the manual).</p>
					<p>The warranty also excludes damage resulting from accident, neglect, improper operation or misuse, use for a purpose for which it is not intended, act of God, improper assembly, non-compliance with the recommended maintenance instructions, improper or incorrectly performed maintenance or repairs performed by other dealers than the authorized BERGAMONT dealers, use of parts or devices incompatible with the Product, and alteration of the Product. The warranty does not cover damage resulting from infiltration with sweat, impacts, colour changes due to exposure of sunlight or similar external influences. All Products come with a manual; please observe the instructions included in the manual or attached elsewhere to the Product. To the extent permitted by law, consequential and incidental damages are not recoverable under this warranty.</p>
		  	</div>
		  	<div className="mb-4">
		  		<h6 className="black bold">How do you make a claim under this warranty?</h6>
		  		<p>To make a claim under this warranty, you must, as a first step, notify a BERGAMONT dealer of the claimed defect within the warranty period and timely return the Product to a BERGAMONT dealer at your expense for inspection. All returned Products must be accompanied by the proof of purchase (receipt) from an authorized BERGAMONT dealer. Without this document no claim can be made. In case of replacement or refund, the returned Product passes over to the property of BERGAMONT. Proof of purchase must necessarily be presented together with the defective part in case of a warranty claim.</p>
		  	</div>
		  	<div className="mb-4">
		  		<h6 className="black bold">How do state laws affect your rights under this limited warranty?</h6>
		  		<p>This warranty is a voluntary manufacturer's warranty of BERGAMONT, additional claims from state rights shall remain unaffected.</p>
		  	</div>
		  	<div className="mb-4">
		  		<h6 className="black bold">Recommendation</h6>
		  		<p>We strongly recommend that you contact and order only authorized BERGAMONT dealers for yearly maintenance services and for repairs, as improper or incorrectly performed maintenance works or repairs void this limited warranty. Costs for maintenance service have to be borne by the consumer.</p>
		  	</div>
		  </div>
		);
	}
}

export default BergamontWarranty;