import React from "react"
import ContentLoader from "react-content-loader"

const ContentLoaderLogo = (props) => (
  <ContentLoader 
    speed={2}
    width={'150'}
    height={'100%'}
    viewBox="0 0 130 60"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="11" y="20" rx="3" ry="3" width="88" height="6" /> 
    <rect x="11" y="30" rx="3" ry="3" width="52" height="6" />
  </ContentLoader>
)

export default ContentLoaderLogo