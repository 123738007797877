import React from 'react';
import {Container, Row, Col, Button, Card, Form} from 'react-bootstrap';
import CoreBillingAddress from '../../../../core/components/users/CoreBillingAddress';
import { connect } from "react-redux";
import Tostify from '../../../components/Toaster';
import { withTranslation } from 'react-i18next';

class BillingAddress extends CoreBillingAddress {

	async handleSubmit() {
		this.storeAddresses(this.state.billingAddress.id);
	}

	render(){
		const {errors, countries, statesArray,citiesArray, addresses} = this.state;
		const { t } = this.props;
		return (
			<React.Fragment> 
				<Tostify />
				<div className="main">
					<div className="main_section">
						<Container>	
							<Row className="flex-lg-row">
								<Col lg={12}>
									<h1 className="page_heading bold text-uppercase black text-left mb-3">Billing Address update</h1>
								</Col>
								<Col lg={12} className="mt-3 mt-lg-0">	
									<Card className="shadow-sm">
										<Card.Body>
									  	<Form className="fields-group-md mb-4">
											  <Form.Group>
											    <Form.Label>{t('name')} <span className="red">*</span></Form.Label>
											    <Form.Control 
											    	onChange={(e)=>this.changeFormdataValues(e,'name' ,'billingAddress')} 
											    	value={this.state.billingAddress.name} type="text" />												    
							      			<span className="red">{errors['shipping_addresses[name]']}</span>
											  </Form.Group>								
											  <Form.Group>
											    <Form.Label>{'Flat no/Building/Society/House no'} <span className="red">*</span></Form.Label>
											    <Form.Control  
											    			value={this.state.billingAddress.street_address} 
											    			onChange={(e)=>this.changeFormdataValues(e,'street_address' ,'billingAddress')} type="text" />												    
											  	<span className="red">{errors['shipping_addresses[street_address]']}</span>
											  </Form.Group>
											  <Form.Group>
											    <Form.Label>{"Full Address (Street/Area/Landmark)"} <span className="red">*</span></Form.Label>
											    <Form.Control as="textarea" 
											    			value={this.state.billingAddress.full_address} 
											    			onChange={(e)=>this.changeFormdataValues(e,'full_address' ,'billingAddress')} type="text" />												    
											  	<span className="red">{errors['shipping_addresses[full_address]']}</span>
											  </Form.Group>
											  <Form.Group>
											    <Form.Label>{t('country')} <span className="red">*</span></Form.Label>
											    <Form.Control  id='billingCountry' value={this.state.billingAddress.country} onChange={(e)=>this.changeFormdataValues(e,'country' ,'billingAddress')} as="select">
											    	<option>{t('selectCountry')}.</option>
											      {countries.map((country,index)=>(
												      <option key={index}>{country.name}</option>
												    ))}  
											    </Form.Control>
											    <span className="red">{errors['shipping_addresses[country]']}</span>
											  </Form.Group>
											  <Form.Group>
											    <Form.Label>{t('state')} <span className="red">*</span></Form.Label>
											    <Form.Control  value={this.state.billingAddress.state} as="select" onChange={(e)=>this.changeFormdataValues(e,'state' ,'billingAddress')}>
											    	<option>{'Select State'}</option>
											      {statesArray.billingAddress.states.map((state,index)=>(
												      <option key={index}>{state.name}</option>
											      ))}
											    </Form.Control>
											    <span className="red">{errors['shipping_addresses[state]']}</span>
											  </Form.Group>
											   <Form.Group>
												    <Form.Label>{t('city')} <span className="red">*</span></Form.Label>
												    <Form.Control value={this.state.billingAddress.city} as="select" onChange={(e)=>this.changeFormdataValues(e,'city','billingAddress')}>
												    	<option>{t('selectRegion')}.</option>
												    	{citiesArray.billingAddress.cities.map((city,index)=>(
													      <option key={index}>{city.name}</option>
												      ))}
												    </Form.Control>
												  	<span className="red">{errors['billingAddress[city]']}</span>
												  </Form.Group>
											  <Form.Group>
											    <Form.Label>{t('zipCode')} <span className="red">*</span></Form.Label>
											    <Form.Control  value={this.state.billingAddress.postal_code} onChange={(e)=>this.changeFormdataValues(e,'postal_code' ,'billingAddress')} type="text" />												    
											  	<span className="red">{errors['shipping_addresses[postal_code]']}</span>

											  </Form.Group>
											  <Form.Group>
											    <Form.Label>{t('email')} <span className="red">*</span></Form.Label>
											    <Form.Control  onChange={(e)=>this.changeFormdataValues(e,'email_id' ,'billingAddress')} 
											    							value={this.state.billingAddress.email_id} type="email" />
											  	<span className="red">{errors['shipping_addresses[email_id]']}</span>
											  </Form.Group>
											  <Form.Group>
											    <Form.Label>{t('mobile')} <span className="red">*</span></Form.Label>
											    <Form.Control  
											    							value={this.state.billingAddress.mobile_no} onChange={(e)=>this.changeFormdataValues(e,'mobile_no' ,'billingAddress')} type="text" />
											    <span className="red">{errors['shipping_addresses[mobile_no]']}</span>
											  </Form.Group>
											</Form>
											<div className="text-center">
												<Button 
													style={this.props.styles.primary_btn}
													className="btn btn-md" 
													onClick={() => this.handleSubmit()}
												> { t('updateAddress')}
												</Button>				  
											</div>
										</Card.Body>	
									</Card>	
								</Col>

							</Row>
						</Container>
					</div>
				</div>					
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
  return {
  	user: state.users.user,
  	styles: state.styles.styles,
    settings: state.settings.settings,
  }
}

export default withTranslation()(connect(mapStateToProps, null)(BillingAddress));


