import { getCategories } from '../core/helpers/ServicesHelper';

function categoryAction(categories) {
  return {
        	type: "SET_CUSTOMER_CATEGORIES",
        	payload:categories,
    		}
};

async function getCategoriesAPICall() {
  return await getCategories();
};


export const setCategoryData = (data) => async (dispatch) =>{
  var getCategories = await getCategoriesAPICall();
  if(getCategories.data.status == 'success'){
	  dispatch(categoryAction(getCategories.data.data))
  }
}

const initialState = {categories: []}
const categories = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_CUSTOMER_CATEGORIES':
      return { categories: action.payload}; 
  }
  return state
}

export default categories;