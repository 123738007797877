import React from 'react';
import {Container, Row, Col, Table, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import sizeChart from '../../../assets/images/faq/size_chart.png';

class BikeFaq extends React.Component {	
	render(){
		return (
			<div className="tab-pane fade" id="accessories_faq" role="tabpanel" aria-labelledby="accessories_faq_tab">			
				<h5 className="black bold mt-5">Parts and Accessories</h5>
				<div className="card mb-3">
	        <div className="card-body">
						<ol className="pl-3">
							<li className="pb-2">
								<h6 className="bold black">Are Syncros plastic bottles BPA free?</h6>
								<p>Yes, Syncros bottles are BPA free</p>
							</li>
							<li className="pb-2">
								<h6 className="bold black">What is Antibacterial Bottle?</h6>
								<p>We have bottles with Antibacterial Polypropylene body which limit the germs growing in it, resulting in a safe water drinking when thirsty.</p>
							</li>
							<li className="pb-2">
								<h6 className="bold black">What grade of alloy is used in Syncros products?</h6>
								<p>Syncros uses high grade Alloy AL7075, AL2014, AL7050, AL6061 etc. to produce its products for better quality which offers high strength and low weight. Each product will have the type of ally mentioned in the specification tab.</p>
							</li>
							<li className="pb-2">
								<h6 className="bold black">Are Syncros saddle bags Waterproof or Water Repellent or Dustproof?</h6>
								<p>Syncros has variety of products available in saddle bags category. Some Bags have waterproof feature, some water repellent and some dustproof. To know in detail about such features, kindly explore the saddle bags category</p>								
							</li>
							<li className="pb-2">
								<h6 className="bold black">What is integrated storage and integrated cockpit?</h6>
								<p>Integrated Storage concept considers ride essentials as, well, essential. Meaning you should have them on every ride, long or short, whether you need them or not. It offers a safe, sleek and integrated way to carry all of your ride essentials whether you're on the trail or the road.</p>
								<p>Integrated Cockpit concept blurs the line between components and frame, creating a seamless unit that functions as a complete system rather than a collection of parts. The result is lighter, stronger & more aerodynamic - redefining the classic bike aesthetic.</p>
							</li>
							<li className="pb-2">
								<h6 className="bold black">My bolts have become rusty will it covered in warranty?</h6>
								<p>Rust on bolts can be caused due to many reasons like moisture, improper storage of bike or an act of God. Hence, it is not covered under warranty.</p>
							</li>
							<li className="pb-2">
								<h6 className="bold black">How to purchase replacement parts for bike?</h6>
								<p>You can contact your nearest Authorized Dealer of Scott, BERGAMONT, Syncros. You can even call us at <a href="tel:8080754321" target="_blank">8080754321</a> or write to us on cs@sportnetwork.in seeking for more information. Click link to find the nearest authorized dealer of Scott, BERGAMONT, Syncros.</p>
								<p><a href="https://docpdfs.s3-ap-southeast-1.amazonaws.com/scott/dealer_list.pdf" target="_blank">Dealer List</a></p>														
							</li>
							<li className="pb-2">
								<h6 className="bold black">How do I decide which saddle is best for me?</h6>
								<p>Choosing a saddle plays a very important part as sitting position determines the comfort with which your ride bike. Wider saddles are better for upright position and narrow saddles for leaning position. Saddles are designed and equipped to absorb maximum shocks which otherwise is inflicted to spine.</p>
								<p>Personal choice to choose the saddle is of course the first point to consider while purchasing.</p>
							</li>	
							<li className="pb-2">
								<h6 className="bold black">Is wrapping a bar tape a DIY product?</h6>
								<p>We advise you to visit the nearest Scott, BERGAMONT Dealer as they will be able to assist you better.</p>
							</li>	
							<li className="pb-2">
								<h6 className="bold black">What kind of pump to buy?</h6>
								<p>A pump with a small diameter to achieve high pressure in thin tires and wider pump for wider tires to inflate at less pressure. You will find a range of mini pump, floor pump, shock pump form brand on our website.</p>
							</li>
							<li className="pb-2">
								<h6 className="bold black">What are the advantages of using CO2 Cartridge for inflation?</h6>
								<p>The CO2 Nozzle Cartridge can inflate to pressure a tire in no time flat. Select the required valve between Presta and Schrader, screw-in the Cartridge and that's it! You can go back faster on track and spend more time riding.</p>
							</li>	
							<li className="pb-2">
								<h6 className="bold black">Are saddle bags compatible with all seat post and seat rails?</h6>
								<p>The saddle bags are the most popularly used accessory with bike. It attaches securely to the seat post and seat rails with the standard fittings and compatible with all the bikes we offer on the website. The fixing strap system offers a universal fit to any saddle and seat post configuration to make sure you don’t leave home without your saddlebag. You can store your essential tools and spares in the saddle bags which can be a life saver when need arises.</p>
							</li>
						</ol>
					</div>
				</div>	
			</div>
		);
	}
}
	
export default BikeFaq;