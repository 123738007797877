import React from 'react';
import {Container, Row, Col, Tabs, Tab} from 'react-bootstrap';
import { connect } from "react-redux";

import CoreQuotationsListing from '../../../../core/components/transactions/CoreQuotationsListing';
import Orders from '../components/Orders';
import Loader from '../../../components/Loader';


class QuotationListing extends CoreQuotationsListing {
	
	async handleClick (key) {
		var where = 'where[order_type]=2&where[status]='+key;
		this.setState({ where: where });
		this.getQuotations(where);
	}


	render(){
		return (
			<React.Fragment> 
				<div className="main mb-5 mt-5">	
					
						<Container>
							<Row>
								<Col md={6}>
									<h5 className="bold text-uppercase black mb-3 flex-fill">Your Quotations</h5>
								</Col>
							</Row>
							<Row>
								<Col lg={12}>
									{this.state.quotationStates.length > 0 && (
										<Tabs defaultActiveKey={this.state.quotationStates[0].transition_to} className="order_tabs text-capitalize" onSelect={(k) => this.handleClick(k)}>
											{this.state.quotationStates.map((quotation, index) => (
												<Tab key={index} eventKey={quotation.transition_to} title={quotation.transition_to} >
											    {this.state.loader == false && (
														<React.Fragment>
															<Orders setFilterUrl={(value) => this.setFilterUrl(value)} orders={this.state.quotations} images={this.images} />
														</React.Fragment>
													)}
													<Loader loaderState={this.state.loader} />
											  </Tab>
											))}
										</Tabs>
									)}
								</Col>
							</Row>
						</Container>		
				</div>
			</React.Fragment
>		);
	}
}

const mapStateToProps = (state) => {
  return {
    settings:state.settings.settings,
  }
}
export default connect(mapStateToProps, null)(QuotationListing);