import React from 'react';
import {Container, Row, Col, Carousel, Button, Image} from 'react-bootstrap';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import {Link} from 'react-router-dom';
import CoreHome from '../../../../core/components/home/CoreHome';
import bannerHeading from '../../../assets/images/avanti/Avanti-SNS-Emailer-AW-1st-slideimport-bannerHeading.jpg';
import bannerRed from '../../../assets/images/avanti/1A.jpg';
import bannerwhite from '../../../assets/images/avanti/1B.jpg';
import buyNow from '../../../assets/images/avanti/Buy-now.jpg';
import banner3 from '../../../assets/images/avanti/Avanti-SNS-Emailer-AW-3rd-slide.jpg';
import banner4 from '../../../assets/images/avanti/Avanti-SNS-Emailer-AW-4th-slide.jpg';
import banner5 from '../../../assets/images/avanti/Avanti-SNS-Emailer-AW-5th-slide.jpg';
import banner6 from '../../../assets/images/avanti/Avanti-SNS-Emailer-AW-6th-slide.jpg';
// import { withRouter } from 'react-router';

 
class AvantiHomeIndia extends CoreHome {
    render(){
      return(
          <React.Fragment>
             {/* <div className='container'> */}
            <div style={{margin:"0 auto", maxwidth:"1400px", marginTop:'68px'}}>
               <div>
                  <div>
                     <img src={bannerHeading} style={{width:"100%"}}/>  
                  </div>
               <div>   

               <div>

                  <div > 
                    <Link to="/pp/bikes/bikes/road/avanti-giro-fm-1-red-bike7278">
                        <Image src={bannerRed} style={{width:"50%"}} />
                    </Link>
                    <Link to="/pp/bikes/bikes/road/avanti-giro-fm-1-white-bike8439">
                        <Image src={bannerwhite} style={{width:"50%"}} />
                    </Link>
                  </div>
               </div>

               <div>
                  <div style={{width:"100%"}}>
                     <img src={banner3} style={{width:"100%"}}/>
                  </div>
               </div>

               <div>
                  <div style={{width:"100%"}}>
                     <img src={banner4} style={{width:"100%"}}/>
                  </div>
               </div>

               <div>
                  <div style={{width:"100%"}}>
                     <img src={banner5} style={{width:"100%"}}/>
                  </div>
               </div>

               <div>
                  <div style={{width:"100%"}}>
                     <img src={banner6} style={{width:"100%"}}/>
                     
                  </div>
               </div>

               <div>

               </div>
               
                     
                     <div className='d-flex justify-content-center align-items-center'>
                  <a href="locahost:3000/" class="btn  btn-lg active  m-5" style={{backgroundColor : "#004e86", color:"#e0fdff" ,border: "1px solid rgba(0, 0, 0, 0.05)",fontWeight:"bold",fontSize:"23px",paddingRight:'38px',paddingLeft:'38px'}} role="button" aria-pressed="true">BUY NOW</a>
                  </div>
               
               </div>
            </div>
         </div>
         </React.Fragment>
       )
    }
 }

 export default (AvantiHomeIndia);
