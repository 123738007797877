import React from 'react';
import {Container, Button} from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

import EmptyCartImg from '../assets/images/icons/cart.png'; 
import LogoImg from '../assets/images/icons/scott_network_logo.jpeg'; 

class EmptyCart extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div className="container">
        <div className="col-sm-8 mx-auto mt-5">
          <div className="text-center mb-4">
            <img src={EmptyCartImg} className="img-fluid empty_img" />
          </div>
          <div className="text-center">
            <h4 className="black bold">{t('emptyCartText')}!</h4>
            <p>{t('emptyCartSubText')}</p>
            <Link to='/'>
              <Button style={this.props.styles.primary_btn}>{t('shopNow')}</Button>
            </Link>
          </div>
        </div>       
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    styles: state.styles.styles,
  }
}

export default withTranslation()(connect(mapStateToProps)(EmptyCart));



