import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import CustomerFooter from './CustomerFooter';
import DealerFooter from './DealerFooter';

import LogoMini from '../../../assets/images/icons/logo_mini.png';
import Separator from '../../../assets/images/icons/separator.png';
import visa from '../../../assets/images/icons/visa.png';
import mastercard from '../../../assets/images/icons/mastercard.png';
import paypal from '../../../assets/images/icons/paypal.png';
import { dealersPortal } from '../../../../core/helpers/OperationsHelper';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { addClass: false, openDiv: 1, isOpen: false }
  }
  navToggle(openDiv, isOpen = false) {
    if (isOpen == true)
      this.setState({ openDiv: openDiv, isOpen: false });
    else
      this.setState({ openDiv: openDiv, isOpen: true });

  }
  render() {
    const addClass = this.state.addClass;
    const { t } = this.props;
    var d = new Date();
    var currentYear = d.getFullYear();
    return (
      <div className="footer">

        {(dealersPortal == true) &&
          <DealerFooter />
        }
        {(dealersPortal == false) &&
          <CustomerFooter />
        }
        <Container>
          <hr />
          <Row className="footer_bottom social">
            {/* <Col lg={3} md={6} sm={6} className="mb-3 mb-lg-0">
              <p className="footer_title hiphen open text-left text-lg-center">Follow Scott india</p>
              <ul className="social">
                <li>
                  <a href="https://www.facebook.com/SCOTTSportsIndia" target="_blank"><i className="fab fa-facebook-square" /></a>
                </li>
                <li>
                  <a href="https://www.instagram.com/scottsportsindia/" target="_blank"><i className="fab fa-instagram" /></a>
                </li>
                <li>
                  <a href="https://www.youtube.com/scottsportsindia" target="_blank"><i className="fab fa-youtube" /></a>
                </li>
                <li>
                  <a href="https://twitter.com/scottsportsin" target="_blank"><i className="fab fa-twitter" /></a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/scott-sports-india" target="_blank"><i className="fab fa-linkedin" /></a>
                </li>
              </ul>
            </Col>
            <Col lg={3} md={6} sm={6} className="mb-3 mb-lg-0">
              <p className="footer_title hiphen open text-left text-lg-center">Follow BGM india</p>
              <ul className="social">
                <li>
                  <a href="https://www.facebook.com/bergamontIndia/" target="_blank"><i className="fab fa-facebook-square" /></a>
                </li>
                <li>
                  <a href="https://www.instagram.com/bergamont_india/" target="_blank"><i className="fab fa-instagram" /></a>
                </li>               
              </ul>
            </Col>
            <Col lg={3} md={6} sm={6} className="mb-3 mb-lg-0">
              <p className="footer_title hiphen open text-left text-lg-center">Follow Syncros international</p>
              <ul className="social">
                <li>
                  <a href="https://www.facebook.com/SyncrosBike/" target="_blank"><i className="fab fa-facebook-square" /></a>
                </li>
                <li>
                  <a href="https://www.instagram.com/syncrosbike/" target="_blank"><i className="fab fa-instagram" /></a>
                </li>
                <li>
                  <a href="https://www.youtube.com/c/syncrosbike" target="_blank"><i className="fab fa-youtube" /></a>
                </li>               
              </ul>
            </Col>

            <Col lg={3} md={6} sm={6} className="mb-3 mb-lg-0">
            <p className="footer_title hiphen open text-left text-lg-center">Follow Avanti india</p>
              <ul className="social">
                <li>
                  <a href="https://www.facebook.com/AvantiBikesIndia" target="_blank"><i className="fab fa-facebook-square" /></a>
                </li> 
                <li>
                  <a href="https://www.instagram.com/avantibikesin/" target="_blank"><i className="fab fa-instagram" /></a>
                </li>                          
              </ul>
            </Col> */}

            <Col lg={12} md={6} sm={6} className="mb-3 mb-lg-0">
              <p className="footer_title hiphen open text-left text-lg-center">Follow sport network</p>
              <ul className="social">
                <li>
                  <a href="https://www.instagram.com/sportnetwork.in/" target="_blank"><i className="fab fa-instagram" /></a>
                </li>
                <li>
                  <a href="https://www.facebook.com/sportnetwork.in" target="_blank"><i className="fab fa-facebook-square" /></a>
                </li>
              </ul>
            </Col>



          </Row>
        </Container>
        <Container>
          <hr className="mt-4 mb-0" />
          <Row>
            <Col xs={12} className="text-center py-3">
              {dealersPortal === false ?
                <div className="payment_links">
                  <span><img src={visa} /></span>
                  <span><img src={mastercard} /></span>
                  <span><img src={paypal} /></span>
                </div>
                :
                localStorage.length == 1 ?
                  <div className="payment_links">
                    <span><img src={visa} /></span>
                    <span><img src={mastercard} /></span>
                    <span><img src={paypal} /></span>
                  </div>
                  :
                  null
              }

              <p>{t('copyRights')} © 2022 SCOTT NETWORK SOLUTIONS. {t('rights')}.</p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    styles: state.styles.styles,
    dealerBrands: state.dealers.brands,
  }
}

export default withTranslation()(connect(mapStateToProps)(Footer));