import React from 'react';
import {Container, Row, Col, Button, Form} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

import CoreUser from '../../../../core/components/users/CoreUser';



class ForgotPassword extends CoreUser {	
	render(){
		const { t } = this.props;
		return (
			<React.Fragment> 
				<div className="login">	
					<div className="main_section">
						<Container>
							<div className="mb-5">
								<a href="/"><img src={this.props.settings.image_url+'/application_images/original/'+this.props.settings.header_logo} width="100px" /></a>
							</div>							
							<Row>
								<Col lg={6} sm={9} xs={12} className="mx-auto mt-4">
									<div className="">
										<h5 className="bold text-uppercase black text-center mb-4">{t('forgotYourPassword')}</h5>
										<p className="">{t('resetMail')}.</p>
									</div>
									<Form className="fields-group-md">
										<Form.Group as={Row}>
									    <Form.Label column lg="3">{t('email')} :</Form.Label>
									    <Col lg="9">
									    	<Form.Control type="email" onChange={(email)=>this.setState({'email_id':email.target.value})} className="mb-1" />
									    	<span className="red">{(typeof this.state.errors['forgot_password[email_id]'] != 'undefined' && this.state.errors['forgot_password[email_id]'] != "")?this.state.errors['forgot_password[email_id]']:""}</span>									    	
									    </Col>							   
									  </Form.Group>									  
									</Form>
									<Alert show={this.state.showMessage} variant="success">
						        <Alert.Heading>{t('passwordResetRequest')}.</Alert.Heading>
						        <p>
						          {this.state.message}
						        </p>
						        <hr />
						      </Alert>
									<div className="text-center mt-5">
										<Link to='/login'>
											<Button className="btn btn-md transparent mr-3" style={this.props.styles.secondary_btn}>{t('back')}</Button>
										</Link>
										<Button className="btn btn-md" onClick={()=>this.forgotPassword()} style={this.props.styles.primary_btn}>{t('submit')}</Button>
									</div>
								</Col>								
							</Row>
						</Container>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings,
    styles: state.styles.styles,
  }
}

export default withTranslation()(connect(mapStateToProps)(ForgotPassword));