import React from 'react';
import {Container, Row, Col, Button, ButtonGroup, Table} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { Document, Page } from "react-pdf";
import { connect } from "react-redux";

import { getGemetory } from '../../../../core/helpers/ServicesHelper';

class Geomatry extends React.Component { 
  constructor(props) {
    super(props);
    this.state = { numPages: null, pageNumber: 1,pdf:"" };
  }

  showMm() {
   document.getElementById("geomatry_data").classList.add('active');
  }

  showIn() {
    document.getElementById("geomatry_data").classList.remove('active');
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  goToPrevPage = () =>{
    if(this.state.pageNumber >= 1)
      this.setState(state => ({ pageNumber: state.pageNumber - 1 }));
  }

  goToNextPage = () =>{
    if(this.state.pageNumber < this.state.numPages)
      this.setState(state => ({ pageNumber: state.pageNumber + 1 }));
  }


  async getGemetory(url) {
    var gematory = await getGemetory();
    this.setState({pdf:gematory.data.data});
  }


  render() { 
    const { pageNumber, numPages } = this.state;
    const { t } = this.props;
    return (
      <Container>
        <Row>        
          <Col className="center"  align="center" lg={12}>
            {(this.props.geometryAttachment != "" && this.props.geometryAttachment != null)?
              <h5>{t('viewGeometry')} <a href={this.props.settings.image_url+'product_details/product_pdfs/original/'+this.props.geometryAttachment} target="_blank">{t('clickHere')}</a></h5>:
              <h5>{t('noGeometry')}</h5>               
            }
          </Col>
        </Row>
      </Container>
    );
  };
};

const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings,
  }
}

export default withTranslation()(connect(mapStateToProps, null)(Geomatry));
