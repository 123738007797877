import React from 'react';
import { Container, Row, Col, Carousel, Button, Image } from 'react-bootstrap';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CoreHome from '../../../../core/components/home/CoreHome';
import AvantiNewsLetter from './AvantiNewsLetter';


  
class NewsLetter extends CoreHome {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <React.Fragment>
        <div style={{ paddingTop: "112px" }} >
          <div className='d-flex flex justify-content-center p-3' style={{ backgroundColor: "black", color: 'white', }} >
            <h2 style={{ fontWeight: "600" }}>NEWSROOM</h2>
          </div>

          <div className='d-flex flex justify-content-center mt-1 '>
            <h6 className='' style={{ color: 'black', fontSize: "20px", fontWeight: "500", textAlign: "center" }}>For any press and media queries, write to us at cs@sportnetwork.in</h6>
          </div>
        </div>


        <div className='p-5' >
          <ul className="nav nav-pills nav-justified warranty_tabs mt-4  d-flex flex justify-content-center ">

            <li className=""  >
              <a className="nav-link active text-uppercase bold" style={{width:'210px', textAlign:"center",backgroundColor:"#ffb300"}} data-toggle="pill" href="#AvantiNewsLetter">Avanti</a>
            </li>
            
         
          </ul>
        </div>

        <div className="tab-content">
          <div className="tab-pane  active" id="AvantiNewsLetter">
            <AvantiNewsLetter />
          </div>
        </div>

      </React.Fragment>
    )
  }
}

export default (NewsLetter);
