import React from 'react';
import {Container, Row, Col, Button, Table} from 'react-bootstrap';


class ProductSpecifications extends React.Component { 
  render() {   
    return (
      <Row>
        <Col lg={12}>
          <Table size="sm">
            <tr className="specs_list_item row">
            {(this.props.specifications != "" && this.props.specifications.length > 0) && 
              this.props.specifications.map((specification,key)=>(
              <React.Fragment key={key}>
                <td className="left_col">
                  <h7 className="specs_list_title text-uppercase" style={{fontSize:'0.85rem'}}>{specification.field_name}</h7>
                </td>
                <td className="right_col">
                  <div className="specs_list_content">
                    {
                      (specification.field_type != "4")?
                        specification.field_value.split('|').map((value, key) => (
                          <p key={key} className="mb-0 bold pr-0 pr-sm-5">{value}</p>
                        )):(specification.textarea_value != null) && specification.textarea_value.split('|').map((value, key) => (
                        <p key={key} className="mb-0 bold pr-0 pr-sm-5">{value}</p>
                      ))
                    }
                  </div>
                </td>
              </React.Fragment>
            ))
            }
            </tr>
          </Table>          
        </Col>        
      </Row>
    );
  };
};

export default ProductSpecifications;