import React from 'react';
import {Container, Col, Row, Button, Modal,Input} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import CoreProductListing from '../../../../core/components/products/CoreProductListing';
import { getItemVariations, getCartProducts, getWishlistProducts } from  '../../../../core/helpers/ServicesHelper';
import { connect } from "react-redux";

class MoveToCartModal extends React.Component {

  render() {
    const { t,settings,moveToCartModalVisibility} =this.props;
    return (
      <Modal show={moveToCartModalVisibility} >
        <Modal.Header closeButton onClick={()=>this.props.setMoveToCartModalVisibility(false)}>
          <Modal.Title>{t('moveToCart')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div  className="product_sizes">
            <div className="d-flex mb-2 align-items-center">
              <h6 className="mb-0 pr-3 bold">Enter Remark</h6>                         
              <input type="text" onChange={(e)=>this.props.setRemark(e.target.value)} placeholder="Enter Remark"/>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary"className="btn-lg transparent" onClick={()=>this.props.moveItemToCart(this.props.selectedItem)}>Move to cart</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    settings:state.settings.settings
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    increaseCartCount: () => dispatch({ type: 'ADD_TO_CART'}),
    decreaseCartCount: () => dispatch({ type: 'REMOVE_FROM_CART'}),
    updateCartItems: (cartItems) => {dispatch({ type: 'SET_CART_ITEMS', payload: cartItems})},
    increaseWishListCount: () => dispatch({ type: 'ADD_TO_WISHLIST'}),
    decreaseWishlistCount: () => dispatch({ type: 'REMOVE_FROM_WISHLIST'}),
    updateWishListItems: (wishListItems) => {dispatch({ type: 'SET_WISHLIST_ITEMS', payload: wishListItems})},
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(MoveToCartModal));



