import React from 'react';
import {Container, Row, Col, Table} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import CoreSalesVouchers from './../../../../core/components/salesVouchers/CoreSalesVouchers';
import AccountAddress from '../components/AccountAddress';

class AccountingVoucher extends CoreSalesVouchers {	
	render(){
		const { tallyCompany,tallyVoucher, voucherDetails, voucherReference, voucherTaxCalculations } = this.state;
		return (
			<React.Fragment> 
				<div className="main pb-5">	
					<Container className="">
						<h4 className="black bold text-center mb-4">Tax Invoice</h4>
						<Row className="invoice_company_details mb-4">
							<Col sm={6} xs={12} className="address_sec">
								<Row>
									<Col sm={12} className="address">
										<div>
											<AccountAddress tallyCompany={tallyCompany} tallyVoucher={tallyVoucher}/>
										</div>
									</Col>
									<Col sm={12} className="address">
										<div>
											<p className="mb-0">Consignee</p>
											<h5 className="bold black mb-0">{typeof tallyVoucher.party_name != 'undefined' && tallyVoucher.party_name !='' ? tallyVoucher.party_name:''}</h5>
											<p className="mb-0">{typeof tallyVoucher.party_address != 'undefined' && tallyVoucher.party_address !='' ? tallyVoucher.party_address:''}</p>
											<p className="mb-0">GSTIN/UIN: {typeof tallyVoucher.consignee_gst_number != 'undefined' && tallyVoucher.consignee_gst_number !='' ? tallyVoucher.consignee_gst_number:''}</p>
											<p className="mb-0">State Name : {typeof tallyVoucher.consignee_state_name != 'undefined' && tallyVoucher.consignee_state_name !='' ? tallyVoucher.consignee_state_name:''} , 
																					Code : {typeof tallyVoucher.consignee_state_code != 'undefined' && tallyVoucher.consignee_state_code !='' ? tallyVoucher.consignee_state_code:''}</p>
										</div>
									</Col>
									<Col sm={12} className="address">
										<div>
											<p className="mb-0">Buyer (if other than consignee)</p>
											<h5 className="bold black mb-0">{typeof tallyVoucher.party_name != 'undefined' && tallyVoucher.party_name !='' ? tallyVoucher.party_name:''}</h5>
											<p className="mb-0">{typeof tallyVoucher.party_address != 'undefined' && tallyVoucher.party_address !='' ? tallyVoucher.party_address:''}</p>
											<p className="mb-0">GSTIN/UIN: {typeof tallyVoucher.consignee_gst_number != 'undefined' && tallyVoucher.consignee_gst_number !='' ? tallyVoucher.consignee_gst_number:''}</p>
											<p className="mb-0">State Name : {typeof tallyVoucher.consignee_state_name != 'undefined' && tallyVoucher.consignee_state_name !='' ? tallyVoucher.consignee_state_name:''} , 
																					Code : {typeof tallyVoucher.consignee_state_code != 'undefined' && tallyVoucher.consignee_state_code !='' ? tallyVoucher.consignee_state_code:''}</p>
										</div>
									</Col>
								</Row>
							</Col>
							<Col sm={6} xs={12}>
								<Row>
									<Col sm={6} xs={6} className="invoice_info">
										<p className="mb-0">Invoice No.</p>
										<p className="black bold">{typeof tallyVoucher.voucher_no != 'undefined' && tallyVoucher.voucher_no != '' ? tallyVoucher.voucher_no :''}</p>
									</Col>
									<Col sm={6} xs={6} className="invoice_info">
										<p className="mb-0">Dated</p>
										<p className="black bold">{typeof tallyVoucher.date != 'undefined' && tallyVoucher.date != '' ? tallyVoucher.date : ''}</p>
									</Col>
									<Col sm={6} xs={6} className="invoice_info">
										<p className="mb-0">Delivery Note</p>
										<p className="black bold"></p>
									</Col>
									<Col sm={6} xs={6} className="invoice_info">
										<p className="mb-0">Mode/Terms of Payment</p>
										<p className="black bold"></p>
									</Col>
									<Col sm={6} xs={6} className="invoice_info">
										<p className="mb-0">Supplier’s Ref.</p>
										<p className="black bold">{typeof tallyVoucher.voucher_reference_no && tallyVoucher.voucher_reference_no}</p>
									</Col>
									<Col sm={6} xs={6} className="invoice_info">
										<p className="mb-0">Other Reference(s)</p>
										<p className="black bold"></p>
									</Col>
									<Col sm={6} xs={6} className="invoice_info">
										<p className="mb-0">Buyer’s Order No.</p>
										<p className="black bold"></p>
									</Col>
									<Col sm={6} xs={6} className="invoice_info">
										<p className="mb-0">Dated</p>
										<p className="black bold"></p>
									</Col>
									<Col sm={6} xs={6} className="invoice_info">
										<p className="mb-0">Dispatch Document No.</p>
										<p className="black bold"></p>
									</Col>
									<Col sm={6} xs={6} className="invoice_info">
										<p className="mb-0">Delivery Note Date</p>
										<p className="black bold"></p>
									</Col>
									<Col sm={6} xs={6} className="invoice_info">
										<p className="mb-0">Despatched through</p>
										<p className="black bold"></p>
									</Col>
									<Col sm={6} xs={6} className="invoice_info">
										<p className="mb-0">Destination</p>
										<p className="black bold"></p>
									</Col>
									<Col sm={12} xs={12} className="invoice_info">
										<p className="mb-0">Terms of Delivery</p>
										<p className="black bold"></p>
									</Col>
								</Row>
							</Col>
						</Row>	
						<Row>
							<Table className="receipt_table" bordered responsive size="sm">
								<thead>
									<tr>
										<th className="text-center">Sr No.</th>
										<th className="text-center">Description of Goods</th>
										<th className="text-center">HSN/SAC</th>
										<th className="text-center">GST Rate</th>
										<th className="text-center">Quantity</th>
										<th className="text-center">Rate</th>
										<th className="text-center">Per</th>
										<th className="text-center">Amount</th>
									</tr>
								</thead>
								<tbody>
									{voucherDetails.length > 0 && voucherDetails.map((item,index)=>(
										<tr key={index}>
											<td>{index+1}</td>
											<td>
												<p className="black bold mb-1">{item.item_code}</p>
												<p className="mb-0">{item.item_description}</p>
											</td>
											<td>{item.hsn_code}</td>
											<td className="text-right">{parseFloat(item.gst_rate).toFixed(2)}%</td>
											<td className="text-right black bold">{item.quantity} {item.unit}</td>
											<td className="text-right black bold">{parseFloat(item.rate).toFixed(2)}</td>
											<td className="text-right">{item.unit}</td>
											<td className="text-right black bold">{parseFloat(item.amount).toFixed(2)}</td>
										</tr>
									))}
									
									{voucherReference.length > 0 && voucherReference.map((reference,index)=>(
										reference.particular != tallyVoucher.party_name && 
											<tr>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td className="text-right">
													<p className="black bold mb-1">{reference.particular}</p>
												</td>
												<td className="text-right">
													<p className="black bold mb-0">{parseFloat(reference.debit_amount - reference.credit_amount).toFixed(2)}</p>
												</td>
											</tr>
									))}
									{voucherReference.length > 0 && voucherReference.map((reference,index)=>(
										reference.particular == tallyVoucher.party_name && 
											<tr>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td className="text-right">
													<p className="black bold mb-1">Total</p>
												</td>
												<td className="text-right">
													<p className="black bold mb-0">{parseFloat(reference.debit_amount - reference.credit_amount).toFixed(2)}</p>
												</td>
											</tr>
									))}
								</tbody>
								<tfoot>
									<tr>
										<td colspan="7">
											<p className="mb-1">Amount Chargeable (in words) :</p>
											<p className="black bold mb-0">Indian Rupees {this.state.totalAmountInWords} Only</p>
										</td>
										<td className="text-right">E. & O.E</td>
									</tr>
									<tr>
										<td colspan="8">
											<Table className="receipt_table" bordered size="sm">
												<thead>
													<tr>
														<th className="text-center">HSN/SAC</th>
														<th className="text-center">Taxable Value</th>
														<th className="text-center" colspan="2">Central Tax</th>
														<th className="text-center" colspan="2">State Tax</th>
														<th className="text-center" colspan="2">Integrated Tax</th>
														<th className="text-center">Total Tax Amount</th>
													</tr>
													<tr>
														<th className="text-center"></th>
														<th className="text-center"></th>
														<th className="text-center">Rate</th>
														<th className="text-center">Amount</th>
														<th className="text-center">Rate</th>
														<th className="text-center">Amount</th>
														<th className="text-center">Rate</th>
														<th className="text-center">Amount</th>
														<th className="text-center"></th>
													</tr>
												</thead>
												<tbody>
													{    typeof voucherTaxCalculations != 'undefined' 
														&& typeof voucherTaxCalculations.detail != 'undefined' 
														&& (voucherTaxCalculations.detail.map((taxCalculation, index) => (
															<tr>
																<td className="text-right">{taxCalculation.hsn_code}</td>
																<td className="text-right">{parseFloat(taxCalculation.taxable_value).toFixed(2)}</td>
																<td className="text-right">{taxCalculation.central_tax_rate}</td>
																<td className="text-right">{parseFloat(taxCalculation.central_tax_amount).toFixed(2)}</td>
																<td className="text-right">{taxCalculation.state_tax_rate}</td>
																<td className="text-right">{parseFloat(taxCalculation.state_tax_amount).toFixed(2)}</td>
																<td className="text-right">{taxCalculation.integrated_tax_rate}</td>
																<td className="text-right">{parseFloat(taxCalculation.integrated_tax_amount).toFixed(2)}</td>
																<td className="text-right">{parseFloat(taxCalculation.total_tax_amount).toFixed(2)}</td>
															</tr>		
														))
													)}
													{typeof voucherTaxCalculations != 'undefined' && (
														<tr>
															<td className="text-right black bold">Total</td>
															<td className="text-right black bold">{parseFloat(voucherTaxCalculations.total.total_taxable_value).toFixed(2)}</td>
															<td className="text-right black bold"></td>
															<td className="text-right black bold">{parseFloat(voucherTaxCalculations.total.total_central_tax_amount).toFixed(2)}</td>
															<td className="text-right black bold"></td>
															<td className="text-right black bold">{parseFloat(voucherTaxCalculations.total.total_state_tax_amount).toFixed(2)}</td>
															<td className="text-right black bold"></td>
															<td className="text-right black bold">{parseFloat(voucherTaxCalculations.total.total_integrated_tax_amount).toFixed(2)}</td>
															<td className="text-right black bold">{parseFloat(voucherTaxCalculations.total.total_tax_amount).toFixed(2)}</td>
														</tr>
													)}												
												</tbody>
											</Table>
										</td>
									</tr>
									<tr>
										<td colspan="8">
											<p className="mb-1">Tax Amount (in words) :</p>
											<p className="black bold mb-0">Indian Rupees {this.state.totalTaxInWords} Only</p>
										</td>
									</tr>
									<tr>
										<td colspan="4">
											<p className="mb-0">Company’s Service Tax No. : <span className="black bold">{tallyCompany.company_service_tax_no}</span></p>
											<p className="mb-0">Company’s PAN : <span className="black bold">{tallyCompany.company_pan_no}</span></p>
										</td>
										<td colspan="4">
											<p className="mb-0">Company’s Bank Details</p>
											<p className="mb-0">Bank Name : <span className="black bold">{tallyCompany.company_bank_name}</span></p>
											<p className="mb-0">A/c No. : <span className="black bold">{tallyCompany.company_account_number}</span></p>
											<p className="mb-0">Branch & IFS Code : <span className="black bold">Hiranandani Meadows & {tallyCompany.company_bank_ifsc}</span></p>
										</td>
									</tr>
								</tfoot>
							</Table>
						</Row>						
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

export default AccountingVoucher;