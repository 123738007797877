import React, { Component } from 'react';
import { getOrders, getImageContent, storeAddresses, updateAddresses, getAddresses ,getCountries, 
         getStates, getCartListing, getOrderSummery,getUser,checkPinCode,getCities } from '../../helpers/ServicesHelper';
import { toast } from 'react-toastify';
import { dealersPortal, mobile } from '../../helpers/OperationsHelper';
import { submitOrder} from  '../../helpers/TransactionsHelper';
import cookie from 'react-cookies';

export default class CoreCheckout extends React.Component {
  constructor(props){
		super(props);
		this.state={
			checked: false,
			billingAddress: {},
			shippingAddress: {},
      guestLogin:false,
      addresses: [],
			errors: [],
			countries:[],
			loader:true,
      selectedAddressId: "",
      statesArray: {'shippingAddress': { states: [] },
                    'billingAddress': { states: [] } },
      citiesArray: {'shippingAddress': { cities: [] },
                    'billingAddress': { cities: [] } }              
		}
    this.images = [];
	}

  async getAddresses() {
    if (cookie.load('authToken')) {
      if (Object.keys(this.props.user).length > 0 && typeof this.props.user.id != '') {
        console.log(this.props.user);
        var where = 'where[fl_user_addresses.user_id]='+this.props.user.id+'&&where[fl_user_addresses.type]=shipping';      
        var response = await getAddresses(where); 
        if (response.data.status == 'success') {
          response.data.data.map( async (address, index) => {
            if (address.default_address == 1) {
              await this.updateAddresses(address.id); 
            }
          })
          await this.props.setAddresses(response.data.data);
        }
      }
    }
	}

	updateStateList(e, value){
    if (e.target.checked){
      this.setState({billingAddress: this.state.shippingAddress,checked:true,
      							'statesArray':{billingAddress:{states:this.state.statesArray.shippingAddress.states},
      														 shippingAddress:{states:this.state.statesArray.shippingAddress.states}}});
    } else {
      this.setState({billingAddress: {},checked:false});
   	}
  }

  async getCountries(){
    var response = await getCountries();
    if(response.data.status == 'success')
    	this.setState({countries:response.data.data})
    else
    	this.setState({countries:[]})
  }

  async changeFormdataValues(e,name,type,inputType='text'){
  	var setShippSngAddressArray = this.state.shippingAddress;
  	var setBillingAddressArray = this.state.billingAddress;
  
  	this.onChangeCountry(e.target.value,name,type);
    this.onChangeState(e.target.value,name,type);

  	if(type == 'shippingAddress'){
      if (inputType == 'checkbox')
        setShippSngAddressArray[name] = (e.target.checked) ? 1 : 0;
      else 
        setShippSngAddressArray[name] = e.target.value;
  		this.setState({shippingAddress:setShippSngAddressArray})
  	} else if(type == 'billingAddress'){
      if (inputType == 'checkbox')
  		  setBillingAddressArray[name] = (e.target.checked) ? 1 : 0;
      else  
        setBillingAddressArray[name] = e.target.value;
  		this.setState({billingAddress:setBillingAddressArray})
  	}

  	if(this.state.checked == true && type != 'billingAddress'){
  		this.onChangeCountry(e.target.value,name,type);
      this.onChangeState(e.target.value,name,type);
  		setShippSngAddressArray[name] = e.target.value
  		this.setState({billingAddress:setShippSngAddressArray})
  	}
  }

  async onChangeCountry(value,name,type){
  	if(name == 'country'){
  		var where = 'where[countries.name]='+value;
  		var states = await getStates(where);
  		var statesArray = this.state.statesArray;
  		if(states.data.status == 'success'){
  			statesArray[type] = {'states':states.data.data}
  			this.setState({statesArray:statesArray})
  		}else{
  			statesArray[type] = {'states':[]}
  			this.setState({statesArray:statesArray})
  		}

  		if(this.state.checked == true && type != 'billingAddress'){
  			statesArray['billingAddress'] = {'states':this.state.statesArray.shippingAddress.states}
  			this.setState({statesArray:statesArray})
  		}
  	}
  }

  async onChangeState(value,name,type){
    if(name == 'state'){
      var where = 'where[states.name]='+value;
      var states = await getCities(where);
      var citiesArray = this.state.citiesArray;
      if(states.data.status == 'success'){
        citiesArray[type] = {'cities':states.data.data}
        this.setState({citiesArray:citiesArray})
      }else{
        citiesArray[type] = {'cities':[]}
        this.setState({citiesArray:citiesArray})
      }

      if(this.state.checked == true && type != 'billingAddress'){
        citiesArray['billingAddress'] = {'states':this.state.citiesArray.shippingAddress.cities}
        this.setState({citiesArray:citiesArray})
      }
    }
  }

  async updateAddresses(id){
    var formdata = [];
    console.log(id);
    this.setState({ errors: []});
    formdata['shipping_addresses[id]'] = id;
    var result = await updateAddresses(formdata, id);
    if(result.data.status == 'error'){
      this.setState({'errors':result.data.errors})
    }else{     
      await this.setSelecedAddress(id);
    }
  }

  async setSelecedAddress(id) {
    this.setState({ selectedAddressId: id });
  }

  async storeAddresses(id=""){
    console.log(this.state.guestLogin)
    var formdata = [];
  	this.setState({ errors: [], loader: true,selectedAddressId:'' });
    if (id != "") {
      formdata['shipping_addresses[id]'] =id;  
      console.log("address id" + id);
    }
  	formdata['shipping_addresses[type]'] = 'shipping';
  	formdata['shipping_addresses[user_id]'] = (typeof this.props.user.id != 'undefined') ? this.props.user.id:"";
  	formdata['shipping_addresses[name]'] = this.state.shippingAddress.name;
  	formdata['shipping_addresses[street_address]'] = this.state.shippingAddress.street_address; 
    formdata['shipping_addresses[full_address]'] = this.state.shippingAddress.full_address; 
  	formdata['shipping_addresses[city]'] = this.state.shippingAddress.city;
  	formdata['shipping_addresses[state]'] = this.state.shippingAddress.state; 
  	formdata['shipping_addresses[postal_code]'] = this.state.shippingAddress.postal_code;
  	formdata['shipping_addresses[country]'] = this.state.shippingAddress.country; 
  	formdata['shipping_addresses[mobile_no]'] = this.state.shippingAddress.mobile_no; 
  	formdata['shipping_addresses[email_id]'] = this.state.shippingAddress.email_id;
    formdata['shipping_addresses[default_address]'] = this.state.shippingAddress.default_address; 
    if (id == "" && this.props.addressesLength == 0) {
      formdata['billing_addresses[type]'] = 'billing';
      formdata['billing_addresses[name]'] = this.state.billingAddress.name;
      formdata['billing_addresses[street_address]'] = this.state.billingAddress.street_address; 
      formdata['billing_addresses[full_address]'] = this.state.billingAddress.full_address; 
      formdata['billing_addresses[city]'] = this.state.billingAddress.city;
      formdata['billing_addresses[state]'] = this.state.billingAddress.state; 
      formdata['billing_addresses[postal_code]'] = this.state.billingAddress.postal_code;
      formdata['billing_addresses[country]'] = this.state.billingAddress.country; 
      formdata['billing_addresses[mobile_no]'] = this.state.billingAddress.mobile_no; 
      formdata['billing_addresses[email_id]'] = this.state.billingAddress.email_id; 
      formdata['billing_addresses[same_as_shipping_address]'] = (this.state.checked == true) ? 'yes' : 'no';  
    }
    //console.log(formdata);
    if (id != '') {
      var result = await updateAddresses(formdata, id);
      if(result.data.status == 'success'){
        console.log(result)
        this.props.history.push('/checkout');
      }else{
        this.setState({'errors':result.data.errors})
      }
    } else { 
      var result = await storeAddresses(formdata); 
      console.log(result);
      this.handleRedirection(result);	
    }
    if (result.data.status == 'success') {
      if (mobile == false) {
        window.scrollTo(0, 0)
        toast.success('Address updated successfully.', {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }  
    }
  }

  async handleRedirection(result) {
    if (result.data.status == 'success') {
      if(!cookie.load('authToken'))
        await this.getUser(result.data.data.user_id);
      await this.getAddresses();    
      this.setState({shippingAddress:{name:"", email_id: "", mobile_no: "", street_address: "", state: "", city: "", postal_code: "",default_address:0,selectedAddressId:'',statesArray: {'shippingAddress': { states: [] },
                    'billingAddress': { states: [] } } }}); 
    } else {
      this.setState({ loader: false });
      this.setState({errors: result.data.errors});
    } 

  }

  async getUser(userId){
    var response = await getUser(userId);
    if(response.data.status == 'success'){
      this.useStorage(response.data.data[0]);
    }
  }

  useStorage=async(credentials)=>{
    var userData = credentials;
    cookie.save('authToken', credentials.authToken, { path: '/' });
    this.props.setUser(credentials);
  }
}
