import React, { Component } from 'react';
import { getOrderDetail, getImageContent, cancelOrder, cancelOrderItem } from '../../helpers/ServicesHelper';
import { getImageUrl, getImagePath, mobile } from  '../../helpers/OperationsHelper';
import { toast } from 'react-toastify';

export default class CoreOrdersDetail extends React.Component {
  constructor(props){
		super(props);
		this.state={
			orderDetail:[],
			images: {},
			loader: true,
			showSuccessMessage: false,
			errors: [],
			cancleOrder:{'order_id':'','order_detail_id':''},
			returnOrReplacementPoupup:false
		}
		this.images = {};
	}
	
	async componentDidMount(){
		window.history.pushState(null, null, window.location.pathname);
		await this.getOrderDetail();
		this.setState({ loader: false });
	}

	async getOrderDetail(){
		if (mobile == true) {
			var orderId = this.props.route.params.orderId;
		} else {
			var orderId = this.props.match.params.orderId;
			console.log(orderId);	
			var success = this.props.match.params.status;
			if (typeof success != 'undefined') 
				this.setState({ 'showSuccessMessage': true });
		}
		
		var orderDetail = await getOrderDetail(orderId);
		if (orderDetail.data.status == 'success') {
			await this.setState({orderDetail:orderDetail.data.data})	
			if (Object.keys(orderDetail.data.data).length > 0) 
				this.getBase64Images(orderDetail.data.data.products);
		}
	}

  async getBase64Images(order_details){
  	order_details.map((order_detail, index) => {
  		if (order_detail.item_images != null && typeof order_detail.item_images.name != 'undefined' && order_detail.item_images.name != null) {
       	var medium_image = this.props.settings.image_url+'products/images/medium/'+order_detail.item_images.name;
      	this.setImageInState(medium_image, order_detail.id);

      } else {
        this.setImageInState(this.props.settings.image_url+'/application_images/original/'+this.props.settings.error_image, order_detail.id);
      }	
  	})        
  } 

  async setImageInState(base64,id){
    this.images = this.state.images;
    this.images[id] = base64;
    this.setState({ images: this.images });
  }

  // async printOrderSummary(){
  // 	var printContents = document.getElementById('printableArea').innerHTML;
		// var originalContents = document.body.innerHTML;
		// document.body.innerHTML = printContents;
		// window.print();
		// document.body.innerHTML = originalContents;
  // }

  async returnReplace(order_id,order_detail_id){
  	this.setState({'cancleOrder':{'order_id':order_id, order_detail_id:order_detail_id },
  									returnOrReplacementPoupup:true });
  }

  async cancelOrder(order_id, order_detail_id) {
    var formdata = [];
    formdata['order_statuses[id]'] = order_detail_id;
    formdata['order_statuses[status]'] = 'cancelled';    
    var response = await cancelOrderItem(order_detail_id, formdata);
    if (response.data.status == 'success') {
      await this.getOrderDetail();
    } else {
    	toast.error(response.data.errors['refund_requests[order_detail_id]'], {
				position: "top-center",
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
    }
  }
}
