import React from 'react';
import {Col, Row, Card} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

class StoreCreditBalanceCard extends React.Component {
  render() {
    const { balance } = this.props;
    return (
      <Col lg={6} className="mb-10 mb-lg-10">                 
        <Card className="shadow-sm">
          <Card.Body>
            <Row className="mb-2">
              <Col lg={12}>
                <h2 className="small_heading black bold text-capitalize mb-0">Store Credit Balance</h2>
              </Col>                        
            </Row>
            <hr/>
            <Row className="mb-2">
              <Col lg={7}>
                <h6 className="gray">Available store credit balance</h6>
              </Col>
              <Col sm={7}>
                <h4 className="black bold"><i>&#8377;&nbsp;</i>{balance}/-</h4>
              </Col>
            </Row>                     
          </Card.Body>
        </Card>
      </Col>    
    );
  }
}
export default withTranslation()(StoreCreditBalanceCard);



