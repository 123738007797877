import React from 'react';
import {Container, Button} from 'react-bootstrap';
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import { withTranslation } from 'react-i18next';

import defaultImage from '../../../assets/images/products/default.jpeg';
import { getApiUrl } from '../../../../core/helpers/OperationsHelper';

class CompareProduct extends React.Component {
  constructor(props){
    super(props)
  }
  render() {
    const { t } = this.props;
    if(typeof this.props.product != "undefined" && this.props.product != "" && typeof this.props.product.product_images.name != 'undefined')
      var original_image = this.props.settings.image_url+'/products/images/large/'+this.props.product.product_images.name;
    else
      var original_image =  defaultImage;
    return (
      <li className="comparable">
        <div className="product_card">
          <div className="product_inside">
              <img src={original_image} className="product_img img-fluid" />
              <h6 className="text-uppercase bold">{(typeof this.props.product != "undefined" && this.props.product != "")?this.props.product.product.name:"Click compare below the product to add"}</h6>
            <div>
            {(typeof this.props.product != "undefined" && this.props.product != "") && 
              <Button type="button" variant="link" onClick={()=>this.props.removeCompareItem(this.props.product.id)} className="mx-auto compare_btn btn-sm"><i className="far fa-times"></i> Remove</Button>
            }
            </div>
          </div>
        </div>  
      </li> 
    );
  }   
}

const mapStateToProps = (state) => {
  return {
    settings:state.settings.settings
  }
}


export default withTranslation()(connect(mapStateToProps,null)(CompareProduct));