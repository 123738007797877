import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "../../../assets/css/triathlon.css";
import BannerImage from "../../../assets/images/triathlon/Banner_image.jpg";
import Bikes from "../../../assets/images/triathlon/bikes.png";
import Apparel from "../../../assets/images/triathlon/apparel.png";
import Shoe from "../../../assets/images/triathlon/shoe.png";
import Eyewear from "../../../assets/images/triathlon/eyewear.png";
import Helmet from "../../../assets/images/triathlon/helmet.png";
import Accessories from "../../../assets/images/triathlon/accessories.png";
import GetReady from "../../../assets/images/triathlon/get-ready.png";
import Banner from "../../../assets/images/triathlon/banner.png"

class Triathlon extends React.Component {
  render() {
    return (
      <div className="container-fluid text-center my-5 trithlon">
        <div className="row">
          <div className="col-12 mb-3">
            <a href="">
              <img
                src={BannerImage}
                className="img-fluid w-100 h-100"
                alt="not found"
              />
            </a>
          </div>
          {/* <Link to="pl/triathlon/bikes"> */}
          <div className="col-lg-4 col-md-6 col-6">
            <h2>BIKES</h2>
            <a href="pl/triathlon/bikes">
              <img
                src={Bikes}
                className="img-fluid"
                alt="not found"
              />
            </a>
          </div>
          {/* </Link> */}
          <div className="col-lg-4 col-md-6 col-6">
            <h2>APPAREL</h2>
            <a href="pl/triathlon/bike-accessories/apparel">
              <img
                src={Apparel}
                className="img-fluid"
                alt="not found"
              />
            </a>
          </div>
          <div className="col-lg-4 col-md-6 col-6">
            <h2>ACCESSORIES</h2>
            <a href="pl/triathlon/bike-accessories">
              <img
                src={Accessories}
                className="img-fluid"
                alt="not found"
              />
            </a>
          </div>
          <div className="col-lg-4 col-md-6 col-6">
            <h2>SHOE</h2>
            <a href="pl/triathlon/bike-accessories/shoe">
              <img
                src={Shoe}
                className="img-fluid"
                alt="not found"
              />
            </a>
          </div>
          <div className="col-lg-4 col-md-6 col-6">
            <h2>EYEWEAR</h2>
            <a href="pl/triathlon/bike-accessories/eyewear">
              <img
                src={Eyewear}
                className="img-fluid"
                alt="not found"
              />
            </a>
          </div>
          <div className="col-lg-4 col-md-6 col-6">
            <h2>HELMET</h2>
            <a href="pl/triathlon/bike-accessories/helmet">
              <img
                src={Helmet}
                className="img-fluid"
                alt="not found"
              />
            </a>
          </div>
        </div>
      </div>
    );
  }
}
export default Triathlon;