import React from 'react';
import {Container, Row, Col, Button, Form} from 'react-bootstrap';
import { Link } from 'react-router-dom'
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router';

import CoreUser from '../../../../core/components/users/CoreUser';

class Login extends CoreUser {	

	responseGoogle = (response) => {
		this.checkGoogleLogin(response)
	}

	responseGooglError = (response) => {
		console.log(response)
	}

	responseFacebook = (response) => {
		if(response.status != "unknown")
			this.checkFacebookLogin(response);
	}

	async componentDidMount(){
		var otpCount = localStorage.getItem('otpCount');
		if(otpCount != null || otpCount > 0) {
			this.props.setOtpCount(otpCount);
			this.time();
		}

		var param = new URLSearchParams(this.props.location.search).get("checkout")
		if(param == 1)
			this.setState({'isCommingFromCheckout':1})

		await this.checkLoginType();

	}

	async checkLoginType() {
		const paramOne=this.props.match.params.paramOne;
		if (typeof paramOne != 'undefined' && paramOne == 'access-token') {
			this.setState({ isAccessTokenLogin: true });
		} else {
			this.setState({ isAccessTokenLogin: false });
		}
	}

	render(){
		const { t,settings} = this.props;
		return (
			(this.state.redirectTo != "")?<Redirect to={this.state.redirectTo}/>:
			<React.Fragment> 
				<div className="login">	
					<div className="main_section">
						<Container>
							{/* <div>
								<input type="radio" value="en" onClick={(e)=>i18n.changeLanguage(e.target.value)} name="language"  /> English
								<input type="radio" value="fr" onClick={(e)=>i18n.changeLanguage(e.target.value)} name="language" />French
							</div> */}
							<div className="" style={{marginBottom : "55px"}}>  
								<a href="/"><img className="" src={settings.image_url+'/application_images/original/'+this.props.settings.header_logo} width="120px" /></a>
							</div>
							<div className="d-flex align-items-center">
								<div className="flex-fill mt-4">
									<h1 className="page_heading bold text-uppercase black">{t('login')}</h1>
								</div>
								
							</div>
							<Row>
								<Col lg={6} className="left_sec">
									<Form className="fields-group-md">
										{this.state.isAccessTokenLogin == false ?
											<React.Fragment> 
												<Form.Group as={Row}>
											    <Form.Label column lg="4">{t('mobile')} / {t('email')} :</Form.Label>
											    <Col lg="8">
											    	<Form.Control type="text" className="mb-1" value={this.state.loginUser} onChange={(e)=>this.setState({loginUser:e.target.value,error:''})} disabled={this.state.isOtpClick}/>	
											    	<span className="red">{(this.state.error['login[username]']!= "" && typeof this.state.error['login[username]']!= 'undefined')?this.state.error['login[username]']:""}</span>
														<span className="red">{(this.state.error['send_otp[username]']!= "" && typeof this.state.error['send_otp[username]']!= 'undefined')?this.state.error['send_otp[username]']:''}</span>
														<Link className="text-underline float-right" to={"#"} style={{fontSize:'0.95rem'}} onClick={()=>this.checkOtp()}>{this.state.otpText}</Link>	
														
													</Col>							   
											  </Form.Group>
											  <Form.Group as={Row} className="mb-0">
											    <Form.Label column lg="4">{t('password')} / {t('otp')} :</Form.Label>	
													<Col lg="8">
											    	<Form.Control type="password" value={this.state.loginPassword} onChange={(e)=>this.setState({loginPassword:e.target.value,error:''})} />	
														<span className="red">{(this.state.error['password'] != "" && typeof this.state.error['password']!='undefined' )?this.state.error['password']:""}</span>	
											    	<span class ="red">{(this.state.error['otp']!="" && typeof this.state.error['otp']!='undefined')?this.state.error['otp']:""}</span>
													</Col>							   
											  </Form.Group>
											</React.Fragment> : 
											<React.Fragment>
												<Form.Group as={Row}>
											    <Form.Label column lg="4">{t('accessToken')} :</Form.Label>
											    <Col lg="8">
											    	<Form.Control type="text" className="mb-1" value={this.state.accessToken} onChange={(e)=>this.setState({accessToken:e.target.value, error:''})}/>	
											    	<span className="red">{(this.state.error['login[admin_access_token]']!= "" && typeof this.state.error['login[admin_access_token]']!= 'undefined')?this.state.error['login[admin_access_token]']:""}</span>	
													</Col>							   
											  </Form.Group>
											</React.Fragment>
										}
									</Form>
									<Link to="/forgot-password" className="d-block text-right text-underline">{t('forgotPassword')}?</Link>
									{(this.state.otpSent)&&<span className="mx-auto d-block mt-3 text-right green ">{"OTP sent to your registered email/mobile "}</span>}
									<Button className="btn btn-md mx-auto d-block mt-3" onClick={()=>this.checkCredential()} style={this.props.styles.primary_btn}>{t('submit')}</Button>

								</Col>
								{
									(settings.enable_social_login == 'Yes')&&<Col lg={6} className="right_sec text-center text-lg-left mt-5 mt-lg-0">	
										<GoogleLogin
											clientId={this.props.settings.sign_in_with_google_api_key}
											className="btn btn-lg btn_white fab fa-google::before pr-2 google_btn"
											disabled={false}
											buttonText={t('loginWithGoogle')}
											onSuccess={(response) => this.responseGoogle(response)}
											onFailure={(response) => this.responseGooglError(response)}
											cookiePolicy={'single_host_origin'}
										/>
										<br/>
										<br/>
									
										<div className="mt-3">
											<span>{t('notAnAccount')}? <Link to="/register" className="text-underline">{t('register')}</Link></span>
										</div>
								</Col>}
							</Row>
						<hr/>
						</Container>
					</div>				
					{(settings.show_categories_on_login == 'Yes')&&
					<div>
	       		<Container>
							<h2 className="sec_heading text-center">choose your universe</h2>
							<p className="text-center">Check out what's new in the world of SCOTT</p>
							<Row className="sports_ride">
								{
									(this.props.categories.length > 0) &&
										this.props.categories.map((category,index)=>(
											<Col key={index} xs={6} className="item mt15 mb15">
												<Link to={'/pl/'+category.slug}>
													<img src={this.props.settings.image_url+"categories/original/"+category.image} className="img-fluid d-none d-sm-block" />
													<div className="img_btn text-center">
														<span>{category.name}</span>
													</div>
												</Link>
											</Col>
										))
								}            
							</Row>
	       		</Container>
     			</div>}
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
  return {
    otpCount: state.otpCount,
    settings: state.settings.settings,
    styles:state.styles.styles,
    categories:state.categories.categories,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    decrementOtpCount: () => dispatch({ type: 'DECREMENT_OTP_COUNT'}),
    setOtpCount: (otpCount) => {dispatch({ type: 'SET_OTP_COUNT', payload: otpCount})},
    setUser: (user) => {dispatch({ type: 'SET_USER', payload: user})}
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Login));
