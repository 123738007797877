import React, { Component } from 'react'

import { getDealerLeader, getTallyVoucher } from '../../helpers/ServicesHelper'

export default class CoreLedger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vouchers: [],
      accountName: '',
      image: '',
      month: '',
      date: '',
      debit: 0,
      credit: 0,
      closingBal: 0,
      name: '',
      datePicker: '',
      dealer: [],
      creditBal: '',
      totalCredit: '',
      paymentDue: 1,
      dueDate: '',
      dueDetails: [],
      accounts: [],
      startDate: new Date('04/01/2021'),
      endDate: new Date(),
      isDateRange: false,
      dateRange: '',
      errors: [],
      range: '',
      loader: true,
      totalCreditAvailable: '',
      sellerAccount: [],
      getImagePath: '',
      getPdfData: ''
    }

  }

  async componentDidMount() {
    await this.getDealer();
  }

  async getDealer() {
    if (Object.keys(this.props.user).length > 0 && this.props.user.dealer_id != null) {
      var dealer = await getDealerLeader();
      //console.log(dealer.data.data.seller_tally_accounts)
      if (dealer.data.status == 'success') {
        await this.setState({
          creditBal: dealer.data.data.credit_amount_used,
          totalCreditAvailable: dealer.data.data.total_credit_available,
          totalCredit: dealer.data.data.total_credit_amount,
          sellerAccount: dealer.data.data.seller_tally_account,
          dueDetails: dealer.data.data.due_details, loader: false
        });
      }

    } else {
      window.location.assign('/NotFound')
    }
  }

  async getTallyVoucher(val) {
    console.log(val);
    var formdata = [];
    var tally, dealerId;
    var start = this.state.startDate, end = this.state.endDate;
    start = start.getDate() + '-' + (start.getMonth() + 1) + '-' + start.getFullYear();
    end = end.getDate() + '-' + (end.getMonth() + 1) + '-' + end.getFullYear();
    dealerId = (Object.keys(this.props.user).length > 0 && this.props.user.dealer_id != null) ? this.props.user.dealer_id : '';
    if (this.state.dateRange) {
      formdata['tally_vouchers[from]'] = start;
      formdata['tally_vouchers[to]'] = end;
      this.setState({ range: start + " To " + end, month: '' })
    } else {
      if (this.state.month != '' && typeof this.state.month != 'undefined') {
        formdata['tally_vouchers[month]'] = this.state.month.split("- ")[1] + '-' + val;
        this.setState({ range: '' })
      }
      else {
        formdata['tally_vouchers[month]'] = "";
        this.setState({ range: '' })
      }
    }
    formdata['tally_vouchers[account_name]'] = this.state.accountName;
    formdata['access_token'] = 'd2fb9e85bc2ad865a3b3084787299e88';
    tally = await getTallyVoucher(dealerId, formdata);
    if (tally.data.status == 'success') {
      console.log(tally)
      console.log(tally.data);
      if (tally.data.data.length > 0) {
        console.log("array is not empty");
        tally.data.data[0].import_files = tally.data.image_file_name;
        console.log(tally.data.data[0].import_files);
        console.log(tally.data.data);
        await this.setState({ vouchers: tally.data.data })
        await this.setState({ getPdfData: tally.data.image_file_name })
        console.log(this.state.vouchers);
        console.log(this.state.getPdfData);
        await this.getDebitTotal();
        await this.getCreditTotal();
        await this.getClosingBal();
        console.log(this.state.vouchers)
        return this.state.vouchers;
      }
      else {
        console.log("array is empty");
        if (tally.data.image_file_name) {
          // tally.data.data.push({import_files:tally.data.image_file_name});
          // tally.data.data.import_files= tally.data.image_file_name;
          // console.log(tally.data.data);
          await this.setState({ getPdfData: tally.data.image_file_name });
          await this.setState({ vouchers: tally.data.data });
          console.log(this.state.vouchers);
          // return this.state.vouchers;
        }
        else {

          await this.setState({ vouchers: tally.data })
          await this.getDebitTotal();
          await this.getCreditTotal();
          await this.getClosingBal();
          // return this.state.vouchers;
        }

      }
    }
    else {
      await this.setState({ errors: tally.data.errors })
    }
  }

  getDebitTotal() {
    var sum = 0;
    this.state.vouchers.length > 0 && this.state.vouchers.map((item) => (
      sum = parseFloat(sum) + parseFloat(Math.abs(item.debit_amount).toFixed(2))
    ))
    this.setState({ debit: sum });
  }

  getCreditTotal() {
    var sum = 0;
    this.state.vouchers.length > 0 && this.state.vouchers.map((item) => (
      sum = parseFloat(sum) + parseFloat(Math.abs(item.credit_amount).toFixed(2))
    ))
    this.setState({ credit: sum });
  }

  getClosingBal() {
    var result = Math.abs(parseFloat(this.state.debit) - parseFloat(this.state.credit)).toFixed(2);
    this.setState({ closingBal: result });
  }

  getUrl(item, id) {
    window.open('/' + item.split(" ")[0] + (typeof item.split(" ")[1] != 'undefined' &&
      item.split(" ")[1] != '' ? ("-" + item.split(" ")[1]) : '') + '/' + id, "_blank");
  }
}
