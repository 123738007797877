import React from 'react';
import {Container, Row, Col, Table, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import MetaTags from '../../../components/MetaTags';
import BrandOneImg from '../../../assets/images/logos/scott.png';
import BrandTwoImg from '../../../assets/images/logos/bergamont.png';
import BrandThreeImg from '../../../assets/images/logos/syncros.png';
import BrandFourImg from '../../../assets/images/logos/probike.jpg';
import BrandFiveImg from '../../../assets/images/logos/avanti.png';

class AboutUs extends React.Component {	
	componentDidMount() {
	  window.scrollTo(0, 0)
	}
	render(){
		return (
			<React.Fragment> 
				<MetaTags {...this.props}/>
				<div className="main mt-5"> 
					<div className="main_section">
						<Container className="mt-4">
							<h5 className="bold text-uppercase black mb-3 text-left mb-3">SPORTNETWORK – OUTDOOR IS LIFE.</h5>					
							<div className="">
								<p><i>We want to get everyone outdoor.</i></p>
								<p>At SPORTNETWORK we want to get everyone to take up a sport and get outdoor. We want everyone to be adventurous, experience the thrill and lead an active lifestyle by making informed decisions about the products they choose</p>
								<p>A lot of us avoid outdoors due to inhibitions with regards to safety or fear of not performing. We enable everyone to overcome all those fears by providing the best of the outdoor products, equipment and gear through brands which are exclusively available only through our online platform.</p>
								<p>We have something for all, from the early beginners to the professionals and athletes. We believe if you enjoy something, you want to try it again and then again and again. But a poor experience puts an end to the journey. And so we believe our products will add to the experience multifold to believe OUTDOOR IS LIFE.</p>
								<p>We have the best of the brands for bicycles, motorsports and running products, equipment and gear with a focus on increasing the range to more outdoor sports in future.</p>
							</div>
							<div className="mt-5">
	              <h2 className="sec_heading text-center">Our Brands</h2>
	              <div className="text-center">
	                <ul className="list-unstyled brand_list first">
	                  <li>
	                      <img src={BrandOneImg} className="img-fluid" />
	                  </li>
	                  <li>
	                      <img src={BrandTwoImg} className="img-fluid" />
	                  </li>                  
	                </ul>
	                <ul className="list-unstyled brand_list second">
	                  <li>
	                      <img src={BrandThreeImg} className="img-fluid" />
	                  </li>
	                  <li>
	                      <img src={BrandFourImg} className="img-fluid" />
	                  </li>
	                  <li>
	                      <img src={BrandFiveImg} className="img-fluid" />
	                  </li>                  
	                </ul>
	              </div>              
	            </div>
						</Container>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default AboutUs;
