export const styleSheet = async (settings) => {
	return {
		primary_btn:{
			'background-color':settings.button_primary_color,
			'color':settings.primary_button_text_color,
			'border-color':settings.primary_button_border_color,
		},
		secondary_btn:{
			'background-color':settings.secondary_button_color,
			'color':settings.secondary_button_text_color,
		},
		banner_secondary_btn:{
			'background-color':settings.secondary_button_color,
			'color':settings.banner_button_text_color,
		},
		 buttonbackgroundColor:{
		 	'background-color':settings.call_to_action_button_deactive_text_color
		 },
 		 card_light_purple:{
		 	'background-color': 'yellow',
 		 	color: "#fff"
 		 }
	}
}