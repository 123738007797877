import React from 'react';
import {Container, Col, Row, Button} from 'react-bootstrap';

import Filter from './Filter';
import VariationFilter from './VariationFilter';

class FilterModal extends React.Component {
  render() {
    return (
      <div id="filter-modal" className="modal fade" tabIndex="-1">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h4 className="black">
                Filters
              </h4>
              <button type="button" className="close" data-dismiss="modal">
                  <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Filter 
                filters={this.props.filters} 
                category_id = {this.props.categoryId}
                productTypeFilters={this.props.productTypeFilters}
                changeProductTypeFilters={(values)=>this.props.propschangeProductTypeFilters(values)}
                changeFilters={(values)=>this.props.propschangeFilters(values)}
              />
              <VariationFilter 
                  variationfilterData={this.props.variationfilterData} 
                  productTypevariationFilters={this.props.productTypevariationFilters} 
                  changeProductTypeVariationFilters={(values)=>this.props.changeProductTypeVariationFilters(values)}
                  changeVariationFilters={(values)=>this.props.changeVariationFilters(values)}
                />
            </div>
            <div className="modal-footer text-center">
              <button type="button" className="btn btn-md btn-primary mx-auto" data-dismiss="modal">Submit</button>
            </div>            
          </div>
        </div>
      </div>
    );
  }
}

export default FilterModal;



