import React, { Component } from 'react'
import {Container, Row, Col, Carousel, Button, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next';

import checkedImg from '../../../assets/images/icons/checked.png';
class DueAmountTable extends React.Component {
	render(){
		const{dueDetails,totalCredit} = this.props;
		const { t } = this.props;
		return(
			<React.Fragment>
				{dueDetails.length > 0  ?
          <Card className="h-100" style={{backgroundColor: '#fff9c4'}}>
            <Card.Body className="text-center">
              <Row>
                <Col xs={6}>
                    <div>
                      <h6 className="bold mb-2 black">{t('paymentDue')}</h6>
                    </div>  
                </Col>
                <Col xs={6}>
                    <div>
                      <h6 className="bold mb-2 black">{t('dueOn')}</h6>
                    </div>  
                </Col>
              </Row>
              <Row>
                {
                  dueDetails.map((dueDetail,index)=>(  
                    <React.Fragment>
                      <Col xs={6}>
                        <div>
                          {(index==0) ?
                            <h4 key={index} className="mb-3 red">
                              <i className="fal fa-rupee-sign"></i>
                              {parseFloat(dueDetail.due_amount).toFixed(2)}
                            </h4> :
                            <h4  key={index}className="mb-3 black">
                              <i className="fal fa-rupee-sign"></i>
                              {parseFloat(dueDetail.due_amount).toFixed(2)}
                            </h4>
                          }
                        </div>                                         
                      </Col>
                      <Col xs={6}>
                        <div>
                          {(index==0)?
                            <h4 key={index} className="mb-3 red">
                              {dueDetail.due_date}
                            </h4> :
                            <h4  key={index}className="mb-3 black">
                              {dueDetail.due_date}
                            </h4>
                          }
                        </div>                                         
                      </Col>
                    </React.Fragment>  
                  ))
                }
              </Row>
              <Row>
                {(totalCredit != null && totalCredit != 0)&&
                  <Col lg={12} className="text-right mt-3">
                    <Link to="/dealer-ledger" className="text-underline">{t('viewStmt')}</Link>
                  </Col>
                }
              </Row> 
            </Card.Body>
          </Card>
          :
          <Card className="h-100" style={{backgroundColor: '#fff9c4'}}>
            <Card.Body className="text-center">
              <Row>
                <Col xs={12}>
                  <div>
                    <h6 className="bold mb-4 black">{t('paymentNotRequired')}</h6>
                    <img src={checkedImg} width="40px" />
                  </div>                        
                </Col>                        
              </Row>
              <Row>
                {(totalCredit != null && totalCredit != 0) &&
                  <Col lg={12} className="text-right mt-3">
                    <Link to="/dealer-ledger" className="text-underline">{t('viewStmt')}</Link>
                  </Col>
                }
              </Row>  
            </Card.Body>
          </Card>
        }
			</React.Fragment>
		)
	}
}

export default withTranslation()(DueAmountTable);