import React from 'react';
import {Container, Row, Col, Carousel,Table, Tabs, Tab} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import Button from 'react-bootstrap-button-loader';
import { connect } from "react-redux";

import { dealersPortal } from '../../../../core/helpers/OperationsHelper';

class ProductInventory extends React.Component {

  render(){
    const { t, quantities, inventories }= this.props;

    return(
      <Table className="mx-auto mt-3 mb-0 inventory_table" size="sm">
        <tbody>
          {(dealersPortal == true && inventories.length > 0) ?
            <React.Fragment>
              <div className="d-flex mb-1">
                <div className="pr-3" style={{fontSize: '15px'}}><i class="fas fa-check-circle green"></i> <span className="bold">{'Available'}</span></div>
                <div className="pr-3" style={{fontSize: '15px'}}><i class="fas fa-clock yellow"></i> <span className="bold">{'Future availability'}</span></div>
                <div className="pr-3" style={{fontSize: '15px'}}><i class="fas fa-times-circle red"></i> <span className="bold">{'Sold Out'}</span></div>
              </div>
              {inventories.map((inventory,index)=>(
                <React.Fragment key={index}>
                  <tr >
                    <td className="text-nowrap">
                      <div className="d-flex mb-1">
                        <div className="pr-3">SKU Code: <span className="bold">{inventory.custom_skucode}</span></div>
                        <div className="pr-3">Variation: <span className="bold">{inventory.variation}</span></div>
                      </div>
                      <div className="d-flex mb-1 align-items-center">
                        {(typeof this.props.settings.quantity_selector != 'undefined' && this.props.settings.quantity_selector === 'Yes') && (<div className="d-flex align-items-center">
                          <div className="pr-3">Quantity:</div>
                          <div>
                            <div className="pr-3">
                              <input style={{width:'60px'}} type="number" className="form-control" min={0} value={quantities[inventory.id]} onChange={(e, id) => this.props.setQuantities(e.target.value, inventory.id)} />
                            </div>
                          </div>
                        </div> )}
                        {(inventory.split_stock == null || inventory.split_stock == 'no') && inventory.current_stock != null && inventory.current_stock > 0 && 
                          <div className="pr-3 "><i class="fas fa-check-circle green" style={{fontSize: '15px'}}></i></div>
                        }
                        {(inventory.split_stock == 'yes') && inventory.dealer_stock != null && inventory.dealer_stock > 0 && 
                          <div className="pr-3 "><i class="fas fa-check-circle green" style={{fontSize: '15px'}}></i></div>
                        }
                        {inventory.future_availability_quantity != null && inventory.future_availability_quantity > 0 && 
                          <div className="pr-3"><i class="fas fa-clock yellow" style={{fontSize: '15px'}}></i></div>
                        }

                        {(inventory.split_stock == null || inventory.split_stock == 'no') && (inventory.current_stock == null || inventory.current_stock == 0) && (inventory.future_availability_quantity == 0 || inventory.future_availability_quantity == null) &&
                          <div className="pr-3"><i class="fas fa-times-circle red" style={{fontSize: '15px'}}></i></div>
                        }
                        {(inventory.split_stock == 'yes') && (inventory.dealer_stock == null || inventory.dealer_stock <= 0) && (inventory.future_availability_quantity == 0 || inventory.future_availability_quantity == null) &&
                          <div className="pr-3"><i class="fas fa-times-circle red" style={{fontSize: '15px'}}></i></div>
                        }
                        {inventory.color != '' && this.props.checkColorOption == true &&
                          <div className="pr-3">
                            <Button 
                              className="btn-sm mr-2" 
                              onClick={()=>this.props.selectvariation('color',inventory.color, 1)}
                              style={this.props.styles.primary_btn}>
                              {'Check colors'}
                            </Button>
                          </div>
                        }
                        
                      </div>
                      <span className="red bold pr-3">{this.props.errors['bulk_cart_details['+index+'][quantity]']}</span>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </React.Fragment> : 
            (dealersPortal == true)&&
            <React.Fragment>
              <div className="d-flex mb-1">
                <div className="pr-3" style={{fontSize: '15px'}}><i class="fas fa-times-circle red"></i> <span className="bold">{'Out of Stock'}</span></div>
              </div>  
            </React.Fragment>
          }
        </tbody>
      </Table> 
    )
  }
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings,
    styles: state.styles.styles,
  }
}

export default withTranslation()(connect(mapStateToProps)(ProductInventory));