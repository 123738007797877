import React from 'react';
import {Col, Row, Card} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

class BillingAddressCard extends React.Component {
  
  render() {
    const { t,billindAddress } = this.props;
    return (
      <Col lg={6} className="mb-3 mb-lg-0">                 
        <Card className="shadow-sm">
          <Card.Body>
            <Row className="mb-2">
              <Col lg={12}>
                <h2 className="small_heading black bold text-capitalize mb-0 position-absolute">Billing Address</h2>
                <Link to={"/billing-address-update"}><span className="float-right position-relative"><i className="fa fa-edit"></i></span></Link>
              </Col>                        
            </Row>
            <hr/>
            <Row className="mb-2">
              <Col sm={5}>
                <h6 className="">{t('name')} :</h6>
              </Col>
              <Col sm={7}>
                <h6 className="black bold">{(billindAddress.name)?billindAddress.name:'-'}</h6>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col sm={5}>
                <h6 className="">{t('email')} :</h6>
              </Col>
              <Col sm={7}>
                <h6 className="black bold">{(billindAddress.email_id)?billindAddress.email_id:'-'}</h6>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col sm={5}>
                <h6 className="">{t('mobile')} :</h6>
              </Col>
              <Col sm={7}>
                <h6 className="black bold">{(billindAddress.mobile_no)?billindAddress.mobile_no:'-'}</h6>
              </Col>  
            </Row>
            <Row className="mb-2">
              <Col sm={5}>
                <h6 className="">{t('address')} :</h6>
              </Col>
              <Col sm={7}>
                <p className="black gray mb-0">{billindAddress.street_address}</p>
                <p className="black gray mb-0">{billindAddress.full_address}</p>
                <p className="black gray mb-0">{(billindAddress.city)?billindAddress.city:'-'}</p>
                <p className="black gray mb-0">{(billindAddress.state)?billindAddress.state:'-'}</p>
                <p className="black gray mb-0">{(billindAddress.state)?billindAddress.postal_code:'-'}</p>
                <p className="black bold mb-0">{(billindAddress.country)?billindAddress.country:'-'}</p>
              </Col>
            </Row>                      
          </Card.Body>
        </Card>
      </Col>    
    );
  }
}
export default withTranslation()(BillingAddressCard);



