import React from 'react';
import {Container, Button} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";

import LogoImg from '../assets/images/icons/scott_network_logo.jpeg'; 
import EmptyWishlistImg from '../assets/images/icons/wishlist.png'; 


class EmptyWishlist extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div className="container">
        <div className="col-sm-8 mx-auto mt-5">
          <div className="text-center mb-4">
            <img src={EmptyWishlistImg} className="img-fluid empty_img" />
          </div>
          <div className="text-center">
            <h4 className="black bold">{t('emptyWishText')}!</h4>
            <p>{t('emptyWishSubText')}</p>
            <Button style={this.props.styles.primary_btn}>{t('exploreNow')}</Button>
          </div>
        </div>       
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    styles: state.styles.styles,
  }
}


export default withTranslation()(connect(mapStateToProps)(EmptyWishlist));



