import React from 'react';
import {Container, Row, Col, Table, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom'


class ProbikeWarranty extends React.Component {	
	render(){
		return (		
			<div className="mt-5">
		  	<h2 className="small_heading bold black text-center mb-5 text-uppercase">Probike Warranty</h2>
		  	<div className="mb-4">
		  		<h3 className="content_heading black bold mb-3 text-underline">WARRANTY & RETURNS POLICY: REFUNDS AND EXCHANGES</h3>
		  		<p>At Sportnetwork, we offer a flexible returns policy and are happy to accept items back for a refund or exchange if items purchased are damaged/faulty upon receipt.</p>
					<p>Goods cannot be returned or exchanged if the customer has simply changed their mind.</p>
					<p>Refunds and exchanges are permitted where the goods purchased do not perform as advertised.</p>
					<p>Unfortunately, sale or discontinued items cannot be exchanged or refunded.</p>
		  	</div>
		  	<div className="mb-4">
		  		<h3 className="content_heading black bold text-underline">EXCHANGE POLICY</h3>
		  		<p>Exchanges can only be processed to your original order.</p>
					<p>Exchanges will only be made with equivalent product basis availability.</p>
					<p>All goods must be exchanged within 7 days of receipt.</p>
					<p>Price difference in exchanged goods will be issued by a store credit to the value of difference.</p>
					<p>Goods must be in original, saleable condition and undamaged packaging.</p>
					<p>Goods must not be fitted or used.</p>
					<p>Original proof of purchase must be provided.</p>					  		
		  	</div>
		  	<div className="mb-4">
		  		<h3 className="content_heading black bold text-underline mb-3">INCORRECTLY SUPPLIED ITEMS</h3>
		  		<p>If we have sent you an item which is different to what is listed on your invoice, we will be happy to make an exchange and supply the replacement item. The replacement will be delivered Freight Free.</p>
					<p>Goods must be returned within 7 days of receipt</p>
					<p>Goods must be in original, saleable condition and undamaged packaging.</p>
					<p>Goods must not be fitted or used.</p>
					<p>Clothing and apparel items must have tags still attached and not be used.</p>
					<p>Original proof of purchase must be provided.</p>
					<p>This will be settled either via replacement with the correct product or via store credits.</p>
		  	</div>
		  	<div className="mb-4">
		  		<h3 className="content_heading black bold text-underline mb-3">WARRANTY ISSUES</h3>
		  		<p>Warranty claim can be filed through this link <a href="https://customerconnect.sportnetwork.in/warranties" target="_blank">File Your Warranty</a></p>
					<p>You can reach out to us on our Helpline <a href="tel:8080754321" target="_blank">8080754321</a> (10am-6pm Mon-Fri) or on email <a href="mailto:cs@sportnetwork.in" target="_blank">cs@sportnetwork.in</a></p>
		  	</div>		  	
		  </div>
		);
	}
}

export default ProbikeWarranty;