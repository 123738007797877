import React, { Component } from 'react'
import { storeContactus } from '../../helpers/ServicesHelper'

export class CoreContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading:true,
      name:'',
      lname:'',
      contactUsLoadingButton:false,
      email:'',
      reason:'',
      image:null,
      howCanHelp:'',
      errors:[],
      success:false,
    };
  }

  async submitContactUs(){
    this.setState({errors:[],success:false,contactUsLoadingButton:true})
    var formdata = new FormData(document.getElementById('contactUs'));
    var response = await storeContactus(formdata);
    if(response.data.status == 'success'){
      document.getElementById("contactUs").reset();
      this.setState({success:true,contactUsLoadingButton:false})
    }else{
      this.setState({errors:response.data.errors,contactUsLoadingButton:false})
    }
  }
}

export default CoreContactUs;

