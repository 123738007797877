import React from 'react';
import {Container, Row, Col, Carousel, Button} from 'react-bootstrap';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import {Link} from 'react-router-dom';

import CoreHome from '../../../../core/components/home/CoreHome';
import MetaTags from '../../../components/MetaTags';

import bannerOne from '../../../assets/images/product-offer/MOTOSaleXmas-SNS_SizeEmailer-1AW.jpg';
import bannerTwo from '../../../assets/images/product-offer/MOTO_SaleXmas-SNS_SizeEmailer-2AW.jpg';
import bannerThree from '../../../assets/images/product-offer/MOTOSaleXmas-SNS_SizeEmailer-3AW.jpg';
import bannerFour from '../../../assets/images/product-offer/MOTOSaleXmas-SNS_SizeEmailer-4AW.jpg';

class Product extends CoreHome { 
  render() {
    return (
      <React.Fragment>         
        <div className="main container">          
          <div className="">
            <a href="https://sportnetwork.in/cpl/52b471ba06a50b82849f2eba254f9b0e" target="_blank">
              <img className="img-fluid" src={bannerOne} />
            </a>    
          </div>
          <div className="container-fluid">
            <div className="col-sm-12">
              <a href="https://sportnetwork.in/cpl/52b471ba06a50b82849f2eba254f9b0e" target="_blank">
                <img className="img-fluid" src={bannerTwo} />
              </a>
            </div>
            <div className="col-sm-12 mt-3">
              <a href="https://sportnetwork.in/cpl/aec558cb793a9f6929ace7d93f39ade4" target="_blank">
                <img className="img-fluid" src={bannerThree} />
              </a>
            </div>
            <div className="col-sm-12">
              <a href="https://sportnetwork.in/cpl/aec558cb793a9f6929ace7d93f39ade4" target="_blank">
                <img className="img-fluid" src={bannerFour} />
              </a>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings,
    styles: state.styles.styles,
    categories:state.categories.categories,
    brands:state.brands.brands,
  }
}

export default withTranslation()(connect(mapStateToProps, null)(Product));

