import React from 'react';
import {Container, Row, Col, Button, Card, Form, Tabs, Tab} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import CoreDealer from '../../../../core/components/dealer/CoreDealer';
class DealerApproval extends CoreDealer {
	render(){
		const { t } = this.props;
		return (
			<React.Fragment> 
				<div className="main mb-2 mt-5">					
					<Container>
						<Row>
							<Col md={12} className="text-center mx-auto">
								<Card >
								  <Card.Body>
								    <Card.Title className="red bold">{t('approvalPending')}</Card.Title>
								    <Card.Text>
								      {t('approvalMsg')}
								    </Card.Text>
                		<Button className="mr-2" onClick={()=>this.removeStore()}>{t('backToLogin')}</Button>
								  </Card.Body>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}


export default withTranslation()(DealerApproval);
