const initialState = { states: [] }

const orders = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_ORDER_STATES':
      return { states: action.payload}; 	 
  }
  return state
}

export default orders;