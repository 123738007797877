import React from 'react';
import {Container, Col, Row, Button, Modal,Input} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

import CoreProductListing from '../../../../core/components/products/CoreProductListing';
import { getItemVariations, getCartProducts, getWishlistProducts } from  '../../../../core/helpers/ServicesHelper';

class VariationModal extends React.Component {

  render() {
    const { t,settings,showVariationModal} =this.props;
    return (
      <Modal show={this.props.showVariationModal} >
        <Modal.Header closeButton>
          <Modal.Title>{t('modalHeading')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(this.props.variations != "" && settings.remarkPopupEnable != 'Yes')?(
            this.props.variations.map((variation,variation_key)=>(
              <div key={variation_key} className="product_sizes">
                <div className="mb-2 align-items-center">
                  <h6 className="mb-0 pr-3 bold">Select {variation.name} :</h6>                         
                  {variation.options.map((variation_option,variation_option_key)=>(
                    <button 
                      key={variation_option_key} 
                      onClick={()=>this.props.selectvariation(variation.name,variation_option.sku_no)} 
                      className={(this.props.selectedVariations[variation.name] === variation_option.sku_no) ? 'size_btn btn-primary' : 'size_btn'}
                    >
                      <text className={(this.props.selectedVariations[variation.name] === variation_option.sku_no)?"white":'black'}>{variation_option.value}</text>
                    </button>
                  ))}  
                </div>
              </div>
            ))):
            <div  className="product_sizes">
              <div className="d-flex mb-2 align-items-center">
                <h6 className="mb-0 pr-3 bold">Enter Remark</h6>                         
                <input type="text" placeholder="Enter Remark"/>
              </div>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="btn-lg mr-2" style={this.props.styles.primary_btn} onClick={()=>this.props.addRemoveProductFromCart(this.props.productId, 1)}>Add to cart</Button>
          <Button variant="primary" className="btn-lg transparent" style={this.props.styles.secondary_btn} onClick={()=>this.props.addRemoveProductFromWishlist(this.props.productId, 1)}>Add to Wishlist</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    settings:state.settings.settings,
    styles: state.styles.styles,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    increaseCartCount: () => dispatch({ type: 'ADD_TO_CART'}),
    decreaseCartCount: () => dispatch({ type: 'REMOVE_FROM_CART'}),
    updateCartItems: (cartItems) => {dispatch({ type: 'SET_CART_ITEMS', payload: cartItems})},
    increaseWishListCount: () => dispatch({ type: 'ADD_TO_WISHLIST'}),
    decreaseWishlistCount: () => dispatch({ type: 'REMOVE_FROM_WISHLIST'}),
    updateWishListItems: (wishListItems) => {dispatch({ type: 'SET_WISHLIST_ITEMS', payload: wishListItems})},
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(VariationModal));



