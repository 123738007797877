import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import React from 'react';
import {Container, Row, Col, Button, DropdownButton, Dropdown, Collapse, Form, Accordion} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider';
import {connect} from 'react-redux';

import CoreFilter from '../../../../core/components/products/CoreFilter'
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;

class VariationFilter extends CoreFilter { 
  render() { 
    const { t } = this.props;
    return (
      <div>
        {
          (typeof this.props.productTypevariationFilters != "undefined") && this.props.productTypevariationFilters.map((filterVal,index)=>(
              (filterVal.type === 'multiselect') && (
                <div key={'VariationDiv'+filterVal.name.replace(/\s/g,'')+"_"+index} id={"variationfilter_"+filterVal.name.replace(/\s/g,'')+"_"+index} className="custom_collapse">
                  <div className="custom_toggle">
                    <a data-toggle="collapse" href={"#collapse_variation_"+filterVal.name.replace(/\s/g,'')+"_"+index} className="gray">
                      <span className="text-uppercase bold flex-fill mb-1">{filterVal.name}</span>              
                    </a>
                  </div>
                  <div id={"collapse_variation_"+filterVal.name.replace(/\s/g,'')+"_"+index} className="custom_toggle_data collapse show" data-parent={"#variationfilter_"+filterVal.name.replace(/\s/g,'')+"_"+index}>
                    <Form>
                    {
                      filterVal.values.map((value,key)=>(
                        <Form.Check key={'input_variation_'+key+value+filterVal.name} type="checkbox" id={"model_variation_"+index+"_"+value.replace(/\s/g,'')}>
                          <Form.Check.Input className="customCheck" onChange={(e,name,type)=>this.changeVariationFilterState(value,filterVal.name,filterVal.type)} type="checkbox" isValid />
                          <Form.Check.Label>{value}</Form.Check.Label>
                        </Form.Check>
                      ))
                    }
                    </Form>
                  </div>
                </div>
              )
            )
          )
        }
      </div>
    );
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setVariationFilters: (data) => {dispatch({ type: 'SET_VARIATION_FILTERS', payload: data})},
    setPage: (page) => {dispatch({ type: 'SET_FILTERPAGE', payload: page})},
  }
}

export default withTranslation()(connect(null,mapDispatchToProps)(VariationFilter));
