import React from 'react';
import {Container, Row, Col, Button, Table, Tabs, Tab, Card, Form,} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import cookie from 'react-cookies';

import ProductOne from '../../../assets/images/products/spark-rc-cycle.png';
import CoreCheckout from '../../../../core/components/transactions/CoreCheckout';
import OrderSummery from '../components/OrderSummery';
import Addresses from '../components/Addresses';
import Loader from '../../../components/Loader';
import { connect } from "react-redux";
import loader from '../../../assets/loader/circle-loader.gif';
import { dealersPortal } from '../../../../core/helpers/OperationsHelper';
import Tostify from '../../../components/Toaster';
import { withTranslation } from 'react-i18next';
import MetaTags from '../../../components/MetaTags';
class Checkout extends CoreCheckout {

	async componentDidMount(){
		await this.getAddresses();	
		this.setState({loader:false})
	}

	render(){
		var formID = this.state.checked ? 'same-shipping' : 'billing-form';
		const {errors, countries, statesArray, checked, loader, addresses} = this.state;
		const { t } = this.props;
		console.log(this.props.addresses);
		return (
			<React.Fragment> 
				<Tostify/>
				<MetaTags {...this.props}/>
				<div className="main mb-5 ">
					<div className="main_section mt-4">	
						{(loader == false) &&
								<Container>			
									<h1 className="page_heading bold text-uppercase black mb-3">{t('checkout')} {t('details')}</h1>
									{!cookie.load('authToken') && (
										<div className="d-flex mt-3 align-items-center mb-3">
											<div>
												<Button onClick={() => window.location.href = '/login?checkout=1'} style={this.props.styles.primary_btn}>{t('login')}</Button>
											</div>
											<div className="pl-3">
												<p className="mb-0">{t('or')}</p>
											</div>
											<div className="pl-5">
												<Form.Group className="mb-0">
								      		<Form.Check
										        custom
										        onClick={(e)=>(this.setState({'guestLogin':e.target.checked}))}
										        type={"checkbox"}
										        label={"Login as Guest"}
										        id={"guest_login"}
										      />
								      	</Form.Group>
											</div>
										</div>
									)}
									<Row className="flex-column-reverse flex-lg-row">
							  		<Col lg={8} className="mt-3 mt-lg-0">				  			
										
										<div className="tab-content">
										  <div className="tab-pane container active p-0" id="checkout">
										  	<div className="tab-content">								  		
													<div className="tab-pane container active p-0">
														<Card className="shadow-sm">
															<Card.Body>
															 	{this.props.addresses.length > 0 && (
															 		<React.Fragment>
																 		<h2 className="small_heading bold black mb-2">{t('existingAddress')}</h2>
																 		<span className="red">{this.state.errors['shipping_addresses[id]']}</span>
																	 	<div className="mt-2 mb-3 seller_check">
																			<Row className="d-flex pd7 flex-row">
																				{this.props.addresses.map((address, index) => (
																					<Col key={index} className='mt-3 mb-3 mb-lg-0' sm={4}>
																				    <div key={`custom-inline-${address.id}`} className="border p-2 h-100">
																				      <div>
																				      	{dealersPortal == true && (<p className="mb-0 bold black" style={{fontSize:'0.95rem'}}>{address.trading_name}</p>)}
																				      	{dealersPortal == false && (<p className="mb-0 bold" style={{fontSize:'0.95rem'}}>{address.name}</p>)}
																				      	<p className="mb-0" style={{fontSize:'0.95rem'}}>{address.mobile_no}</p>
																				      	<p className="mb-0" style={{fontSize:'0.95rem'}}>{address.street_address}</p>
																				      	<p className="mb-0" style={{fontSize:'0.95rem'}}>{address.full_address}</p>
																				      	<p className="mb-0" style={{fontSize:'0.95rem'}}>{address.city}</p>
																				      	<p className="mb-0" style={{fontSize:'0.95rem'}}>{address.postal_code}</p>
																				      	<p className="mb-0" style={{fontSize:'0.95rem'}}>{address.state}</p>
																				      	<p className="mb-0" style={{fontSize:'0.95rem'}}>{address.country}</p>
																				      	<p className="mb-0" style={{fontSize:'0.95rem'}}><a href={"mailto:" + address.email_id} target="_blank">{address.email_id}</a></p>
																				      	<p className="mb-0" style={{fontSize:'0.95rem'}}><a href={"tel:" + address.mobile_no} target="_blank">{address.mobile_no}</a></p>
																				      </div>
																				      <div>
																				      	{
																				      		(this.state.selectedAddressId == "")?
																					      		(address.default_address == 1) ? 
																					      			<Button 
																							      		variant="success"
																							      		className="btn btn-xs d-block w-100 mt-2"
																							      	> {t('selectedAddress')}
																							      	</Button> : 
																							      	<Button 
																							      		onClick={() => this.updateAddresses(address.id)} 
																							      		className="btn btn-xs d-block w-100 mt-2"
																							      		style={this.props.styles.primary_btn}
																							      	> {t('deliveryToAddress')}
																							      	</Button>:
																							    (this.state.selectedAddressId == address.id) ? 
																							    		<Button 
																							      		variant="success"
																							      		className="btn btn-xs d-block w-100 mt-2"
																							      	> {t('selectedAddress')}
																							      	</Button> : 
																							      	<Button 
																							      		onClick={() => this.updateAddresses(address.id)} 
																							      		className="btn btn-xs d-block w-100 mt-2"
																							      		style={this.props.styles.primary_btn}
																							      	> {t('deliveryToAddress')}
																							      	</Button>  	
																				      	}
																				      	
																					      <div className="mt-2 d-flex">
																					      	<Link to={'/edit-addresses/'+address.id} className="btn btn-xs btn-primary transparent w-100" style={this.props.styles.secondary_btn}>Edit</Link>
																					      	{/*<Button onClick={() => this.updateAddresses(address.id)} className="btn btn-xs transparent w-50">Delete</Button>*/}
																					      </div>
																					    </div>
																				    </div>
																					</Col>
																				))}
																			</Row>
																		</div>
																		<hr className="mt-4 mb-4" />
																	</React.Fragment>
															 	)}
															 	<Addresses 
															 		addressesLength={this.props.addresses.length}
															 		{...this.props} 
															 		shippingAddress={this.state.shippingAddress} 
															 		statesArray={this.state.statesArray}
															 		showBillingAddress={this.props.addresses.length > 0 ? false : true}
															 		setStatesArray={false} 
															 	/>
															</Card.Body>
														</Card>
													</div>
													<div className="tab-pane container fade p-0" id="store_pickup">
														<Form className="fields-group-md mt-5">
														  <Form.Group>
														    <Form.Label>{t('email')} <span className="red">*</span></Form.Label>
														    <Form.Control type="email" />
														    <Form.Text className="text-muted">
														      {t('accountCheckout')}.
														    </Form.Text>
														  </Form.Group>
														  <Button className="facebook_btn mt-3 mb-3"><i className="fab fa-facebook-f mr-2"></i> {t('signInFb')} </Button>
														  <hr className="mb-5" />
														</Form>
													</div>
												</div>
										  </div>
										  <div className="tab-pane container fade" id="payments">
										  </div>						  
										</div>
							  	</Col>
									<OrderSummery
										error={(typeof this.state.errors['shipping_addresses[id]'] != 'undefined' 
															&& this.state.errors['shipping_addresses[id]'] != "")?1:0}
										parentProps={this.props}
										placeOrder={this.props.addresses.length > 0 ? true : false}
										order={false}
										applyCouponCode={true}
										useStoreCredit={true}
										settings={this.props.settings}
										validateSellerSelection=''
										{...this.props}
									/>
							  </Row>
									
							</Container>
						}
						<Loader loaderState={loader} />
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
  return {
  	user: state.users.user,
    settings: state.settings.settings,
    addresses: state.addresses.addresses,
    styles:state.styles.styles,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCartCount: (count) => {dispatch({ type: 'SET_CART_COUNT', payload: count})},
    setCartProducts: (products) => {dispatch({ type: 'SET_CART_PRODUCTS', payload: products})},
    setAddresses: (addresses) => {dispatch({ type: 'SET_ADDRESSES', payload: addresses})},
    setUser: (user) => {dispatch({ type: 'SET_USER', payload: user})}
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Checkout));