import React from 'react';
import {Container, Row, Col, Button, Form} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';
import { getImageContent, getCartListing, getOrderSummery, 
         validateSellerSelection, applyCouponCode, removeCouponCode, getStoreCreditBalance, applyStoreCredit } from '../../helpers/ServicesHelper';
import { checkValidHash, mobile,getMobileToast,dealersPortal } from  '../../helpers/OperationsHelper';
import { submitOrder} from  '../../helpers/TransactionsHelper';

export default class CoreOrderSummery extends React.Component { 
  constructor(props){
    super(props);
    this.state={
      carts: [],
      images: [],
      orderSummery: [],
      errors: [],
      success: false,
      discountCoupanCode:'',
      storeCreditBalance: 0,
      storeCredit: 0, 
      placeOrderButtonLoader: false,
      placeReplacementOrderButtonLoader: false
    }
    this.images = [];
  }
  
  async componentDidMount(){
    window.history.pushState(null, null, window.location.pathname);
    await this.getCartListing();
    await this.getOrderSummery();
    if (cookie.load('authToken')) {
      await this.getStoreCreditBalance();
    }
  }

  async getStoreCreditBalance() {
    if (Object.keys(this.props.user).length > 0 && this.props.user.id != '') {
      var response = await getStoreCreditBalance(this.props.user.id);
      if (response.data.status == 'success') {
        this.setState({ storeCreditBalance: response.data.data })
      } else {
        console.log(response.data.status)
      }
    }
  }

  async getCartListing(){
    var carts = await getCartListing();
    if (carts.data.status == 'success') {
      this.setState({carts:carts.data.data});
      this.getBase64Images(carts.data.data);
    }
  }

  async getOrderSummery(where="") {
    var orderSummery = await getOrderSummery(where);
    console.log(orderSummery);
    if (orderSummery.data.status == 'success') {
      this.setState({orderSummery:orderSummery.data.data});
    } 
  }

  async getBase64Images(carts){
    carts.map((cart,index)=>{
      if (cart.item_details.item_images != null && typeof cart.item_details.item_images.name != 'undefined' && cart.item_details.item_images.name != null) {
        var medium_image = this.props.settings.image_url+'products/images/medium/'+cart.item_details.item_images.name;
        this.setImageInState(medium_image, cart.id);
      } else {
        this.setImageInState(this.props.settings.image_url+'application_images/original/4DKzaX6c97WRJZtN.png', cart.id);
      }
    })    
  }  
  async setImageInState(base64,id){
    this.images = this.state.images;
    this.images[id] = base64;
    this.setState({ images: this.images }); 
  }

  async placeOrder(orderType=0){
    if (orderType == 0) {
      this.setState({ placeOrderButtonLoader: true });
    } else {
      this.setState({ placeReplacementOrderButtonLoader: true });
    }
    
    var validateSellers = true;
    if (this.props.validateSellerSelection != '') 
      validateSellers = await this.props.validateSellerSelection();
    
    if(validateSellers == true){
      if (Object.keys(this.props.user).length > 0 && this.props.user.id != '' && this.props.user.user_type != '') {
        const userId = this.props.user.id;
        const userType = this.props.user.user_type;
        var response = await submitOrder(this.state.carts, orderType, userId, userType); 
      } 

      console.log(response);
      
      if (response.data.status == 'success') {
        await checkValidHash();
        if(dealersPortal == true || cookie.load('accessToken'))
          this.props.parentProps.history.push('/order-detail/'+response.data.data.id+'/1');
        else {
          if (response.data.data.amount_to_be_paid > 0) {
            this.props.parentProps.history.push('/payment/'+response.data.data.id);
          } else {
            this.props.parentProps.history.push('/order-detail/'+response.data.data.id+'/1');
          }
        }
        this.props.setCartCount(0);
        this.props.setCartProducts({});
      } else {
        if ( typeof response.data.errors != 'undefined' && typeof response.data.errors['orders[shipping_address_id]'] != 'undefined' && response.data.errors['orders[shipping_address_id]'] != '') {
          var message = response.data.errors['orders[shipping_address_id]'];
        } else if ( typeof response.data.errors != 'undefined' && typeof response.data.errors['orders[coupon_code]'] != 'undefined' && response.data.errors['orders[coupon_code]'] != '') {
          var message = response.data.errors['orders[coupon_code]'];
        } else {
          var message = 'Your product is out of stock please check your cart.';
        }
        
        toast.error(message, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }); 
      
      }
      this.setState({ placeOrderButtonLoader: false });
      this.setState({ placeReplacementOrderButtonLoader: false });
    }
    this.setState({ placeOrderButtonLoader: false });
    this.setState({ placeReplacementOrderButtonLoader: false });
  }

  async applyCouponCode(){
    var formdata = [];
    this.setState({'errors':[]});
    formdata['discounts[coupon_code]'] = this.state.discountCoupanCode;
    var response = await applyCouponCode(formdata);
    if(response.data.status == 'success'){
      await this.getOrderSummery();
    }else{
      if(response.data.status == 'error')
        this.setState({'errors':response.data.errors});
      else
        this.setState({'errors':{'discounts[coupon_code]':'something went wrong'}})
    }
  }

  async removeCouponCode(){
    this.setState({'errors':[]});
    var response = await removeCouponCode();
    if(response.data.status == 'success'){
      await this.getOrderSummery();
    }else{
      if(response.data.status == 'error')
        this.setState({'errors':response.data.errors});
      else
        this.setState({'errors':{'discounts[coupon_code]':'something went wrong'}})
    }
  }

  async applyStoreCredit(){
    var formdata = [];
    this.setState({'errors':[],success:false});
    formdata['store_credits[debit_amount]'] = this.state.storeCredit;
    var response = await applyStoreCredit(formdata);
    if(response.data.status == 'success'){
      this.setState({success:true})
      await this.getOrderSummery();
    }else{
      if(response.data.status == 'error')
        this.setState({'errors':response.data.errors});
      else
        this.setState({'errors':{'store_credits[debit_amount]':'something went wrong'}})
    }
  }
}