const initialState = {count: 0, otpCount:30, products: {}}

const carts = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_CART_COUNT':
    	return { count: action.payload, otpCount: state.otpCount, products: state.products };
    case 'SET_OTP_COUNT':
      return { count: state.count, otpCount: action.payload, products: state.products }
    case 'DECREMENT_OTP_COUNT':
      return { count: state.count, otpCount: state.otpCount - 1, products: state.products }
    case 'SET_CART_PRODUCTS':
      return { count: state.count, otpCount: state.otpCount - 1, products: action.payload }  
  }
  return state
}

export default carts;