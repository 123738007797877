import React from 'react';
import {Container, Button,Col,Form} from 'react-bootstrap';
import { Link } from 'react-router-dom'
import  Autocomplete  from 'react-autocomplete'
class Search extends React.Component {
  render() {
    return (
      (typeof this.props.tags != 'undefined') &&
        <React.Fragment>
        <Col lg={6} xs={9} className="text-center pr-0">
          <Form.Group className="mb-2 search_bar">
            <div className="wrapper">
              <div className="sc-bxivhb frjscc">
                <Autocomplete
                  getItemValue={(item) => item.label}
                  items={this.props.tags}
                  value={this.props.selectedTag}
                  onChange={(e) => this.props.handleOnSelect(e.target.value)}
                  onSelect={(val) => this.props.handleOnSelect(val)}
                  shouldItemRender={(item, value) => item.label.toLowerCase().indexOf(value.toLowerCase()) > -1}
                  renderItem={(item, isHighlighted) =>
                    <div>
                      {item.label}
                    </div>
                  }
                />
              </div>  
            </div>  
          </Form.Group>
        </Col>
        <Col lg={2} xs={3} className="text-left">
          <Form.Group className="mt-0 mb-0">
            <button className="btn btn-md btn-primary black" onClick={()=>this.props.SearchItem()}>Search</button>
          </Form.Group>
        </Col>      
      </React.Fragment>
    );
  }
}

export default Search;



