import React from 'react';
import {Container, Row, Col, Table, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom'

class GeneralFaq extends React.Component {	
	render(){
		return (
			<div className="tab-pane active" id="general_faq" role="tabpanel" aria-labelledby="general_faq_tab">			
				<h5 className="black bold mt-5">General</h5>								
				<div id="GeneralAccordion" className="mt-3">
			    <div className="card mb-3">
			      <div className="card-header">
			        <a className="card-link" data-toggle="collapse" href="#collapseOne">
			          <h6 className="black bold mb-0">Orders and Delivery:</h6>
			        </a>
			      </div>
			      <div id="collapseOne" className="collapse" data-parent="#GeneralAccordion">
			        <div className="card-body">
			          <ol className="pl-3">
									<li className="pb-2">
										<h6 className="bold black">Why is the bike delivered to the store and not my house?</h6>
										<p>In order to ensure the quality of the Products, and because assembly, configuration and consultation are required for certain products that includes bicycle, skis, ski binding, ski boots or avalanche backpack should be collected from Stores/Seller which you have selected while placing the order. You will receive an SMS once your product is ready to collect from store.</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">Will the accessories be delivered to my home or will it also have to be collected from store?</h6>
										<p className="mb-1">In case of accessories your product will be delivered to your shipping address based on the delivery mode you select. You also have the option to collect from store</p>
										<p className="bold">*Note: In case your order has multiple items which includes a bike, then by default the complete order will be “Collect from Store”</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">Can I Return a Bike?</h6>
										<p>No returns are accepted where assembly, configuration and consultation are required for certain products which includes bicycle, skis, ski binding, ski boots or avalanche backpack which are collected from stores Hence it is advisable for customers to check that you have received a correct product and all relevant documents like warranty card, manuals etc. as per your order. Only after satisfaction of the received product, you should submit the customer feedback form to the Store.</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">What is Collect from Stores?</h6>
										<p>Apart from home delivery, we give an extra option for our customers to collect their product from the nearest selected store while placing the order. For bikes it is a default delivery mode.</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">How do I track my order?</h6>
										<p>Once your order is shipped, you will receive tracking details via email and sms.</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">Can I make changes in my order? (preshipment and postshipment)</h6>
										<p>You cannot change or edit your order preshipment or post shipment, you need to cancel and place a new order. Once cancelled the refund will be processed and it may take 10-14 working days to reflect in your account depending upon the payment mode or instrument you have used while placing the order.</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">How to cancel the order? ( Postshipment and predelivery penality charges)</h6>
										<p>You can raise a request to cancel the order after logging into your account in your orders tab. You can also write to us at <a href="mailto:cs@sportnetwork.in" target="_blank">cs@sportnetwork.in</a> or call us <a href="tel:8080754321" target="_blank">8080754321</a> to cancel your order</p>
										<p>If your order has already been dispatched and you want to cancel the order, you can refuse to accept the delivery of your order. Once it is cancelled, the refund will be processed, and it may take 10-14 working days to reflect in your account depending upon the payment mode or instrument you have used while placing the order. You will receive the cancellation details on your mail once refund process is initiated.</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">How many days does it take to refund?</h6>
										<p>Once the refund process is initiated from our side, it may take 10-14 working days to reflect in your account depending upon the payment mode or instrument you have used while placing the order. In some cases, we may require your banking details for the refund.</p>																		
									</li>
									<li className="pb-2">
										<h6 className="bold black">What are the delivery charges and delivery time? (Zone wise delivery Days, Rest of India)</h6>
										<p>The charges and delivery time differ depending upon mode you select.</p>		
										<Table>
											<thead>
												<tr>
													<th>Shipping Mode</th>
													<th>Charge</th>
													<th>Delivery Days</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>Products less than 1000</td>
													<td>100</td>
													<td>5-7 Days</td>
												</tr>
												<tr>
													<td>Collect From Stores</td>
													<td></td>
													<td>10 Days</td>
												</tr>
												<tr>
													<td>Home Delivery</td>
													<td></td>
													<td>5-7 Days</td>
												</tr>
											</tbody>
										</Table>
										<p>Please be informed that we do not have our own logistics and rely on third party logistics service provider.</p>																
									</li>
									<li>
										<h6 className="bold black">Can I change the shipping mode/shipping address after the order is placed?</h6>
										<p>No, you cannot change the shipping mode/shipping address after placing the order.</p>																		
									</li>
								</ol>
			        </div>
			      </div>
			    </div>
			    <div className="card mb-3">
			      <div className="card-header">
			        <a className="collapsed card-link" data-toggle="collapse" href="#collapseTwo">
			        	<h6 className="black bold mb-0">Return/ Exchange:</h6>
			      	</a>
			      </div>
			      <div id="collapseTwo" className="collapse" data-parent="#GeneralAccordion">
			        <div className="card-body">
			          <p>Please visit the below link to know about the return / exchange policy and the process to initiate the return.</p>
			          <p><a href={window.location.origin+"/buyer-return-policy"} target="_blank">Buyer Return Policy</a></p>
			        </div>
			      </div>
			    </div>
			    <div className="card mb-3">
			      <div className="card-header">
			        <a className="collapsed card-link" data-toggle="collapse" href="#collapseThree">
			          <h6 className="black bold mb-0">Reverse Pickup:</h6>
			        </a>
			      </div>
			      <div id="collapseThree" className="collapse" data-parent="#GeneralAccordion">
			        <div className="card-body">
			           <ol className="pl-3">
									<li className="pb-2">
										<h6 className="bold black">Is my pin code serviceable for reverse pickup?</h6>
										<p>If the product has been delivered to you at your pin code by our logistics service provider, then it is possible for reverse pickup.</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">When will the reverse pickup happen?</h6>
										<p>Once we receive your request for return/exchange, we will place a request with our logistics service provider for reverse pickup. The reverse pickup ideally in metros takes about 3 business days and for Non-Metros about 5 business days subjective to their service. You will receive a communication mail as well as SMS about the reverse pickup.</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">When is my reverse pickup cancelled?</h6>
										<p>Your reverse pickup will automatically get cancelled if our logistics service provider has attempted the pickup 3 times with no success. In such a case, you need to raise a fresh request by call on <a href="tel:8080754321" target="_black">8080754321</a> or through email at <a href="mailto:cs@sportnetwork.in" target="_blank">cs@sportnetwork.in</a></p>
									</li>
									<li>
										<h6 className="bold black">Should I pack the order while returning?</h6>
										<p>Yes, your order needs to be packed properly and neatly in the same box in original condition with all the tags intact along with it so that it reaches in proper condition. Logistics service provider may reject the reverse pickup for open packages.</p>
									</li>
								</ol>
			        </div>
			      </div>
			    </div>
			    <div className="card mb-3">
			      <div className="card-header">
			        <a className="collapsed card-link" data-toggle="collapse" href="#collapseFour">
			          <h6 className="black bold mb-0">Payment:</h6>
			        </a>
			      </div>
			      <div id="collapseFour" className="collapse" data-parent="#GeneralAccordion">
			        <div className="card-body">
			           <ol className="pl-3">
									<li className="pb-2">
										<h6 className="bold black">What are the modes of Payment?</h6>
										<p>We have tied up with Razor pay as our payment gateway partner. All major cards and banks are supported via Razor pay. Customers can choose to pay with Credit Cards, Debit Cards, Internet Banking, Cash Cards, Wallets, EMI, UPI to make online payment.</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">Is Cash on Delivery (COD) option available?</h6>
										<p className="mb-1">No, we do not have COD option on our website</p>
										<p className="mb-1"><sup>*</sup>EMI Option is available only for Bikes.</p>
										<p><sup>*</sup>Terms and Conditions applicable as per the service providers.</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">Is it possible to transact from outside India?</h6>
										<p>Yes, you can shop on our website from outside India through the payment mode/currrency supported by Razor pay. Please note that the shipping address should be in India as we do not ship outside India.</p>
										<p><sup>*</sup>Any currency conversion rates that are charged by your bank will be payable as per their policy.</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">What is reason of my payment failure?</h6>
										<p>There are certain reasons of payment failure. Please check any of the below issues before your purchase again.
											<ul>
												<li>Website issues</li>
												<li>Payment Gateway issue</li>
												<li>Insufficient Funds</li>
												<li>Verification Failure</li>
											</ul>
										</p>
									</li>																	
									<li className="pb-2">
										<h6 className="bold black">What if my amount is debited but I do not receive order detail?</h6>
										<p>We ensure that your order is booked once we receive the payment from your bank in our bank. But sometimes due to unforeseen circumstances, the amount gets debited from your bank, but we do not receive the payment in our bank. In such case, you need to contact your bank for the same. For more information you can reach us on <a href="tel:8080754321" target="_blank">8080754321</a> or write to us at <a href="mailto:cs@sportnetwork.in" target="_blank">cs@sportnetwork.in</a> so that we can assist you to solve the issue.</p>
									</li>
									<li>
										<h6 className="bold black">Will my discount coupon be refunded if I return back the order?</h6>
										<p>The discount coupon will not be refunded back in case of return.</p>
									</li>
								</ol>
			        </div>
			      </div>
			    </div>
			    <div className="card">
			      <div className="card-header">
			        <a className="collapsed card-link" data-toggle="collapse" href="#collapseFive">
			          <h6 className="black bold mb-0">Warranty:</h6>
			        </a>
			      </div>
			      <div id="collapseFive" className="collapse" data-parent="#GeneralAccordion">
			        <div className="card-body">
			        	<p>Please be informed that different brands have different warranty policy. To know the warranty policy in detail you can visit the below brand links</p>
			        	<h6>Additional Queries:</h6>
			          <ol className="pl-3">
									<li className="pb-2">
										<h6 className="bold black">Is registration necessary to make a purchase online?</h6>
										<p>No, it’s not mandatory to register to purchase online. But, we advise you to register as your order details. This also makes the return/ exchange process easier and convenient. Kindly note that we follow data protection privacy policy as per Information Technology Act 2011.</p>
									</li>
									<li className="pb-2">
										<h6 className="bold black">How can I update/edit my details?</h6>
										<p className="mb-1">You can login into your account on the website, you can check the profile section to update/edit your details.</p>																		
									</li>
									<li className="pb-2">
										<h6 className="bold black">How to reset your password?</h6>
										<p>There is a forgot password link below your sign in options. Follow the steps to reset your password.</p>
									</li>
									<li>
										<h6 className="bold black">What is the customer service support timings?</h6>
										<p>You can write to us with your queries on <a href="mailto:cs@sportnetwork.in" target="_blank">cs@sportnetwork.in</a>. All your queries will be answered within 48 business hours. We are also available on the call from 10am to 6pm except on Saturday, Sunday and public holidays.</p>
									</li>
								</ol>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
		);
	}
}

export default GeneralFaq;