import React from 'react';
import { Link } from 'react-router-dom';
import {Container, Row, Col, Button, Card, Form, Tabs, Tab, Table} from 'react-bootstrap';
import {connect} from 'react-redux';
import { withTranslation } from 'react-i18next';
import cookie from 'react-cookies';

import Variations from './Variations';
import { getImageUrl,dealersPortal } from  '../../../../core/helpers/OperationsHelper';
import loader from '../../../assets/loader/circle-loader.gif';

class OrderProductDetail extends React.Component {
	render(){
		const { t } = this.props;
		var authToken = cookie.load('authToken');
   	console.log(authToken);
		return (
			<React.Fragment>
				<Card.Body className="cart_item">
    			{typeof this.props.orderDetails != 'undefined' && (
    			 this.props.orderDetails.map((product, index) => (
    				<React.Fragment key={index}>
    					<div className="d-flex mb-2">
	    					<div className="flex-fill">
	                {this.props.showStatus == true && <h6 className={(product.status == 'cancelled'?'red text-capitalize bold mb-0':'green text-capitalize bold mb-0')}>{product.status}</h6>}
	              </div>
            		{(product.brand_image != null)&&
            			<div className="justify-content-end d-none d-sm-block align-items-end">
  								<img src={this.props.settings.image_url+'brands/original/'+product.brand_image} width="100px" />
  							</div>
  							}
  						</div>
	    				<Row className="d-flex align-items-center">
	    					<Col lg={2} md={2} xs={4} className="mb-3 mb-sm-0">
	    						<div style={{border:'1px solid #ddd'}}>
	    							{(typeof this.props.images[product.id] != 'undefined' && this.props.images[product.id] != "") ?
		                  <img src={this.props.images[product.id]} className="product_img img-fluid" /> :
		                  <img src={loader} className="product_img img-fluid" /> 
		                }
	    						</div>
	    					</Col>
	    					<Col lg={8} md={7} xs={8} className="product_item_details">	    						
	    						<div className="d-flex" style={{height:'78%'}}>
	    							<div className="flex-fill">
	    								<Link to={"/pp/"+product.slug} className="product_item_name">{product.name}</Link>
	    								<Table borderless width="100%" className="mt-2">
	    									<tbody>

			    								<tr className="item_options">
		                        <td width="15%" className="px-0 py-0 pb-0"><p className="text-capitalize black mb-0">{t('model')}</p></td>
		                        <td width="85%" className="px-0 py-0 pb-0"><p className="text-capitalize black mb-0">: {product.item_code}</p></td>
		                      </tr> 
		                      {product.product_type_field_currency == 1 && (
		                      	<tr className="item_options">
				    									<td width="15%" className="px-0 py-0 pb-0"><p className="text-capitalize black mb-0">{product.product_type_field_name}</p></td>
				    									<td width="85%" className="px-0 py-0 pb-0"><p className="text-capitalize black mb-0">: <i className="fal fa-rupee-sign"></i> {product.product_type_field_value - ( (product.product_type_field_value * product.dealer_discount) / 100 )}</p></td>
				    								</tr>
		                      )}
		                      {product.product_type_field_currency == 0 && (
		                      	<tr className="item_options">
				    									<td width="15%" className="px-0 py-0 pb-0"><p className="black mb-0">{product.product_type_field_name} :</p></td>
				    									<td width="85%" className="px-0 py-0 pb-0"><p className="black mb-0">: {product.product_type_field_value - ( (product.product_type_field_value * product.dealer_discount) / 100 )} {product.product_type_field_unit}</p></td>
				    								</tr>
		                      )}	    								
				    							{product.variations.map((data,key)=>(
														<tr key={key} className="item_options">
															<td width="15%" className="px-0 py-0 pb-0"><p className="text-capitalize mb-0">{data.variation_name}</p></td>
															<td width="85%" className="px-0 py-0 pb-0"><p className="text-capitalize mb-0">: {data.variation_value} </p></td>
														</tr>
													))}
		                      {(product.delivered_at != '' && product.deliversed_at != null)&&
			                      <tr className="item_options">
			                        <td width="15%" className="px-0 py-0 pb-0"><p className="text-capitalize black mb-0">{t('deliveredAt')}</p></td>
			                        <td width="85%" className="px-0 py-0 pb-0"><p className="text-capitalize black mb-0">: {product.delivered_at}</p></td>
			                      </tr>                    
		                    	}
													<tr className="item_options">
		                        <td width="15%" className="px-0 py-0 pb-0"><p className="text-capitalize black mb-0">Qty :</p></td>                        
		                        <td width="85%" className="px-0 py-0 pb-0"><p className="text-capitalize black mb-0">: {product.quantity}</p></td>
		                      </tr>
		                      {(product.deliver_at && dealersPortal == false)&&
		                      	<tr className="item_options">
		                        	<td width="15%" className="px-0 py-0 pb-0"><p className="text-capitalize black mb-0">Delivery Type :</p></td>                        
		                        	<td width="85%" className="px-0 py-0 pb-0"><p className="text-capitalize black mb-0">: {product.deliver_at}</p></td>
		                      	</tr>
		                      }

		                      {(product.courier_tracking_link && dealersPortal == false)&&
		                      	<tr className="item_options">
		                        	<td width="15%" className="px-0 py-0 pb-0"><p className="text-capitalize black mb-0">Tracking Detail :</p></td>                        
		                        	<td width="85%" className="px-0 py-0 pb-0"><p className="text-capitalize black mb-0">: <a href={"https://sportnetwork0f.aftership.com/"+product.courier_tracking_link.substring(product.courier_tracking_link.lastIndexOf('/') + 1)+"?page-name=tracking-page"} target="_blank" className="text-capitalize black mb-0">View tracking detail</a></p></td>
		                      	</tr>
		                      }
		                     </tbody>
		                    </Table>
		                      {(product.seller_name != null && product.seller_name != '') &&(
				    								<div className="pr-2 mt-3">		
					    								<label className="form-label mb-0">{t('seller')} :</label>
					    								<div className="border p-2 mb-2">
												      	<p className="mb-0 bold" style={{fontSize:'0.95rem'}}>{product.seller_name}</p>
												      	<p className="mb-0" style={{fontSize:'0.95rem'}}>{product.seller_address}</p>
												      </div>											     
												      {(authToken != 'undefined' && dealersPortal == false) && (
													      <a href={"http://admin.sportnetwork.in/invoices/v1/invoices_v1/index?seller_id="+product.seller_id+"&order_id="+this.props.orderID+"&authToken="+authToken} target="_blank" className="text-underline">View Invoice</a>)}
											      </div>

				    							)}		    							
	    							</div>	    							
	    						</div>	   						
	    											    						
	    					</Col>
	    					<Col lg={2} md={3} xs={12} className="text-right">
  								{(product.status != 'Delivered' && product.status != 'Pending' && product.courier_tracking_link != null)&&
  									<a target="_blank" href={product.courier_tracking_link} className="btn btn-sm order_links btn-primary mb-2 d-block" style={this.props.styles.secondary_btn}>Track item</a>
  								}
  								<Link to={"/pp/"+product.category_slug+'/'+product.slug} className="btn btn-sm order_links btn-primary mb-2 d-block" style={this.props.styles.secondary_btn}>View Product</Link>
  								{(product.status == 'approved' && this.props.showCancelOrderButton == true) && 
										<Link className="btn btn-sm order_links btn-primary d-block" onClick={() => this.props.cancelOrderItem(product.id)} to='#' style={this.props.styles.secondary_btn}>Cancel Order</Link>
  								}
    							{(product.refund_replace_request == 1) && 
  									<Link className="btn btn-sm order_links btn-primary d-block" onClick={() => this.props.returnReplaceItem(product.id)} to='#' style={this.props.styles.secondary_btn}>{t('returnReplaceRequest')}</Link>
									}
	    					</Col>								    												    					
	    				</Row>
	    				<Row>
	    					<Col sm={12} className="mt-3">
	    						{product.refund_replace_request_note != '' && this.props.showNotes == true && 
	    							<React.Fragment>
		    							<hr className="d-block mt-1 mb-2" />
		    							<div>
		    								<h6 className="black bold mb-0">Refund Or Replace</h6>
		    								<p className="mb-0 ">{product.refund_replace_request_note}</p>
		    								{product.refund_type != '' && product.refund_type != null && <p className="mb-0 text-capitalize">Type: {product.refund_type}</p>}
		    								{product.refund_amount != 0 && product.refund_amount != null && <p className="mb-0">Refund Amount: <i className="fal fa-rupee-sign"></i> {product.refund_amount}</p>}
		    								{product.store_credit_amount != 0 && product.store_credit_amount != null && <p className="mb-0">Store Credit Amount: <i className="fal fa-rupee-sign"></i> {product.store_credit_amount}</p>}
		    								{product.refund_status != '' &&  <p className="mb-0 text-capitalize">Status: <span className="blue"> {product.refund_status} </span> </p>}
		    							</div>
		    						</React.Fragment>
	    						}
	    						
	    					</Col>
	    				</Row>
	    				{index < this.props.orderDetails.length - 1 && 
	    					<hr className="d-block mt-1 mb-2"></hr>
	    				}
	    			</React.Fragment>

    			)))}
  			</Card.Body>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
  return {
    styles: state.styles.styles,
  }
}

export default withTranslation()(connect(mapStateToProps, null)(OrderProductDetail));