import React, { Component } from 'react'
import { Switch, Route, BrowserRouter as Router, } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Favicon from 'react-favicon';
import { I18nextProvider } from 'react-i18next';
import { connect, Provider } from 'react-redux'

import Home from '../../../pages/home/views/Home';
import DealerHome from '../../../pages/dealer/views/Home';

import ProductListing from '../../../pages/products/views/ProductListing';
import ProductList from '../../../pages/products/views/Product';
import ProductDetails from '../../../pages/products/views/ProductDetails'
import ProductCompare from '../../../pages/products/views/ProductCompare'
import PreBooking from "../../../pages/products/views/PreBooking";
// import CustomerFeedback from '../../../pages/users/views/CustomerFeedback';

import CartListing from '../../../pages/transactions/views/CartListing';
import OrderDetails from '../../../pages/transactions/views/OrderDetails';
import OrderListing from '../../../pages/transactions/views/OrderListing';
import OrderResult from '../../../pages/transactions/views/OrderResult';
import WishListing from '../../../pages/transactions/views/WishListing';

import Checkout from '../../../pages/transactions/views/Checkout';
import PreparingCheckout from '../../../pages/transactions/views/PreparingCheckout';
import SellerSelection from '../../../pages/transactions/views/SellerSelection';
import EditAddresses from '../../../pages/transactions/views/EditAddresses';
import Payment from '../../../pages/transactions/views/Payment';

import CatalogListing from '../../../pages/catalogs/views/CatalogListing';
import CreateCatalog from '../../../pages/catalogs/views/CreateCatalog';

import Login from '../../../pages/users/views/Login';
import Register from '../../../pages/users/views/Register';
import ForgotPassword from '../../../pages/users/views/ForgotPassword';
import ResetPassword from '../../../pages/users/views/ResetPassword';
import ChangePassword from '../../../pages/users/views/ChangePassword';
import BillingAddress from '../../../pages/users/views/BillingAddress';
import EditProfile from '../../../pages/users/views/EditProfile';
import Triathlon from '../../../pages/home/views/Triathlon';
import ContactUs from '../../../pages/customerConnect/views/ContactUs';
import PrivacyPolicy from '../../../pages/customerConnect/views/PrivacyPolicy';
import BuyerReturnPolicy from '../../../pages/customerConnect/views/BuyerReturnPolicy';
import TermsConditions from '../../../pages/customerConnect/views/TermsConditions';
import Warning from '../../../pages/customerConnect/views/Warning';
import Warranty from '../../../pages/customerConnect/views/Warranty';
import AboutUs from '../../../pages/customerConnect/views/AboutUs';
import Manuals from '../../../pages/customerConnect/views/Manuals';
import ManualsBike from '../../../pages/customerConnect/views/ManualsBike';
import ManualsMoto from '../../../pages/customerConnect/views/ManualsMoto';
import ManualsBikeView from '../../../pages/customerConnect/views/ManualsBikeView';
import ManualsHelmetView from '../../../pages/customerConnect/views/ManualsHelmetView';
import ManualsBackpacksView from '../../../pages/customerConnect/views/ManualsBackpacksView';
import ManualsApparelView from '../../../pages/customerConnect/views/ManualsApparelView';
import ManualsGlovesView from '../../../pages/customerConnect/views/ManualsGlovesView';
import DeclarationOfConfirmityBikes from '../../../pages/customerConnect/views/DeclarationOfConfirmityBikes';
import MotoManualsBootsView from '../../../pages/customerConnect/views/MotoManualsBootsView';
import MotoManualsGogglesView from '../../../pages/customerConnect/views/MotoManualsGogglesView';
import MotoManualsHelmetsView from '../../../pages/customerConnect/views/MotoManualsHelmetsView';
import MotoManualsProtectionView from '../../../pages/customerConnect/views/MotoManualsProtectionView';
import MotoManualsApparelView from '../../../pages/customerConnect/views/MotoManualsApparelView';
import MotoManualsGlovesView from '../../../pages/customerConnect/views/MotoManualsGlovesView';
import DeclarationOfConfirmityMoto from '../../../pages/customerConnect/views/DeclarationOfConfirmityMoto';

import Product from '../../../pages/offer/views/product';

import Profile from '../../../pages/users/views/Profile';

import Redirect from '../../../../core/components/redirects/CoreRedirects';

import FindDealer from '../../../pages/customerConnect/views/FindDealer';

import PrivateRoute from '../../../../core/components/routes/CorePrivateRoute';
import RedirectWithStatus from '../../../../core/components/routes/CoreRedirectWithStatus';
import PublicRoute from '../../../../core/components/routes/CorePublicRoute';

import NotFound from '../../NotFound';
import { getImagePath } from '../../../../core/helpers/OperationsHelper';

import i18n from '../../../utils/i18n';

import store from '../../../../store';
import { getMetaTags, getRedirectUrl } from '../../../../core/helpers/ServicesHelper';
import AvantiHomeIndia from '../../../pages/home/views/AvantiHomeIndia';
import AvantiHome from '../../../pages/home/views/AvantiHome';
// import ComingSoon from '../../../pages/home/views/ComingSoon';

// import Strip from '../../headers/scott/Strip';
import AvantiWarranty from '../../../pages/customerConnect/components/AvantiWarranty';
import NoCost from '../../../pages/customerConnect/views/NoCostEMI_TC';
import NewsLetter from '../../../pages/home/views/NewsLetter';
import Avanti2 from '../../../pages/home/views/Avanti2';


class CustomerRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.state = { metatags: [], 'to': '' }
  }

  async componentDidMount() {
    await this.getRedirectUrl();
  }

  async getRedirectUrl() {
    var fromUrl = this.props.location.pathname;
    var url = await getRedirectUrl(fromUrl);
    if (url.data.status == 'success' && url.data.data.length > 0) {
      this.setState({ redirect: true, to: url.data.data[0].to });
    } else {
      this.setState({ redirect: true, to: '/404' })
    }
  }


  render() {
    console.log()
    return (
      <div>
        <I18nextProvider i18n={i18n}>
          <Router>
            <Favicon url={(typeof this.props.settings != 'undefined') && this.props.settings.image_url + 'application_images/original/' + this.props.settings['customer_favicon']} />
            <Provider store={store}>
              <Switch>
                <PublicRoute restricted={false} path="/" component={Home} exact sensitive />

                <PublicRoute restricted={true} header={false} path='/login/:paramOne?' component={Login} exact sensitive />
                <PublicRoute restricted={true} header={false} path='/register' component={Register} exact sensitive />
                <PublicRoute restricted={true} header={false} path='/forgot-password' component={ForgotPassword} exact sensitive />
                <PublicRoute restricted={true} header={false} path='/reset-password' component={ResetPassword} exact sensitive />
                <PrivateRoute restricted={false} path='/change-password' component={ChangePassword} exact sensitive />
                <PrivateRoute restricted={false} path='/edit-password' component={EditProfile} exact sensitive />
                <PrivateRoute restricted={false} path="/profile" component={Profile} />

                {/* <Route path="/customer-feedback" component={CustomerFeedback} /> */}
                <PrivateRoute restricted={false} path="/billing-address-update" component={BillingAddress} />

                {/* <PublicRoute path="/pl/bikes/bikes/pre-booking" component={PreBooking} /> */}
                <PublicRoute path="/triathlon-store" component={Triathlon} />
                <PublicRoute path="/pl/:paramOne/:paramTwo?/:paramThree?" component={ProductListing} />
                <PublicRoute path="/product-list" component={ProductList} />
                <PublicRoute path="/pl" component={ProductListing} />
                <PublicRoute path="/bpl/:paramOne" component={ProductListing} />
                {/* <PublicRoute path="/cpl/:paramOne" component={ProductListing} /> */}
                <PublicRoute path="/cpl/:paramOne" component={ProductListing} />
                <PublicRoute path="/pp/:paramOne/:paramTwo?/:paramThree?/:paramFour?" component={ProductDetails} />
                <PublicRoute path="/bpp/:paramOne/:paramTwo?" component={ProductDetails} />
                <PublicRoute path="/cpp/:paramOne/:paramTwo?" component={ProductDetails} />
                <PublicRoute restricted={false} path="/product-compare" component={ProductCompare} />

                <PublicRoute restricted={false} path="/carts" component={CartListing} />
                <PublicRoute restricted={false} path="/wishlists" component={WishListing} />

                <PublicRoute restricted={false} path="/checkout" component={Checkout} />
                <PublicRoute restricted={false} path="/preparing-checkout" component={PreparingCheckout} />

                <PublicRoute restricted={false} path="/seller-selection" component={SellerSelection} />

                <PrivateRoute path="/edit-addresses/:id" component={EditAddresses} />
                <PrivateRoute path="/orders" component={OrderListing} />
                <PrivateRoute path="/order-detail/:orderId/:status?" component={OrderDetails} />
                <PrivateRoute path='/order-result/:orderId' component={OrderResult} />
                <PrivateRoute path='/payment/:orderId' component={Payment} />

                <PrivateRoute path="/catalogs" component={CatalogListing} />
                <PrivateRoute path="/create-catalog" component={CreateCatalog} />
                <PrivateRoute path="/edit-catalog/:id" component={CreateCatalog} />

                <PublicRoute restricted={false} path="/contact-us" component={ContactUs} />
                <PublicRoute restricted={false} path="/privacy-policy" component={PrivacyPolicy} />
                <PublicRoute restricted={false} path="/buyer-return-policy" component={BuyerReturnPolicy} />
                <PublicRoute restricted={false} path="/terms-conditions" component={TermsConditions} />
                <PublicRoute restricted={false} path="/warning" component={Warning} />
                <PublicRoute restricted={false} path="/warranty" component={Warranty} />
                <PublicRoute restricted={false} path="/about-us" component={AboutUs} />
                <PublicRoute restricted={false} path='/redirects' component={Redirect} exact sensitive />
                <PublicRoute restricted={false} path="/find-dealer" component={FindDealer} />
                <PublicRoute restricted={false} path="/manuals" component={Manuals} />
                <PublicRoute restricted={false} path="/manuals-bike" component={ManualsBike} />
                <PublicRoute restricted={false} path="/manuals-moto" component={ManualsMoto} />
                <PublicRoute restricted={false} path="/manuals-bike-view" component={ManualsBikeView} />
                <PublicRoute restricted={false} path="/manuals-helmet-view" component={ManualsHelmetView} />
                <PublicRoute restricted={false} path="/manuals-backpacks-view" component={ManualsBackpacksView} />
                <PublicRoute restricted={false} path="/manuals-apparel-view" component={ManualsApparelView} />
                <PublicRoute restricted={false} path="/manuals-gloves-view" component={ManualsGlovesView} />
                <PublicRoute restricted={false} path="/declaration-of-confirmity-bikes" component={DeclarationOfConfirmityBikes} />
                <PublicRoute restricted={false} path="/moto-manuals-boots-view" component={MotoManualsBootsView} />
                <PublicRoute restricted={false} path="/moto-manuals-goggles-view" component={MotoManualsGogglesView} />
                <PublicRoute restricted={false} path="/moto-manuals-helmets-view" component={MotoManualsHelmetsView} />
                <PublicRoute restricted={false} path="/moto-manuals-protection-view" component={MotoManualsProtectionView} />
                <PublicRoute restricted={false} path="/moto-manuals-apparel-view" component={MotoManualsApparelView} />
                <PublicRoute restricted={false} path="/moto-manuals-gloves-view" component={MotoManualsGlovesView} />
                <PublicRoute restricted={false} path="/declaration-of-confirmity-moto" component={DeclarationOfConfirmityMoto} />
                <PublicRoute restricted={false} path="/product" component={Product} />
                <PublicRoute path="/avantihomeindia" component={AvantiHomeIndia} />
                <PublicRoute path="/avantihome" component={AvantiHome} />
                {/* <Route  path="/strip" component={Strip} /> */}
                <Route path="/avantiwarranty" component={AvantiWarranty} />
                <PublicRoute path="/nocost_emi" component={NoCost} />
                {/* <PublicRoute  path="/comingsoon" component={ComingSoon} /> */}
                <PublicRoute path="/newsletter" component={NewsLetter} />
                <Route path="/avanti" component={Avanti2} />

                {(this.state.to != "") && <RedirectWithStatus
                  status={301}
                  from={this.props.location.pathname}
                  to={this.state.to}
                />
                }
                <Redirect to={'/redirects'} />
              </Switch>
            </Provider>
          </Router>
        </I18nextProvider>
      </div>
    )
  }
}


export default CustomerRoutes;
