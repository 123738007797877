import React from 'react';
import {Container, Row, Col, Table, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom'


class ScottWarranty extends React.Component {	
	render(){
		return (		
			<div className="mt-5">
		  	<h2 className="small_heading bold black text-center mb-5 text-uppercase">Scott Bike Limited Warranty</h2>
		  	<div className="mb-4">
		  		<h3 className="content_heading black bold">WHAT IS COVERED?</h3>
		  		<p>This warranty covers defects in materials and workmanship at the time of transfer of risks in frames, swingarms and forks (provided it is a fork of SCOTT) on SCOTT branded bikes sold completely assembled by SCOTT or an authorized SCOTT dealer (“Product”).</p>
		  	</div>
		  	<div className="mb-4">
		  		<h3 className="content_heading black bold">HOW LONG DOES COVERAGE LAST?</h3>
		  		<p>This voluntary manufacturer’s warranty is limited to five years for frames and swingarms, respectively two years for forks, from the date of purchase of the Product and is limited to the first purchaser of the Product and subject to the prior registration of your SCOTT-bike on <a href="https://customerconnect.sportnetwork.in/registrations/basic_informations" target="_blank">Bike Registration</a> within 10 days as on the date of purchase. Transfer of the Product from the first purchaser to another person terminates this limited warranty.</p>
		  		<p>A claim that has been accepted under the Voluntary Manufacturer’s Warranty does not then prolong the Voluntary Manufacturer’s Warranty.</p>
		  		<p>The limited warranty of five years for the frames and swingarms shall only be granted in case once a year a maintenance service has been effected according to maintenance requirements as set forth in the manual. The effected annual maintenance service shall be confirmed by stamp and signature of an authorised SCOTT dealer. In case such an annual maintenance service has not been effected the warranty of five years for the frame shall be reduced to three years. Costs for maintenance and service have to be borne by the owner of the Product.</p>
		  		<p>On Gambler, Voltage Fr and Volt-X the warranty period is limited to two years.</p>
		  		<p>Repaired or replaced Products are covered for the remainder of the original warranty period and subject to the conditions outlined in the original warranty, to the extent permitted by law.</p>
		  		<p>Hereby SCOTT grants a worldwide voluntarily manufacturer’s warranty. To the extent permitted by law and unless a shorter duration is stipulated by law, any warranties implied by law are limited in duration to maximum five, respectively two years, from the date of purchase of the Product and are limited to the first purchaser of the Product.</p>
		  	</div>
		  	<div className="mb-4">
		  		<h3 className="content_heading black bold">WHAT WILL SCOTT DO IN THE EVENT OF A GUARANTEE CASE?</h3>
		  		<p>SCOTT will replace the defective product with a product of a quality or nature and similar level, will repair or refund the purchase price (after presentation of the proof of purchase of the product), in its sole discretion. Non-defective components are replaced at your expense. In such a case, we will contact you before replacing the non-defective part for your agreement.</p>
		  	</div>
		  	<div className="mb-4">
		  		<h3 className="content_heading black bold">WHAT DOES THIS LIMITED WARRANTY NOT COVER?</h3>
		  		<p>This limited warranty does not cover defects which did not exist before the transfer of risks. This limited warranty does not cover Products used in rental operations. This limited warranty does not cover purchases of not completely assembled bikes. This limited warranty does not cover any defect caused by “wear and tear” (a complete list of all parts of “wear and tear” can be found in the manual, accident, neglect, improper handling, colour fade due to exposure to sunlight, abuse, misuse, an act of God, improper assembly, non-compliance with recommended maintenance and care procedures, improper or incorrectly performed maintenance or repairs performed by someone other than an authorized SCOTT dealer, use of parts or devices not consistent with the Product, and alteration of the Product. All Products come with a manual; please carefully follow the instructions located there or affixed elsewhere to the Product. To the extent permitted by law, consequential and incidental damages are not recoverable under this limited warranty.</p>
		  	</div>
		  	<div className="mb-4">
		  		<h3 className="content_heading black bold">HOW DO YOU MAKE A CLAIM UNDER THIS LIMITED WARRANTY?</h3>
		  		<p>To make a claim under this limited warranty, you must notify SCOTT of the claimed defect within the warranty period and timely return the Product to SCOTT at your expense for inspection. Please contact your authorized SCOTT dealer, call SCOTT’s customer service <a href="tel:8080754321" target="_blank">8080754321</a> or via <a href="https://customerconnect.sportnetwork.in/warranties" target="_blank">Customer Connect</a>. All returned Products must be accompanied by proof of purchase (receipt) from an authorized SCOTT dealer or this limited warranty will not apply. In case of replacement or refund, returned Product becomes the property of SCOTT.</p>
		  		<p>A protocol for the handing over of the Product (which you will find at the end of the manual) will remain in copy at the SCOTT dealer after acceptance and signature of the consumer. It is obligatory to show this protocol of handing over together with the defective part in case of a warranty claim given that it provides evidence of purchase or this limited warranty will not apply.</p>
		  	</div>
		  	<div className="mb-4">
		  		<h3 className="content_heading black bold">HOW DO STATE LAWS AFFECT YOUR RIGHTS UNDER THIS LIMITED WARRANTY?</h3>
		  		<p>This limited warranty gives you specific legal rights, and you may also have other rights, which vary from state to state.</p>
		  	</div>
		  	<div className="mb-4">
		  		<h3 className="content_heading black bold">RECOMMENDATION</h3>
		  		<p>We strongly recommend that you use only authorized SCOTT dealers for yearly maintenance services and for repairs, as improper or incorrectly performed maintenance or repairs voids this limited warranty. Costs for maintenance service have to be borne by the consumer.</p>
		  	</div>
		  </div>
		);
	}
}

export default ScottWarranty;