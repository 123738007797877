import React from 'react';
import {Container, Row, Col, Button, DropdownButton, Dropdown, Collapse, Form, Accordion} from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

import ReactSlider from "react-slick";
import ReactImageMagnify from 'react-image-magnify';

import ReactFancyBox from 'react-fancybox'
import 'react-fancybox/lib/fancybox.css'

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import loader from '../../../assets/loader/circle-loader.gif';


class Slider extends React.Component { 
	constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      photoIndex: 0,
      isOpen: false,
    };
    
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  componentDidUpdate(prevProps,prevState){
    if(this.slider1 != this.state.nav1){
      this.setState({
        nav1: this.slider1,
        nav2: this.slider2
      });
    }
  }

  render() {
    const { photoIndex, isOpen } = this.state;
    const images = (this.props.images).sort((firstItem, secondItem) => firstItem.sequence - secondItem.sequence);
    console.log(images);
		return (
			<>    
        {Object.keys(images).length > 0 && (
          <React.Fragment>
            <div className="main_slider">
              <ReactSlider
                asNavFor={this.state.nav2}
                ref={slider => (this.slider1 = slider)}
              >
                {Object.keys(images).map((key,index) => (
                  <div className="main_img_div" key={'main_img_div'+key}>
                    <img src={images[key].original} width="100%" />
                    <span className="view_icon" onClick={() => this.setState({ isOpen: true, photoIndex: index })}><i className="far fa-expand"></i></span>
                  </div>
                ))}
              </ReactSlider>
            </div>
            <div className="thumbnail_slider mt-3">
              <ReactSlider
                asNavFor={this.state.nav1}
                ref={slider => (this.slider2 = slider)}
                slidesToShow={5}
                slidesToScroll={5}
                infinite= {false}
                focusOnSelect={true}
              >
                {Object.keys(images).map(key => (
                  <div className="img_div" key={'img_div'+key}>
                    <img src={images[key].thumbnail} width="100%" />              
                  </div>
                ))}
              </ReactSlider>
            </div>

            {isOpen && (
              <Lightbox
                mainSrc={typeof images[photoIndex] != 'undefined' ? images[photoIndex].original : 'https://www.w3schools.com/howto/img_forest.jpg'}
                nextSrc={(photoIndex < images.length)?images[(photoIndex + 1) % images.length]:1}
                prevSrc={(photoIndex > 0)?images[(photoIndex + images.length - 1) % images.length]:0}
                onCloseRequest={() => this.setState({ isOpen: false })}
                enableZoom={true}
                wrapperClassName="image-lightbox"
                onMovePrevRequest={() =>
                  this.setState({
                    photoIndex: (photoIndex + images.length - 1) % images.length,
                  })
                }
                onMoveNextRequest={() =>
                  this.setState({
                    photoIndex: (photoIndex + 1) % images.length,
                  })
                }
              />
            )}
          </React.Fragment>
        )}  
      </> 	
   	)
	}
}

export default Slider;