import React, { Component } from 'react';
import { default as converter } from 'number-to-words';

import {getSalesVoucher } from '../../helpers/ServicesHelper';

class CoreSalesVouchers extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      vouchers:{},
      tallyVoucher:{},
      tallyCompany:{},
      voucherDetails:[],
      voucherReference:[],
      amount:'',
      total:'',
      amounSales:'',
      gst:'',
      gstInWords:'',
      taxInWords:'',
      totalAmountInWords: '',
      totalTaxInWords: ''
    }
  } 

  async componentDidMount(){
    await this.getSalesVouchers()
  }

  async getSalesVouchers(){
    const voucherId=this.props.match.params.id;
    const vouchers = await getSalesVoucher(voucherId);
    if (vouchers.data.status === 'success') {
      await this.setState({vouchers:vouchers.data.data,
                           tallyVoucher:vouchers.data.data.tally_voucher,
                           tallyCompany:vouchers.data.data.tally_company,
                           voucherDetails:vouchers.data.data.voucher_details,
                           voucherReference:vouchers.data.data.voucher_reference,
                           voucherTaxCalculations:vouchers.data.data.tax_calculations});

      if (this.state.voucherReference.length > 0) {
        this.state.voucherReference.map((reference,index)=>{
          if (reference.particular == this.state.tallyVoucher.party_name) {
            const words=converter.toWords(reference.debit_amount - reference.credit_amount);
            this.setState({totalAmountInWords:words});  
          }
        });
      }

      if (typeof this.state.voucherTaxCalculations != 'undefined') {
        const words = converter.toWords(this.state.voucherTaxCalculations.total.total_tax_amount);
        this.setState({totalTaxInWords:words});
      }
    }
    else {
      console.log(vouchers.data.status);
    }
  }

}

export default CoreSalesVouchers;