import React, { Component } from 'react';
import { Container, Row, Col, Form, Button, Dropdown, DropdownButton, Nav } from 'react-bootstrap';
import cookie from 'react-cookies';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import Autocomplete from 'react-autocomplete';

import CustomerHeader from './CustomerHeader';
import DealerHeader from './DealerHeader';
import CoreHeader from '../../../../core/components/header/CoreHeader';
import { getApiUrl, dealersPortal } from '../../../../core/helpers/OperationsHelper.js';
import { getCustomerHeaderFields } from '../../../../core/helpers/ServicesHelper';
import Search from '../../../components/Search';

class Header extends CoreHeader {
  async componentDidMount() {
    if (cookie.load('authToken'))
      this.setState({ userLog: "LOGOUT" })
    else
      this.setState({ userLog: "LOGIN" })
    this.selectTags();
  }


  render() {
    console.log(this.props.catalogCount);
    let searchToggleClass = [""];
    if (this.state.addClass) {
      searchToggleClass.push('searchbox-open');
    }

    const isOpen = this.state.isOpen;
    const { t } = this.props;
    const paramOne = this.props.match.path.split('/');
    console.log("Dealers Portal", dealersPortal);
    return (

      (this.state.redirectTo != "") ? <Redirect to={this.state.redirectTo} /> :
        <div className="main_nav">
          <Container>
            <Row>
              <div className="d-block d-lg-none">
                <a id="hamburger" className={`toggle-nav ${this.state.isOpen == true ? "crossBurger" : ""}`} onClick={() => this.sidebarToggle(this.state.isOpen)}>
                  <span className="top-bar"></span>
                  <span className="middle-bar"></span>
                  <span className="bottom-bar"></span>
                  <i className="fal fa-times cancel"></i>
                </a>
              </div>

              {(dealersPortal == true) &&
                <DealerHeader
                  isOpen={this.state.isOpen}
                  dealerCategories={this.props.dealerCategories}
                  dealerBrands={this.props.dealerBrands}
                  settings={this.props.settings}
                  categories={this.props.userCategories}
                  subCategories={this.props.userSubCategories}
                  subSubCategories={this.props.userSubSubCategories}
                />
              }

              {(dealersPortal == false) &&
                <CustomerHeader
                  isOpen={this.state.isOpen}
                  settings={this.props.settings}
                  categories={this.props.userCategories}
                  subCategories={this.props.userSubCategories}
                  subSubCategories={this.props.userSubSubCategories}
                />
              }

              <div className="right-nav">
                <ul className="list-unstyled">
                  {(paramOne[1] != 'pl') &&
                    <React.Fragment>
                      <li>
                        <Link to="#" data-toggle="collapse" data-target="#searchBox" className="position-relative">
                          <i className="far fa-search" style={{ marginTop: '-6px' }}></i>
                        </Link>
                      </li>

                      <div id="searchBox" class="collapse">
                        <Container>
                          <Row className="p-3">
                            <Search
                              handleOnSelect={(e) => this.handleOnSelect(e)}
                              tags={this.state.tags}
                              selectedTag={this.state.selectedTag}
                              SearchItem={() => this.SearchItem()}
                            />
                          </Row>
                        </Container>
                      </div>
                    </React.Fragment>
                  }
                  {/*}*/}
                  {(this.props.settings.enable_wishlist == 'Yes') &&
                    ((Object.keys(this.props.user).length == 0 || (Object.keys(this.props.user).length > 0 && this.props.user.user_type != 0)) && (
                      <li className="cart_count" style={{ paddingTop: '10px' }}>
                        <Link to="/wishlists"><i className="far fa-heart"></i></Link>
                        <span className="badge badge-danger">{this.props.wishListCount}</span>
                      </li>
                    ))}
                  {(Object.keys(this.props.user).length == 0 || (Object.keys(this.props.user).length > 0 && this.props.user.user_type != 0)) && (
                    <li className="cart_count" style={{ paddingTop: '10px' }}>
                      <Link to="/carts"><i className="far fa-shopping-cart"></i></Link>
                      <span className="badge badge-danger">{this.props.cartCount}</span>
                    </li>
                  )}
                  {(Object.keys(this.props.user).length > 0 && this.props.user.user_type == 0) && (
                    <li className="cart_count" style={{ paddingTop: '10px' }}>
                      <Link to={this.props.catalogCount > 0 ? '/cpl/' + this.props.catalogSymbol : '/catalogs'}><i class="fal fa-clipboard-list-check"></i></Link>
                      <span class="badge badge-danger">{this.props.catalogCount}</span>
                    </li>
                  )}
                  <li>
                    <DropdownButton
                      menuAlign="right"
                      title={<i className="far fa-user-circle"></i>}
                      id="dropdown-menu-align-right"
                      className="user_dropdown"
                    >
                      {(dealersPortal == true) && (
                        <Link to="/dealer-profile" className="text-capitalize">{t('myProfile')}</Link>
                      )}

                      {(dealersPortal == false && cookie.load('authToken')) && (
                        <React.Fragment>
                          <Link to="/profile" className="text-capitalize">{t('myProfile')}</Link>
                          {/* <Link to="/customer-feedback" className="text-capitalize">{t('Customer Feedback')}</Link> */}
                          <Link to="/change-password" className="text-capitalize">{t('changePassword')}</Link>
                        </React.Fragment>
                      )}
                      {(Object.keys(this.props.user).length == 0 || (Object.keys(this.props.user).length > 0 && this.props.user.user_type != 0)) && (
                        <Link to="/orders">{t('myOrders')}</Link>
                      )}
                      {(dealersPortal == true) && (
                        <Link to="/dealer-ledger">{t('accounts')}</Link>
                      )}
                      {(dealersPortal == true) && (
                        <Link to="/change-password">{t('changePassword')}</Link>
                      )}
                      {(Object.keys(this.props.user).length > 0 && this.props.user.user_type == 0) && (
                        <Link to="/catalogs">{t('myCatalogs')}</Link>
                      )}
                      {(Object.keys(this.props.user).length > 0 && this.props.user.user_type == 0) && (
                        <Link to="/create-catalog">{t('createCatalog')}</Link>
                      )}
                      {cookie.load('authToken') ?
                        <>
                          <hr className="mt-1 mb-1" />
                          <Link onClick={() => this.removeStore()} eventKey="5">{t('logout')}</Link>
                        </> :
                        <>
                          <Link onClick={() => this.removeStore()} eventKey="5">{t('login')}</Link>
                        </>
                      }
                    </DropdownButton>
                  </li>
                </ul>
              </div>
            </Row>
          </Container>
        </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    cartCount: state.carts.count,
    wishListCount: state.wishlists.count,
    catalogCount: state.catalogs.count,
    settings: state.settings.settings,
    dealerCategories: state.dealers.categories,
    dealerBrands: state.dealers.brands,
    catalogSymbol: state.catalogs.symbol,
    user: state.users.user,
    userCategories: state.users.categories,
    userSubCategories: state.users.subCategories,
    userSubSubCategories: state.users.subSubCategories,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setCartCount: (count) => { dispatch({ type: 'SET_CART_COUNT', payload: count }) },
    setWishListCount: (count) => { dispatch({ type: 'SET_WISHLIST_COUNT', payload: count }) },
    setCatalogCount: (count) => { dispatch({ type: 'SET_CATALOG_COUNT', payload: count }) },
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Header));