
import React from 'react';
import {Container, Row, Col, Table, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import MetaTags from '../../../components/MetaTags';
import Faq from '../components/Faq';

class NoCost extends React.Component {  
    componentDidMount() {
      window.scrollTo(0, 0)
    }
    render(){
        return (
            <React.Fragment> 
                <MetaTags {...this.props}/>
                <div className="main mt-5">
                    <div className="main_section">
                        <Container className="">
                            <h1 className="page_heading bold text-uppercase black mb-3 text-left mb-3">No Cost EMI Terms and Conditions</h1>
                            {/* <div>
                                <p className="black bold">PLEASE READ THESE TERMS OF USE (“TERMS”), CAREFULLY. BY USING THIS PORTAL, YOU INDICATE YOUR UNDERSTANDING AND ACCEPTANCE OF THESE TERMS. IF YOU DO NOT AGREE TO THESE TERMS YOU MAY NOT USE THIS PORTAL, IN ITS ENTIRETY.</p>
                            </div>
                            <div className="">
                                <h2 className="small_heading black bold mt-4 text-underline mb-2">1. Definitions</h2>
                                <ol type="1." className="pl-3">
                                    <li className="pb-2">“Buyer(s)” means the users who subscribe and register on the Portal for purchasing the Products.</li>
                                    <li className="pb-2">“User”, “You”, “Your” means any person who browses, views, accesses or uses this Portal;</li>
                                    <li className="pb-2">“Use” or “Using” means to browse, access, view, purchase the Products or otherwise gain benefit from using this Portal;</li>
                                    <li className="pb-2">“We”, “SNS”, “Us” and “Our” means Scott Network Solutions.</li>
                                    <li className="pb-2">“Portal” means www.sportnetwork.in and the mobile application duly owned, operated and maintained by SNS.</li>
                                    <li className="pb-2">“Products” shall mean and include Seller’s products, listed by the Sellers for selling and distributing to the Users on the Portal.</li>
                                    <li className="pb-2">“Sellers” shall include the individuals or the entities that offer the Products, through the Portal.</li>
                                </ol>
                            </div>
                            <div className="">
                                <h2 className="small_heading black bold mt-4 text-underline mb-2">2. Introduction</h2>
                                <p>Welcome to our Portal. Our Portal offers sports related products including but not limited to exclusive sports equipment, bikes, sports gear, sports apparel, sports accessories, water sports bikes, accessories, gear. If You continue to browse and use this Portal, You are agreeing to comply with and be bound by these Terms, which together with our privacy policy (“Privacy Policy”) govern SNS's relationship with You.</p>
                            </div>
                            <div className="">
                                <h2 className="small_heading black bold mt-4 text-underline mb-2">3. Eligibility</h2>
                                <p>You represent and warrant that:</p>
                                <ol type="a">
                                    <li className="pb-2">you are fully able, competent and authorized to enter into the terms, conditions, obligations, representations and warranties set forth in these Terms;</li>
                                    <li className="pb-2">you are of legal age to form a binding contract and are not a person barred from availing the Products under the applicable laws.</li> 
                                </ol>
                            </div>
                            <div className="">
                                <h2 className="small_heading black bold mt-4 text-underline mb-2">4. User Account</h2>
                                <p>You represent and warrant that:</p>
                                <ol type="a">
                                    <li className="pb-2">In order to purchase the Products offered on this Portal, You are required to register and create a User Account with password (“User Account”). At the time of registration, You shall be required to share certain information such as name, age, gender, mobile no., address, city, state, email ID, date of birth, anniversary date, etc. with SNS. You understand that only upon the creation of User Account, you would be in a position to buy or purchase the Products offered on the Portal.</li>
                                        <li className="pb-2">Without prejudice to section 3.1 and 3.2 above, The Portal is also available to Users on a temporary basis, where Users may view, access and browse through the Portal, without creating a User Account.</li>
                                </ol>
                            </div> */}
                            <div className="">
                                <h4 className="small_heading black bold mt-4 mb-2">The following terms and conditions apply to transactions made on the
SPORTNETWORK.IN Store Online (<a href="https://www.sportnetwork.in/" target="_blank">www.sportnetwork.in</a>) using EMI or No Cost
EMI as a payment option:</h4><br/>
                            
                                <ol type="">
                                    <li className="pb-2"> SPORTNETWORK.IN facilitates EMI and No Cost EMI. You will be presented with EMI and No Cost EMI payment options if your total transaction order meets the relevant minimum order value (INR 36000/-) for your eligible credit card as set by your eligible Credit Card issuing bank, and in the event that SPORTNETWORK.IN is running a No Cost EMI promotion.</li>
                                    <li className="pb-2"> When you choose the No Cost EMI payment option, the amount comparable to the entire amount of interest charged on an EMI transaction will be deducted from your total transaction value. Your qualified credit card issuing bank will be responsible for repaying the principle loan amount as well as any applicable interest.</li>
                                    <li className="pb-2"> For No Cost EMI, the amount equivalent to the total amount of interest payable on an EMI transaction will be discounted from your total transaction value upon choosing the No Cost EMI payment option. You will be liable to repay the principle loan amount and applicable interest to your eligible credit card issuing bank.</li>
                                    <li className="pb-2"> EMI and No Cost EMI are offered by and subject to the terms and conditions applicable between you and your eligible credit card issuing bank. SPORTNETWORK.IN is not involved in any approval, extension, pricing, modification, pre-closure, closure or any matter incidental to offering the EMI payment option financing to you. This is decided at the sole discretion of your eligible credit card issuing bank.</li>
                                    <li className="pb-2"> No Cost EMI is not available or applicable for purchases made from offline dealer store or anywhere else other than SPORTNETWORK.IN</li>
                                    <li className="pb-2"> You understand that SPORTNETWORK.IN may share transaction order information with your qualifying credit card issuing bank for these purposes, and you expressly consent to this data sharing. </li>
                                    <li className="pb-2"> If your qualified credit card issuing bank refuses to process your EMI or No Cost EMI payment option transaction, SPORTNETWORK.IN will not be liable.</li>
                                    <li className="pb-2"> SPORTNETWORK.IN presents representative EMI and No Cost EMI related information (EMI amount, interest rate paid, and total amount payable) for your transaction on a "AS IS" basis, based on information given with SPORTNETWORK.IN by your qualifying credit card issuing bank. Your eligible credit card issuing bank is the authoritative source of this information, and you should contact your eligible credit card issuing bank if you have any questions.</li>
                                    <li className="pb-2"> For further information, we recommend reading the terms and conditions of your eligible credit card issuing bank that offers an EMI payment option.</li>
                                    <li className="pb-2"> For the purpose of arranging an EMI or No Cost EMI payment option, SPORTNETWORK.IN does not charge you any processing or convenience fees. Processing and/or convenience fees may be charged by your eligible credit card issuing bank, depending on the terms and conditions between you and your eligible credit card issuing bank.</li>
                                    <li className="pb-2"> We recommend that you contact your qualifying credit card issuing bank if you have a complaint, dispute, or inquiry regarding an EMI transaction. SPORTNETWORK.IN is not liable for the same.</li>
                                    <li className='pb-2'> SPORTNETWORK.IN may enable a refund if you cancel or return your product(s) purchased using an EMI or No Cost EMI payment option in accordance with SPORTNETWORK.IN's Sales Terms and Conditions. You should check with your qualified credit card issuing bank to see how cancellations or refunds would affect your EMI as well as any pre-closure or interest costs.</li>
                                    <li className='pb-2'> All orders will be subject to local sales tax, GST, and any other applicable taxes and levies, which you will be responsible for paying.</li>
                                    <li className='pb-2'> The EMI and No Cost EMI payment options may be combined with other SPORTNETWORK.IN promotions that are directed to individual end-user consumers and offered during the same promotional period on the SPORTNETWORK.IN Store Online, subject to the terms, conditions, and limitations applicable to this promotion and such other SPORTNETWORK.IN promotion(s).</li>
                                    <li className='pb-2'> The facilitation of the EMI payment option by SPORTNETWORK.IN is void where banned or limited by law.</li>
                                    <li className='pb-2'> SPORTNETWORK.IN reserves the right, without notice, to amend, modify, or discontinue the availability of an EMI payment option on the SPORTNETWORK.IN Store Online and Terms and Conditions. Such changes will not affect orders that have already been processed and are covered by this version of the Terms and Conditions. To use the EMI payment option on the SPORTNETWORK.IN Store Online, you must be at least 18 years old.</li>
                                </ol>
                            </div>
                            {/* <div className="">
                                <h2 className="small_heading black bold mt-4 text-underline mb-2">6. Anti Spam Policy</h2>
                                <p>You certify that You will use the Services in accordance with these Terms of Use. You are forbidden to transmit unsolicited commercial email (spam) by using the Services. Additionally, you are required to reconfirm (permission pass) or stop mailing an existing list when it is determined to be in violation of our anti-spam policy. Repeated violations or failures to comply with this anti-spam policy will result in termination of Your access to the Services. You agree and acknowledge that, we shall not be held liable for any claim that may arise due to Your violation of this anti-spam policy.</p>
                            </div>
                            <div className="">
                                <h2 className="small_heading black bold mt-4 text-underline mb-2">7. Prohibited Conduct</h2>
                                <p>You agree not to engage in any of the following activities:</p>
                                <ul>
                                    <li>Violating laws and rights:
                                        <p>You shall not (a) use the Portal for any illegal purpose or in violation of any local, state, national, or international laws, (b) violate or encourage others to violate any right of or obligation to a third party, including but not limited to, by infringing, misappropriating, or violating intellectual property, confidentiality, or privacy rights.</p>
                                    </li>
                                    <li>Solicitation:
                                        <p>You shall not use the Portal for the transmission of advertising or promotional materials, including junk mail, spam, chain letters, or any other form of unsolicited or unwelcome solicitation.</p>
                                    </li>
                                    <li>Disruption:
                                        <p>You shall not use the Portal in any manner that could disable, overburden, damage, or impair the Portal, or interfere with any other party’s use and enjoyment of the Portal; including by (a) uploading or otherwise disseminating any virus, adware, spyware, worm or other malicious code, or (b) interfering with or disrupting any network, equipment, or server connected to or used to provide any of the Services on the Portal, or violating any regulation, policy, or procedure of any network, equipment, or server.</p>
                                    </li>
                                    <li>Harming others:
                                        <p>You shall not share or transmit contents or details that are harmful, offensive, obscene, abusive, invasive of privacy, defamatory, hateful or otherwise discriminatory, false or misleading, or incites an illegal act.</p>
                                    </li>
                                    <li>Impersonation or unauthorized access:
                                        <p>You shall not impersonate another person or entity, or misrepresent your affiliation with a person or User, while using the Portal.</p>
                                        <p>You shall not use or attempt to use another’s account or personal information; and, you shall not attempt to gain unauthorized access to the Portal, or the computer systems or networks connected to the Portal, through hacking password mining or any other means.</p>
                                    </li>
                                </ul>
                            </div>
                            <div className="">
                                <h2 className="small_heading black bold mt-4 text-underline mb-2">8. Payment</h2>
                                <ol type="a">
                                    <li className="pb-2">Currently, the Portal is free of cost for the Users for accessing or buying the Products. However, the SNS reserves that right to levy certain fees or charges, to the Users, for the use of the Portal or certain features available at the Portal. You agree to pay any such fees, as may be applicable. You understand that the SNS may, at any time, charge, modify or waive fees required to use the Portal. Your continued use of the Portal after such change shall be considered to be Your acceptance of such changes, and the applicability of these Terms of Use to such changes.</li>
                                    <li className="pb-2">Prices for Products would be listed on the Portal and are incorporated into these Terms of Use by reference. All prices are exclusive of taxes unless stated otherwise and are in Indian rupees. Prices, Products and services are offered by the respective Seller and may change in accordance with the brand guidelines or other terms and conditions applicable to each Seller.</li>
                                    <li className="pb-2">Users can pay for the Products through various modes of payment:
                                        <ul>
                                            <li className="pb-2">Debit Card/Credit Card/ATM Card</li>
                                            <li className="pb-2">Net Banking</li>
                                            <li className="pb-2">E-Wallets</li>
                                            <li className="pb-2">Any other mode of payment as may be acceptable to SNS</li>
                                        </ul>
                                    </li>
                                    <li className="pb-2">Use of the payment facilities provided by the Portal shall not render the Portal liable or responsible for the non-delivery, non-receipt, non-payment, damage, breach of representations and warranties, non-provision of after sales or warranty services or fraud as regards the Products listed on the Portal. The Portal shall not be responsible for any damages, interests or claims arising from not processing a transaction. We should receive any payment made by credit card or any other mode as mentioned above on time. If for any reason We do not receive payment from the credit card issuer or any agent involved in any of the above payment modes, you agree that You shall pay all amounts due upon demand by Us.</li>
                                    <li className="pb-2">Every User hereby agrees to provide accurate information, such as credit/debit information for purchasing Products on the Portal.  Every User further warrants that he/she shall not use payment information or instrument that is not lawfully owned by the User. The Portal disclaims all liabilities arising out of loss of any information pertaining to the Confidentiality of the credit/debit card details or pre-paid instrument account. In addition to these Terms of Use, the terms and conditions of the bank or other financial institution shall also be applicable to every User. The Portal disclaims any liability arising out of declining of payment by such bank or financial institution.</li>
                                    <li className="pb-2">The Portal may in its sole discretion impose limits on the number of transaction which an individual holding a financial instrument may use for payment for Products.</li>
                                    <li className="pb-2">The Portal is merely a facilitator for providing the User with payment channels through automated online electronic payments (either itself or through Sellers), cash on delivery, collection and remittance facility for the payment of Products purchased by the User on the Portal using the existing authorized banking infrastructure and credit card payment gateway networks (of either the Portal or Sellers).</li>
                                </ol>
                            </div>      
                            <div className="">
                                <h2 className="small_heading black bold mt-4 text-underline mb-2">9. Return Policy and Cancellation Policy</h2>
                                <p>The Portal has a refund, return and cancellation policy which is detailed in <a href={window.location.origin+"/buyer-return-policy"} target="_blank">Buyer Return Policy</a></p>
                            </div>      
                            <div className="">
                                <h2 className="small_heading black bold mt-4 text-underline mb-2">10. Advertisements</h2>
                                <p>The Portal may post, display, publish or provide advertisements at various places on the Portal. If You access any of these advertisements the terms and conditions of those links will govern Your usage. These advertisements might set cookies on their websites which may use Your data, information for various other links. You are advised to refer to the relevant terms and conditions of the respective advertisements to know more about their practice. SNS does not endorse, recommend, market or warrant the advertisements, or quality, suitability, merchantability, fitness, commitment, reasonability of the products offered by such links. SNS shall not be responsible for any of these advertisements.</p>
                            </div>      
                            <div className="">
                                <h2 className="small_heading black bold mt-4 text-underline mb-2">11. Ownership of Intellectual Property</h2>
                                <ol type="a">
                                    <li className="pb-2">The Portal and all the rights including but not limited to intellectual property rights subsisting under or in relation to the Portal are owned by SNS and its affiliates, subsidiaries, licensors as the case may be. SNS respects copyright, and we prohibit Users from submitting, uploading, posting, or otherwise transmitting any Content or details on the Portal that violates another person’s proprietary rights.</li>
                                    <li className="pb-2">If You believe that the Portal contains elements that infringe Your intellectual property rights in Your work, please notify Us immediately. If we receive intellectual property right infringement claim notification, and it is felt appropriate, we may remove all such content which is indicated as infringing and/or take any other appropriate action at our discretion.</li>  
                                    <li className="pb-2">All materials on this Portal, including but not limited to audio, images, photographs, software, text, icons and such like (the “Portal Content”), are protected by copyright under the copyright laws or any other relevant intellectual property laws. You cannot use the Portal Content, except as specified herein.</li>
                                    <li className="pb-2">There may be proprietary logos, service marks and trademarks found on the Portal whether owned/used by us or otherwise. By displaying them on the Portal, we are not granting You any license to utilize those proprietary logos, service marks, or trademarks. Any unauthorized use of the Portal Content may violate copyright laws, trademark laws, the laws of privacy and publicity, and civil and criminal statutes.</li>
                                </ol>
                            </div>  
                            <div className="">
                                <h2 className="small_heading black bold mt-4 text-underline mb-2">12. Indemnification</h2>
                                <p>You agree to indemnify, defend and hold SNS, its partners, subsidiaries, affiliates, associates, successors, assigns and licensors or any of their respective officers, agents, vendors, licensors, representatives, advertisers, Sellers harmless from and against any and all claims, actions, losses, expenses, damages and costs (including attorneys' fees), arising out of Your use of the Portal or resulting from any i) breach or violation of these Terms of Use by You, or due to Your activities related to the Portal or due to any breach of applicable laws ii) any claims made by any third party due to, or arising out of, or in connection with, Your use of the Portal.</p>
                            </div>      
                            <div className="">
                                <h2 className="small_heading black bold mt-4 text-underline mb-2">13. Disclaimer and Limitations of Liability</h2>
                                <p>THE PORTAL, CONTENT, MATERIALS, PRODUCTS ARE MADE AVAILABLE TO YOU ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY REPRESENTATION OR WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, OR ANY GUARANTEE OR ASSURANCE THAT ALL FEATURES, FUNCTIONS OR OPERATIONS THEREOF WILL BE AVAILABLE. WITHOUT LIMITING THE FOREGOING, SNS SHALL NOT BE RESPONSIBLE OR LIABLE FOR ANY MALICIOUS CODE, DELAYS, INACCURACIES, ERRORS, OR OMISSIONS. YOU UNDERSTAND, ACKNOWLEDGE AND AGREE THAT YOU ARE ASSUMING THE ENTIRE RISK AS TO YOUR DATA, ITS QUALITY, ACCURACY, PERFORMANCE, TIMELINESS, ADEQUACY, COMPLETENESS, CORRECTNESS, AUTHENTICITY, SAFETY, SECURITY AND VALIDITY OF ANY AND ALL FEATURES AND FUNCTIONS OF THE PORTAL INCLUDING, WITHOUT LIMITATION, POSTINGS AND MATERIALS ASSOCIATED WITH YOUR USE OF THE PORTAL, OR YOUR OWN INFORMATION AND DATA THAT YOU VOLUNTARILY SHARE ON THE PORTAL.</p>
                                <p>YOU UNDERSTAND AND AGREE THAT, TO THE FULLEST EXTENT PERMISSIBLE BY LAW, THE PORTAL, SNS AND THEIR SUCCESSORS AND ASSIGNS, OR ANY OF THEIR AFFILIATES, OR THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, LICENSORS, REPRESENTATIVES, OPERATIONAL SELLERS, ADVERTISERS, OR VENDORS, SHALL NOT BE LIABLE FOR ANY LOSS OR DAMAGE, OF ANY KIND, DIRECT OR INDIRECT, IN CONNECTION WITH OR ARISING FROM USE OF THE PRODUCTS OR THE PORTAL, OR FOR THE ACCURACY, RELIABILITY OR CONTENT OF ANY INFORMATION, PRODUCTS PROVIDED THROUGH THE PORTAL, BUT NOT LIMITED TO, COMPENSATORY, CONSEQUENTIAL, INCIDENTAL, INDIRECT, SPECIAL OR PUNITIVE DAMAGES. SNS SHALL NOT BE RESPONSIBLE FOR THE DELAY, INTERRUPTION OR INABILITY TO USE THE PORTAL OR FAILURE TO PROVIDE THE PRODUCTS, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE. FURTHER, SNS SHALL NOT BE HELD RESPONSIBLE FOR NON-AVAILABILITY OF THE PORTAL DURING PERIODIC MAINTENANCE OPERATIONS OR ANY UNPLANNED SUSPENSION OF ACCESS TO THE PORTAL THAT MAY OCCUR DUE TO TECHNICAL REASONS OR FOR ANY REASON BEYOND SNS’S CONTROL.  NOTWITHSTANDING ANYTHING CONTAINED IN THIS AGREEMENT OR OTHERWISE, THE MAXIMUM LIABILITY OF SNS FOR DIRECT DAMAGES UNDER THIS AGREEMENT SHALL NOT EXCEED RUPEES THOUSAND (RS.1000/-).</p>
                            </div>              
                            <div className="">
                                <h2 className="small_heading black bold mt-4 text-underline mb-2">14. Force Majeure</h2>
                                <p>Without limiting the foregoing, under no circumstances shall SNS be held liable for any damage or loss, penalty due to delay or deficiency in performance of the Portal or availability of the Products,  resulting directly or indirectly from act of nature, natural forces, or causes beyond its reasonable control, including, without limitation, Internet failures, change in law, government regulations, computer equipment failures, telecommunication failures, major electricity failures, internet outage, downtime, floods, storms, accidents, civil disturbances, riots, strikes, shortages of labour, fluctuations in heat, light, fire or air.</p>
                            </div>
                            <div className="">
                                <h2 className="small_heading black bold mt-4 text-underline mb-2">15. Entire Agreement</h2>
                                <p>These Terms of Use along with the Privacy Policy contain the sole legal understanding and agreement between You and SNS and supersedes any and all prior understandings.</p>
                            </div>
                            <div className="">
                                <h2 className="small_heading black bold mt-4 text-underline mb-2">16. Notice</h2>
                                <p>All notices of SNS will be served by email or by general notification on the Portal. Any notice provided to SNS pursuant to these Terms of Use should be sent to support services, email ID of SNS or registered office address of SNS.</p>
                            </div>
                            <div className="">
                                <h2 className="small_heading black bold mt-4 text-underline mb-2">17. Changes</h2>
                                <p>We have and continue to have the right to modify, change, substitute, remove, suspend or update these Terms of Use or any information in it at any time by sufficiently highlighting on the Portal about such change. Such changes shall be effective immediately upon posting to the Portal. We assume no responsibility for functionality which is dependent on Your browser or other third party software to operate. For the avoidance of doubt, we may also withdraw any information from the Portals at any time.</p>
                            </div>
                            <div className="">
                                <h2 className="small_heading black bold mt-4 text-underline mb-2">18. Applicable Law</h2>
                                <p>These Terms of Use shall be governed by and interpreted and construed in accordance with the laws of India. The courts in Pune shall have exclusive jurisdiction in respect of any matters arising hereunder.</p>
                            </div>
                            <div className="">
                                <h2 className="small_heading black bold mt-4 text-underline mb-2">19. No Agency Relationship</h2>
                                <p>You agree that no joint venture, employment, or agency relationship exists between You and SNS as a result of these Terms of Use or from Your use of the Portal.</p>
                            </div>
                            <div className="">
                                <h2 className="small_heading black bold mt-4 text-underline mb-2">20. Severability</h2>
                                <p>If any part of these Terms of Use is held to be invalid or unenforceable by any law or regulation or final determination of a competent court or tribunal, that provision will be deemed severable and will not affect the validity and enforceability of the remaining provisions.</p>
                            </div> */}
                        </Container>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


export default NoCost;
