import React from 'react';
import {Container, Row, Col, Button, Card, Form} from 'react-bootstrap';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import CoreDealer from '../../../../core/components/dealer/CoreDealer';

class DealerProfile extends CoreDealer {		
	render(){
		const { t } = this.props;
		return (
			<React.Fragment> 
				<div className="main mt-5">
					<Container>		
						<Row>
							<Col lg={12}>
								<div className="mb-3">
									<h5 className="bold text-uppercase black text-left">{t('myProfile')}</h5>
								</div>
							</Col>	
						</Row>				
						<Row>
							<Col lg={4} className="mb-3 mb-lg-0">									
								<Card className="shadow-sm">
									<Card.Body>
										<Row className="mb-2">
											<Col lg={12}>
												<h5 className="black bold text-capitalize mb-0">{this.state.profile.name?this.state.profile.name:'-'}</h5>
											</Col>												
										</Row>
										<hr/>
										<Row className="mb-2">
											<Col sm={5}>
												<h6 className="">{t('contactNo')} :</h6>
											</Col>
											<Col sm={7}>
												<h6 className="black bold">{this.state.profile.mobile_no?this.state.profile.mobile_no:'-'}</h6>
											</Col>
										</Row>
										<Row className="mb-2">
											<Col sm={5}>
												<h6 className="">{t('email')} :</h6>
											</Col>
											<Col sm={7}>
												<h6 className="black bold">{this.state.profile.email_id?this.state.profile.email_id:'-'}</h6>
											</Col>
										</Row>											
									</Card.Body>
								</Card>
								
								{/* <div className="text-center mt-5">
									<Link to='/login'>
										<Button className="btn btn-md transparent mr-3">Back</Button>
									</Link>
									<Button className="btn btn-md" onClick={()=>this.registration()}>Submit</Button>
								</div> */}
							</Col>
							<Col lg={8}>									
								<Card className="shadow-sm">
									<Card.Body>
										<Row className="mb-2">
											<Col sm={4}>
												<h6 className="">Seller Classification :</h6>
											</Col>
											<Col sm={8}>
												<h6 className="black bold">{this.state.profile.classification_name}</h6>
											</Col>
										</Row>
										<Row className="mb-2">
											<Col sm={4}>
												<h6 className="">Total Credit Amount :</h6>
											</Col>
											<Col sm={8}>
												<h6 className="black bold"><i className="fal fa-rupee-sign"></i> {this.state.profile.total_credit_amount}</h6>
											</Col>
										</Row>
										<Row className="mb-2">
											<Col sm={4}>
												<h6 className="">Trading / Company Name :</h6>
											</Col>
											<Col sm={8}>
												<h6 className="black bold">{this.state.profile.trading_name}</h6>
											</Col>
										</Row>
										<Row className="mb-2">
											<Col sm={4}>
												<h6 className="">{t('billingAddress')} :</h6>
											</Col>
											<Col sm={8}>
												<h6 className="black bold">{this.state.profile.billing_address?this.state.profile.billing_address:'-'}</h6>
											</Col>
										</Row>
										<Row className="mb-2">
											<Col sm={4}>
												<h6 className="">{t('deliveryAddress')} :</h6>
											</Col>
											<Col sm={8}>
												<h6 className="black bold">{this.state.profile.delivery_address?this.state.profile.delivery_address:'-'}</h6>
											</Col>
										</Row>
										<Row className="mb-2">
											<Col sm={4}>
												<h6 className="">{t('gst')} :</h6>
											</Col>
											<Col sm={8}>
												<h6 className="black bold">{this.state.profile.gst_number?this.state.profile.gst_number:'-'}</h6>
											</Col>
										</Row>
										<Row className="mb-2">
											<Col sm={4}>
												<h6 className="">Country :</h6>
											</Col>
											<Col sm={8}>
												<h6 className="black bold">{this.state.profile.country}</h6>
											</Col>
										</Row>
										<Row className="mb-2">
											<Col sm={4}>
												<h6 className="">State :</h6>
											</Col>
											<Col sm={8}>
												<h6 className="black bold">{this.state.profile.state}</h6>
											</Col>
										</Row>
										<Row className="mb-2">
											<Col sm={4}>
												<h6 className="">City :</h6>
											</Col>
											<Col sm={8}>
												<h6 className="black bold">{this.state.profile.city}</h6>
											</Col>
										</Row>
										<Row className="mb-2">
											<Col sm={4}>
												<h6 className="">{t('pinCode')} :</h6>
											</Col>
											<Col sm={8}>
												<h6 className="black bold">{this.state.profile.pin_code?this.state.profile.pin_code:'-'}</h6>
											</Col>
										</Row>
										<hr/>											
										<Row className="mb-2">
											<Col sm={4}>
												<h6 className="">Categories :</h6>
											</Col>
											<Col sm={8}>
												<h6 className="black bold">{this.state.profile.category_name}</h6>
											</Col>
										</Row>
										<Row className="mb-2">
											<Col sm={4}>
												<h6 className="">Brands :</h6>
											</Col>
											<Col sm={8}>
												<h6 className="black bold">{this.state.profile.brand_name}</h6>
											</Col>
										</Row>
									</Card.Body>
								</Card>
							</Col>
						</Row>

					</Container>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
  return {
    user: state.users.user,
  }
}

export default withTranslation()(connect(mapStateToProps)(DealerProfile));