import React from 'react';

import MasterProduct from './MasterProduct';
import {
	getProductListing, getImageContent, getCatalogListing,
	getProductTypeFilteList, getItemVariations,
	getCategoryClassification, getProductTypevariationFilteList, selectTags
} from '../../helpers/ServicesHelper';
import { getImageUrl, getImagePath, getParentRoute, dealersPortal, mobile } from '../../helpers/OperationsHelper';
import { getBreadcrumbsArray } from '../../helpers/BreadcrumbsHelper';
import queryString from 'query-string';
class CoreProductListing extends MasterProduct {
	constructor(props) {
		super(props);
		console.log(props);
		this.state = {
			compareProducts: [],
			products: [],
			images: {},
			totalProducts: 0,
			pageNo: 1,
			remark: '',
			maxPageNo: 0,
			inventories: [],
			filters: [],
			variationFilters: [],
			isLoading: true,
			productTypeFilters: [],
			productTypeVariationFilters: [],
			sortBy: "",
			sortByName: '',
			sortIn: '',
			loader: true,
			quantity: 1,
			selectedVariations: {},
			selectedVariationsNames: {},
			variations: [],
			allImages: [],
			errors: [],
			showVariationModal: false,
			productId: '',
			loadMoreReset: false,
			productName: '',
			isSortPopupVisible: false,
			cartButtonLoader: false,
			cartListButtonLoader: {},
			wishlistButtonLoader: false,
			wishlistListButtonLoader: false,
			breadcrumbs: [],
			buttonIcon: 3,
			imageLoading: [],
			active: true,
			clasifications: [],
			categoryClassification: '',
			concatValues: '',
			redirectUrl: '',
			loadMoreLoader: false,
			quantities: {},
			inventories: [],
			shareUrl: '',
			isFetching: false,
			productNumColums: 3,
			popupFor: 1,
			tags: [],
			appliedSearch: false,
			selectedTag: '',
			showingResultFor: '',
			setIsSearchVisible: false,
			variationSkuCombinations: [],
			selectedVariationKey: '',
			selectVariationSkuCode: '',
		}
		this.images = {};
	}

	// when component is rendered
	async componentDidMount() {
		window.scrollTo(0, 0);
		var getPropsValue = queryString.parse(this.props.location.search);
		if (typeof getPropsValue.q != 'undefined') {
			await this.setState({
				appliedSearch: true,
				selectedTag: getPropsValue.q,
				showingResultFor: getPropsValue.q,
				setIsSearchVisible: true
			})
		}
		// if(this.props.match.url == 'cpl')
		// {
		// 	await this.getCatalog();
		// }
		var parentRoute = await getParentRoute(this.props.match.url);
		if (parentRoute == 'cpl') {
			await this.getCatalog();
			await this.getBreadcrumbs();
			await this.getProductTypeFields();    //api on page load
			await this.getProductTypevariationFilteList();
		}
		else {
			await this.getProductRelatedData();
			await this.getCompareProducts();     //api on page load
			await this.selectTags();             //api on page load
		}

		window.history.pushState(null, null, window.location.pathname);
	}

	//api on page load
	async selectTags() {
		var response = await selectTags();
		if (response.data.status == 'success') {
			this.setState({ 'tags': response.data.data })
		}
	}

	// async getCatalog()
	// {

	// }
	async getProductRelatedData() {
		//api on page load
		await this.getProductListing();
		await this.getProductTypeFields();    //api on page load
		await this.getProductTypevariationFilteList();    //api on page load 
		await this.getBreadcrumbs();         //api on page load
	}

	//when route of the page is updated
	componentDidUpdate(nextProps, nextState) {
		if ((nextProps.match.url != this.props.match.url)
			|| (nextProps.location.search != this.props.location.search)) {
			this.resetFilters();
			window.scrollTo(0, 0);
			this.getProductRelatedData();
		}
	}

	//no api call
	resetFilters() {
		var checkItem = document.getElementsByClassName('customCheck');
		for (var i = 0; i < checkItem.length; i++) {
			if (checkItem[i].type == 'checkbox')
				checkItem[i].checked = false;
		}
		window.history.pushState(null, null, window.location.pathname);
		this.setState({
			products: [],
			isLoading: true,
			pageNo: 1,
			variationFilters: [],
			filters: []
		})
	}

	//api on page load
	async getProductTypeFields() {
		var where = await this.getProductTypeFieldsWhereConditions();
		await getProductTypeFilteList(where).then((res) => {
			this.setState({ productTypeFilters: res.data.data });
			this.props.setFilters(res.data.data);
		})
	}

	//api on page load
	async getProductTypevariationFilteList() {
		var where = await this.getProductTypeFieldsWhereConditions();
		await getProductTypevariationFilteList(where).then((res) => {
			this.setState({ productTypeVariationFilters: res.data.data });
			this.props.setVariationFilters(res.data.data);
		})
	}

	//api on page load
	async getBreadcrumbs() {
		var breadcrumbs = await getBreadcrumbsArray(this.props.match);
		console.log(this.props.match);
		this.setState({ breadcrumbs: breadcrumbs });
	}

	//on click of product tile on listing page
	async getItemVariations(productId, type = 1, isDelete = 0) {
		if (this.props.settings.remarkPopupEnable == 'Yes' && isDelete != 1 && type == 1) {
			this.setState({ showVariationModal: true, popupFor: type, quantity: 1 });
		} else {
			var response = await getItemVariations(productId);
			if (response.data.status == 'success' && typeof response.data.data.variations != 'undefined') {
				if (Object.keys(response.data.data.variations).length > 0) {
					var selectedVariations = this.state.selectedVariations;
					var selectedVariationsNames = this.state.selectedVariationsNames;
					var selectedVariationKey = '';
					var selectVariationSkuCode = '';
					for (var i = 0; i < response.data.data.variations.length; i++) {
						response.data.data.variations[i].options.map((value, index) => {
							if (index === 0) {
								selectedVariations[response.data.data.variations[i].name] = value.sku_no;
								selectedVariationsNames[response.data.data.variations[i].name] = value.value;
								selectedVariationKey = response.data.data.variations[i].name;
								selectVariationSkuCode = value.sku_no;
							}
						})
					}
					var arrayValues = [], concatValues = "";
					Object.keys(selectedVariations).map((key, index) => {
						arrayValues[index] = this.state.selectedVariations[key];
					})
					var concatValues = arrayValues.join('-');
					this.setState({
						variations: response.data.data.variations,
						variationSkuCombinations: response.data.data.variation_sku_combinations,
						selectedVariationKey: selectedVariationKey,
						selectVariationSkuCode: selectVariationSkuCode,
						selectedVariations: selectedVariations,
						selectedVariationsNames: selectedVariationsNames,
						showVariationModal: true,
						quantity: 1,
						popupFor: type,
						concatValues: concatValues
					});
				} else {
					if (type == 1)
						this.addRemoveProductFromCart(productId, isDelete);
					else
						this.addRemoveProductFromWishlist(productId, isDelete);
				}
			}
		}
	}

	//no api call
	handleImageLoaded(productId) {
		var imageLoading = this.state.imageLoading;
		imageLoading[productId] = false;
		this.setState({ imageLoading: imageLoading })
	}

	//when filter value is changed
	async changeFilters(values) {
		await this.setState({ 'filters': values, pageNo: 1, isLoading: true })
		if (this.state.filters.length > 0) {
			this.setState({ images: [] });
			this.getProductListing(true);
		}
	}

	//when variation filter value is changed
	async changeVariationFilters(values) {
		await this.setState({ pageNo: 1, isLoading: true })
		if (this.state.variationFilters.length > 0) {
			this.setState({ images: [] });
			this.getProductListing(true);
		}
	}

	//opn change of sort
	async changeSort(values, sortByName, sortIn) {
		await this.setState({ 'sortBy': values, sortByName: sortByName, isLoading: true, pageNo: 1, sortIn: sortIn, isSortPopupVisible: false })
		if (this.state.sortBy != "")
			this.getProductListing(true);
	}

	//no api
	async changeProductTypeFilter(values) {
		await this.setState({ 'productTypeFilters': values })
	}

	//no api
	async changeProductTypeVariationFilter(values) {
		await this.setState({ 'productTypeVariationFilters': values })
	}

	//api on page load

	async getCatalog(filterApplied = false) {
		console.log("in get catalog");
		const paramOne = this.props.match.params.paramOne;
		if (typeof paramOne != 'undefined') {
			var where = `catalog_id=${paramOne}&page_no=1`;
			// where += '&where[fl_item_categories.category_slug]=' + paramOne;
		}
		// var where =' catalog_id=10&page_no=1';
		// var where = await this.getWhereConditions(filterApplied);
		console.log(where);
		var products = await getCatalogListing(where);
		console.log(products);
		if (products.data.status == 'success') {
			if (filterApplied == false) {
				console.log(products.data.data);
				await this.setState({ products: this.state.products.concat(products.data.data), 'isLoading': false, loader: false, 'loadMoreLoader': false, isFetching: false });
				this.props.setProducts(this.state.products)
			}
			else {
				await this.setState({ pageNo: 1, products: products.data.data, 'isLoading': false, loader: false, 'loadMoreLoader': false, isFetching: false });
				this.props.setProducts(this.state.products)
			}

			if (filterApplied == true || this.state.pageNo == 1) {
				var maxPageNo = Math.ceil(products.data.count / 12);
				this.setState({ 'maxPageNo': parseInt(maxPageNo), 'totalProducts': products.data.count, pageNo: 1 });
			}
			this.getBase64Images(products.data.data);
		}
	}

	async getCatalogOnLoad(where) {
		console.log(where);
		// const paramOne = this.props.match.params.paramOne;
		// if (typeof paramOne != 'undefined') {
		// 	var where = `catalog_id=${paramOne}&page_no=1`;
		// 	// where += '&where[fl_item_categories.category_slug]=' + paramOne;
		// }
		// var where =' catalog_id=10&page_no=1';
		// var where = await this.getWhereConditions(filterApplied);
		// console.log(where);
		var products = await getCatalogListing(where);
		console.log(products);
		var filterApplied = false;
		if (products.data.status == 'success') {
			if (filterApplied == false) {
				console.log(products.data.data);
				await this.setState({ products: this.state.products.concat(products.data.data), 'isLoading': false, loader: false, 'loadMoreLoader': false, isFetching: false });
				this.props.setProducts(this.state.products)
			}
			else {
				await this.setState({ pageNo: 1, products: products.data.data, 'isLoading': false, loader: false, 'loadMoreLoader': false, isFetching: false });
				this.props.setProducts(this.state.products)
			}

			if (filterApplied == true || this.state.pageNo == 1) {
				var maxPageNo = Math.ceil(products.data.count / 12);
				this.setState({ 'maxPageNo': parseInt(maxPageNo), 'totalProducts': products.data.count, pageNo: 1 });
			}
			this.getBase64Images(products.data.data);
		}
	}

	async getProductListing(filterApplied = false) {
		var where = await this.getWhereConditions(filterApplied);
		console.log(where);
		var parentRoute = await getParentRoute(this.props.match.url);
		const paramOne = this.props.match.params.paramOne;
		if (parentRoute === 'cpl') {
			var products = await getCatalogListing(where);
			console.log(products);
			if (products.data.status == 'success') {
				if (filterApplied == false) {
					console.log(products.data.data);
					console.log(this.state.products);
					await this.setState({ products: this.state.products.concat(products.data.data), 'isLoading': false, loader: false, 'loadMoreLoader': false, isFetching: false });
					console.log(this.state.products);
					this.props.setProducts(this.state.products)
				}
				else {
					await this.setState({ pageNo: 1, products: products.data.data, 'isLoading': false, loader: false, 'loadMoreLoader': false, isFetching: false });
					this.props.setProducts(this.state.products)
				}

				if (filterApplied == true || this.state.pageNo == 1) {
					var maxPageNo = Math.ceil(products.data.count / 12);
					this.setState({ 'maxPageNo': parseInt(maxPageNo), 'totalProducts': products.data.count, pageNo: 1 });
				}
				this.getBase64Images(products.data.data);
			}
		}
		else {
			console.log('product listing loader');

			var products = await getProductListing(where);
			console.log(products);
			if (products.data.status == 'success') {
				console.log('under success');
				if (filterApplied == false) {
					console.log('under filter applied');
					await this.setState({ products: this.state.products.concat(products.data.data), 'isLoading': false, loader: false, 'loadMoreLoader': false, isFetching: false });
					this.props.setProducts(this.state.products)
				}
				else {
					console.log('under non- filter applied');
					await this.setState({ pageNo: 1, products: products.data.data, 'isLoading': false, loader: false, 'loadMoreLoader': false, isFetching: false });
					this.props.setProducts(this.state.products)
				}

				if (filterApplied == true || this.state.pageNo == 1) {
					var maxPageNo = Math.ceil(products.data.count / 12);
					this.setState({ 'maxPageNo': parseInt(maxPageNo), 'totalProducts': products.data.count, pageNo: 1 });
				}
				this.getBase64Images(products.data.data);
			}
		}
	}

	//no api call (for product list item)
	async getWhereConditions(getWhereConditions = false) {
		var where = '';
		var parentRoute = await getParentRoute(this.props.match.url);
		const paramOne = this.props.match.params.paramOne;
		console.log(this.props.match.url);
		var getCatalogID = this.props.match.url.split('/');
		console.log(getCatalogID[2]);

		console.log(parentRoute);


		if (parentRoute == 'bpl') {
			if (typeof paramOne != 'undefined')
				where += '&where[brands.slug]=' + paramOne;
		}

		if (parentRoute == 'cpl') {

			where += `catalog_id=${getCatalogID[2]}`;
		}

		// console.log(where);

		if (dealersPortal)
			where += '&dealer=1';
		if (this.state.filters.length > 0)
			where += '&productTypeFilter=' + JSON.stringify(this.state.filters);

		if (this.state.variationFilters.length > 0)
			where += '&productTypeVariationFilter=' + JSON.stringify(this.state.variationFilters);

		if (this.state.categoryClassification != "") {
			where += '&where[fl_items.category_classification]=' + this.state.categoryClassification.replace('+', "%2B")
		}

		const paramTwo = this.props.match.params.paramTwo;

		const paramThree = this.props.match.params.paramThree;

		// var where = '';

		if (parentRoute == 'pl') {
			if (typeof paramOne != 'undefined')
				where += '&where[fl_item_categories.category_slug]=' + paramOne;

			if (typeof paramTwo != 'undefined')
				where += '&where[fl_item_categories.sub_category_slug]=' + paramTwo;

			if (typeof paramThree != 'undefined')
				where += '&where[fl_item_categories.sub_sub_category_slug]=' + paramThree;
		}

		if (parentRoute == 'bpl') {
			if (typeof paramOne != 'undefined')
				where += '&where[brands.slug]=' + paramOne;
		}


		if (dealersPortal)
			where += '&dealer=1';
		if (this.state.filters.length > 0)
			where += '&productTypeFilter=' + JSON.stringify(this.state.filters);

		if (this.state.variationFilters.length > 0)
			where += '&productTypeVariationFilter=' + JSON.stringify(this.state.variationFilters);

		if (this.state.categoryClassification != "") {
			where += '&where[fl_items.category_classification]=' + this.state.categoryClassification.replace('+', "%2B")
		}

		if (this.state.selectedTag != "" && this.state.appliedSearch == true) {
			where += '&tagName=' + this.state.selectedTag
		}

		console.log(where);
		if (this.state.sortBy != "")
			where += this.state.sortBy;
		if (getWhereConditions == true) {
			this.setState({ pageNo: 1 })
			where += '&page_no=1';
		} else
			where += '&page_no=' + this.state.pageNo;
		return where;
	}

	//no api call (for product filter data)
	async getProductTypeFieldsWhereConditions() {
		var parentRoute = await getParentRoute(this.props.match.url);

		const paramOne = this.props.match.params.paramOne;

		const paramTwo = this.props.match.params.paramTwo;

		const paramThree = this.props.match.params.paramThree;

		var where = '';

		if (parentRoute == 'pl') {
			if (typeof paramOne != 'undefined')
				where += '&category_slug=' + paramOne;

			if (typeof paramTwo != 'undefined')
				where += '&sub_category_slug=' + paramTwo;

			if (typeof paramThree != 'undefined')
				where += '&sub_sub_category_slug=' + paramThree;
		}

		if (parentRoute == 'bpl') {
			if (typeof paramOne != 'undefined')
				where += '&brand_slug=' + paramOne;
		}

		return where;
	}

	//api on click of load more button
	async loadMoreProducts() {
		// let url = window.location.href;
		// let ar = url.split('/');
		// if (ar[3] === "cpl") {
		// 	// console.log('in catalog listing');
		// 	await this.setState({ 'pageNo': parseInt(this.state.pageNo + 1), 'loadMoreLoader': true });
		// 	var where = `catalog_id=${ar[4]}&page_no=${this.state.pageNo}`;
		// 	await this.getCatalogOnLoad(where);
		// }
		// else {
		// 	// console.log('in normal  product listing');

		// 	await this.getProductListing();
		// }
		// console.log(ar[4]);

		await this.setState({ 'pageNo': parseInt(this.state.pageNo + 1), 'loadMoreLoader': true });
		await this.getProductListing();
	}

	//no api call
	async getBase64Images(products) {
		products.map((value, index) => {
			if (typeof value.name != 'undefined' && value.name != null) {
				// this.setImageInState(value.id,value.name);
				var medium_image = this.props.settings.image_url + 'products/images/medium/' + value.name;
				this.setImageInState(medium_image, value.id);
			} else {
				this.setImageInState(this.props.settings.image_url + '/application_images/original/' + this.props.settings.error_image, value.id);
			}
		})
	}

	// no api call
	async setImageInState(base64, id) {
		this.images = this.state.images;
		this.images[id] = base64;
		if (Object.keys(this.images).length == this.state.products.length) {
			this.setState({ images: this.images });
		}
	}

	//no api call
	async setCurrentProductParams(productId, productName) {
		this.setState({ productId: productId, productName: productName });
	}

	//no api call
	getFilterData = async () => {
		if (typeof this.props.filters != 'undefined' && this.props.filters.length > 0) {
			var filters = this.state.filters;
			this.props.filters.map((productTypeFilter, index) => {
				if (productTypeFilter.selected_values != "") {
					if (productTypeFilter.type == 'range') {
						if ((productTypeFilter.selected_values['min_value'] != productTypeFilter.values['min_value']) ||
							(productTypeFilter.selected_values['max_value'] != productTypeFilter.values['max_value'])) {
							filters[index] = {
								'type': productTypeFilter.type,
								'name': productTypeFilter.name,
								'values': productTypeFilter.selected_values
							};
						} else {
							delete filters[index];
						}
					} else {
						filters[index] = {
							'type': productTypeFilter.type,
							'name': productTypeFilter.name,
							'values': productTypeFilter.selected_values
						};
					}
				}
			});
		}
	}


	//no api
	async handleOnSelect(item) {
		await this.setState({ selectedTag: item })
	}


	// api call check search tag match or not
	async SearchItem() {
		if (this.state.selectedTag != "") {
			await this.setState({ 'appliedSearch': true })
			if (this.state.showingResultFor != this.state.selectedTag) {
				this.setState({ showingResultFor: this.state.selectedTag })
				this.props.history.push('/pl?q=' + this.state.selectedTag, { q: this.state.selectedTag });
			}
		} else {
			this.getProductListing(true);
			this.setState({ showingResultFor: '' })
		}
	}

	//no api
	async handleChange(item, result) {
		this.setState({ 'selectedTag': '' })
		if (item.split('').length > 1)
			this.setState({ 'selectedTag': item })
	}

	//api on click of clear search link
	clearSearch() {
		this.setState({ 'appliedSearch': false, showingResultFor: '', selectedTag: '' })
		this.getProductListing(true);
	}
}

export default CoreProductListing;