import React from 'react';
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import { getApiUrl , getMobileToast ,mobile} from '../../helpers/OperationsHelper.js';
import { storeCart, storeWishList, updateWishList, updateCart, addCompareProduct , 
	       getCompareProducts, deleteCompareProduct, addRemoveCatalogProduct, deleteCart, deleteWishList, getCartProducts, getWishlistProducts, getCatalogProducts, storeBulkCart, getItemInventory } from '../../helpers/ServicesHelper';

class MasterProduct extends React.Component {

	// on click of product cart button
	async getItemInventory(id, showModal=0 ,type=1, isDelete,cartAction=1) {
		if(this.props.settings.remarkPopupEnable == 'Yes' && isDelete != 1 && cartAction == 1){
			this.setState({ showVariationModal: true,popupFor:type,quantity:1 });
		}else{
	    var response = await getItemInventory(id);
	    if (response.data.status == 'success') {
	    	if (response.data.data.length > 0 ) {
	    		if (showModal == 1) 
	    			this.setState({ showVariationModal: true,popupFor:type,quantity:1 });	
	    		
	  			var quantities = this.state.quantities;
	    		response.data.data.map((inventory, index) => {
	    			quantities[inventory.id] = inventory.quantity;
	    		})
	    		this.setState({ inventories: response.data.data, quantities: quantities });
	    	} else {
	    		if(cartAction == 1)
	    			this.addRemoveProductFromCart(id, isDelete);
	    	}	    	
	    } 
	  }
  } 

  // on click of product increment btn
	changeQuantity = (quantity) => {
		if(quantity >= 1)
			this.setState({quantity})
  } 

  //no api call
  async setQuantities(value, id) {
    var quantities = this.state.quantities;
    quantities[id] = value;
    this.setState({ quantities: quantities });
  }

  //on click even when click on single variation
	selectvariation = async(name,  variationValue, changeImage=1, variationName) => {
		this.setState({ errors: [] });
		var selectedVariations = this.state.selectedVariations;
		selectedVariations[name] = variationValue;
		await this.setState({selectedVariations, selectVariationSkuCode: variationValue});
		if (name.toLowerCase() == 'color' && changeImage == 1) {
			this.getColorImages(this.state.allImages,variationValue)
		}
		this.checkVariationModel();
		this.selectvariationname(name, variationName);
	}

	// no api call
	selectvariationname = async(name,  variationValue) => {
		var selectedVariationsNames = this.state.selectedVariationsNames;
		selectedVariationsNames[name] = variationValue;
		await this.setState({selectedVariationsNames, selectedVariationKey: name});
		console.log(selectedVariationsNames, name, this.state.selectVariationSkuCode);
	}

	// no api call
	async checkVariationModel(){
		const {inventories ,selectedVariations,productDetail} = this.state
		var arrayValues = [],concatValues="";
		Object.keys(selectedVariations).map((key,index)=>{
			arrayValues[index] = this.state.selectedVariations[key];
		})
		var concatValues = arrayValues.join('-');
		this.setState({concatValues});
		if(typeof productDetail != 'undefined')
			this.setState({'modelNo':productDetail.item_code})
		inventories.map((invenotry,index)=>{
			if(invenotry.system_skucode == concatValues){
				if(invenotry.model_no != "" && invenotry.model_no != null)
					this.setState({'modelNo':invenotry.model_no})
				else if(typeof productDetail != 'undefined'){
					this.setState({'modelNo':productDetail.item_code})
				}
			}
		})
	}

	// no api call
	async getColorImages(images,color){
  	var imageArray = [];
    var length = Object.keys(images).length;
    var count = 0;
    var modelNo = this.state.productDetail.item_code;
		for (var i = 0; i < length; i++) {
			console.log(color,images[i].color)
			if(color == images[i].color || images[i].color == null){
		  	imageArray[count] = { original: images[i].original , thumbnail: images[i].thumbnail, 'name': images[i].name,color:images[i].sku_no,color:images[i].model_no};
		  	count++;
		  }
		}	

		if(imageArray.length <= 0)
			imageArray = this.state.allImages;
	 	this.setState({'images':imageArray,modelNo:modelNo});
  }

	
  //on click of compare product
 	addCompareProduct = async(item_id) => {
  	var formdata = [];
  	this.setState({'errors':[]})
		formdata['compares[item_id]'] = item_id;
		formdata['compares[status]'] = 0;
		var response = await addCompareProduct(formdata);
		if(response.data.status == 'success')
			this.getCompareProducts();
		else {
			this.setState({'errors':response.data.errors})
			this.errorToast(response.data.errors['compares[item_id]']);
		}

  }

  //api call for compare product
	getCompareProducts = async() => {
		var compareProducts = await getCompareProducts();
		if(typeof compareProducts.data.data.products !='undefined' && compareProducts.data.status == 'success' 
			 && compareProducts.data.data.products.length > 0){
			this.setState({compareProducts:compareProducts.data.data.products})
		}else{
			this.setState({compareProducts:[]})
		}
	}

	//when click on remove compare product
	removeCompareItem = async(compare_id) => {
		await deleteCompareProduct(compare_id);
		await this.getCompareProducts();
	}

	//when click on remove all compare product
	removeAllCompareItem = async() => {
		this.state.compareProducts.map(async(compare_item,index)=>{
			await this.removeCompareItem(compare_item.id);
		});
	}	

	async getCatalogProducts(catalogId) {
		var catalogProducts = await getCatalogProducts(catalogId);
		if (catalogProducts.data.status == 'success') {
      this.props.setCatalogCount(Object.keys(catalogProducts.data.data).length);
      this.props.setCatalogProducts(catalogProducts.data.data);
		}
	}

	async addRemoveProductFromCatalog(productId,catalogId,id=""){
		var formdata = [];
  	formdata['catalog_products[catalog_id]'] = catalogId;
  	formdata['catalog_products[item_id]'] = productId;
  	formdata['catalog_products[id]'] = id;
  	if(id != ""){
  		formdata['catalog_products[is_delete]'] = 1;
  	}else{
  		formdata['catalog_products[is_delete]'] = 0;
  	}
  	var response = await addRemoveCatalogProduct(formdata,id);
  	if(response.data.status == 'success'){
  		await this.getCatalogProducts(catalogId);
  	}
  }
 
	//api call for cart product
  async getCartProducts() {
		var cartProducts = await getCartProducts();
		if (cartProducts.data.status == 'success') {
      this.props.setCartCount(cartProducts.data.count);
      this.props.setCartProducts(cartProducts.data.data);
		} 
	}

	// on click of add to cart btn from detail
	async addRemoveProductIntoCartFromDetail(productName,type){
  	this.setState({ showVariationModal: true,popupFor:type,productName: productName});
  }

  // on click of add to cart btn from listing
  async addRemoveProductFromCart(productId, isDelete=0){
  	this.setState({ cartButtonLoader: true});
  	this.setState({ cartListButtonLoader: {item_id:productId,loading:true} }); 
  	this.handleAddToCart(productId, isDelete);
  }

  //no api call
  handleAddToCart = async (productId, isDelete) => {
		await this.setCartFromdata(productId, isDelete);
  }

  //no api call
  setCartFromdata = async (itemId, isDelete) => {
		var formdata = [];
		formdata['carts[cart]'] = 1;
		formdata['carts[remark]'] = this.state.remark;
		formdata['carts[action_type]'] = 2;
		formdata['carts[quantity]'] = this.state.quantity;
		formdata['carts[item_id]'] = itemId;
		formdata['carts[variation_combination]'] = this.state.concatValues;
		if (isDelete == 1) {
			formdata['carts[delete]'] = 1;
		}
		this.storeCart(formdata);
	}

		// on click of add to cart for bulk
	setBulkCartFromdata = async (id) => {
		var count = 0;
		var formdata = [];
    formdata['bulk_carts[item_id]'] = id;

    this.state.inventories.map((inventory, index) => {
    	if (typeof this.state.quantities[inventory.id] != 'undefined' && this.state.quantities[inventory.id] > 0 && this.state.quantities[inventory.id] != '') {
    		count++;
	    	formdata['bulk_cart_details['+index+'][quantity]'] = this.state.quantities[inventory.id];
	      formdata['bulk_cart_details['+index+'][action_type]'] = 2;
	      formdata['bulk_cart_details['+index+'][cart]'] = 1;
	      formdata['bulk_cart_details['+index+'][variation_combination]'] = inventory.system_skucode;
    	}
    })
    
    if (count > 0) 
			this.storeBulkCart(formdata);
		else
			this.errorToast('Please enter valid quantity.');
	}

	//on click of move to cart 
	async moveToCart(id) {
  	return await this.setMoveToCartFormdata(id);	
  }

  //no api call
  setMoveToCartFormdata = async (id) => {
  	var formdata = [];
		formdata['carts[id]'] = id;
		formdata['carts[action_type]'] = 2;
		formdata['carts[remark]'] = this.state.remark;
		return this.updateCart(formdata, id);
  }

  //api call
	storeCart = async(formdata) => {
		var response = await storeCart(formdata);
		this.setState({remark:""})
		this.storeCartResponse(response);
	}

	//api call
	storeBulkCart = async (formdata) => {
    var response = await storeBulkCart(formdata);
    this.storeBulkCartResponse(response);
  }

  //api call
	updateCart = async(formdata, id) => {
		var response = await updateCart(formdata, id);
		this.updateCartResponse(response, id);
		return response;
	}

	//on click of delete btn in cartlist
	deleteCart = async (id, showToast=1) => {
		this.setState({ errors: [] });
		var response = await deleteCart(id);
		this.deleteCartResponse(response, showToast);
		return response;
	}	

	//no api call
	storeCartResponse = async (response,itemId) => {
		this.setState({errors:[]});
		if(response.data.status === 'error'){
			if(response.data.errors['carts[quantity]'] != ''){
				await this.errorToast(response.data.errors['carts[quantity]']);	
			}
			this.setState({'errors':response.data.errors});
		}
		else{
			this.setState({ showVariationModal: false });
			await this.successToast('Cart updated successfully');
			/*this.props.increaseCartCount();*/
			this.setCartProducts(response.data); 
		}
		this.setState({ cartButtonLoader: false });
		this.setState({ cartListButtonLoader: {} });
	}

	//no api call
	storeBulkCartResponse = async (response) => {
		this.setState({errors:[]});
		if(response.data.status === 'error'){
			if(response.data.errors['bulk_carts[item_id]'] != ''){
				await this.errorToast(response.data.errors['bulk_carts[item_id]']);	
			}
			this.setState({'errors':response.data.errors});
		}
		else{
			this.setState({ showVariationModal: false });
			await this.successToast('Cart updated successfully.');
			/*this.props.increaseCartCount();*/
			this.setCartProducts(response.data); 
		}
		this.setState({ cartButtonLoader: false });

	}

	//no api call
	updateCartResponse = async (response, id) => {
		this.setState({errors:[]});
		if(response.data.status === 'error'){
			if(response.data.errors['carts[quantity]'] != ''){
				await this.errorToast(response.data.errors['carts[quantity]']);	
			}
			this.setState({'errors':response.data.errors});
		}
		else{
			this.setState({   remark: '' });
			await this.successToast('Cart updated successfully.');
			this.setCartProducts(response.data); 
		}
		this.setState({ cartButtonLoader: false,showVariationModal: false,moveToCartModalVisibility: false });
	}

	//no api call
	deleteCartResponse = async (response, showToast=1) => {
		this.setState({errors:[]});
		if(response.data.status === 'error'){
			if(response.data.errors['carts[quantity]'] != ''){
				await this.errorToast(response.data.errors['carts[quantity]']);	
			}
			this.setState({'errors':response.data.errors});
		}
		else{
			if (showToast ==1) {
				await this.successToast('Product removed from cart');
			}
			this.setState({ showVariationModal: false });
			/*this.props.decreaseCartCount();*/
			await this.getCartProducts();
		}
		this.setState({ cartButtonLoader: false });
	}


	//no api call
	setCartProducts = async (cartProducts) => {
		this.props.setCartCount(cartProducts.count);
    this.props.setCartProducts(cartProducts.data);
    await this.getWishlistProducts();
		this.setState({ cartButtonLoader: false,cartListButtonLoader:{},wishlistButtonLoader:false });
	}

	//api call
	async getWishlistProducts() {
		var wishlistProducts = await getWishlistProducts();
		if (wishlistProducts.data.status == 'success') {
      this.props.setWishListCount(wishlistProducts.data.count);
      this.props.setWishListProducts(wishlistProducts.data.data);
		} 
	}

	// on click add to wishlist btn
	async addRemoveProductFromWishlist(productId, isDelete=0){
		this.setState({ wishlistButtonLoader: true,wishlistListButtonLoader: {item_id:productId,loading:true}});
  	this.handleAddToWishlist(productId, isDelete);
  }


  handleAddToWishlist = async (productId, isDelete) => {
		await this.setWishListFromdata(productId, isDelete);
  }

	setWishListFromdata = async (itemId, isDelete) => {
		var formdata = [];
		formdata['wishlists[cart]'] = 1;
		formdata['wishlists[action_type]'] = 1;
		formdata['wishlists[quantity]'] = this.state.quantity;
		formdata['wishlists[item_id]'] = itemId;
		formdata['wishlists[variation_combination]'] = this.state.concatValues;
		if (isDelete == 1) {
			formdata['wishlists[delete]'] = 1;
		} 
		this.storeWishList(formdata);
	}

	//no api call (move to wishlist)
	async moveToWishList(id) {
  	return await this.setMoveToWishListFormdata(id);	
  }

  //no api call
  setMoveToWishListFormdata = async (id) => {
  	var formdata = [];
		formdata['wishlists[id]'] = id;
		formdata['wishlists[action_type]'] = 1;
		return this.updateWishList(formdata, id);
  }

  //api call
	storeWishList = async(formdata) => {
		var response = await storeWishList(formdata);
		this.storeWishListResponse(response);
	}	
	
	//api call
	updateWishList =async(formdata,id) => {
		var response = await updateWishList(formdata, id);
		this.updateWishListResponse(response);
		return response;
	}

	//api call
	deleteWishList = async (id, showToast=1) => {
		this.setState({ errors: [] });
		var response = await deleteWishList(id);
		this.deleteWishlistResponse(response, showToast);
		return response;
	}
	
	//no api call
	storeWishListResponse = async (response) => {
		this.setState({errors:[]});
		if(response.data.status === 'error'){
			if(response.data.errors['wishlists[quantity]'] != ''){
				await this.errorToast(response.data.errors['wishlists[quantity]']);	
			}
			this.setState({'errors':response.data.errors});
		}
		else{
			this.successToast('Wishlist updated successfully.');
			this.setState({ showVariationModal: false });
			/*this.props.increaseWishListCount();*/
			this.setWishlistProducts(response.data); 
		}
		this.setState({ wishlistButtonLoader: false,wishlistListButtonLoader:{} });
	}

	//no api call
	updateWishListResponse = async (response) => {
		this.setState({errors:[]});
		if(response.data.status === 'error'){
			if(response.data.errors['wishlists[quantity]'] != ''){
				await this.errorToast(response.data.errors['wishlists[quantity]']);	
			}
			this.setState({'errors':response.data.errors});
		}
		else{
			await this.successToast('Wishlist updated successfully.');
			this.setWishlistProducts(response.data);
		}
		this.setState({ wishlistButtonLoader: false,wishlistListButtonLoader:{},showVariationModal: false });
	}

	//no api call
	deleteWishlistResponse = async (response, showToast) => {
		this.setState({errors:[]});
		if(response.data.status === 'error'){
			if(response.data.errors['wishlists[quantity]'] != ''){
				await this.errorToast(response.data.errors['wishlists[quantity]']);	
			}
			this.setState({'errors':response.data.errors});
		}
		else{
			if (showToast == 1) {
				await this.successToast('Product removed from wishlist');	
			}
			
			this.setState({ showVariationModal: false });
			await this.getWishlistProducts();
		}
		this.setState({ wishlistButtonLoader: false,wishlistListButtonLoader:{} });
	}

	//no api call
	setWishlistProducts = async (wishlistProducts) => {
		this.props.setWishListCount(wishlistProducts.count);
    this.props.setWishListProducts(wishlistProducts.data);
		await this.getCartProducts();
		this.setState({ wishlistButtonLoader: false,wishlistListButtonLoader:{} });
	}

	//for error toast msg
	errorToast = async (message) => {	
		toast.error(message, {
			position: "top-center",
			autoClose: 2000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});	
	}

	//for success toast msg
	successToast = async (message) => {
		if (mobile == false) {
			toast.info(message, {
				position: "top-center",
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}
	}	
}

export default MasterProduct;