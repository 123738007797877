import { combineReducers } from 'redux'
import users from './users';
import carts from './carts';
import wishlists from './wishlists';
import catalogs from './catalogs';
import settings from './settings';
import dealers from './dealers';
import styles from './styles';
import filters from './filters';
import products from './products';
import orders from './orders';
import domain from './domain';
import addresses from './addresses';
import categories from './categories';
import brands from './brands';

export default combineReducers({
  users,
  carts,
  wishlists,
  catalogs,
  settings,
  dealers,
  styles,
  filters,
  products,
  orders,
  domain,
  addresses,
  categories,
  brands
})