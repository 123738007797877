import React, { Component } from 'react';
import { getOrders, getImageContent, storeAddresses, updateAddresses, getAddresses ,getCountries, 
         getStates, getCartListing, getOrderSummery } from '../../helpers/ServicesHelper';
import { dealersPortal, mobile } from '../../helpers/OperationsHelper';
import { submitOrder} from  '../../helpers/TransactionsHelper';
import cookie from 'react-cookies';

export default class CoreEditAddresses extends React.Component {
  constructor(props){
		super(props);
		this.state={
			shippingAddress: {},
      statesArray: {'shippingAddress': { states: [] },
                    'billingAddress': { states: [] } }
		}
	}

  async componentDidMount() {
    window.history.pushState(null, null, window.location.pathname);
    await this.getAddresses();
  }

  async getAddresses() {
    if (cookie.load('authToken')) {
      if (Object.keys(this.props.user).length > 0 && typeof this.props.user.id != '') {
        var id = this.props.match.params.id;
        var where = 'where[fl_user_addresses.id]='+id+'&where[fl_user_addresses.user_id]='+this.props.user.id;      
        var response = await getAddresses(where);  
        if (response.data.status == 'success') {
          this.setState({ shippingAddress: response.data.data[0] });
          await this.onChangeCountry(response.data.data[0].country, 'country', 'shippingAddress');
        }
      }
    } 
	}

  async onChangeCountry(value,name,type){
    if(name == 'country'){
      var where = 'where[countries.name]='+value;
      var states = await getStates(where);
      var statesArray = this.state.statesArray;
      if(states.data.status == 'success'){
        statesArray[type] = {'states':states.data.data}
        this.setState({statesArray:statesArray})
      }else{
        statesArray[type] = {'states':[]}
        this.setState({statesArray:statesArray})
      }

      if(this.state.checked == true && type != 'billingAddress'){
        statesArray['billingAddress'] = {'states':this.state.statesArray.shippingAddress.states}
        this.setState({statesArray:statesArray})
      }
    }
  }
}
