import React from 'react';
import {Container, Col, Row, Button} from 'react-bootstrap';
import Filter from './Filter';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
class SortModal extends React.Component {
  render() {
    const {t} = this.props
    return (
      <div id="sort-modal" className="modal fade" tabIndex="-1">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header border-0">
              <div>
                Sort By
              </div>
              <button type="button" className="close" data-dismiss="modal">
                  <span>&times;</span>
              </button>
            </div>
              <div className="modal-body">
               {
                this.props.filters.map((value,index)=>(
                  (value.type == 'range')&&
                    <React.Fragment key={index}>
                      <div className="text-center">
                        <a className="close" data-dismiss="modal" onClick={()=>this.props.changeSort('&order_column=fl_item_product_type_fields.field_value&order_by=desc&is_filter_applied=1&field_sequence='+value.field_sequence,value.name,'DESC')} href="#" className="black">{value.name+" : "}<span className="bold">{(value.type == 'range')?t("desc"):t("highToLow")}</span></a>
                      </div>
                      <hr className="mt-2 mb-2" />
                      <div className="text-center">
                        <a className="close" data-dismiss="modal" onClick={()=>this.props.changeSort('&order_column=fl_item_product_type_fields.field_value&order_by=asc&is_filter_applied=1&field_sequence='+value.field_sequence,value.name,'ASC')} href="#" className="black">{value.name+" : "}<span className="bold">{(value.type == 'range')?t("asc"):t("lowToHigh")}</span></a>
                      </div>
                      <hr className="mt-2 mb-2" />
                    </React.Fragment>
                  ))
                }
              </div>  
                        
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    settings:state.settings.settings,
    filters: state.filters.filters,
  }
}


export default withTranslation()(connect(mapStateToProps, null)(SortModal));



