import React from 'react';
import {Container, Row, Col, Button, Card, Form} from 'react-bootstrap';
import { Link } from 'react-router-dom'


class Call extends React.Component {	
	render(){
		return (			
		  <div className="tab-pane fade" id="call_faq" role="tabpanel" aria-labelledby="call_faq_tab">
		  	<Card>
		  		<Card.Body>
		  			<div className="text-center pt-5">
			  			<h5 className="black bold text-center text-uppercase mb-4">Give us a call</h5>
			  			<p>10:00am - 18:00pm IST Monday - Friday</p>
			  			<h4 className="black">Call Us</h4>
			  			<h4 className="black bold"><a href="tel:8080754321" className="black" target="_blank">8080754321</a></h4>
			  		</div>
		  		</Card.Body>
		  	</Card>
		  </div>
		);
	}
}

export default Call;