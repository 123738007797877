import React from 'react';
import {Container, Row, Col, Table, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import sizeChart from '../../../assets/images/faq/size_chart.png';

class RunningFaq extends React.Component {	
	render(){
		return (
			<div className="tab-pane fade" id="running_faq" role="tabpanel" aria-labelledby="running_faq_tab">			
				<h5 className="black bold mt-5">Running</h5>
				<div className="card mb-3">
	        <div className="card-body">
						<ol className="pl-3">
							<li className="pb-2">
								<h6 className="bold black">What model of SCOTT Running shoes is right for me?</h6>
								<p>Running shoes should be comfortably snug around your midfoot and heel, with about a thumb's width of space between your longest toe and the end of the shoe. Generally, your running shoe size is half a size to a size and a half bigger than your dress shoe size. The reason you want this much space is twofold. First of all, when you run your feet will swell. Any shoe that is a little tight will soon become numbing. Secondly, when you run your foot will slide forward a little in your shoe. If your toes hit the end of your shoe repeatedly, the impact will cause you to bruise and eventually lose your toenails. If this happens, your shoes are definitely too small!</p>
								<p className="mb-0">Please refer to our below size chart:</p>
								<img src={sizeChart} className="img-fluid mt-2" />
							</li>
							<li className="pb-2">
								<h6 className="bold black">How long will pair of my running shoes will last?</h6>
								<p>A good running shoe will last approximately 600-800 kilometres. If you run 50km per week, that's 3-4 months. Any walking or training around you do in the shoes also impacts their life, if you want your shoes to last longer, only use them for running. Alternating pairs of shoes can also extend their life (within that 600-800 kilometre window). This is because every time you wear your shoes, the midsole compresses. It takes 24-48 hours for the midsole to fully decompress. If it's not allowed to do so, the shoe will not cushion you as well and will wear out more quickly.</p>
							</li>
							<li className="pb-2">
								<h6 className="bold black">Can I Put orthopaedic inserts into my running shoes?</h6>
								<p>If your orthopaedic inserts are specially made for sports, you can easily take out the SCOTT insoles and put your orthopaedic insoles inside. This is recommended only for shoes with neutral support, do not use your own insoles with medial support shoes.</p>
							</li>
							<li className="pb-2">
								<h6 className="bold black">How can I prevent blisters?</h6>
								<p>Don't wear cotton socks! When you sweat, cotton gets wet and soggy and stays wet and soggy. This softens the skin and causes blisters. Synthetic socks, made out of materials like Coolmax and Drynamix, will wick the moisture away from your feet to keep them dry and blister free.</p>
							</li>
							<li className="pb-2">
								<h6 className="bold black">Do you offer replacement insoles?</h6>
								<p>Yes, you can buy it from our website.</p>
							</li>
							<li className="pb-2">
								<h6 className="bold black">Do you offer replacement shoe laces?</h6>
								<p>No, we don’t offer shoe laces.</p>
							</li>
							<li className="pb-2">
								<h6 className="bold black">How can I wash my SCOTT running shoes?</h6>
								<p>Your running shoes are a training device which need regularly care and cleaning to ensure a longer lifetime. If your shoes are really dirty, let them dry and brush off the mud and coarse dirt thoroughly without any water. Do not use water, as this will result in the penetration of water and mud into the sensitive mesh material. Once the coarse dirt is off the shoe you can wash the shoes with a cloth using water and gentle soap. If your inner soles are dirty you can take them out and hand wash them with warm water and soap. The outsole is robust and durable and can be washed with a strong brush and water.</p>									
							</li>
							<li className="pb-2">
								<h6 className="bold black">Can I wash my SCOTT running shoes in a washing machine?</h6>
								<p>You should not wash your running shoes in the washing machine, as the temperature and the laundry detergent will damage the adhesive bonding between the sole and the tooling. Furthermore, the flexibility of the sole construction will decrease due to temperature and water which causes harder EVA midsoles. Reflective prints and design colour print might peel off easier due to the temperature and moisture during the washing process.</p>									
							</li>								
						</ol>
					</div>
				</div>	
			</div>
		);
	}
}

export default RunningFaq;