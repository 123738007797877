import React from 'react';
import {Container, Row, Col, Table, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import MetaTags from '../../../components/MetaTags';
import motoManualBootsImg from '../../../assets/images/thumbnail/manual-moto-boots.jpg';

import pdf1 from '../../../assets/pdf/Moto-Boots-Manual/Boots-550_Manual_A6_2016_MOTOSPORTS_SCOTT-Sports_Multi.pdf';
import pdf2 from '../../../assets/pdf/Moto-Boots-Manual/Boots-350_Manual_A6_2016_MOTOSPORTS_SCOTT-Sports_Multi.pdf';
import pdf3 from '../../../assets/pdf/Moto-Boots-Manual/Boots_Manual_A6_2020_MOTOSPORTS_SCOTT-Sports_Multi.pdf';


class MotoManualsBootsView extends React.Component {	
	componentDidMount() {
	  window.scrollTo(0, 0)
	}
	render(){
		return (
			<React.Fragment>
        <MetaTags {...this.props}/> 
				<div className="main">
					<div className="main_section">
						<Container className="">
              <h1 className="page_heading black mb-4 text-uppercase bold"><Link to="/manuals-moto"><i className="fal fa-long-arrow-left pr-2 black" style={{fontWeight:'700'}}></i></Link> Boots Manuals | SCOTT Moto</h1>
							<Row className="pd7">
                <Col lg={3} sm={12} className="mb-4 mb-lg-0">
                  <ul className="nav nav-tabs vertical_tabs">
                    <li className="nav-item">
                      <a className="nav-link active" data-toggle="tab" href="#all_boots">
                        <i className="far fa-chevron-right"></i> All Boots Manuals | SCOTT Moto
                      </a>
                    </li>  
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#sixteen_boots">
                        <i className="far fa-chevron-right"></i> 2016 Boots Manuals | SCOTT Moto
                      </a>
                    </li>                  
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#twenty_boots">
                        <i className="far fa-chevron-right"></i> 2020 Boots Manuals | SCOTT Moto
                      </a>
                    </li>
                  </ul>
                </Col>
                <Col lg={9} sm={12}>
                  <div className="tab-content">
                    <div className="tab-pane container active" id="all_boots">
                      <Row className="pd7 pdf_list">
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf1} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={motoManualBootsImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Boots-550_Manual_A6_2016_MOTOSPORTS_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf2} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={motoManualBootsImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Boots-350_Manual_A6_2016_MOTOSPORTS_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf3} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={motoManualBootsImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Boots_Manual_A6_2020_MOTOSPORTS_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>                                           
                      </Row>
                    </div>
                    <div className="tab-pane container fade" id="sixteen_boots">
                      <Row className="pd7 pdf_list">                       
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf1} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={motoManualBootsImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Boots-550_Manual_A6_2016_MOTOSPORTS_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf2} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={motoManualBootsImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Boots-350_Manual_A6_2016_MOTOSPORTS_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>                
                      </Row>
                    </div>
                    <div className="tab-pane container fade" id="twenty_boots">
                      <Row className="pd7 pdf_list">
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf3} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={motoManualBootsImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Boots_Manual_A6_2020_MOTOSPORTS_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col> 
                      </Row>    
                    </div>                                    
                  </div>
                </Col>
              </Row>
						</Container>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default MotoManualsBootsView;