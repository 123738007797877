import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import MetaTags from '../../../components/MetaTags';
import BikeImg from '../../../assets/images/products/bike.jpg'; 
import HelmetImg from '../../../assets/images/products/helmet.jpg';
import BackpacksImg from '../../../assets/images/products/bagpacks.jpg'; 
import ApparelImg from '../../../assets/images/products/apparel.jpg'; 
import GlovesImg from '../../../assets/images/products/gloves.jpg'; 

class ManualsBike extends React.Component {	
	componentDidMount() {
	  window.scrollTo(0, 0)
	}
	render(){
		return (
			<React.Fragment>
        <MetaTags {...this.props}/> 
				<div className="main">
					<div className="main_section">
						<Container className="">
							<h1 className="page_heading bold text-uppercase black mb-3 text-left mb-3"><Link to="/manuals"><i className="fal fa-long-arrow-left pr-2 black" style={{fontWeight:'700'}}></i></Link> Bike Manuals and Documentation</h1>
              <Row className="sports_ride box_card mt-4">
                <Col md={6} className="item mb-5">
                  <Row className="align-items-center">
                    <Col xs={6}>
                      <div>
                        <img src={BikeImg} className="img-fluid" />
                        <div className="img_btn text-center">
                          <span>bike</span>
                        </div>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div>
                        <Link to="/manuals-bike-view">Bike Product Manuals</Link><br/>
                        <Link to="/declaration-of-confirmity-bikes">Declaration of Conformity</Link>
                      </div>
                    </Col>
                  </Row>                  
                </Col> 
                <Col md={6} className="item mb-5">
                  <Row className="align-items-center">
                    <Col xs={6}>
                      <div>
                        <img src={HelmetImg} className="img-fluid" />
                        <div className="img_btn text-center">
                          <span>helmet</span>
                        </div>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div>
                        <Link to="/manuals-helmet-view">Helmet Product Manuals</Link><br/>
                        <Link to="/declaration-of-confirmity-bikes">Declaration of Conformity</Link>
                      </div>
                    </Col>
                  </Row>                  
                </Col>
                <Col md={6} className="item mb-5">
                  <Row className="align-items-center">
                    <Col xs={6}>
                      <div>
                        <img src={BackpacksImg} className="img-fluid" />
                        <div className="img_btn text-center">
                          <span>backpacks & bags</span>
                        </div>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div>
                        <Link to="/manuals-backpacks-view">Backpacks Product Manuals</Link><br/>
                        <Link to="/declaration-of-confirmity-bikes">Declaration of Conformity</Link>
                      </div>
                    </Col>
                  </Row>                  
                </Col> 
                <Col md={6} className="item mb-5">
                  <Row className="align-items-center">
                    <Col xs={6}>
                      <div>
                        <img src={ApparelImg} className="img-fluid" />
                        <div className="img_btn text-center">
                          <span>Apparel</span>
                        </div>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div>
                        <Link to="/manuals-apparel-view">Apparel Product Manuals</Link><br/>
                        <Link to="/declaration-of-confirmity-bikes">Declaration of Conformity</Link>
                      </div>
                    </Col>
                  </Row>                  
                </Col>      
                <Col md={6} className="item mb-5">
                  <Row className="align-items-center">
                    <Col xs={6}>
                      <div>
                        <img src={GlovesImg} className="img-fluid" />
                        <div className="img_btn text-center">
                          <span>Gloves</span>
                        </div>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div>
                        <Link to="/manuals-gloves-view">Gloves Product Manuals</Link><br/>
                        <Link to="/declaration-of-confirmity-bikes">Declaration of Conformity</Link>
                      </div>
                    </Col>
                  </Row>                  
                </Col>                          
              </Row>
						</Container>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default ManualsBike;