import React from 'react';
import {registerDealer, checkDealerStatus, getDealerProfileView} from '../../helpers/ServicesHelper'
import CoreUser from '../users/CoreUser';
class CoreDealer extends CoreUser {
  
	componentDidMount(){
		this.profileView();
	}

  registration=async()=>{
		var formdata=[]
		formdata['dealers[name]']=this.state.registerUser;
		formdata['dealers[email_id]']=this.state.email_id;
		formdata['dealers[mobile_no]']=this.state.mobile_no;
		formdata['dealers[password]']=this.state.registerPassword;
    formdata['confirm_password']=this.state.confirm_password;
		var credentials=await registerDealer(formdata);
		if(credentials.data.status==='success'){
			this.setState({'redirectTo':'/dealer-approval'})
		}
		else{
			this.setState({errorRegister:credentials.data.errors})
		}
  }


  checkApproval=async()=>{
    var approvalData = await checkDealerStatus(this.props.user.dealer_id);
    if(approvalData.data.status=='success'){
      if(approvalData.data.data.approval_status=='approve'){
      	this.setState({'redirectTo':'/'})
      }
    }
  }

	profileView=async()=>{
		if(typeof this.props.user.dealer_id != 'undefined' && this.props.user.dealer_id != 'null'){
			var profile=await getDealerProfileView(this.props.user.id);
			if(profile.data.status=='success'){
				await this.setState({profile:profile.data.data});
			}
			else{
				this.setState({profile:''});
			}
		}
	}


}

export default CoreDealer;