import React from 'react';
import {Container, Row, Col, Table, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom'


class AvantiWarranty extends React.Component {	
	render(){
		return (		
			<div className="mt-5">
		  	<h2 className="small_heading bold black text-center mb-5 text-uppercase">WARRANTY CLAIMS</h2>
		  	<div className="mb-4">
		  		
		  		<p>If your query relates to a warranty assessment please  contact your nearest <a href='/find-dealer'>Avanti store</a> . All potential claims must
go through a store for assessment. Proof of ownership will be required.</p>
<p>This warranty supersedes any previous defects warranty.</p>

		  	</div>
		  	<div className="mb-4">
		  		<h3 className="content_heading black bold">1. AVANTI DEFECTS WARRANTY</h3>
		  		<p>AVANTI warrants to you, the original purchaser (Purchaser) of an AVANTI brand bicycle, that this bicycle and
certain identified parts of it (as set out in paragraphs 3.1 and 3.2 of this document, and collectively referred to as
the Bicycle), when used for normal riding purposes, are free from material defects in workmanship and materials
for the relevant periods nominated below (Warranty).</p>
		  		<p>This Warranty is governed by the laws of the state and/or country where the Bicycle was purchased, and
applies from the date of an original retail purchase from a dealer authorised by AVANTI. A full list of dealers can
be found at <a href="/">Sportnetwork.in</a> . The benefits to the Purchaser given under this Warranty are separate from and in
addition to (and therefore do not affect or limit) any rights or remedies of the Purchaser in respect to the Bicycle
under the Indian Consumer Act. AVANTI does not authorise or allow anyone, including AADs(Avanti Authorised Dealer), to extend or vary this Warranty or
any other warranties given by AVANTI. If any provision of this Warranty is held invalid, unenforceable or illegal for
any reason, the rest of this Warranty will remain in full force apart from such provision, which will be deemed to
have been deleted.</p>
		  		
		  	</div>
		  	<div className="mb-4">
		  		<h3 className="content_heading black bold">2. THE PURCHASER'S ATTENTION IS DRAWN TO THE FOLLOWING:</h3>
		  		<p>2.1. The Bicycle is only designed and intended for the purpose of normal riding, and is specifically not designed
or intended for any other uses, including jumping, stunt riding, participation in “motocross” bicycle riding,
mountain bicycle racing or other uses not recommended in the owner’s manual.</p>
		  		<p>2.2. Sunshine can produce ultra violet rays, which has the effect of causing paintwork
to fade quickly, and it is usual for this fading to be more noticeable, and to occur at a faster rate, in neon or
fluorescent paints (despite the presence of ultra violet inhibitors in the paint).</p>
		  		<p>2.3. To the extent permitted by ICA and other any other relevant law, other than in accordance with this
Warranty, and as part of the terms of the sale of the Bicycle:</p>
		  		<p>a) AVANTI will not be liable for, and the Purchaser will hold AVANTI harmless in connection with, any form of
defect, malfunction, failure, loss, damage, cost, injury or harm of any kind (whether direct, indirect, special or
consequential) howsoever arising from the use or supply of the Bicycle to the Purchaser; and</p>
		  		<p>(b) the Purchaser, AVANTI and any relevant AAD each agree to exclude any warranties implied by law.</p>
		  		<p>2.4. Despite anything else in this Warranty or the owner’s manual, nothing in this Warranty or the owner’s manual
will exclude, limit or modify any warranty or liability of AVANTI implied or imposed by any applicable law, if to do
so would be unlawful or make any part of this Warranty void or voidable.</p>

		  	</div>
		  	
			  <div className="mb-4">
		  		<h3 className="content_heading black bold">3. WHAT THIS WARRANTY COVERS AND TERM OF THIS WARRANTY</h3>
		  		<p>3.1. This Warranty only covers bicycles that have a wheelbase exceeding 640mm or have a wheel size of 16”
(40cm) and larger.</p>
		  		<p>3.2. This Warranty covers:</p>
		  		<p>(a) standard steel, alloy and carbon frames and framesets, for as long as the Purchaser owns the Bicycle (but
note that the fork is not a part of the frame or frameset);</p>
		  		<p>(b) suspension frames and framesets (including but not limited to, all suspension attachment points, bushings,
pivots, fasteners, stays and shock units) for two years;</p>
		  		<p>(c) all other standard components and accessories forming part of the Bicycle, including standard forks, wheels,
suspension forks, rear shock absorbers, drive train, brakes, seat post, handlebar and stem, paint, decals and
finish for one year.</p>
		  		<p>3.3. The frame warranty is limited to five years for the original purchaser. Claims must be made through an
Authorised Dealer. Warranty on your frame or frameset does not guarantee that the Bicycle will last
forever. The length of the life of the Bicycle will vary depending on the type of the Bicycle, riding conditions and
care the Bicycle receives.</p>

		  	</div>


			  <div className="mb-4">
		  		<h3 className="content_heading black bold">4. EXCEPTIONS TO THIS WARRANTY</h3>
				<p>4.1. This Warranty does not apply:</p>
				<p>(a) where the Bicycle or any part of it:
fails due to an accident, any neglect, abuse, normal wear and tear, misuse, lack of rider skill, improper operation,
improper assembly, improper or lack of repair, improper or lack of maintenance, alteration,modification, or any
other abnormal, excessive or improper use;
has been subjected to a use for which it was not designed or intended or promoted by AVANTI, including but not
limited to jumping, stunt riding, participation in “motocross” bicycle riding, mountain bicycle racing, trick riding,
ramp riding, downhill racing, jumping, aggressive riding, riding on severe terrain, riding in severe climates, riding
with heavy loads, any other uses not recommended in the owner’s manual or any similar activities;
has had a motor attached or has been used for hire, rental or any other commercial purpose;
has been modified or is otherwise than as supplied by AVANTI; or
does not consist solely of original parts or equipment;</p>
				<p>(b) where any defect, damage, malfunction or other failure of the Bicycle or any part of it resulted directly or
indirectly from:
a person other than an AAD adjusting or failing to adjust any part requiring normal maintenance and service
(examples include adjustment of brakes and derailleur mechanisms, tyre inflation, lubrication, and tightening of
screws, nuts and bolts);
any failure to follow the owner’s manual; or
normal wear and tear, including the results of fatigue (fatigue damage is considered normal wear and tear, being
a symptom of the frame or frameset being worn out through normal use, and the Purchaser should regularly
inspect the frame or frameset, and ensure that the frame or frameset is periodically checked by an AAD for
indicators of fatigue, stress or any other potential failure); or</p>
				<p>(c) where coverage for suspension components is warranted, in respect to such components for the original
manufacturer’s warranty period, as noted in that original manufacturer’s documentation where supplied.</p>
				<p>4.2. This Warranty only applies to the Purchaser and only if the Bicycle was purchased from an AAD. It may not
be assigned or transferred by the Purchaser to any other person.</p>
				<p>4.3. Except as expressly provided in this Warranty and otherwise required by law, AVANTI gives no warranties in
relation to the Bicycle or any of its parts, either express or implied, including but not limited to any implied
warranties relating to quality, fitness for any particular purpose or ability to achieve a particular result.</p>
				<p>4.4. To the extent permitted by law:</p>
				<p>(a) AVANTI’s sole liability for breaching any term of this Warranty is limited, at AVANTI’s sole election, to
AVANTI:
repairing or replacing the defective item;

supplying the equivalent of the defective item; or
reimbursing the cost of any of the above; and
</p>
<p>(b) AVANTI’s total liability in relation to the Bicycle and all its parts shall be no greater than the amount of the
original retail price paid by the Purchaser for the Bicycle and all its parts.</p>

 



    		 </div>









		  	<div className="mb-4">
		  		<h3 className="content_heading black bold">5. CLAIMING ON THE WARRANTY</h3>
		  		<p>5.1. In order to make a claim under this Warranty, the Purchaser must deliver to the AAD from which the Bicycle
was purchased:</p>
		  		<p>(a) the Bicycle or relevant part thereof;</p>
		  		<p>(b) written reasons why the Purchaser considers that the Purchaser has a claim under this Warranty;</p>
		  		<p>(c) all things necessary to support the Purchaser’s claim, including:
details of the Bicycle or relevant part thereof (by brand, model and serial number);
original proof of purchase which identifies the Bicycle or relevant part thereof by serial number;
details of the AAD from which the Bicycle was purchased (including name and address);
evidence that regular servicing and maintenance has been undertaken on the Bicycle by an AAD, to show that
the Bicycle or relevant part thereof has been correctly maintained; and
evidence that regular servicing and maintenance has been undertaken on the Bicycle by an AAD, to show that
the Bicycle or relevant part thereof has been correctly maintained; and
</p>
		  		<p>(d) If this Warranty applies to the Bicycle or relevant part thereof, AVANTI will repair the Bicycle or relevant part
thereof.</p>
		  		<p>5.2. To the extent possible under law, all reasonable costs and expenses in connection with the processing of the
Purchaser’s warranty claim, including all costs associated with transportation and delivery, shall be borne by the
Purchaser.</p>
		  		<p>5.3. Warranty claim can be filed through this <a href='https://customerconnect.sportnetwork.in/warranties'>link</a>. You can
reach out to us on our Helpline <a href="tel:8080754321" target="_blank">8080754321</a> (10am-6pm Mon-Fri) or on email <a href='mailto:cs@sportnetwork.in' target="_blank">cs@sportnetwork.in</a>
</p>

		  	</div>
		  </div>
		);
	}
}

export default AvantiWarranty;