import React, { Component } from 'react'
import { Switch, Route, BrowserRouter as Router, } from 'react-router-dom';
import { Helmet } from 'react-helmet'
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux'
import cookie from 'react-cookies';
import i18n from '../../../utils/i18n';
import Favicon from 'react-favicon'

import DealerHome from '../../../pages/dealer/views/Home';

import ProductListing from '../../../pages/products/views/ProductListing';
import ProductDetails from '../../../pages/products/views/ProductDetails'
import ProductCompare from '../../../pages/products/views/ProductCompare'
import CartListing from '../../../pages/transactions/views/CartListing';
import WishListing from '../../../pages/transactions/views/WishListing';
import OrderDetails from '../../../pages/transactions/views/OrderDetails';
import OrderListing from '../../../pages/transactions/views/OrderListing';
import OrderResult from '../../../pages/transactions/views/OrderResult';

import QuotationListing from '../../../pages/transactions/views/QuotationListing';

import Checkout from '../../../pages/transactions/views/Checkout';
import EditAddresses from '../../../pages/transactions/views/EditAddresses';
import PreparingCheckout from '../../../pages/transactions/views/PreparingCheckout';

import DealerLedger from '../../../pages/dealer/views/DealerLedger';
import DealerProfile from '../../../pages/dealer/views/DealerProfile';

import DealerLogin from '../../../pages/dealer/views/DealerLogin';
import DealerRegister from '../../../pages/dealer/views/DealerRegister';
import DealerApproval from '../../../pages/dealer/views/DealerApproval';
import ForgotPassword from '../../../pages/dealer/views/ForgotPassword';
import ResetPassword from '../../../pages/dealer/views/ResetPassword';
import ChangePassword from '../../../pages/dealer/views/ChangePassword';
import EditProfile from '../../../pages/users/views/EditProfile';

import Redirect from '../../../../core/components/redirects/CoreRedirects';

import PrivateRoute from '../../../../core/components/routes/CorePrivateRoute';
import RedirectWithStatus from '../../../../core/components/routes/CoreRedirectWithStatus';
import PublicRoute from '../../../../core/components/routes/CorePublicRoute';

import ContactUs from '../../../pages/customerConnect/views/ContactUs';
import PrivacyPolicy from '../../../pages/customerConnect/views/PrivacyPolicy';
import BuyerReturnPolicy from '../../../pages/customerConnect/views/BuyerReturnPolicy';
import TermsConditions from '../../../pages/customerConnect/views/TermsConditions';
import Warning from '../../../pages/customerConnect/views/Warning';
import Warranty from '../../../pages/customerConnect/views/Warranty';
import AboutUs from '../../../pages/customerConnect/views/AboutUs';
import Manuals from '../../../pages/customerConnect/views/Manuals';
import ManualsBike from '../../../pages/customerConnect/views/ManualsBike';
import ManualsMoto from '../../../pages/customerConnect/views/ManualsMoto';
import ManualsBikeView from '../../../pages/customerConnect/views/ManualsBikeView';
import ManualsHelmetView from '../../../pages/customerConnect/views/ManualsHelmetView';
import ManualsBackpacksView from '../../../pages/customerConnect/views/ManualsBackpacksView';
import ManualsApparelView from '../../../pages/customerConnect/views/ManualsApparelView';
import ManualsGlovesView from '../../../pages/customerConnect/views/ManualsGlovesView';
import DeclarationOfConfirmityBikes from '../../../pages/customerConnect/views/DeclarationOfConfirmityBikes';
import MotoManualsBootsView from '../../../pages/customerConnect/views/MotoManualsBootsView';
import MotoManualsGogglesView from '../../../pages/customerConnect/views/MotoManualsGogglesView';
import MotoManualsHelmetsView from '../../../pages/customerConnect/views/MotoManualsHelmetsView';
import MotoManualsProtectionView from '../../../pages/customerConnect/views/MotoManualsProtectionView';
import MotoManualsApparelView from '../../../pages/customerConnect/views/MotoManualsApparelView';
import MotoManualsGlovesView from '../../../pages/customerConnect/views/MotoManualsGlovesView';
import DeclarationOfConfirmityMoto from '../../../pages/customerConnect/views/DeclarationOfConfirmityMoto';

import Product from '../../../pages/offer/views/product';

import NotFound from '../../NotFound';

import Receipt from '../../../pages/salesVouchers/views/Receipt';
import CreditNote from '../../../pages/salesVouchers/views/CreditNote';
import AccountingVoucher from '../../../pages/salesVouchers/views/AccountingVoucher';

import { getImagePath } from '../../../../core/helpers/OperationsHelper';
import { getMetaTags } from '../../../../core/helpers/ServicesHelper';

import store from '../../../../store';
import AvantiPDF from '../../../pages/dealer/views/AvantiPDF';

class DealerRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = { metatags: [] }
  }

  render() {
    var title = 'Scott Sport Dealer';
    return (
      <div>
        <I18nextProvider i18n={i18n}>
          <Router>
            <Favicon url={(typeof this.props.settings != 'undefined') && this.props.settings.image_url + 'application_images/original/' + this.props.settings['dealer_favicon']} />
            <Provider store={store}>
              <Switch>
                <PrivateRoute restricted={true} path="/" header={true} component={DealerHome} exact sensitive />

                <PublicRoute restricted={true} path='/login/:paramOne?' header={false} component={DealerLogin} exact sensitive />
                <PublicRoute restricted={true} path='/register' header={false} component={DealerRegister} exact sensitive />
                <PublicRoute restricted={true} path='/forgot-password' header={false} component={ForgotPassword} exact sensitive />
                <PublicRoute restricted={true} path='/reset-password' header={false} component={ResetPassword} exact sensitive />

                <PrivateRoute path='/change-password' component={ChangePassword} exact sensitive />
                <PrivateRoute path='/edit-profile' component={EditProfile} exact sensitive />

                <PrivateRoute path="/pl/:paramOne/:paramTwo?/:paramThree?" component={ProductListing} />
                <PrivateRoute path="/pl" component={ProductListing} />
                <PrivateRoute path="/bpl/:paramOne" component={ProductListing} />
                <PrivateRoute path="/cpl/:paramOne" component={ProductListing} />

                <PrivateRoute path="/pp/:paramOne/:paramTwo?/:paramThree?/:paramFour?" component={ProductDetails} />
                <PrivateRoute path="/bpp/:paramOne/:paramTwo?" component={ProductDetails} />
                <PrivateRoute path="/cpp/:paramOne/:paramTwo?" component={ProductDetails} />

                <PrivateRoute path="/product-compare" component={ProductCompare} />

                <PrivateRoute path="/carts" component={CartListing} />
                <PrivateRoute path="/wishlists" component={WishListing} />

                <PublicRoute restricted={false} path="/preparing-checkout" component={PreparingCheckout} />
                <PrivateRoute path="/checkout" component={Checkout} />
                <PrivateRoute path="/edit-addresses/:id" component={EditAddresses} />

                <PrivateRoute path="/orders" component={OrderListing} />
                <PrivateRoute path="/order-detail/:orderId/:status?" component={OrderDetails} />
                <PrivateRoute path='/order-result/:orderId' component={OrderResult} />

                <PrivateRoute path="/quotations" component={QuotationListing} />

                <PrivateRoute path="/dealer-ledger" component={DealerLedger} exact sensitive />
                <PrivateRoute path="/dealer-profile" component={DealerProfile} exact sensitive />
                <PublicRoute restricted={true} path="/dealer-approval" header={false} component={DealerApproval} exact sensitive />

                <PublicRoute restricted={false} path='/redirects' component={Redirect} exact sensitive />

                <Route restricted={false} path="/404" component={NotFound} />

                <PrivateRoute path='/rcpt/:id' header={false} component={Receipt} />

                <PrivateRoute path='/receipt/:id' header={false} component={Receipt} />

                <PrivateRoute path='/credit-note/:id' header={false} component={CreditNote} />

                <PrivateRoute path='/debit-note/:id' header={false} component={CreditNote} />

                <PrivateRoute path='/sales/:id' header={false} component={AccountingVoucher} />

                <PublicRoute restricted={false} path="/contact-us" component={ContactUs} />
                <PublicRoute restricted={false} path="/privacy-policy" component={PrivacyPolicy} />
                <PublicRoute restricted={false} path="/buyer-return-policy" component={BuyerReturnPolicy} />
                <PublicRoute restricted={false} path="/terms-conditions" component={TermsConditions} />
                <PublicRoute restricted={false} path="/warning" component={Warning} />
                <PublicRoute restricted={false} path="/warranty" component={Warranty} />
                <PublicRoute restricted={false} path="/about-us" component={AboutUs} />
                <PublicRoute restricted={false} path="/manuals" component={Manuals} />
                <PublicRoute restricted={false} path="/manuals-bike" component={ManualsBike} />
                <PublicRoute restricted={false} path="/manuals-moto" component={ManualsMoto} />
                <PublicRoute restricted={false} path="/manuals-bike-view" component={ManualsBikeView} />
                <PublicRoute restricted={false} path="/manuals-helmet-view" component={ManualsHelmetView} />
                <PublicRoute restricted={false} path="/manuals-backpacks-view" component={ManualsBackpacksView} />
                <PublicRoute restricted={false} path="/manuals-apparel-view" component={ManualsApparelView} />
                <PublicRoute restricted={false} path="/manuals-gloves-view" component={ManualsGlovesView} />
                <PublicRoute restricted={false} path="/declaration-of-confirmity-bikes" component={DeclarationOfConfirmityBikes} />
                <PublicRoute restricted={false} path="/moto-manuals-boots-view" component={MotoManualsBootsView} />
                <PublicRoute restricted={false} path="/moto-manuals-goggles-view" component={MotoManualsGogglesView} />
                <PublicRoute restricted={false} path="/moto-manuals-helmets-view" component={MotoManualsHelmetsView} />
                <PublicRoute restricted={false} path="/moto-manuals-protection-view" component={MotoManualsProtectionView} />
                <PublicRoute restricted={false} path="/moto-manuals-apparel-view" component={MotoManualsApparelView} />
                <PublicRoute restricted={false} path="/moto-manuals-gloves-view" component={MotoManualsGlovesView} />
                <PublicRoute restricted={false} path="/declaration-of-confirmity-moto" component={DeclarationOfConfirmityMoto} />
                <PublicRoute restricted={false} path="/product" component={Product} />
                <PublicRoute restricted={false} path="/avantipdf" component={AvantiPDF} />

                <Redirect to={'/redirects'} />
              </Switch>
            </Provider>
          </Router>
        </I18nextProvider>
      </div>
    )
  }
}

export default DealerRoutes;
