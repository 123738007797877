import React from 'react';
import {Container, Row, Col, Table} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { default as converter } from 'number-to-words';

import CoreCreditVoucher from './../../../../core/components/salesVouchers/CoreCreditVoucher';
import AccountAddress from '../components/AccountAddress';
class CreditNote extends CoreCreditVoucher {	
	render(){
		const { vouchers, tallyCompany, tallyVoucher, amount, voucherReference, totalAmountInWords} = this.state;
		
		return (
			<React.Fragment> 
				<div className="main pb-5">
					<Container className="">
						<div className="border p-3">
							<Row className="mb-4">
								<Col md={{ span: 6, offset: 3 }} className="text-center">						
									
									<AccountAddress tallyCompany={tallyCompany} tallyVoucher={tallyVoucher}/>
								</Col>
							</Row>
							<hr/>
							<Row className="mt-4">
								<Col xs={12}>
									<h4 className="black bold text-center mb-3">{tallyVoucher.voucher_type}</h4>
								</Col>
								<Col sm={6}>
									<p className="mb-0">No. :   <span className="bold black">{typeof tallyVoucher.voucher_no != 'undefined' && 
													tallyVoucher.voucher_no != ''?tallyVoucher.voucher_no:''}</span></p>
									<p>Ref : <span className="bold black">{typeof tallyVoucher.voucher_reference_no != 'undefined' && 
													tallyVoucher.voucher_reference_no != ''?tallyVoucher.voucher_reference_no:''}    {typeof tallyVoucher.date !='undefined' && tallyVoucher.date !='' ?"dt."+tallyVoucher.date:''}</span></p>
								</Col>
								<Col sm={6} className="text-right">
									<p>Dated : <span className="bold black">{typeof tallyVoucher.date !='undefined' && tallyVoucher.date !='' ?tallyVoucher.date:''}</span></p>
								</Col>
								<Col sm={6}>
									<Row className="mb-3">
										<Col sm={3} xs={4}>
											<p>Party’s Name :</p>
										</Col>
										<Col sm={9} xs={8}>
											<p className="black bold mb-0">{typeof tallyVoucher.party_name != 'undefined' && tallyVoucher.party_name !='' ? tallyVoucher.party_name:'' }</p>
											<p className="mb-0">{typeof tallyVoucher.party_address != 'undefined' && tallyVoucher.party_address !='' ? tallyVoucher.party_address:''}</p>
										</Col>
									</Row>
									<Row>
										<Col sm={3} xs={4}>
											<p>GSTIN/UIN :</p>
										</Col>
										<Col sm={9} xs={8}>
											<p className="black bold">{typeof tallyVoucher.party_gst_number != 'undefined' && tallyVoucher.party_gst_number !='' ? tallyVoucher.party_gst_number:''}</p>
										</Col>
									</Row>
								</Col>								
							</Row>
							<Row>
								<Col sm={12}>
									<div className="table-responsive">
										<Table className="receipt_table" width="100%">
											<thead>
												<tr>
													<th className="black" width="70%">Particulars</th>
													<th className="text-right black" width="30%">Amount</th>
												</tr>
											</thead>
											<tbody>																				
												{voucherReference.length > 0 && voucherReference.map((reference)=>(
													<tr>
														<td>
															<p className="mb-0">{reference.particular}</p>										
														</td>
														<td className="bold black text-right">
															<p className="bold black mb-0 mt-0"><i className="fal fa-rupee-sign" style={{fontWeight:'700'}}></i>
															{Math.abs(reference.debit_amount)>0?(parseFloat(Math.abs(reference.debit_amount))).toFixed(2):
															(parseFloat(Math.abs(reference.credit_amount))).toFixed(2)}</p>
														</td>
													</tr>											
												))}
											</tbody>
											<tbody>
												<tr>
													<td colspan="2">
														<div>
															<p className="mb-0">On Account of :</p>
															<p className="pl-0 pl-sm-4 black bold">{typeof tallyVoucher.narration != 'undefined' && tallyVoucher.narration != '' ? tallyVoucher.narration:''}</p>
														</div>
														<div>
															<p className="mb-0">Amount (in words) :</p>
															<p className="pl-0 pl-sm-4 black bold">Indian Rupees {totalAmountInWords} Only</p>
														</div>
													</td>
												</tr>
											</tbody>
											<tfoot>
												<tr>
													<td>
														<p className="mb-0"><span className="bold">{typeof tallyCompany.company_pan_no!='undefined' && tallyCompany.company_pan_no!='' ? 
																							"Company’s PAN :"+tallyCompany.company_pan_no:""}</span> </p>
													</td>
													<td className="text-right">
														<p className="bold mb-0">for SCOTT Sports India Pvt Ltd</p>
													</td>
												</tr>
											</tfoot>
										</Table>
									</div>
								</Col>
							</Row>
						</div>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

export default CreditNote;