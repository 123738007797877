import React from 'react';
import { Container, Row, Col, Button, Table, Tabs, Tab, Card, Form, } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import cookie from 'react-cookies';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

import ProductOne from '../../../assets/images/products/spark-rc-cycle.png';
import CoreCheckout from '../../../../core/components/transactions/CoreCheckout';
import OrderSummery from '../components/OrderSummery';
import Loader from '../../../components/Loader';
import loader from '../../../assets/loader/circle-loader.gif';
import { dealersPortal } from '../../../../core/helpers/OperationsHelper';
import Tostify from '../../../components/Toaster';

class Addresses extends CoreCheckout {

	async componentDidMount() {
		await this.getCountries();
	}

	async componentDidUpdate(prevProps, prevState) {
		if (JSON.stringify(prevProps.shippingAddress) != JSON.stringify(this.props.shippingAddress)) {
			this.setState({ shippingAddress: this.props.shippingAddress });
		}

		if (prevState.statesArray.shippingAddress.states.length != this.props.statesArray.shippingAddress.states.length) {
			if (this.props.setStatesArray == true) {
				this.setState({ statesArray: this.props.statesArray });
			}
		}
	}

	async handleSubmit() {
		this.storeAddresses(typeof this.props.shippingAddress.id != 'undefined' ? this.props.shippingAddress.id : '');
	}

	render() {
		var formID = this.state.checked ? 'same-shipping' : 'billing-form';
		const { errors, countries, checked, statesArray, citiesArray, loader, addresses } = this.state;
		const { t, showBillingAddress } = this.props;
		return (
			<React.Fragment>
				<h5 className="bold black mb-3">{t('createNewAddress')}</h5>
				<h6 className="bold black mb-3">{t('shipping')}</h6>
				<Form className="fields-group-md mb-4">
					<Form.Group>
						<Form.Label>{t('name')} <span className="red">*</span></Form.Label>
						<Form.Control value={this.state.shippingAddress.name} onChange={(e) => this.changeFormdataValues(e, 'name', 'shippingAddress')} type="text" />
						<span className="red">{errors['shipping_addresses[name]']}</span>
					</Form.Group>
					<Form.Group>
						<Form.Label>{'Address 1'} <span className="red">*</span></Form.Label>
						<Form.Control value={this.state.shippingAddress.street_address} onChange={(e) => this.changeFormdataValues(e, 'street_address', 'shippingAddress')} type="text" />
						<span className="red">{errors['shipping_addresses[street_address]']}</span>
					</Form.Group>
					<Form.Group>
						<Form.Label>{"Address 2"} <span className="red">*</span></Form.Label>
						<Form.Control as="textarea" value={this.state.shippingAddress.full_address} onChange={(e) => this.changeFormdataValues(e, 'full_address', 'shippingAddress')} type="text" />
						<span className="red">{errors['shipping_addresses[full_address]']}</span>
					</Form.Group>
					<Form.Group>
						<Form.Label>{t('country')} <span className="red">*</span></Form.Label>
						<Form.Control value={this.state.shippingAddress.country} onChange={(e) => this.changeFormdataValues(e, 'country', 'shippingAddress')} as="select">
							<option>{t('selectCountry')}.</option>
							{countries.map((country, index) => (
								<option key={index}>{country.name}</option>
							))}
						</Form.Control>
						<span className="red">{errors['shipping_addresses[country]']}</span>
					</Form.Group>
					<Form.Group>
						<Form.Label>{t('state')} <span className="red">*</span></Form.Label>
						<Form.Control value={this.state.shippingAddress.state} as="select" onChange={(e) => this.changeFormdataValues(e, 'state', 'shippingAddress')}>
							<option>{t('selectRegion')}.</option>
							{statesArray.shippingAddress.states.map((state, index) => (
								<option key={index}>{state.name}</option>
							))}
						</Form.Control>
						<span className="red">{errors['shipping_addresses[state]']}</span>
					</Form.Group>

					<Form.Group>
						<Form.Label>{t('city')} <span className="red">*</span></Form.Label>
						<Form.Control value={this.state.shippingAddress.city} as="select" onChange={(e) => this.changeFormdataValues(e, 'city', 'shippingAddress')}>
							<option>{t('selectRegion')}.</option>
							{citiesArray.shippingAddress.cities.map((city, index) => (
								<option key={index}>{city.name}</option>
							))}
						</Form.Control>
						<span className="red">{errors['shipping_addresses[city]']}</span>
					</Form.Group>

					<Form.Group>
						<Form.Label>{t('zipCode')} <span className="red">*</span></Form.Label>
						<Form.Control value={this.state.shippingAddress.postal_code} onChange={(e) => this.changeFormdataValues(e, 'postal_code', 'shippingAddress')} type="text" />
						<span className="red">{errors['shipping_addresses[postal_code]']}</span>
						<span className="red">{errors['shipping_addresses[id]']}</span>
					</Form.Group>
					<Form.Group>
						<Form.Check
							custom
							checked={(this.state.shippingAddress.default_address == 1) ? true : false}
							type={"checkbox"}
							onClick={(e) => this.changeFormdataValues(e, 'default_address', 'shippingAddress', 'checkbox')}
							label={"Set as default address"}
							id={"default_address"}
							style={{ paddingLeft: 20 }}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>{t('email')} <span className="red">*</span></Form.Label>
						<Form.Control value={this.state.shippingAddress.email_id} onChange={(e) => this.changeFormdataValues(e, 'email_id', 'shippingAddress')} type="email" />
						<span className="red">{errors['shipping_addresses[email_id]']}</span>
					</Form.Group>
					<Form.Group>
						<Form.Label>{t('mobile')} <span className="red">*</span></Form.Label>
						<Form.Control value={this.state.shippingAddress.mobile_no} onChange={(e) => this.changeFormdataValues(e, 'mobile_no', 'shippingAddress')} type="text" />
						<span className="red">{errors['shipping_addresses[mobile_no]']}</span>
					</Form.Group>
				</Form>
				{showBillingAddress == true && (
					<React.Fragment>
						<hr className="mt-3 mb-3" />
						<h6 className="bold black mb-3">{t('billingAddress')}</h6>
						<Form.Group>
							<Form.Check
								custom
								defaultChecked={this.state.checked}
								onClick={(e) => this.updateStateList(e)}
								type={"checkbox"}
								label={"Same as Shipping Address"}
								id={"billing_check"}
								style={{ paddingLeft: 20 }}
							/>
						</Form.Group>
						{(checked == false) &&
							<Form className="fields-group-md mb-4">
								<Form.Group>
									<Form.Label>{t('name')} <span className="red">*</span></Form.Label>
									<Form.Control

										onChange={(e) => this.changeFormdataValues(e, 'name', 'billingAddress')}
										value={this.state.billingAddress.name} type="text" />
									<span className="red">{errors['billing_addresses[name]']}</span>
								</Form.Group>
								<Form.Group>
									<Form.Label>{'Address 1'} <span className="red">*</span></Form.Label>
									<Form.Control
										value={this.state.billingAddress.street_address} onChange={(e) => this.changeFormdataValues(e, 'street_address', 'billingAddress')} type="text" />
									<span className="red">{errors['billing_addresses[street_address]']}</span>
								</Form.Group>
								<Form.Group>
									<Form.Label>{"Address 2"} <span className="red">*</span></Form.Label>
									<Form.Control as="textarea"
										value={this.state.billingAddress.full_address} onChange={(e) => this.changeFormdataValues(e, 'full_address', 'billingAddress')} type="text" />
									<span className="red">{errors['billing_addresses[full_address]']}</span>
								</Form.Group>
								<Form.Group>
									<Form.Label>{t('country')} <span className="red">*</span></Form.Label>
									<Form.Control id='billingCountry' value={this.state.billingAddress.country} onChange={(e) => this.changeFormdataValues(e, 'country', 'billingAddress')} as="select">
										<option>{t('selectCountry')}.</option>
										{countries.map((country, index) => (
											<option key={index}>{country.name}</option>
										))}
									</Form.Control>
									<span className="red">{errors['billing_addresses[country]']}</span>
								</Form.Group>
								<Form.Group>
									<Form.Label>{t('state')} <span className="red">*</span></Form.Label>
									<Form.Control value={this.state.billingAddress.state} as="select" onChange={(e) => this.changeFormdataValues(e, 'state', 'billingAddress')}>
										<option>{t('selectState')}.</option>
										{statesArray.billingAddress.states.map((state, index) => (
											<option key={index}>{state.name}</option>
										))}
									</Form.Control>
									<span className="red">{errors['billing_addresses[state]']}</span>
								</Form.Group>
								<Form.Group>
									<Form.Label>{t('city')} <span className="red">*</span></Form.Label>
									<Form.Control value={this.state.shippingAddress.city} as="select" onChange={(e) => this.changeFormdataValues(e, 'city', 'billingAddress')}>
										<option>{t('selectRegion')}.</option>
										{citiesArray.billingAddress.cities.map((city, index) => (
											<option key={index}>{city.name}</option>
										))}
									</Form.Control>
									<span className="red">{errors['billingAddress[city]']}</span>
								</Form.Group>
								<Form.Group>
									<Form.Label>{t('zipCode')} <span className="red">*</span></Form.Label>
									<Form.Control value={this.state.billingAddress.postal_code} onChange={(e) => this.changeFormdataValues(e, 'postal_code', 'billingAddress')} type="text" />
									<span className="red">{errors['billing_addresses[postal_code]']}</span>

								</Form.Group>
								<Form.Group>
									<Form.Label>{t('email')} <span className="red">*</span></Form.Label>
									<Form.Control onChange={(e) => this.changeFormdataValues(e, 'email_id', 'billingAddress')}
										value={this.state.billingAddress.email_id} type="email" />
									<span className="red">{errors['billing_addresses[email_id]']}</span>
								</Form.Group>
								<Form.Group>
									<Form.Label>{t('mobile')} <span className="red">*</span></Form.Label>
									<Form.Control
										value={this.state.billingAddress.mobile_no} onChange={(e) => this.changeFormdataValues(e, 'mobile_no', 'billingAddress')} type="text" />
									<span className="red">{errors['billing_addresses[mobile_no]']}</span>
								</Form.Group>
							</Form>
						}
					</React.Fragment>
				)}

				<div className="text-center">
					<Button
						style={this.props.styles.primary_btn}
						className="btn btn-md"
						onClick={() => this.handleSubmit()}
					> {typeof this.props.shippingAddress.id != 'undefined' ? t('updateAddress') : 'Save The Address'}
					</Button>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.users.user,
		settings: state.settings.settings,
	}
}

export default withTranslation()(connect(mapStateToProps, null)(Addresses));


