import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Filter from '../components/Filter';
import VariationFilter from '../components/VariationFilter';
import { getBreadcrumbsArray } from '../../../../core/helpers/BreadcrumbsHelper';

const PreBooking = () => {

    let [filters, setFilters] = useState([]);
    let [products, setproducts] = useState([]);

    useEffect(() => {
        console.log("use effect ran");
        // async function getBreadcrumbs() {
        //     var breadcrumbs = await getBreadcrumbsArray({ path: '/pre-booking?/:bikdId', url: '/pre-booking', params: {} });
        //     // console.log(this.props.match);
        //     // this.setState({ breadcrumbs: breadcrumbs });
        // }
        // getBreadcrumbs();

        async function getFilters() {
            await axios.get("http://bhaskar.faberlounge.com/masters/v1/product_type_field_sections_v1?' '&category_slug=bikes")
                .then((res) => {
                    console.log(res)
                    console.log("filters = " + filters)
                    setFilters(filters = [...res.data.data]);
                    console.log(filters);
                })
                .catch(err => console.log(err))
        }

        async function getProducts() {
            await axios.get("http://bhaskar.faberlounge.com/products/v1/products_v1/index?&where[fl_item_categories.category_slug]=bikes&where[fl_item_categories.sub_category_slug]=bikes&where[fl_items.prebooking]=1")
                .then((res) => {
                    console.log(res);
                    // setproducts(products = [...res.data.data]);
                })
                .catch(err => console.log(err));
        }

        getFilters();
        getProducts();

    }, [])

    return (
        <>
            <div className='pre-booking-main-div'>

                <h1>Pre booking page</h1>

                <div className="row">

                    <div className="col-lg-3 d-none d-lg-block">

                        <Filter
                            productTypeFilters={filters}
                            changeFilters={(values) => this.changeFilters(values)}
                        />
                    </div>

                </div>

                <div className="d-block d-lg-none sticky_btn_bar">
                    <a data-toggle="modal" data-target="#filter-modal" className="text-uppercase black sticky_btn">
                        <i className="fal fa-filter"></i>Filter
                    </a>
                    <a data-toggle="modal" data-target="#sort-modal" className="text-uppercase black sticky_btn">
                        <i className="fal fa-sort"></i> Sort by
                    </a>
                </div>


                <VariationFilter />

            </div>
        </>
    )
}

export default PreBooking